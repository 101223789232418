import { Form, FormInstance, Input, Modal } from "antd"
import React from "react"
import { useState } from "react";
import { useRef } from "react"
import { request } from '../../../../../../utils/request';
import { apis } from '../../../../../../utils/apis';
import { InfoChannelSelector } from '../../../../CaseManager/components/CaseEditor/components/InfoChannelSelector';
interface Props {
    visible: boolean;
    template: any,
    onCancel: () => void;
    onCaseCreate: (c: any) => void;
    floorId: number;
}

export const AddCaseModal = (props: Props) => {

    const formRef = useRef<FormInstance>();

    const [loading, setLoading] = useState(false);

    
    const onCaseCreate = async (data: any) => {
        setLoading(true);
        data.caseFloorId = props.floorId;
        const res = await request.post(apis.caseTemplateInstance, data);
        if(res.code === 1) {
            res.data.rest_infoChannel = data.infoChannel;
            props.onCaseCreate(res.data);
        }
        setLoading(false);
    }

    return (
        <Modal
            title={"创建" + props.template?.name + '房型的房间'}
            centered
            visible={props.visible}
            onOk={() => {
                formRef.current?.submit()
            }}
            okButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            onCancel={props.onCancel}
            cancelButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            width={400}
            destroyOnClose
        >
            <Form
                ref={formRef}
                labelCol={{span: 5}}
                initialValues={{
                    templateId: props.template?.id,
                    infoChannel: "1A",
                }}
                onFinish={onCaseCreate}
            >
                <Form.Item name="templateId" hidden />
                <Form.Item label="房间号" name="name" rules={[{required: true, message: "请输入房型名称"}]}>
                    <Input placeholder="请输入房型名称" autoComplete="off" autoFocus/>
                </Form.Item>
                <Form.Item label="信道" name="infoChannel" rules={[{required: true, message: "请选择信道"}]}>
                    <InfoChannelSelector mode="edit"/>
                </Form.Item>
            </Form>
        </Modal>
    )

}