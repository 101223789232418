import { Checkbox } from "antd";
import { useEffect, useState } from "react"
import { apis } from "../../../utils/apis"
import { request } from "../../../utils/request"

interface Props {
    value?: string,
    onChange?: (value?: string) => void;
}

export function TenantChannelSelector(props: Props) {

    const [ tenantChannels, setTenantChannels ] = useState<any[]>([]);
    const [value, setValue]= useState<string>(props.value);

    useEffect(() => {
        request.get(apis["/upms/tenant/channel/list"])
            .then((res) => {
                if(res.code === 1) {
                    setTenantChannels(res.data);
                }
            })
    }, []);

    return (
        <div>
        {
            tenantChannels.length > 0 && tenantChannels.map((item) => {
                return (
                    <div className="flex center" style={{justifyContent: 'space-between', padding: '3px 0'}} key={item.key} onClick={() => {
                        if(value === item.key && value) {
                            setValue(undefined);
                            props.onChange(undefined);
                        }else {
                            setValue(item.key);
                            props.onChange && props.onChange(item.key);
                        }
                    }}> 
                        <span>
                        <Checkbox checked={item.key === props.value} style={{marginRight: 10}}/>
                        <span>{item.name}</span>
                        </span>
                        {
                            item.parent && (
                                <span>所属: {item.parentChannel?.name}</span>
                            )
                        }
                    </div>
                )
            })
        }
        </div>
    )

}