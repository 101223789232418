import { CheckOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Skeleton, Spin, Tooltip } from "antd";
import { forwardRef, useEffect, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface HexProjectProps {
    selectedHexId?: number;
    onHexProjectSelected: (hex: any) => void;
}

const HexSelector = forwardRef((props: HexProjectProps, ref: any) => {

    const [loading, setLoading] = useState(false);
    const [hexProjects, setHexProjects] = useState([] as any[]);
    const [hexGroups, setHexGroups] = useState([] as any[]);
    const [cates, setCates] = useState([] as any[]);
    const [selectedCateId, setSelectedCateId] = useState(0);
    const [data, setData] = useState([] as any[]);

    useEffect(() => {
        getHexProjects();
    }, []);

    const getHexProjects = async () => {
        setLoading(true);
        try {
            const [res1, res2] = await Promise.all([
                request.get(apis.hexProject),
                request.get(apis.hexGroup)
            ]);
            if (res1.code === 1) {
                setHexProjects(res1.data);
            }
            if (res2.code === 1) {
                setHexGroups(res2.data);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (hexProjects.length === 0 || hexGroups.length === 0) {
            return;
        }
        for (let i = 0; i < hexProjects.length; i++) {
            const project = hexProjects[i];
            project.groups = hexGroups.filter((h) => h.projectId === project.projectId);
            project.selectedGroupId = project.groups[0]?.id;
            project.selectedGroupName = project.groups[0]?.name;
            project.selectedVersionId = 0;
            project.selectedVersionName = "智能跟随最新版";
        }
        setData(hexProjects);
    }, [hexProjects, hexGroups])


    useEffect(() => {
        const cateIdSet = new Set<number>();
        const cates: any[] = [{
            id: 0,
            name: "全部"
        }];
        for (let i = 0; i < data.length; i++) {
            const hex = data[i];
            if (!cateIdSet.has(hex.cateId)) {
                cateIdSet.add(hex.cateId);
                cates.push({
                    id: hex.cateId,
                    name: hex.cateName,
                });
            }
        }
        setCates(cates);
    }, [data]);


    const [displayHexs, setDisplayHexs] = useState([] as any[]);

    useEffect(() => {
        if (selectedCateId === 0) {
            setDisplayHexs(data);
        } else {
            const displayHexs = data.filter((hp) => {
                return hp.cateId === selectedCateId;
            });
            setDisplayHexs(displayHexs);
        }
    }, [data, selectedCateId]);

    const [selectedHexId, setSelectedHexId] = useState(props.selectedHexId || 0);
    useEffect(() => {
        if (!selectedHexId) {
            props.onHexProjectSelected(null);
        } else {
            const p = hexProjects.find((h) => h.projectId === selectedHexId);
            props.onHexProjectSelected(p);
        }
    }, [selectedHexId]);


    const [groupLoading, setGroupLoading] = useState(false);
    const [hexLoading, setHexLoading] = useState(false);

    const [groupVersionsMap, setGroupVersionsMap] = useState({} as any);
    const getHexVersion = async (hexGroupId: number) => {
        setHexLoading(true);
        try {

            const res = await request.get(apis.hexVersion + `/search`, { hexGroupId });
            if (res.code === 1) {
                groupVersionsMap[hexGroupId] = res.data;
            }
            setGroupVersionsMap({ ...groupVersionsMap });
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <Skeleton active />
        )
    }

    return (
        <div className="hex-project-select">
            <div className="title bold" style={{ paddingBottom: 10, fontSize: 15 }}>请选择要关联的硬件产品</div>
            <div className="flex" style={{ padding: '8px 0 16px 0', alignItems: 'center' }}>
                <div style={{ width: '4em' }}>分类: </div>
                <div className="flex">
                    {
                        cates.length > 0 && cates.map((c) => {
                            return (
                                <div
                                    className="cate-item"
                                    style={{ marginLeft: 20, padding: '3px 16px', background: selectedCateId === c.id ? 'rgba(0, 0, 0, .07)' : '', borderRadius: 6, cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedCateId(c.id);
                                    }}
                                >{c.name}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="hex-project-list" style={{ padding: '10px 0' }}>
                {
                    displayHexs.length > 0 && displayHexs.map((hex, index) => {
                        return (
                            <div
                                key={"hex-" + index}
                                className="hex-project-item"
                                style={{ position: 'relative', boxShadow: '0 3px 10px rgba(0, 0, 0, .1)', margin: '0 auto', width: 600, marginBottom: 10, padding: '10px 24px', cursor: 'pointer' }}
                                onClick={() => {
                                    setSelectedHexId(hex.projectId);
                                }}
                            >
                                {
                                    selectedHexId === hex.projectId && (
                                        <div
                                            className="flex center bg-primary"
                                            style={{ position: 'absolute', bottom: 12, left: 10, width: 20, height: 20, borderRadius: 10 }}

                                        >
                                            <CheckOutlined style={{ color: "#fff" }} />
                                        </div>
                                    )
                                }
                                <span style={{ fontSize: 16, marginLeft: 16 }}>{hex.projectName}</span>
                                {
                                    selectedHexId === hex.projectId && (

                                        <Dropdown trigger={["click"]} onVisibleChange={(visible) => {
                                            if (visible) {
                                                getHexVersion(hex.selectedGroupId);
                                            }
                                            return visible;
                                        }} overlay={(
                                            <Menu selectedKeys={["version-" + hex.selectedVersionId]}>
                                                <Menu.Item key={"version-" + 0} onClick={() => {
                                                        hex.selectedVersionId = 0;
                                                        hex.selectedVersionName = "智能跟随最新版";
                                                        setData([...data]);
                                                    }}>
                                                    <span className="bold">智能跟随最新版</span>
                                                </Menu.Item>
                                                {
                                                    groupVersionsMap[hex.selectedGroupId]?.map((item) => {
                                                        return (
                                                            <Menu.Item key={"version-" + item.id} onClick={() => {
                                                                hex.selectedVersionId = item.id;
                                                                hex.selectedVersionName = item.name;
                                                                setData([...data]);
                                                            }}>
                                                                <span className="bold">{item.name}</span>, {item.description}
                                                            </Menu.Item>
                                                        )
                                                    })
                                                }
                                            </Menu>
                                        )}>
                                            <span style={{ float: 'right' }}>
                                                HEX版本：<Button type="link">{hex.selectedVersionName}</Button>
                                            </span>
                                        </Dropdown>
                                    )
                                }
                                {
                                    hex.groups?.length > 1 && (

                                        <Dropdown trigger={["click"]} overlay={(
                                            <Menu >
                                                {
                                                    hex.groups?.map((g) => {
                                                        return (
                                                            <Menu.Item key={"group-" + g.id} onClick={() => {
                                                                hex.selectedGroupId = g.id;
                                                                hex.selectedGroupName = g.name;
                                                                setData([...data]);
                                                            }}>{g.name}</Menu.Item>
                                                        )
                                                    })
                                                }
                                            </Menu>
                                        )}>
                                            <span style={{ float: 'right' }}>
                                                HEX组：<Button type="link">{hex.selectedGroupName || '请选择HEX分组'} </Button>
                                            </span>
                                        </Dropdown>
                                    )
                                }
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )

})

export default HexSelector;