import { message, Modal, Radio, Skeleton } from "antd"
import { useEffect } from "react"
import { request } from '../../../../../../utils/request';
import { apis } from '../../../../../../utils/apis';
import { useState } from "react";
import QRCode from 'qrcode.react';

interface Props {
    visible: boolean,
    customer: any,
    onCancel: () => void,
}

export const HotelDeliverQrcode = (props: Props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!props.visible) {
            return ;
        }
        if (props.customer?.id) {
            init();
        }
    }, [props.visible]);

    const init = async () => {
        setLoading(true);
        getCustomerHotel();
    }

    const [hotel, setHotel] = useState(null as any);
    const getCustomerHotel = async () => {
        console.log("getCustomerHotel");
        const res = await request.get(apis.caseCustomerHotel + `/${props.customer?.id}`);
        if (res.code === 1) {
            setHotel(res.data);
        }
    }

    const [billingStrategies, setBillingStrategies] = useState([] as any[]);
    const getBillingStrategy = async () => {
       
        const res = await request.get(apis.hotelBillingStrategy)
        if (res.code === 1) {
            setBillingStrategies(res.data);
        }
    }

    useEffect(() => {
        console.log("hotel", hotel);
        if(hotel) {

            getBillingStrategy();
        }
    }, [hotel]);

    const [selectedStrategy, setSelectedStrategy] = useState(null as any);
    const [ticket, setTicket] = useState(null as any);
    const getTicket = async () => {
        if(!hotel) {
            return ;
        }
        if (!selectedStrategy) {
            message.info("请选择计费策略");
            return ;
        }
        const res = await request.get(apis.hotelDelivery + `/${hotel.id}`, {
            billingStrategyId: selectedStrategy.id,
        });
        if (res.code === 1) {
            console.log(res.data);
            
            setTicket(res.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!selectedStrategy) {
            if (billingStrategies.length > 0) {
                setSelectedStrategy(billingStrategies[0]);
            }
        }
    }, [billingStrategies]);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
        }
        getTicket();
    }, [selectedStrategy]);

    return (
        <Modal
            visible={props.visible}
            centered
            title={props.customer?.name}
            onCancel={props.onCancel}
        >
            {
                loading ? (
                    <Skeleton active/>
                ) : (
                    <div>
                        {hotel?.name}
                        <div>
                            <Radio.Group>
                            {
                                billingStrategies.length > 0 && billingStrategies.map((item) => {
                                    return (
                                        <Radio>
                                            {item.desc}
                                        </Radio>
                                    )
                                })
                            }
                            </Radio.Group>
                        </div>
                        {
                            ticket && (
                                <QRCode value={"https://develop.huafeiiot.com/qrc?key=" + ticket.lastCode} renderAs="canvas" />
                            )
                        }
                    
                    </div>
                )
            }
        </Modal>
    )

}