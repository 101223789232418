import { CloudFilled } from '@ant-design/icons';
import React from 'react'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingAccepts: string;
    onSetBindingSource?: (app: any) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (item: any) => void;
    onViewBindRelations?: (item: any) => void;
    onCancelView?: () => void;
}

export const OfflineSpeakerNodeCell = (props: Props) => {


    const { node } = props;
    // const [sourceDisabled, setSourceDisabled] = useState(false);
    // const [sourceSelected, setSourceSelected] = useState(false);


    return (
        <div className="node-cell curtain-control-node-cell">
            <div className="one-switch">
                <div className="switch-item">离线音箱</div>
            </div>
            <div>
            </div>
            <div className="prod-name">
            {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
                
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )

}