import { useEffect, useState } from "react";
import { ControlLayout, ControlWidget } from "./Connector";
import ControlProductCellWrapper, { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";
import { Switch, SwitchCellWrapper } from "./SwitchCell";

interface Props extends SwitchCellWrapper {
    silk1: { cn?: string, en?: string };
    silk2: { cn?: string, en?: string };
    silk3: { cn?: string, en?: string };
}

const ThreeSwitchCell = (props: Props) => {

    const [sources, setSources] = useState([] as any[]);
    const [targets, setTargets] = useState([] as any[]);

    const onDataChange = (sources: any[], targets: any[]) => {
        setSources(sources);
        setTargets(targets);
    }

    const [threeTargets, setThreeTargets] = useState(targets);
    useEffect(() => {
        const threeTargets: any[] = [8, 9, 10];
        for (let i = 0; i < threeTargets.length; i++) {
            const item = threeTargets[i];
            const target = targets.find((t) => t.endpoint === item);
            threeTargets[i] = target;
        }
        setThreeTargets(threeTargets);
    }, [targets]);

    return (
        <ControlProductCellWrapper
            {...props}
            style={{ background: props.backgroundColor || "#6a6867" }}
            onDataChange={onDataChange}
            controlWidgets={(
                <ControlLayout
                    layout="3-bottom|bottom|bottom"
                >
                    <ControlWidget
                        source={sources[0]}
                        target={threeTargets[0]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[1]}
                        target={threeTargets[1]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[2]}
                        target={threeTargets[2]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                </ControlLayout>
            )}
        >
            <div className="flex" style={{ height: "100%" }}>
                <Switch
                    border
                    width={79}
                    bottom={{ ...props.silk1, number: 0, endpoints: [sources[0], threeTargets[0]] }}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
                <Switch
                    border
                    width={79}
                    bottom={{ ...props.silk2, number: 1, endpoints: [sources[1], threeTargets[1]] }}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
                <Switch
                    width={80}
                    bottom={{ ...props.silk3, number: 2, endpoints: [sources[2], threeTargets[2]] }}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
            </div>
        </ControlProductCellWrapper>
    )
}
export default ThreeSwitchCell;

