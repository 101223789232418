import { Form, Input, message } from "antd";
import { forwardRef } from "react";
import ModalForm from "../../../../components/ModalForm";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

interface AddUserProps {
    onUserAdd: (user: any) => void;
}

export const AddUserModal = forwardRef((props: AddUserProps, ref: any) => {

    const onSubmit = async (data: any) => {
        if(!data.phoneNumber && !data.email) {
            message.warn("至少填写手机号、邮箱中的一项");
            return ;
        }
        if(!data.password) {
            message.warn("请输入密码");
            return ;
        }
        if(data.password !== data.repeatPassword) {
            message.warn("两次输入的密码不一致");
            return ;
        }
        return request.post(apis["/upms/user/create"], data)
            .then((res) => {
                if(res.code === 1) {
                    props.onUserAdd(res.data);
                    return true;
                }
            })
    }

    return (
        <ModalForm
            ref={ref}
            title={"添加用户"}
            labelCol={{span: 4}}
            onSubmit={onSubmit}
        >
            <Form.Item
                label="昵称"
                name="nickName"
                rules={[{required: true}]}
            >
                <Input placeholder="请输入用户昵称" autoComplete="off" autoFocus={false}/>
            </Form.Item>
            <Form.Item
                label="手机号"
                name="phoneNumber"
            >
                <Input placeholder="请输入手机号" autoComplete="off"/>
            </Form.Item>
            <Form.Item
                label="邮箱"
                name="email"
            >
                <Input placeholder="请输入邮箱" autoComplete="off"/>
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[{required: true}]}
            >
                <Input type="password" placeholder="请输入密码"/>
            </Form.Item>
            <Form.Item
                label="重复密码"
                name="repeatPassword"
                rules={[{required: true}]}
            >
                <Input type="password" placeholder="请重复密码"/>
            </Form.Item>
        </ModalForm>
    )
})