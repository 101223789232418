import { CloudFilled, EditOutlined, NodeIndexOutlined, WifiOutlined } from '@ant-design/icons';
import { message, Form, Modal, FormInstance, Input, Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { apis } from '../../../../../../../utils/apis';
import { request } from '../../../../../../../utils/request';
import { SourceConnector, TargetConnector } from '../NodeCellConnector';
// import '

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingTargets: any[];
    bindingAccepts: string;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onNodeAppUpdate?: (nodeApp: any) => void;
    endpointPairMap: { [endpoint: number]: number };
    onRenameApp: (app: any) => void;
    className?: string;
    viewSource: any;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}


const renameFormRef = React.createRef<FormInstance>();


const endpointPairMap: any = {
    1: 8,
    2: 9,
    3: 10,
    4: 11,
    5: 12,
    6: 13,
    8: 1,
    9: 2,
    10: 3,
    11: 4,
    12: 5,
    13: 6,
}

export const SixButtonSwitch1C3SNodeCell = (props: Props) => {
    const [node, setNode] = useState(props.node);
    const [switchs, setSwitchs] = useState([] as any[]);

    useEffect(() => {
        const _switchs: any[] = [];
        const endpointOk: any = {};
        for (const endpoint in endpointPairMap) {
            endpointOk[endpoint] = false;
        }
        for (let i = 0; i < node.apps.length; i++) {
            const app = node.apps[i];
            if (app.endpoint > 11) {
                continue;
            }
            if (endpointOk[app.endpoint]) {
                continue;
            }
            endpointOk[app.endpoint] = true;
            endpointOk[endpointPairMap[app.endpoint]] = true;
            let sourceEndpoint;
            let targetEndpoint;
            if (app.endpoint <= 6) {
                sourceEndpoint = app.endpoint;
                targetEndpoint = endpointPairMap[app.endpoint];
            } else {
                sourceEndpoint = endpointPairMap[app.endpoint];
                targetEndpoint = app.endpoint;
            }
            const targetApp = node.apps.find((_app: any) => _app.endpoint === endpointPairMap[app.endpoint]);
            _switchs.push({
                appId: app.id,
                name: app.name,
                sourceApp: app,
                targetApp,
                sourceEndpoint,
                targetEndpoint
            })
        }
        setSwitchs(_switchs);
        setNode(props.node);
    }, [props.node]);


    const [sourceDisabledMap, setSourceDisabledMap]: any = useState({});
    const [sourceSelectedMap, setSourceSelectedMap]: any = useState({});
    const [targetDisabledMap, setTargetDisabledMap]: any = useState({});
    const [targetSelectedMap, setTargetSelectedMap]: any = useState({});


    useEffect(() => {
        if (!props.node) {
            return;
        }
        if (props.bindMode === "wait") {
            //source部分
            let sourceUpdate = false;
            for (let i = 0; i < props.node.apps.length; i++) {
                const app = props.node.apps[i];
                if (app.type?.indexOf("target") > -1) {
                    continue;
                }
                if (sourceDisabledMap[app.id]) {
                    sourceDisabledMap[app.id] = false;
                    sourceUpdate = true;
                }
                if (sourceSelectedMap[app.id]) {
                    sourceSelectedMap[app.id] = false;
                    sourceUpdate = true;
                }
            }
            if (sourceUpdate) {
                setSourceDisabledMap({ ...sourceDisabledMap });
                setSourceSelectedMap({ ...sourceSelectedMap });
            }
            //target部分
            let targetUpdate = false;
            for (let i = 0; i < props.node.apps.length; i++) {
                const app = props.node.apps[i];
                if (app.type === "source") {
                    continue;
                }
                if (!targetDisabledMap[app.id]) {
                    targetDisabledMap[app.id] = true;
                    targetUpdate = true;
                }
                if (targetSelectedMap[app.id]) {
                    targetSelectedMap[app.id] = false;
                    targetUpdate = true;
                }
            }
            if (targetUpdate) {
                setTargetDisabledMap({ ...targetDisabledMap });
                setTargetSelectedMap({ ...targetSelectedMap });
            }
        } else if (props.bindMode === "targets" || props.bindMode === "view") {
            //source
            for (let i = 0; i < props.node.apps.length; i++) {
                const app = props.node.apps[i];
                if (app.type?.indexOf("target") > -1) {
                    continue;
                }
                if (app.id !== (props.bindMode === "view" ? props.viewSource : props.bindingSource)?.id) {
                    sourceDisabledMap[app.id] = true;
                } else {
                    sourceDisabledMap[app.id] = false;
                    sourceSelectedMap[app.id] = true;
                }
            }
            //target
            const isScene = props.bindingTargets.length > 0 && props.bindingTargets[0].type === "scene";
            for (let i = 0; i < props.node.apps.length; i++) {
                const nodeApp = props.node.apps[i];
                if (nodeApp.type?.indexOf("target") > -1) {
                    continue;
                }
                let has = false;
                const targetApp = props.node.apps.find((_app: any) => _app.endpoint === endpointPairMap[nodeApp.endpoint]);
                if (!targetApp) {
                    continue;
                }
                let expectKey = targetApp.key;
                //针对人体存在的情况
                if (!(props.bindingAccepts.indexOf("not") > -1)) {
                    expectKey = props.bindingAccepts;
                }
                if (expectKey !== targetApp.key) {
                    targetDisabledMap[targetApp.id] = true;
                } else {
                    if (!isScene) {
                        targetDisabledMap[targetApp.id] = false;
                    } else {
                        targetDisabledMap[targetApp.id] = true;
                    }
                    targetSelectedMap[targetApp.id] = false;
                    if (!isScene) {
                        for (let j = 0; j < props.bindingTargets.length; j++) {
                            const item = props.bindingTargets[j];
                            const _targetApp = item.target;
                            if (_targetApp.id === targetApp.id) {
                                targetSelectedMap[targetApp.id] = true;
                                has = true;
                                break;
                            }
                        }
                    }
                }

            }
            setSourceDisabledMap({ ...sourceDisabledMap });
            setTargetDisabledMap({ ...targetDisabledMap });
            setTargetSelectedMap({ ...targetSelectedMap });

        }
    }, [props.bindingSource, props.bindingTargets, props.bindingAccepts, props.node, props.bindMode]);


    const onUpdateNodeAppIsWireless = (app: any, isWireless: -1 | 1) => {
        request.put(apis.caseNodeApp + `/${app.id}`, {
            isWireless
        })
            .then((res) => {
                if (res.code === 1) {
                    if (typeof props.onNodeAppUpdate === 'function') {
                        props.onNodeAppUpdate(res.data);
                        if (res.data.isWireless) {
                            if (typeof props.onRemoveBindingTarget === 'function') {
                                props.onRemoveBindingTarget("app", res.data);
                            }
                        }
                    }
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }

    const [renameModalVisible, setRenameModalVisible] = useState(false);
    const [renameTemp, setRenameTemp] = useState("");
    const [renameApp, setRenameApp] = useState<any>();
    const openRenameAppModal = (app: any) => {
        if (!app) {
            return;
        }
        setRenameModalVisible(true);
        setRenameTemp(app.name);
        setRenameApp(app);
    }
    const closeRenameAppModal = () => {
        setRenameModalVisible(false);
        setRenameTemp("");
        setRenameApp(undefined);
    }

    const onRenameApp = (data: any) => {
        if (data.name === renameApp.name) {
            closeRenameAppModal();
            return;
        }
        request.put(apis.caseNodeApp + `/${data.id}`, data)
            .then((res) => {
                if (res.code === 1) {
                    if (typeof props.onRenameApp === 'function') {
                        closeRenameAppModal();
                        props.onRenameApp(res.data);
                        message.success("已修改名称");
                    }
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }

    return (
        <div className="node-cell three-button-switch-node-cell">
            <Modal
                centered
                title="重命名"
                visible={renameModalVisible}
                onOk={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    renameFormRef.current?.submit();
                }}
                onCancel={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    closeRenameAppModal();
                }}
                width={300}
                destroyOnClose
            >
                <Form
                    ref={renameFormRef}
                    initialValues={{
                        name: renameTemp,
                        id: renameApp?.id
                    }}
                    onValuesChange={(data) => {
                        if ("name" in data) {
                            setRenameTemp(data.name)
                        }
                    }}
                    onFinish={(data) => {
                        const appIds: number[] = [];
                        for (let i = 0; i < node.apps.length; i++) {
                            const app = node.apps[i];
                            if (app.endpoint === props.endpointPairMap[renameApp.endpoint]) {
                                appIds.push(app.id);
                                break;
                            }
                        }
                        data.moreAppIds = appIds;
                        onRenameApp(data);

                    }}
                >
                    <Form.Item hidden name="id"></Form.Item>
                    <Form.Item label="名称" name="name" rules={[{ required: true, message: "请输入名称" }]}>
                        <Input placeholder="请输入名称" autoFocus autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>
            <div className={"six-switch"}>
                <div className='flex'>

                    {
                        switchs.length > 0 && switchs.filter((s) => s.sourceEndpoint > 3).map((_switch: any) => {

                            return (

                                <div className="switch-item" style={{ fontSize: 10, textAlign: 'center', verticalAlign: "top" }}>
                                    <div className="flex-center flex-column flex-1" style={{ width: '100%', height: '100%' }}>
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (_switch.targetApp) {
                                                //有继电器endpoint
                                                openRenameAppModal(_switch.targetApp);
                                            } else {
                                                //无继电器endpoint
                                                openRenameAppModal(_switch.sourceApp);
                                            }
                                        }}>
                                            <span className="name">
                                                {_switch.name}
                                            </span>
                                            {/* <Button size="small" type="link" disabled={props.bindMode !== "wait"}>
                                                <EditOutlined
                                                />
                                            </Button> */}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                                            {
                                                !sourceDisabledMap[_switch.appId] && (
                                                    <SourceConnector
                                                        disabled={sourceDisabledMap[_switch.appId]}
                                                        selected={sourceSelectedMap[_switch.appId]}
                                                        connected={_switch.sourceApp.isSource}
                                                        onClick={() => {
                                                            if (_switch.sourceApp.isSource) {
                                                                if (props.bindMode === "view") {
                                                                    if (typeof props.onCancelView === 'function') {
                                                                        props.onCancelView();
                                                                    }
                                                                } else if (props.bindMode === "wait") {
                                                                    if (typeof props.onViewBindRelations === 'function') {
                                                                        props.onViewBindRelations(_switch.sourceApp, "curtain");
                                                                    }
                                                                }
                                                            } else {
                                                                if (props.bindMode === "wait") {
                                                                    if (typeof props.onSetBindingSource === "function") {
                                                                        props.onSetBindingSource(_switch.sourceApp, "curtain");
                                                                    }
                                                                } else if (props.bindMode === "targets") {
                                                                    if (typeof props.onResetBindingSource === "function") {
                                                                        props.onResetBindingSource();
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                            {
                                                _switch.targetApp?.isWireless === -1 && !targetDisabledMap[_switch.targetApp.id] ? (
                                                    <TargetConnector
                                                        disabled={targetDisabledMap[_switch.targetApp.id]}
                                                        selected={targetSelectedMap[_switch.targetApp.id]}
                                                        onClick={(disabled?: boolean, selected?: boolean) => {
                                                            if (props.bindMode === "targets" || props.bindMode === "view") {
                                                                if (!selected) {
                                                                    if (typeof props.onAddBindingTarget === 'function') {
                                                                        props.onAddBindingTarget("app", _switch.targetApp);
                                                                    }
                                                                } else {
                                                                    if (typeof props.onRemoveBindingTarget === 'function') {
                                                                        props.onRemoveBindingTarget("app", _switch.targetApp);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                ) : undefined
                                            }
                                        </div>

                                        <div style={{ marginTop: 5 }}>
                                            {
                                                !_switch.targetApp && (
                                                    <div style={{ color: '#aaa' }}>无继电器</div>
                                                )
                                            }
                                            {
                                                _switch.targetApp?.isWireless === 1 && (
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onUpdateNodeAppIsWireless(_switch.targetApp, -1);
                                                        }}
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: '0px 5px', cursor: 'pointer' }}>
                                                        {/* <WifiOutlined /> */}
                                                        无线
                                                    </div>
                                                )
                                            }
                                            {
                                                _switch.targetApp?.isWireless === -1 && (
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onUpdateNodeAppIsWireless(_switch.targetApp, 1);
                                                        }}
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "purple", fontWeight: "bold", borderRadius: 5, padding: '0px 5px', cursor: 'pointer' }}>
                                                        {/* <NodeIndexOutlined /> */}
                                                        控制线
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='flex'>

                    {
                        switchs.length > 0 && switchs.filter((s) => s.sourceEndpoint < 4).map((_switch) => {
                            return (

                                <div className="switch-item" style={{ fontSize: 10, textAlign: 'center', verticalAlign: "top" }}>
                                    <div className="flex-center flex-column flex-1" style={{ width: '100%', height: '100%' }}>
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (_switch.targetApp) {
                                                //有继电器endpoint
                                                openRenameAppModal(_switch.targetApp);
                                            } else {
                                                //无继电器endpoint
                                                openRenameAppModal(_switch.sourceApp);
                                            }
                                        }}>
                                            <span className="name">
                                                {_switch.name}
                                            </span>
                                            {/* <Button size="small" type="link" disabled={props.bindMode !== "wait"}>
                                                <EditOutlined
                                                />
                                            </Button> */}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                                            {
                                                !sourceDisabledMap[_switch.appId] && (
                                                    <SourceConnector
                                                        disabled={sourceDisabledMap[_switch.appId]}
                                                        selected={sourceSelectedMap[_switch.appId]}
                                                        connected={_switch.sourceApp.isSource}
                                                        onClick={() => {
                                                            if (_switch.sourceApp.isSource) {
                                                                if (props.bindMode === "view") {
                                                                    if (typeof props.onCancelView === 'function') {
                                                                        props.onCancelView();
                                                                    }
                                                                } else if (props.bindMode === "wait") {
                                                                    if (typeof props.onViewBindRelations === 'function') {
                                                                        props.onViewBindRelations(_switch.sourceApp, "not general_ir_human_sensor");
                                                                    }
                                                                }
                                                            } else {
                                                                if (props.bindMode === "wait") {
                                                                    if (typeof props.onSetBindingSource === "function") {
                                                                        props.onSetBindingSource(_switch.sourceApp, "not general_ir_human_sensor");
                                                                    }
                                                                } else if (props.bindMode === "targets") {
                                                                    if (typeof props.onResetBindingSource === "function") {
                                                                        props.onResetBindingSource();
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                            {
                                                _switch.targetApp?.isWireless === -1 && !targetDisabledMap[_switch.targetApp.id] ? (
                                                    <TargetConnector
                                                        disabled={targetDisabledMap[_switch.targetApp.id]}
                                                        selected={targetSelectedMap[_switch.targetApp.id]}
                                                        onClick={(disabled?: boolean, selected?: boolean) => {
                                                            if (props.bindMode === "targets" || props.bindMode === "view") {
                                                                if (!selected) {
                                                                    if (typeof props.onAddBindingTarget === 'function') {
                                                                        props.onAddBindingTarget("app", _switch.targetApp);
                                                                    }
                                                                } else {
                                                                    if (typeof props.onRemoveBindingTarget === 'function') {
                                                                        props.onRemoveBindingTarget("app", _switch.targetApp);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                ) : undefined
                                            }
                                        </div>

                                        <div style={{ marginTop: 5 }}>
                                            {
                                                !_switch.targetApp && (
                                                    <div style={{ color: '#aaa' }}>无继电器</div>
                                                )
                                            }
                                            {
                                                _switch.targetApp?.isWireless === 1 && (
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onUpdateNodeAppIsWireless(_switch.targetApp, -1);
                                                        }}
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: '0px 5px', cursor: 'pointer' }}>
                                                        {/* <WifiOutlined /> */}
                                                        无线
                                                    </div>
                                                )
                                            }
                                            {
                                                _switch.targetApp?.isWireless === -1 && (
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onUpdateNodeAppIsWireless(_switch.targetApp, 1);
                                                        }}
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "purple", fontWeight: "bold", borderRadius: 5, padding: '0px 5px', cursor: 'pointer' }}>
                                                        {/* <NodeIndexOutlined /> */}
                                                        控制线
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="prod-name">
                {
                    props.node.isOnline && (
                        <CloudFilled />
                    )
                }
                <span>{node.name}</span>

            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )

}