import { Form, Input, message } from "antd";
import { forwardRef } from "react";
import ModalForm from "../../../components/ModalForm";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import { TenantChannelSelector } from "./TenantChannelSelector";

interface Props {

    onTenantCreate: (tenant: any) => void

}

export const CreateTenantModal = forwardRef((props: Props, ref: any) => {

    const onSubmit = async (data: any) => {
        return request.post(apis['/upms/tenant/create'], data)
            .then((res) => {
                if (res.code === 1) {
                    props.onTenantCreate(res.data);
                    message.success("已创建！");
                    return true;
                }
            }); 

    }

    return (

        <ModalForm
            ref={ref}
            title="创建租户"
            onSubmit={onSubmit}
            labelCol={{span: 5}}
        >
            <Form.Item label="租户名称" name={"name"} rules={[{required: true}]}>
                <Input placeholder="请输入租户名称" autoComplete="off" />
            </Form.Item>
            <Form.Item label="租户通道" name={"channelKey"} rules={[{required: true}]}>
                <TenantChannelSelector />
            </Form.Item>
            <Form.Item label="管理员UUID" name={"userUUID"} rules={[{required: true}]} style={{ marginTop: 10 }}>
                <Input placeholder="请输入管理员用户的UUID"/>
            </Form.Item>
        </ModalForm>
    )

})