import { useState } from "react";
import { ControlLayout, ControlWidget } from "./Connector";
import ControlProductCellWrapper, { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";

interface Props extends ControlProductCellWrapperProps {

}

const ControlProductCell = (props: Props) => {

    const [sources, setSources] = useState([] as any[]);
    const [targets, setTargets] = useState([] as any[]);

    const onDataChange = (sources: any[], targets: any[]) => {
        setSources(sources);
        setTargets(targets);
    }

    return (
        <ControlProductCellWrapper 
            {...props}
            imagePrimary={true}
            onDataChange={onDataChange}
            controlWidgets={(
                <ControlLayout>
                    {(sources.length > targets.length ? sources : targets).map((app, index) => {
                        return (
                            <ControlWidget
                                source={sources[index]}
                                target={targets[index]}
                                onSourceClick={props.onSourceClick}
                                onTargetClick={props.onTargetClick}
                                onControlLineClick={props.onControlLineClick}
                                sourceEndpoint={props.bindSourceEndpoint}
                                targetEndpoints={props.bindTargetEndpoints}
                                onEndpointNameChange={props.onEndpointNameChange}
                            />
                        )
                    })}
                </ControlLayout>
            )}
        />
    )
}

export default ControlProductCell;