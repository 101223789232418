import { Form, message } from "antd";
import moment from 'moment';
import React, { createRef, CSSProperties, forwardRef, PropsWithChildren, useImperativeHandle, useState } from "react";
import { sleep } from "../../../utils";
import { apis } from "../../../utils/apis";
import { existsSync, fs, mkdirSync, tarExtra } from "../../../utils/electron";
import { request } from "../../../utils/request";
import { TI_CMD } from "../utils";
import './style.less';

interface HexWriterProps {
    caseId?: number;
    caseNodeId?: number;
    hexFile?: any;
    style?: CSSProperties
    device?: any;
    onStart?: () => void;
    onInterrupt?: () => void;
    onErase?: () => Promise<void>; //清除回调
    onFinish?: (device: any, hexFile: any, macAddr: string) => Promise<void>;
    onEnd?: () => void;
    onCanScan: () => void;
    onEscape?: () => void;
    onDisableScan: () => void;
}

interface State {
    logs: {
        time: moment.Moment,
        content: string,
        color: string
    }[],
}

export default class HexWriter extends React.Component<HexWriterProps, State> {


    constructor(props: Readonly<HexWriterProps>) {
        super(props);
        this.state = {
            logs: [],
        };
    }

    componentDidMount(): void {
        window.addEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = (e: any) => {
        if (e.code === 'Space') {
            if (!(window as any).inElectron) {
                message.warn("浏览器下不能烧录, 请打开华飞物联软件");
                return;
            }
            this.onStartWrite();
        } else if (e.code === 'Escape') {
            //不在过程中时, 可以扫码的
            if (!this.doing) {
                this.props.onCanScan();
                this.props.onEscape();
                this.disabled = true;
            }
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("keydown", this.onKeyDown);
    }

    componentDidUpdate(prevProps: Readonly<HexWriterProps>, prevState: Readonly<State>, snapshot?: any): void {
        let deviceChanged = false;
        if (prevProps.device !== this.props.device) {
            this.device = this.props.device;
            deviceChanged = true;
        }
        let hexFileChanged = false;
        if (prevProps.hexFile !== this.props.hexFile) {
            this.hexFile = this.props.hexFile;
            hexFileChanged = true;
        }
        if (deviceChanged || hexFileChanged) {
            if (this.device && this.hexFile) {
                const { props } = this;
                this.logRef.current.init(`${props.device.deviceCode}，${props.device.spu.name}, ${props.hexFile.fileName}，插入烧录针后，请按空格键开始烧录`, "lightblue");

                this.disabled = false;
                this.resetProgress();
            }
        }
    }

    /**
     * 1、取消禁止烧录
     * 2、并给进度条重置
     */
    resetProgress = () => {
        for (let i = 0; i < this.stepRefs.length; i++) {
            const ref = this.stepRefs[i];
            ref.current.reset();
        }
    }

    eraseRef = createRef<any>();
    macRef = createRef<any>();
    writeRef = createRef<any>();
    verifyRef = createRef<any>();
    finishRef = createRef<any>();
    stepRefs = [this.eraseRef, this.macRef, this.writeRef, this.finishRef/* , this.verifyRef, this.finishRef */];

    logRef = createRef<any>();

    device: any;
    hexFile: any;
    disabled = true;
    doing = false;
    hasBreak = false;   //中断过， 中断后需要烧录时要重置进度条


    addLog = (message: string, type: "node" | "stdout" | "stderr" | "finish") => {
        const messages = message.split("\r\n");
        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if (!message) {
                continue;
            }
            if (type === "stdout") {
                let method;
                if (message === ".") {
                    method = this.logRef.current.tail;
                } else {
                    method = this.logRef.current.add;
                }
                method(message, "#fff");
            } else if (type === "stderr") {
                this.logRef.current.add(message, "#f00");
            } else if (type === "node") {
                this.logRef.current.add(message, "gray");
            } else if (type === "finish") {
                this.logRef.current.add(message, "lightgreen");
            }
        }
    }

    macAddrTemp: string = "";
    onMacAddrRead = (macAddr: string) => {
        this.macAddrTemp = macAddr;
    }

    /**
     * 烧录主控逻辑
     * @returns 
     */
    onStartWrite = async () => {
        if (this.hasBreak) {
            this.resetProgress();
            this.hasBreak = false;
        }
        if (this.disabled) {
            return;
        }
        if (!this.device || !this.hexFile) {
            message.info("未扫码或没选择HEX");
            return;
        }
        this.props.onDisableScan();
        this.disabled = true;
        this.props.onStart && this.props.onStart();
        this.doing = true;
        const config = {
            filePath: "D:\\hex-files\\core\\" + this.hexFile.uuid + (this.hexFile.fileName.indexOf(".tar.gz") > -1 ?   ".tar.gz" : this.hexFile.fileName.indexOf(".bin") > -1 ? ".bin" : ".hex"),
            hexFile: this.hexFile
        }
        let _break = false;
        for (let i = 0; i < this.stepRefs.length; i++) {
            const ref = this.stepRefs[i];
            const _continue = await ref.current.start(config, this.addLog);
            console.log("step", i, "_continue", _continue);
            if (!_continue) {
                _break = true;
                break;
            } else {
                this.addLog(_continue, "finish");
            }
            await sleep(100);
        }
        if (!_break) {
            this.resetProgress();
            this.props.onEnd && this.props.onEnd();
            this.props.onCanScan();
        } else {
            this.disabled = false;
            this.hasBreak = true;
            this.props.onInterrupt && this.props.onInterrupt();
        }
        this.doing = false;
    }

    onFinish = () => {
        const logs = this.logRef.current.get();
        const startTime = Date.now();
        return new Promise(async (resolve) => {
            await request.post(apis.hex + `/write/log`, {
                caseId: this.props.caseId || 0,
                caseNodeId: this.props.caseNodeId || 0,
                deviceCode: this.device.deviceCode,
                macAddr: this.macAddrTemp,
                hexFileName: this.hexFile.fileName,
                logs
            });
            this.props.onFinish && await this.props.onFinish(this.device, this.hexFile, this.macAddrTemp);
            const usedTime = Date.now() - startTime;
            const s = 2000 - usedTime;
            await sleep(s);
            resolve(void 0);
        });
    }

    render() {
        const { props } = this;
        return (
            <div className="hex-writer" style={{ padding: 10, height: 300, background: "#fff", ...(props.style || {}) }}>
                <Form>
                    {
                        props.children && (
                            <div style={{ height: `calc(${props.style?.height || "300px"} - 50px)` }}>
                                {props.children}
                            </div>
                        )
                    }
                    <Form.Item label="日志" style={{ marginBottom: 10, height: props.children ? 0 : undefined, overflow: 'hidden' }}>
                        <Logs
                            ref={this.logRef}
                            style={{ height: `calc(${props.style?.height || "300px"})` }}
                        />
                    </Form.Item>
                    <Form.Item label="进度" style={{ marginBottom: 0 }}>
                        <div className="flex">
                            <EraseStep
                                ref={this.eraseRef}
                                onErase={props.onErase}
                            />
                            <MacStep
                                ref={this.macRef}
                                onMacAddrRead={this.onMacAddrRead}
                            />
                            <WriteStep
                                ref={this.writeRef}
                            />
                            <FinishStep
                                ref={this.finishRef}
                                onFinish={this.onFinish}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

interface EraseProps {
    onErase?: () => Promise<void>;
}
const EraseStep = forwardRef((props: EraseProps, ref) => {

    const ERASE_CMD = `C:/"Program Files (x86)"/"Texas Instruments"/"SmartRF Tools"/"Flash Programmer"/bin/SmartRFProgConsole.exe S CE`;

    const TELINK_ACTIVE = `C:/"BDT"/"release_v5.6.0"/config/Cmd_download_tool.exe 1 8258 ac`;

    const TELINK_ERASE_CMD1 = `C:/"BDT"/"release_v5.6.0"/config/Cmd_download_tool.exe 1 8258 wf 0 -s 472k -e`; //仅限 TLSR8258F512 非bootloader模式
    const TELINK_ERASE_CMD2 = `C:/"BDT"/"release_v5.6.0"/config/Cmd_download_tool.exe 1 8258 wf 78000 -s 32k -e`;

    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    const start = (config: any, addLog: any) => {
        if (config.hexFile.fileName.indexOf(".hex") > -1) {
            setError(null);
            return new Promise(async (resolve) => {
                const stop = pre();
                let i = 30;
                await sleep(100);
                TI_CMD(ERASE_CMD, (message, type) => {
                    if (message.includes("Texas Instruments SmartRF Flash Programmer")) {
                        const temp = stop();
                        if (temp > 30) {
                            i = temp;
                        }
                        setProgress(i);
                        addLog(message, type);
                    } else if (message.includes("Erasing entire flash")) {
                        setProgress((100 - i) / 2);
                        resolve(message);
                    } else if (message.includes("Chip erased OK")) {
                        props.onErase && props.onErase();
                        setProgress(100);
                        resolve(message);
                    } else {
                        addLog(message, type);
                    }
                    if (type === 'stderr') {
                        setError("接触中断");
                        resolve(false);
                    }
                });
            });
        } else if (config.hexFile.fileName.indexOf(".tar.gz") > -1
        || config.hexFile.fileName.indexOf(".bin") > -1) {
            setError(null);
            return new Promise(async (resolve) => {
                const stop = pre();
                await sleep(300);
                //激活
                const r1 = await new Promise(async (resolve) => {
                    TI_CMD(TELINK_ACTIVE, (message, type) => {
                        console.log(type, message);
                        
                        if (message.includes('activate failed!')) {
                            setError("激活失败");
                            stop();
                            addLog(message, type);
                            resolve(false)
                        } else if (message.includes("Activate OK!")) {
                            stop();
                            addLog(message, type);
                            resolve("激活成功");
                        }
                        if (type === 'stderr') {
                            setError("接触中断");
                            resolve(false);
                        }
                    });
                });
                if (!r1) {
                    resolve(false);
                    return;
                }
                setProgress(30);
                //清除1
                const r2 = await new Promise(async (resolve) => {
                    let i = 30;
                    TI_CMD(TELINK_ERASE_CMD1, (message, type) => {
                        console.log(type, message);
                        if (message.includes("Erase Error")) {
                            addLog(message, type);
                            setError("Erase失败");
                            resolve(false);
                        } else if (message.includes("Total Time:")) {
                            addLog(message, type);
                            resolve(message);
                        } else {
                            i += 0.25;
                            setProgress(i)
                            addLog(message, type)
                        }
                        if (type === 'stderr') {
                            setError("接触中断");
                            resolve(false);
                        }
                    });
                });
                if (!r2) {
                    resolve(false);
                    return;
                }
                setProgress(60);
                //清除2
                const r3 = await new Promise(async (resolve) => {
                    let i = 60;
                    TI_CMD(TELINK_ERASE_CMD2, (message, type) => {
                        console.log(type, message);
                        if (message.includes("Erase Error")) {
                            addLog(message, type);
                            setError("Erase失败");
                            resolve(false);
                        } else if (message.includes("Total Time:")) {
                            addLog(message, type);
                            resolve(message);
                        } else {
                            i += 0.25;
                            setProgress(i)
                            addLog(message, type)
                        }
                        if (type === 'stderr') {
                            setError("接触中断");
                            resolve(false);
                        }
                    });
                });
                if (!r3) {
                    resolve(false);
                    return;
                }
                if(config.hexFile.fileName.indexOf(".tar.gz") > -1) {
                    setProgress(90);
                    const extractDir = `D:\\hex-files\\core\\${config.hexFile.uuid}`;
                    if(!existsSync(extractDir)) {
                        mkdirSync(extractDir);
                    }
                    //提取
                    await tarExtra({
                        file: config.filePath,
                        cwd: extractDir
                    });
                }
                setProgress(100);
                resolve("ok")
            });
        }
    }

    const pre = () => {
        let stop = false;
        let i: number;
        setProgress(5);
        (async () => {
            for (i = 5; i < 30; i++) {
                setProgress(i + 1);
                await sleep(200);
                if (stop) {
                    console.log("stop了");
                    break;
                }
            }
        })()
        return () => {
            stop = true;
            return i;
        }
    }

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setProgress(0);
                setError(null);
            },
            start
        }
    });

    return (
        <Step width={100} progress={progress} error={error} style={{ marginLeft: 0 }}>准备</Step>
    )

});

interface MacStepProops {
    onMacAddrRead: (macAddr: string) => void
}
const MacStep = forwardRef((props: MacStepProops, ref) => {

    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    const READ_MAC_CMD = `C:/"Program Files (x86)"/"Texas Instruments"/"SmartRF Tools"/"Flash Programmer"/bin/SmartRFProgConsole.exe S RI(F=0) `;
    const TELINK_READ_MAC_CMD = `C:/"BDT"/"release_v5.6.0"/config/Cmd_download_tool.exe 1 8258 rf 76000 -s 8 `;

    const start = (config: any, addLog: any) => {
        
        if (config.hexFile.fileName.indexOf(".hex") > -1) {
            setError(null);
            return new Promise((resolve) => {
                setProgress(10);
                TI_CMD(READ_MAC_CMD, (message, type) => {
                    if (message.includes("Texas Instruments SmartRF Flash Programmer")) {
                        setProgress(30);
                        addLog(message, type);
                    } else if (message.includes("Reading IEEE MAC address")) {
                        setProgress(60);
                        addLog(message, type);
                    } else if (message.includes("IEEE address:")) {
                        setProgress(100);
                        const macAddr = message.slice(message.length - 23, message.length);
                        props.onMacAddrRead(macAddr);
                        resolve(message);
                    } else {
                        addLog(message, type);
                    }
                    if (type === 'stderr') {
                        setError("读取MAC地址失败");
                        resolve(false);
                    }
                });
            });
        }else if(config.hexFile.fileName.indexOf(".tar.gz") > -1
         || config.hexFile.fileName.indexOf(".bin") > -1) {
            setError(null);
            return new Promise((resolve) => {
                // setProgress(10)
                TI_CMD(TELINK_READ_MAC_CMD, (message, type) => {
                    if(message.includes("TC32 EVK : Swire OK")) {
                        setProgress(30);
                        addLog(message, type);
                    }else if(message.includes("Reading IEEE MAC address")) {
                        setProgress(60);
                        addLog(message, type);
                    }else if (message.includes("076000:")) {    //076000:  d9 35 78 38 c1 a4 73 b1   实际地址是 a4 c1 38 78 35 d9 b1 73
                        console.log(message);
                        
                        setProgress(100);								
                                                                       // 00 11 22 33 44 55 66 77 实际的
                                                                        // 77 66 55 44 33 22 11 00 读或写
                                                                        
                        const macAddrTemp = message.match(/[a-z0-9]{2}( [a-z0-9]{2}){7}/g)[0];
                        console.log("mac Addr Temp:");
                        console.log(macAddrTemp);
                        const macTempSplits = macAddrTemp.split(' ')
                        const mac = [
                            macTempSplits[5],
                            macTempSplits[4],
                            macTempSplits[3],
                            macTempSplits[2],
                            macTempSplits[1],
                            macTempSplits[0],
                            macTempSplits[7],
                            macTempSplits[6]
                        ].join('.').toUpperCase();
                        console.log("mac addr:");
                        console.log(mac);
                        props.onMacAddrRead(mac);
                        resolve(message);
                    }else {
                        addLog(message, type);
                    }
                    if (type === 'stderr') {
                        setError("读取MAC地址失败");
                        resolve(false);
                    }
                })

            })
        }

    }

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setProgress(0);
                setError(null);
            },
            start
        }
    });

    return (
        <Step width={100} progress={progress} error={error}>读取MAC地址</Step>
    )
});

const WriteStep = forwardRef((props: any, ref) => {

    const [error, setError] = useState(null as string);
    const [progress, setProgress] = useState(0)

    const start = (config: any, addLog: any) => {
        setError(null);
        const WRITE_CMD = `C:/"Program Files (x86)"/"Texas Instruments"/"SmartRF Tools"/"Flash Programmer"/bin/SmartRFProgConsole.exe S EPV F="${config.filePath}" LD`;
        const TELINK_WRITE_CMD = `C:/"BDT"/"release_v5.6.0"/config/Cmd_download_tool.exe 1 8258 wf 0 -i "${config.filePath}"`;
        console.log(TELINK_WRITE_CMD);
        
        if(config.hexFile.fileName?.indexOf("hex") > -1) {

            return new Promise((resolve) => {
                let i = 30;
                TI_CMD(WRITE_CMD, (message, type) => {
                    if (message.includes("Texas Instruments SmartRF Flash Programmer")) {
                        setProgress(5);
                        addLog(message, type);
                    } else if (message.includes(".")) {
                        setProgress(i += 0.5);
                        addLog(message, type);
                    } else if (message.includes("Locking Debug Commands")) {
                        setProgress(90);
                        addLog(message, type);
                    } else if (message.includes("Erase, program and verify OK")) {
                        setProgress(100);
                        resolve(message);
                    } else {
                        addLog(message, type);
                    }
                    if (type === 'stderr') {
                        if (message.includes("Could not open specified HEX file")) {
                            setError("烧录失败:找不到HEX文件");
                        } else {
                            setError("未知原因");
                        }
                        resolve(false);
                    }
                });
            });
        }else if(config.hexFile.fileName?.indexOf(".tar.gz") > -1) {
            //telink多段烧录
            return new Promise(async (resolve) => {
                const paths: string[] = fs.readdirSync(`D:/hex-files/core/${config.hexFile.uuid}`);
                // console.log("paths:", paths);
                
                let ii = 0;
                let _break = false;
                for (let i = 0; i < paths.length; i++) {
                    const path = paths[i];
                    const [channelAndSuffix, version, ...reversedName] = path.split('-').reverse();
                    const [name, addr] = reversedName[0].split("#");
                    const filePath = `D:/hex-files/core/${config.hexFile.uuid}/${path}`;
                    const TELINK_WRITE_CMD = `C:/"BDT"/"release_v5.6.0"/config/Cmd_download_tool.exe 1 8258 wf ${addr} -i "${filePath}"`;
                    const res = await new Promise((resolve) => {
                        TI_CMD(TELINK_WRITE_CMD, (message, type) => {
                            console.log(type, message);
                            if (message.includes("Erase Error")) {
                                addLog(message, type);
                                setError("烧录中断");
                                resolve(false);
                            }else if(message.includes("File Download to Flash at address ")) {
                                setProgress((i + 1) * (100 / paths.length));
                                ii = (i + 1) * (100 / paths.length);
                                addLog(message, type);
                                resolve(message);
                            }else {
                                ii += 0.2;
                                setProgress(ii);
                                addLog(message, type);
                            }
                        });
                    });
                    if(!res) {
                        _break = true;
                        break;
                    }
                }
                if(_break) {
                    setError('烧录不成功');
                    resolve(false)
                }else {
                    setProgress(100);
                    resolve("success")
                }

            });
        }else if(config.hexFile.fileName?.indexOf(".bin") > -1) {
            //telink单段烧录
                let i = 0;
                let ii = 0;
                const paths = { length: 1 }
                return new Promise((resolve) => {
                    TI_CMD(TELINK_WRITE_CMD, (message, type) => {
                        console.log(type, message);
                        if (message.includes("Erase Error")) {
                            addLog(message, type);
                            setError("烧录中断");
                            resolve(false);
                        }else if(message.includes("File Download to Flash at address ")) {
                            setProgress((i + 1) * (100 / paths.length));
                            ii = (i + 1) * (100 / paths.length);
                            addLog(message, type);
                            resolve(message);
                        }else {
                            ii += 0.2;
                            setProgress(ii);
                            addLog(message, type);
                        }
                    });
                });
            
        }
    }

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setProgress(0);
                setError(null);
            },
            start
        }
    });

    return (
        <Step progress={progress} error={error}>烧写程序</Step>
    )
});

interface FinishStepProps {
    onFinish?: () => Promise<any>
}
const FinishStep = forwardRef((props: FinishStepProps, ref) => {

    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null as string);

    const animate = () => {
        let stop = false;
        (async () => {
            for (let i = 0; i < 100; i++) {
                setProgress(i + 1);
                await sleep(30);
                if (stop) {

                    break;
                }
            }
        })()
        return () => {
            stop = true;
        }
    }


    const start = async (config: any, addLog: any) => {
        const stop = animate();
        props.onFinish && await props.onFinish();
        stop();
        setProgress(100);
        return "烧录完成";
    }

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setProgress(0);
                setError(null);
            },
            start
        }
    });

    return (
        <Step width={100} progress={progress} style={{ marginRight: 0 }}>结束</Step>
    )
});


interface StepProps {
    progress?: number;
    error?: string;
    width?: number;
    style?: CSSProperties
}
const Step = (props: PropsWithChildren<StepProps>) => {
    return (
        <div className="flex relative" style={{ width: props.width, height: 30, flex: props.width ? undefined : 1, margin: '0 2px', background: "#000", ...(props.style || {}) }}>
            <div className="progress" style={{ width: `${props.progress || 0}%`, background: props.error ? "#f00" : undefined }}></div>
            <div className="tip flex center" style={{ color: props.error ? '#ff0' : undefined }}>{props.error || props.children}</div>
        </div>
    )
}

const Logs = forwardRef((props: any, ref) => {

    const [logs, setLogs] = useState<any[]>([]);


    useImperativeHandle(ref, () => {
        return {
            init: (message?: string, color?: string) => {
                const logs = [];
                if (message) {
                    logs.push({
                        time: moment(),
                        content: message,
                        color: color,
                    });
                }
                setLogs(logs);
            },
            add: (message: string, color: string) => {
                logs.push({
                    time: moment(),
                    content: message,
                    color,
                });
                setLogs([...logs]);
            },
            tail: (message: string, color: string) => {
                if (logs.length === 0) {
                    return;
                }
                const lastLog = logs[logs.length - 1];
                if (lastLog.content[lastLog.content.length - 1] !== message) {
                    logs.push({
                        time: moment(),
                        content: message,
                        color,
                    });
                } else {
                    logs[logs.length - 1].content += message;
                    logs[logs.length - 1].time = moment();
                }
                setLogs([...logs]);
            },
            get: () => {
                return logs;
            }
        }
    })

    return (
        <div className="" style={{ height: `calc(${props.style?.height || '300px'} - 60px)`, background: "#232323", padding: '3px 0', overflowY: 'scroll' }}>
            {
                logs.map((log, index) => {
                    return (
                        <div key={"log-" + index} className="line" style={{ color: log.color }}>
                            【{log.time.format('HH:mm:ss SSS')}】{log.content}
                        </div>
                    )
                })
            }
        </div>
    )
});