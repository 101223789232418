import { CheckSquareFilled, ClockCircleFilled, CloudFilled, LoadingOutlined, PlayCircleOutlined, WarningFilled } from "@ant-design/icons";
import { Button, Checkbox, Modal, Skeleton, Tooltip } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import CaseList from "../../../../../#case-manager/Home/components/CaseList";
import { sleep } from "../../../../../../utils";
import { apis } from "../../../../../../utils/apis";
import { getNodeImageUrlByTypeKey } from "../../../../../../utils/constants";
import { request } from "../../../../../../utils/request";
import CaseConfigPrepare from "./CaseConfigPrepare";
import { MacCheck } from "./MacCheck";
import { NetJoin } from "./NetJoin";
import NetNodesWaiting from "./NetNodesWaiting";
import PermitJoin from "./PermitJoin";

interface Props extends RouteChildrenProps {
    customer?: any;
    caseId: number;
    onCaseChange: (c: any) => void;
    simple?: boolean;
    fullScreen?: boolean;
    auto?: boolean;
}
const CaseConfig = (props: Props) => {

    return (
        <div className="flex">
            <div style={{ width: 200, height: 'calc(100vh - 160px)', overflowX: 'hidden' }} className="no-scroll-bar">
                <CaseList
                    customer={props.customer}
                    ignoreTemplate
                    onCaseChange={props.onCaseChange}
                    caseId={props.caseId}
                />
            </div>
            <div style={{ flex: 1, width: props.simple ? 'calc(100vw - 400px)' : 'calc(100vw - 500px)', height: "calc(100vh - 500px)" }}>
                <CaseConfigMain
                    caseId={props.caseId}
                    fullScreen={props.fullScreen}
                    auto={props.auto}
                />
            </div>
        </div>
    )
}


let numberTemp = 0;
let caseIdTemp = 0;
interface CaseMainCofigProps {
    caseId?: number;
    fullScreen?: boolean;
    auto?: boolean;
}
const CaseConfigMain = (props: CaseMainCofigProps) => {

    const [step, setStep] = useState(-1);
    const [c, setC] = useState<any>(null)
    const [caseNodes, setCaseNodes] = useState<any[]>([]);
    const [areas, setAreas] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [refs] = useState<any>({});
    const [caseConfigs, setCaseConfigs] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [checking, setChecking] = useState(true);
    const [configing, setConfiging] = useState(false);
    const [autoConfig, setAutoConfig] = useState(true);


    useEffect(() => {
        caseIdTemp = props.caseId;
        numberTemp = 0;
        setAreas([]);
        setCaseNodes([]);
        setStep(-1);
    }, [props.caseId]);

    useEffect(() => {
        if (!props.caseId) {
            return;
        }
        if (step !== 4) {
            return;
        }
        getData(props.caseId);
    }, [props.caseId, step]);

    const getData = async (caseId: number, loading = true) => {
        setLoading(loading);
        const [resC, res1, res2] = await Promise.all([
            request.get(apis.case + `/${props.caseId}`),
            request.get(apis.caseNode, { caseId: caseId }),
            request.get(apis.case + `/node/list/config`, { caseId: caseId })
        ])
            .finally(() => {
                setLoading(false);
            });

        // console.log("开关occupancy", c.welcomeType)
        if (resC.data.welcomeType === 2) {
            console.log("开关occupancy")
            for (let i = 0; i < res1.data.caseNodes.length; i++) {
                const node = res1.data.caseNodes[i];
                if (node.typeKey.indexOf("button_switch") > -1 && node.name.indexOf("网关") === -1) {
                    node.shouldEnableOccupancy = true;
                    break;
                }
            }
            for (let i = 0; i < res1.data.caseNodes.length; i++) {
                const node = res1.data.caseNodes[i];
                if(node.typeKey.indexOf("button_switch") > -1) {
                    node.shouldHotelMode = true;
                }
            }
        }
        
        for (let i = 0; i < res1.data.caseNodes.length; i++) {
            const node: any = res1.data.caseNodes[i];
            let shouldDelay = false;
            for (let j = 0; j < node.apps.length; j++) {
                const a = node.apps[j];
                if (a.flowingDelayTime > 0) {
                    shouldDelay = true;
                    break;
                }
            }
            node.shouldDelay = shouldDelay;
            console.log("shouldDelay", shouldDelay);
        }

        setC(c)
        setCaseNodes(res1.data.caseNodes);
        setCaseConfigs(res2.data);
    }

    useEffect(() => {
        let allOnline = true;
        let hasNoMac = false;
        const areas: any[] = [];
        for (let i = 0; i < caseNodes.length; i++) {
            const caseNode = caseNodes[i];
            if (!caseNode.isOnline) {   
                allOnline = false;
            }
            if (!caseNode.mac) {
                hasNoMac = true;
            }   
            let area = areas.find((a) => a.id === caseNode.caseAreaId);
            if (area) {
                area.caseNodes.push(caseNode);
            } else {
                areas.push({
                    id: caseNode.caseAreaId,
                    name: caseNode.caseAreaName,
                    caseNodes: [caseNode]
                });
            }
        }
        setChecking(!allOnline);
        setAreas(areas);
    }, [caseNodes]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (step !== 4) {
            return;
        }
        const data: any[] = [];
        for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            for (let j = 0; j < area.caseNodes.length; j++) {
                const caseNode = area.caseNodes[j];
                caseNode.configs = caseConfigs.find((cc) => cc.id === caseNode.caseNodeId);
            }
            data.push(area);
        }
        setData(data);
    }, [areas, caseConfigs, loading, step]);
    


    useEffect(() => {
        console.log("1 onStartConfig", data.length, loading, step);
        if (loading) {
            return;
        }
        console.log("2 onStartConfig", data.length, loading, step);
        if (step !== 4) {
            return;
        }
        if (data.length === 0) {
            return;
        }
        //都有mac地址、且有节点未上线的情况下
        if (props.auto) {
            setTimeout(onStartConfig, 100);
        }
    }, [data, loading, step]);

    const numberRef = useRef<any>();

    const onStartConfig = async () => {
        setConfiging(true);
        for (const key in refs) {
            await refs[key]?.start();
        }
        setConfiging(false);
        //广播关闭允许加入
        Modal.info({
            title: "已全部配置完成，请检查",
            centered: true,
            okText: "知道了"

        });
    }
    if (step === -1) {
        return (
            <MacCheck
                caseId={props.caseId}
                onOk={() => {
                    console.log("case", props.caseId, "step", step);
                    setStep(0);
                }}
            />
        )
    }
    if (step === 0) {
        return (
            <NetJoin
                caseId={props.caseId}
                onOk={() => {
                    console.log("case", props.caseId, "step", step);
                    setStep(1);
                }}      
            />
        )
    } else if (step === 1) {
        return (
            <PermitJoin
                caseId={props.caseId}
                onOk={() => {
                    console.log("case", props.caseId, "step", step);
                    setStep(2);
                }}
            />
        )
    } else if (step === 2) {
        return (
            <NetNodesWaiting
                caseId={props.caseId}
                onOk={() => {
                    console.log("case", props.caseId, "step", step);
                    setStep(3);
                }}
            />
        )
    } else if (step === 3) {
        return (
            <CaseConfigPrepare
                caseId={props.caseId}
                onOk={() => {
                    console.log("case", props.caseId, "step", step);
                    setStep(4);
                }}

            />
        )
    }
    return (
        <div>
            {
                loading ? (
                    <Skeleton active />
                ) : (
                    <div>
                        <div className="flex" style={{ alignItems: 'center', paddingTop: 20 }}>
                            {(
                                <Button
                                    type="primary"
                                    style={{ marginRight: 10 }}
                                    onClick={onStartConfig}
                                    loading={(checking || configing)}
                                    disabled={checking || loading || configing}
                                >
                                    {
                                        checking ? (
                                            <span style={{ color: "purple" }}>
                                                等待节点上线中，请保持网关在线
                                            </span>
                                        ) : configing ? (
                                            <span style={{ color: "blue" }}>
                                                正在配置...
                                            </span>
                                        ) : (
                                            <>
                                                <PlayCircleOutlined />
                                                一键配置
                                            </>
                                        )
                                    }
                                </Button>
                            )
                            }
                            {
                                <div className="flex" style={{ alignItems: 'center' }}>
                                    <Tooltip overlay={(
                                        < >
                                            {
                                                caseNodes.map((n) => {
                                                    return (
                                                        <div className="inline-block" style={{ height: 8, width: 8, borderRadius: 2, backgroundColor: n.isOnline ? 'green' : 'lightgray', margin: '1px' }}></div>
                                                    )
                                                })
                                            }
                                        </>
                                    )}>
                                        <div className="flex" style={{ alignItems: 'center' }}>
                                            上线：
                                            <span style={{ marginLeft: 3 }}>
                                                {caseNodes.filter((cn) => cn.isOnline).length}/{caseNodes.length}
                                            </span>
                                        </div>
                                    </Tooltip>
                                    {
                                        !configing && (
                                            <div style={{ paddingTop: 10, position: 'relative', top: -5 }}>
                                                <Checkbox style={{ marginLeft: 20 }} checked={autoConfig} onClick={() => {
                                                    setAutoConfig(!autoConfig);
                                                }}>全部上线后自动配置</Checkbox>
                                            </div>
                                        )
                                    }
                                    {
                                        configing && (
                                            <span className="bold" style={{ marginLeft: 10, fontSize: 16, marginTop: -3 }}>正在配置第<Number ref={numberRef} number={1} />个</span>
                                        )
                                    }
                                </div>
                            }
                        </div>
                        <div style={{ height: `calc(100vh ${props.fullScreen ? ' - 100px' : ' - 190px'} )`, marginTop: 10, overflowY: 'scroll' }}>
                            {
                                data.map((area, index) => {
                                    return (
                                        <>
                                            <ConfigArea
                                                ref={(ref) => {
                                                    refs[index] = ref;
                                                }}
                                                case={c}
                                                area={area}
                                                onNodeConfigFinish={() => {
                                                    numberTemp++;
                                                    numberRef.current?.setNumber(numberTemp);
                                                }}

                                            />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CaseConfig;


interface ConfigAreaProps {
    case: any;
    area: {
        name: string;
        caseNodes: any[];
    },
    onNodeConfigFinish: (node: any) => void
}
const ConfigArea = forwardRef((props: ConfigAreaProps, ref: any) => {

    const area = props.area;
    const [caseNodes, setCaseNodes] = useState<any[]>([]);
    const [refs] = useState<any>({});

    useEffect(() => {
        setCaseNodes(props.area.caseNodes);
        // setRefs(props.area.caseNodes.map((_) => createRef()));
    }, [props.area.caseNodes]);

    useImperativeHandle(ref, () => {

        return {
            start: async () => {
                console.log("node refs", refs);
                for (const key in refs) {
                    console.log("node", refs[key]);
                    await refs[key]?.start();
                    props.onNodeConfigFinish(refs[key])
                }

            }
        }

    });

    return (
        <div style={{ marginTop: 10 }}>
            <div className="bold flex center" style={{ fontSize: 18, alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10 }}>
                {/* <span style={{ fontWeight: 'normal', fontSize: 13 }}>区域：</span> */}
                {area.name}
                {/* <Button type="primary" ghost size="small" style={{ marginLeft: 10 }}><PlayCircleOutlined /> 配置该区域</Button> */}
            </div>
            <div className="flex" style={{ flexWrap: 'wrap' }}>
                {
                    caseNodes.map(((cn: any, index: number) => {
                        return (
                            <ConfigNode
                                ref={(ref) => {
                                    refs[index] = ref;
                                }}
                                key={"config-node-" + index}
                                case={props.case}
                                caseNode={cn}
                            />
                        )
                    }))
                }
            </div>
        </div>
    )
})

interface ConfigNodeProps {
    case: any;
    caseNode: any
}

const ConfigNode = forwardRef((props: ConfigNodeProps   , ref: any) => {

    const [caseNode, setCaseNode] = useState((props.caseNode || {}) as any);
    const [verifyStatus, setVerifyStatus] = useState(0);
    const [groupStatus, setGroupStatus] = useState(0);
    const [sceneStatus, setSceneStatus] = useState(0);
    const [bindStatus, setBindStatus] = useState(0);
    const [offlineVoiceStatus, setOfflineVoiceStatus] = useState(0);
    const [flag1Status, setFlag1Status] = useState(0);
    const [flag2Status, setFlag2Status] = useState(0);
    const [flag3Status, setFlag3Status] =  useState(0);
    const [flag4Status, setFlag4Status] = useState(0);//组255配置

    const [verifyError, setVerifyError] = useState("");
    const [groupError, setGroupError] = useState("");
    const [sceneError, setSceneError] = useState("");
    const [bindError, setBindError] = useState("");
    const [offlineVoiceError, setOfflineVoiceError] = useState("");
    const [flag1Error, setFlag1Error] = useState("");
    const [flag2Error, setFlag2Error] =  useState("");
    const [flag3Error, setFlag3Error] =  useState("");
    const [flag4Error, setFlag4Error] = useState(""); //组255配置

    useEffect(() => {
        setCaseNode(props.caseNode);
    }, [props.caseNode]);

    const groupConfig = async () => {
        if (groupStatus === 1) {
            return;
        }
        //建组
        if (caseNode.configs.groups.length > 0) {
            setGroupStatus(1);
            const res1 = await request.post(apis.case + `/node/config/group`, {
                caseNodeId: caseNode.caseNodeId
            });
            if (res1.data.restRes.payload) {
                if (res1.data.restRes.payload.status === '0') {
                    setGroupStatus(2);
                    setGroupError("");
                } else {
                    setGroupStatus(-1);
                    setGroupError("Status: 0x" + parseInt(res1.data.restRes.payload.status).toString(16));
                }
            } else {
                setGroupStatus(-1);
                setGroupError("建组超时");
            }
            await sleep(100);
        }
    }

    const sceneConfig = async () => {
        if (sceneStatus === 1) {
            return;
        }
        //建场景
        if (caseNode.configs.scenes.length > 0) {
            setSceneStatus(1);
            const res1 = await request.post(apis.case + `/node/config/scene`, {
                caseNodeId: caseNode.caseNodeId
            });
            if (res1.data.restRes.payload) {
                if (res1.data.restRes.payload.status === '0') {
                    setSceneStatus(2);
                    setSceneError("");
                } else {
                    setSceneStatus(-1);
                    setSceneError("Status: 0x" + parseInt(res1.data.restRes.payload.status).toString(16));
                }
            } else {
                setSceneStatus(-1);
                setSceneError("建场景超时");
            }
            await sleep(100);
        }

    }

    const bindConfig = async () => {    
        if (bindStatus === 1) {
            return;
        }
        //绑定
        if (caseNode.configs.binds.length > 0) {
            setBindStatus(1);               
            const res1 = await request.post(apis.case + `/node/config/bind`, {
                caseNodeId: caseNode.caseNodeId
            });
            if (res1.data.restRes.payload) {
                if (res1.data.restRes.payload.status === '0') {
                    setBindStatus(2);
                    setBindError("");
                } else {
                    setBindStatus(-1);
                    setBindError("Status: 0x" + parseInt(res1.data.restRes.payload.status).toString(16));
                }
            } else {
                setBindStatus(-1);
                setBindError("绑定超时");
            }   
            await sleep(100);
        }

    }

    const offlineVoiceConfig = async () => {
        if(offlineVoiceStatus === 1) {
            return ;
        }
        if(caseNode.configs.offlineVoice) {
            setOfflineVoiceStatus(1);
            const res1 = await request.post(apis.case + `/node/config/offline-voice`, {
                caseNodeId: caseNode.caseNodeId
            });
            
            if (res1.data.restRes?.payload) {
                if (res1.data.restRes.payload.status === '0') {
                    setOfflineVoiceStatus(2);
                    setOfflineVoiceError("");
                } else {
                    setOfflineVoiceStatus(-1);
                    setOfflineVoiceError("Status: 0x" + parseInt(res1.data.restRes.payload.status).toString(16));
                }
            } else {
                setOfflineVoiceStatus(-1);
                setOfflineVoiceError("绑定超时");
            }
            await sleep(100);
        }
    }

    const enableOccupancy = async () => {
        if (caseNode.shouldEnableOccupancy) {

            setFlag1Status(1);
            const res1 = await request.post(apis.case + `/node/config/enable/switch-occupancy-report`, {
                caseNodeId: caseNode.caseNodeId     
            })
                .then((res) => {
                    if (res?.payload?.status === '0') {
                        setFlag1Status(2);
                        setFlag1Error("");
                    } else {
                        setFlag1Status(2);
                        setFlag1Error("激活失败");

                    }
                }).catch((e) => {
                    setFlag1Status(-1);
                    setFlag1Error("激活异常")
                })
            await sleep(100);
        }
    }   

    const delayConfig = async () => {
        setFlag2Status(1);
        const body = {
            caseNodeId: props.caseNode.caseNodeId,
            endpointDelayTimeSet: props.caseNode.apps.filter((a) => a.flowingDelayTime !== 0).map((a) => ({
                endpoint: a.endpoint,
                delayTime: a.flowingDelayTime
            }))
        };
        const res1 = await request.post(apis.caseConfig + `/flowing-delay`, body)
            .then((res) => {
                if (res.code === 1) {
                    setFlag2Status(2);
                    setFlag2Error("");
                } else {
                    setFlag2Status(-1);
                    setFlag2Error("激活失败");
                }
            }).catch((e) => {
                setFlag2Status(-1);
                setFlag2Error("激活异常")
            })
            await sleep(100);
    }

    const hotelModeConfig = async () => {
        if(props.caseNode.shouldHotelMode) {
            setFlag3Status(1);
            request.post(apis.case + '/node/config/enable/hotel-mode', {
                caseNodeId: caseNode.caseNodeId
            })
                .then((res) => {
                    if(res.code === 1) {
                        if(res.data === "ok") {
                            setFlag3Status(2);
                            setFlag3Error("");
                        }else {
                            setFlag3Status(-1);
                            setFlag3Error("激活失败");
                        }
                    }else  {
                        setFlag3Status(-1);
                        setFlag3Error("激活失败");

                    }
                }).catch((e) => {
                    console.log(e);
                    setFlag3Status(-1);
                    setFlag3Error("激活异常")
                })
        }
    }

    const group255Config = async () => {
        if(props.caseNode.typeKey === 'curtain_motor') {
            setFlag4Status(1);
            await request.post(apis.case + "/node/config/enable/group-255", {
                caseNodeId: caseNode.caseNodeId,
            }).then((res) => {
                console.log(res)
                if(res.code === 1) {
                    setFlag4Status(2);
                    setFlag4Error("");

                }else {
                    setFlag4Status(-1);
                    setFlag4Error(res.msg);
                }
            }).catch((e) => {
                setFlag4Status(-1);
                setFlag4Error("配置异常");
            });
        }
    }

    useImperativeHandle(ref, () => {
        return {
            start: async () => {
                await groupConfig();
                await sceneConfig();
                await bindConfig();
                await offlineVoiceConfig();
                await enableOccupancy();
                await delayConfig();
                await hotelModeConfig();
                await group255Config();
            },

        }
    })

    return (
        <div className="flex column" style={{ border: '1px solid #eee', boxShadow: '0 5px 10px rgba(0, 0, 0, .1)', padding: 10, width: '100%', marginRight: 10, marginBottom: 10 }}>
            <div className="flex" style={{ marginBottom: 3, marginRight: 20, paddingRight: 10, }}>
                <div className="flex column center" style={{ marginRight: 10 }}>
                    <img src={getNodeImageUrlByTypeKey(caseNode.typeKey)} alt="" style={{ height: 18, width: 18 }} />
                </div>
                <div className="flex" style={{ alignItems: 'center' }}>
                    {caseNode.isOnline ? (
                        <span style={{ marginTop: 3 }}>
                            <CloudFilled style={{ color: "green", fontSize: 14, marginRight: 5 }} />
                        </span>
                    ) : (
                        <span style={{ marginTop: 3 }}>
                            <CloudFilled style={{ color: "orange", fontSize: 14, marginRight: 5 }} />
                        </span>
                    )}
                    <div className="bold" style={{ fontSize: 14 }}>{caseNode.name}{caseNode.deviceCode ? `（${caseNode.deviceCode}）` : ''} <span style={{ color: "gray", fontWeight: "normal" }}>（{caseNode.mac || "未烧录"}）</span></div>

                </div>
            </div>
            <div className="flex" style={{ color: "#000", fontSize: 14 }}>
                {/* <div style={{ flex: 1 }}>
                    校验：
                    <ConfigNodeStatus
                        status={verifyStatus}
                    />
                </div> */}
                <div style={{ flex: 1 }}>
                    <span onClick={groupConfig} style={{ cursor: "pointer" }}>
                        <span style={{ color: caseNode.configs.groups.length > 0 ? undefined : 'lightgray' }}>{caseNode.configs.groups.length > 0 ? '建组：' : '无需建组'}</span>
                        {
                            caseNode.configs.groups.length > 0 && (
                                <ConfigNodeStatus
                                    status={groupStatus}
                                    error={groupError}
                                />
                            )
                        }
                    </span>
                </div>
                <div style={{ flex: 1 }}>
                    <span onClick={sceneConfig} style={{ cursor: "pointer" }}>
                        <span style={{ color: caseNode.configs.scenes.length > 0 ? undefined : 'lightgray' }}>{caseNode.configs.scenes.length > 0 ? '建场景：' : '无需建场景'}</span>
                        {
                            caseNode.configs.scenes.length > 0 && (
                                <ConfigNodeStatus
                                    status={sceneStatus}
                                    error={sceneError}
                                />
                            )
                        }
                    </span>
                </div>
                <div style={{ flex: 1 }}>
                    <span onClick={bindConfig}>
                        <span style={{ color: caseNode.configs.binds.length > 0 ? undefined : 'lightgray' }}>{caseNode.configs.binds.length > 0 ? '绑定：' : '无需绑定'}</span>
                        {
                            caseNode.configs.binds.length > 0 && (
                                <ConfigNodeStatus
                                    status={bindStatus}
                                    error={bindError}
                                />
                            )
                        }
                    </span>
                </div>
                {
                    props.caseNode.configs.offlineVoice && (
                        <div style={{ flex: 1 }}>
                        <span onClick={offlineVoiceConfig}>
                            <span style={{}}>离线语音</span>
                            <ConfigNodeStatus
                                status={offlineVoiceStatus}
                                error={offlineVoiceError}
                            />

                        </span>
                    </div>
                    )
                }
                {
                    props.caseNode.shouldEnableOccupancy && (
                        <div style={{ flex: 1 }}>
                            <span onClick={enableOccupancy}>
                                <span style={{}}>激活Occupancy</span>
                                <ConfigNodeStatus
                                    status={flag1Status}
                                    error={flag1Error}
                                />

                            </span>
                        </div>

                    )
                }
                {
                    props.caseNode.shouldDelay && (
                        <div style={{ flex: 1 }}>
                            <span onClick={delayConfig}>
                                <span style={{  }}>延迟开灯</span>
                                <ConfigNodeStatus
                                    status={flag2Status}
                                    error={flag2Error}
                                />

                            </span>
                        </div>
                    )
                }
                {
                    !props.case?.useFlowingLight && props.caseNode.shouldHotelMode && (
                        <div style={{flex: 1}}>

                            <span onClick={hotelModeConfig}>
                                <span style={{}}>通电亮灯</span>
                                
                                <ConfigNodeStatus
                                    status={flag3Status}
                                    error={flag3Error}
                                />
                            </span>
                        </div>
                    )
                }
                {
                    props.caseNode.typeKey === 'curtain_motor' && (
                        <div style={{flex: 1}}>

                            <span onClick={group255Config}>
                                <span style={{}}>特殊组255</span>
                                
                                <ConfigNodeStatus
                                    status={flag4Status}
                                    error={flag4Error}
                                />
                            </span>
                        </div>

                    )
                }
            </div>
        </div>
    )
})

const ConfigNodeStatus = (props: { status: number, error?: string; }) => {

    if (props.status === 0) {
        return (
            <span style={{ color: "gray" }}><ClockCircleFilled style={{ marginRight: 5 }} />等待</span>
        )
    } else if (props.status === 1) {
        return (
            <span style={{ color: "blue" }}><LoadingOutlined spin style={{ marginRight: 5 }} />进行中</span>
        )
    } else if (props.status === 2) {
        return (
            <span style={{ color: "green" }}><CheckSquareFilled style={{ marginRight: 5 }} />已完成</span>
        )
    } else if (props.status === -1) {
        return (
            <span style={{ color: "orangered" }}><WarningFilled style={{ marginRight: 5 }} />失败 {props.error ? ": " + props.error : ""}</span>
        )
    }
}


const Number = forwardRef((props: { number?: number }, ref: any) => {

    const [number, setNumber] = useState(props.number);

    useImperativeHandle(ref, () => {
        return {
            setNumber
        }
    });

    return (
        <>{number}</>
    )
})