import { DatePicker, Modal, Form, TimePicker, FormInstance, message } from 'antd'
import moment from 'moment';
import React, { createRef } from 'react'
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';

interface Props {
    caseId: number,
    visible: boolean,
    onCancel: () => void,
    onCaseSubmit: (_case: any) => void,
}

const formRef = createRef<FormInstance>();

/**
 * 用于提交到备货
 * @param props 
 */
export const CaseSubmitModal = (props: Props) => {

    const onSubmit = (data: any) => {
        const deadline = moment(data.deadline).format("YYYY-MM-DD");
        // data.caseId = props.caseId;
        request.post(apis.caseSubmit + `/${props.caseId}`, {
            deadline: deadline
        }).then((res) => {
            if(res.code === 1) {
                props.onCaseSubmit(res.data);
            }else {
                message.warn(res.msg || res.message);
            }
        }).catch(e => {
            message.warn(e);
        })
        // props.onCaseSubmit(data);
    }

    return (
        <Modal
            centered
            maskClosable={false}
            title="提交备货"
            visible={props.visible}
            width={320}
            onOk={() => {
                formRef.current?.submit();
            }}
            onCancel={props.onCancel}
            okText="确认提交备货"
            cancelText="再看看"
        >
            
            <Form ref={formRef} onFinish={onSubmit}>
                <Form.Item label="交货日期" name="deadline" style={{marginBottom: 0}} rules={[{required: true, message: "请选择期望交货日期"}]}>
                    <DatePicker picker={"date"}  style={{width: 200}}  placeholder="请选择期望交货日期"/>
                </Form.Item>
            </Form>
            <span style={{color: 'yellow', marginTop: 8, display: 'inline-block'}}>
                提交前,请确认信道、设备数量，提交后如需修改，需要联系生产人员撤回
            </span>
        </Modal>
    )


}