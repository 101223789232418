import { Form, Input, message } from "antd"
import { forwardRef } from "react";
import ModalForm from "../../../../components/ModalForm"
import { apis } from "../../../../utils/apis"
import { request } from "../../../../utils/request"

interface Props {
    onPasswordUpdated: () => void;
}

export const UpdatePasswordModal = forwardRef((props: Props, ref: any) => {
    
    const onSubmit = async (data: any) => {
        if(data.password !== data.repeatPassword) {
            message.warn("两次密码不一致");
            return ;
        }
        return request.put(apis['/upms//user/password/admin'], data)
            .then((res) => {
                if(res.code === 1) {
                    return true;
                }
            })

    }

    return (
        <ModalForm
            ref={ref}
            centered
            title={"修改密码"}
            onSubmit={onSubmit}
            labelCol={{span: 4}}
        >
            <Form.Item hidden name="uuid" />
            <Form.Item label="新密码" name={"password"} rules={[{required: true}]}>
                <Input type="password" placeholder="请输入新密码" autoComplete="off" autoFocus/>
            </Form.Item>
            <Form.Item label="重复密码" name={"repeatPassword"} rules={[{required: true}]}>
                <Input type="password" placeholder="请重复您刚刚输入的密码" autoComplete="off"/>
            </Form.Item>
        </ModalForm>
    )

})