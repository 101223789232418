import ControlProductCell from "./ControlProductCell";
import { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";
import FourSwitchCell from "./FourSwitchCell";
import OneSwitchCell from "./OneSwitchCell";
import SixSwitchCell from "./SixSwitchCell";
import { SwitchCellWrapper } from "./SwitchCell";
import ThreeSwitchCell from "./ThreeSwitchCell";
import TwoSwitchCell from "./TwoSwitchCell";


export const templates = [
    {
        key: "product-cell",
        name: "通用智能控件",
        component: ControlProductCell,
    }, 
    
    {
        key: "one-switch-cell",
        component: OneSwitchCell,
        name: "一键面板控件",
        options: {
            silkScreen: true
        }
    },
    {
        key: "two-switch-cell",
        component: TwoSwitchCell,
        name: "二键面板控件",
        options: {
            silkScreen: true
        }
    },
    
    {
        key: "three-switch-cell",
        component: ThreeSwitchCell,
        name: "三键面板控件",
        options: {
            silkScreen: true
        }
    },
    
    {
        key: "four-switch-cell",
        component: FourSwitchCell,
        name: "四键面板控件",
        options: {
            silkScreen: true
        }
    },
    
    {
        key: "six-switch-cell",
        component: SixSwitchCell,
        name: "六键面板控件",
        options: {
            silkScreen: true
        }
    }
]


export const TemplateComponent = (
    props:
        {templateKey: string} & 
        ControlProductCellWrapperProps & 
        SwitchCellWrapper &
        {silk1?: any, silk2?: any, silk3?: any, silk4?: any, silk5?: any, silk6?: any}
) => {
    const Component: any = (templates.find((t) => t.key === props.templateKey) || templates[0]).component;
    
    if(!Component) {
        return <></>
    }
    return (
        <Component {...props}/>
    )
}

const _templateKeyNameMap: any = {};
for (let i = 0; i < templates.length; i++) {
    const template = templates[i];
    _templateKeyNameMap[template.key] = template.name;
}
export const templateKeyNameMap = _templateKeyNameMap;