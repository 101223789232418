import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { search2Query } from "../../../utils";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import OrderCaseSpuListA4Template from "../Home/components/ProjectView/CaseOrder/OrderCaseSpuListA4Template";

interface Props extends RouteChildrenProps<{customerId?: string}> {

}

const OrderCaseSpuListPrintView = (props: Props) => {
    const [customer, setCustomer] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>({});
    
    useEffect(() => {
        const customerId = Number(props.match.params.customerId);
        if(customerId) {
            const caseIds = search2Query(props.location.search).selectedCaseIds.split(',').map((item) => Number(item));
            getData(customerId, caseIds)
        }
        
    }, [props.match.params.customerId, props.location.search])

    const getData = async (customerId: number, caseIds: number[]) => {
        setLoading(true);
        const [data1, data2] = await Promise.all([
            request.get(apis.customer + `/${customerId}`)
            .then((res) => {
                if(res.code === 1) {
                    return res.data;
                }
            }),
            request.get(apis.case + `/spu/customer`, {
                customerId: customerId,
                caseIds: caseIds
            }).then((res) => {
                if (res.code === 1) {
                    return res.data;
                }
            })
        ]).finally(() => {
            setLoading(false);
        });
        setCustomer(data1);
        setData(data2);
    }


    if(loading) {
        return (
            <Skeleton active/>
        )
    }
    return (
        <OrderCaseSpuListA4Template 
            customer={customer}
            data={data}
        />
    )

}

export default OrderCaseSpuListPrintView