import { CiCircleFilled, CloudFilled, LeftOutlined } from "@ant-design/icons";
import { Button, Card, message, Skeleton, Steps, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { Route, RouteChildrenProps } from "react-router-dom";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import { ScanWrapper } from "../components/ScanWrapper";
import { } from '../../#case-manager/CaseManager/components/CaseEditor'
import { CurtainControlNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/CurtainControlNodeCell'
import { CurtainControlDoubleNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/CurtainControlDoubleNodeCell'
import { CurtainMotorNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/CurtainMotorNodeCell'
import { FourButtonSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/FourButtonSwitchNodeCell'
import { ThreeButtonSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/ThreeButtonSwitchNodeCell'
import { FourButtonSceneAndSpeakerNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/FourButtonSceneAndSpeakerNodeCell'
import { Plug_inCardPowerNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/Plug_inCardPowerNodeCell'
import { Plug_inCardPowerNodeCell2 } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/Plug_inCardPowerNodeCell2'
import { SmartControlNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/SmartControlNodeCell'
import { OneButtonSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/OneButtonSwitchNodeCell'
import { TwoButtonSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/TwoButtonSwitchNodeCell'
import { ThreeButtonSceneNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/ThreeButtonSceneNodeCell'
import { IRHumanSensorNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/IRHumanSensorNodeCell'
import { DimmableDownlightNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/DimmableDownlightNodeCell'
import { RGBLightStripNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/RGBLightStripNodeCell'
import { OneTouchSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/OneTouchSwitchNodeCell'
import { TwoTouchSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/TwoTouchSwitchNodeCell'
import { ThreeTouchSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/ThreeTouchSwitchNodeCell'
import { FourTouchSwitchNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/FourTouchSwitchNodeCell'
import { OfflineSpeakerNodeCell } from '../../#case-manager/CaseManager/components/CaseEditor/widgets/node-widgets/OfflineSpeakerNodeCell'

import './style.less'
import { extraInfoChannelFromFileName, sleep } from "../../../utils";
import { NODE_KEY_NAME_MAP } from "../../../utils/constants";
import { CaseSelect } from "./CaseSelect";
import { Switch } from 'react-router-dom';

interface Props extends RouteChildrenProps {

}

const { Step } = Steps;

let nodeTemp = {} as any;
let caseNetTemp = {} as any;
let _onRefresh = () => { };

export const PageOutbound2 = (props: Props) => {
    
    return (
        <Switch>
            <Route exact path="/helper/outbound2" component={PageOutbound2Temp} />
            {/* <Route exact path="/helper/outbound2/case"  component={CaseConfig}/> */}
        </Switch>
    )

}
/**
 * 烧录配置一体
 * @param props 
 * @returns 
 */
export const PageOutbound2Temp = (props: Props) => {

    const [scanDisabled, setScanDisabled] = useState(true);
    const [customers, setCustomers] = useState<any[]>([]);
    const [cases, setCases] = useState<any[]>([]);
    const [curCustomer, setCurCustomer] = useState<any>({});

    useEffect(() => {
        getCustomers();
    }, [])

    const getCustomers = async () => {
        const res = await request.get(apis.customer);
        if (res.code === 1) {
            setCustomers(res.data);
        }
    }

    useEffect(() => {
        console.log(curCustomer);
        if ("id" in curCustomer) {
            
            props.history.replace("/helper/project-view/" + curCustomer.id + "/hex-write")
        } else {
            setCases([]);
        }
    }, [curCustomer]);

    const getCustoemrCases = async () => {
        const res = await request.get(apis.caseCustomerCases + `/${curCustomer.id}`);
        if (res.code === 1) {
            setCases(res.data);
        }
    }

    const [code, setCode] = useState("");

    const onRelate = async (code: string) => {
        setCode(code);
    }

    return (
        <div className="page-case-flash-and-config">
            <ScanWrapper placeholder="扫描二维码入库" disabled={scanDisabled} onEnter={onRelate}>
                <CaseConfig
                    code={code}
                    setScanLock={setScanDisabled}
                    customer={curCustomer}
                    cases={cases}
                    onClose={() => {
                        setCurCustomer({});
                    }}
                />
                <Card style={{ border: 0 }} title="请选择要配置的客户">
                {
                    customers.length > 0 && customers.map((c, index) => {
                        return (
                            <Card.Grid style={{ width: "100%", cursor: 'pointer', padding: 0 }} className={'flex ' + (curCustomer.id === c.id ? 'bg-primary' : '')} key={"key-" + index}>
                                <div className="flex1" style={{ width: '100%', padding: 24 }} onClick={() => {
                                    setCurCustomer(c);
                                }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 36, display: 'flex', alignItems: 'center' }}>
                                        <Tag color="blue">{c.type}</Tag>
                                        {c.name}
                                    </div>
                                    <div className="item">
                                        <span style={{ fontSize: 12, marginRight: 8, opacity: 0.5 }}>方案数:</span>
                                        <span style={{ fontSize: 18 }}>{c.casesCount}</span>
                                    </div>
                                </div>
                            </Card.Grid>
                        )
                    })
                }
                </Card>
            </ScanWrapper>
        </div>
    )

}



export const CaseConfig = (props: {
    code: string,
    customer: any,
    cases: any[],
    onClose: () => void,
    setScanLock: (lock: boolean) => void,

}) => {


    const [curCase, setCurCase] = useState({} as any);
    const [caseNodes, setCaseNodes] = useState<any[]>([]);
    const [mode, setMode] = useState<"set-mac" | "config" | "loading">("loading");
    const [curSetMacNode, setCurSetMacNode] = useState<any>({});
    const [caseNet, setCaseNet] = useState({} as any);

    useEffect(() => {
        if (props.cases.length > 0) {
            if (props.cases[0].id !== curCase.id) {
                setCurCase(props.cases[0]);
            }
        } else {
            setCurCase({});
        }
    }, [props.cases]);

    // useEffect(() => {
    //     onRefresh();
    // }, []);

    const onRefresh = async () => {
        await getCaseNodes();
        await getCaseNet();
    }


    useEffect(() => {;
        
        if (props.code) {
            onCode(props.code);
        }
    }, [props.code])

    const onCode = async (code: string) => {
        if (!code) {
            return;
        }
        const res1 = await request.get(apis.productDeviceInfo + `/${code}`);
        if (res1.code !== 1) {
            message.warn(res1.msg || res1.msg);
            return ;
        }
        //产品检查
        const spu = res1.data.spu;
        if (spu?.typeKey !== 'gateway') {
            //非网关的情况，检查类型
            const res0 = await request.post(apis.productDeviceCheckType + `/${code}`, {
                typeKey: nodeTemp?.typeKey,
            });
            if (res0.code !== 1) {
                message.warn(res0.msg || res0.msg);
                return;
            }
        }
        //信道检查
        const infoChannel = extraInfoChannelFromFileName(res1.data.hexFileName);
        if(infoChannel !== caseNet.infoChannel) {
            message.warn("请拿取" + caseNet.infoChannel + "信道的产品（当前拿取的是"  + infoChannel + "信道）");
            return ;
        }
        const macAddr = res1.data.macAddr;
        let res;
        if (res1.data.spu.typeKey === "gateway") {
            res = await request.put(apis.caseNode + `/${caseNetTemp.id}/mac`, {
                mac: macAddr,
                typeKey: "gateway",
                code,
            });
        } else {
            res = await request.put(apis.caseNode + `/${nodeTemp.caseNodeId}/mac`, {
                mac: macAddr,
                code,
            });
        }
        if (res.code !== 1) {
            message.warn(res.msg || res.msg);
        }
        onRefresh();

    }

    useEffect(() => {
        if ("id" in curCase) {
            onRefresh();
        } else {
            setCaseNodes([]);
            setCaseNet({});
        }
    }, [curCase]);

    const getCaseNodes = async () => {
        setMode("loading");
        const res = await request.get(apis.caseNode, { caseId: curCase.id });
        if (res.code === 1) {
            setCaseNodes(res.data.caseNodes);
        }
    }

    const getCaseNet = async () => {
        const res = await request.get(apis.caseNet + `/${curCase.id}`);
        if (res.code === 1) {
            setCaseNet(res.data);
        }
    }
    useEffect(() => {
        caseNetTemp = caseNet;
    }, [caseNet]);

    const [noMacNodes, setNoMacNodes] = useState<any[]>([]);

    useEffect(() => {
        
        const noMacCaseNodes = caseNodes.filter((item) => !item.mac);
        const noMacCaseNode = noMacCaseNodes.length > 0 ? noMacCaseNodes[0] : {};
        if(noMacCaseNodes.length > 0) {
            setNoMacNodes(noMacCaseNodes);
        }
        // setNoMacNodes([]);
        if ("caseNodeId" in noMacCaseNode) {
            setCurSetMacNode(noMacCaseNode)
        }
        if (("caseNodeId" in noMacCaseNode) || !caseNet?.rest_netId) {
            setMode("set-mac");
        } else {
            setCurSetMacNode({});
            setMode("config");
        }
    }, [caseNodes, caseNet]);



    useEffect(() => {
        nodeTemp = curSetMacNode;
    }, [curSetMacNode]);

    const [canConifg, setCanConfig] = useState(true);
    useEffect(() => {
        if (mode === 'set-mac') {
            props.setScanLock(false);
        } else {
            props.setScanLock(true);
        }
    }, [mode]);

    const [configStep, setConfigStep] = useState(0);
    const [configProgress, setConfigProgress] = useState(0);
    const [configErrorMessage, setConfigErrorMessage] = useState("");
    const reset = () => {
        setConfigErrorMessage("");
        setConfigStep(0);
        setConfigProgress(10);
    }
    const startConfig = async () => {
        let startTime = Date.now();
        setCanConfig(false);
        reset();
        //开启允许加入
        const res1 = await request.post(apis.caseConfigNetPermitJoin, {
            caseId: caseNet.caseId,
        });
        if (res1.code !== 1) {
            setConfigErrorMessage(res1.msg || res1.message);
            setCanConfig(true);
            return;
        }
        setConfigProgress(20);
        //等待节点上线
        while (true) {
            const res = await request.get(apis.caseNode, { caseId: curCase.id });
            if (res.code !== 1) {
                message.warn(res.msg || res.message);
                setCanConfig(true);
                return;
            }
            const nodes = res.data.caseNodes;
            setCaseNodes(nodes);
            const onlineNodes = nodes.filter((node) => node.isOnline);
            setConfigProgress(20 + (onlineNodes.length / nodes.length * 50));
            await sleep(10 * 1000);
            if (nodes.length === onlineNodes.length) {
                //广播关闭允许加入
                request.post(apis.caseConfigNetDisableJoinBroadcast, { caseId: caseNet.caseId });
                message.info("全部节点上线, 已广播关闭允许加入");

                break;
            }else {
                message.info("等待节点上线, 已上线：" + onlineNodes.length  +  "/" + caseNodes.length);
            }
        }
        //配前准备, 配置前, 必须调用这个api
        const res2 = await request.post(apis.caseConfigPrepare, {
            caseId: caseNet.caseId
        });
        if (res2.code !== 1) {
            message.warn(res2.msg || res2.message);
            setCanConfig(true);
            return;
        }
        setConfigProgress(0);
        setConfigStep(configStep + 1);
        //配置每个设备
        let looper;
        for (let i = 0; i < caseNodes.length; i++) {
            const node = caseNodes[i];
            let progressTemp = 0;
            looper = setInterval(() => {
                if (progressTemp < 90) {
                    progressTemp++;
                    setConfigProgress(progressTemp + 1);
                }
            }, 100);
            const tRes = await request.post(apis.caseConfigNode, {
                caseNodeId: node.caseNodeId
            });
            clearInterval(looper);
            setConfigProgress(100);
            // await sleep(1000);
            if (tRes.code !== 1) {
                message.warn(res2.msg || res2.message);
                setCanConfig(true);
                return;
            }
            setConfigStep(i + 2);
        }
        setConfigStep(caseNodes.length + 2);
        setCanConfig(true);
        const spend = Date.now() - startTime;
        message.success("配置结束, 用时" + (Math.floor(spend / 1000) + "秒"));
    }

    return (

        <div className={"flex1 " + ("id" in props.customer ? 'flex' : 'none')} style={{ color: "#333", position: 'absolute', background: "#f2f2f2", zIndex: 9, top: 0, left: 0, width: '100vw', height: 'calc(100vh - 30px)' }}>
            <div style={{ marginRight: 10, width: 200 }} className="flex column">
                <div style={{ padding: '10px 12px', fontSize: 14, cursor: 'pointer', background: "#fff", marginBottom: 10 }} onClick={props.onClose}>
                    <LeftOutlined />
                    返回
                </div>
                <div className="flex1" style={{ backgroundColor: "#fff", padding: 12, marginBottom: 10, overflowY: 'scroll' }}>
                    <div style={{ fontSize: 14 }}>当前客户: </div>
                    <div className="flex">
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                            <Tag color="blue">{props.customer.type}</Tag>
                            {props.customer.name}
                        </div>
                    </div>
                    <div style={{ marginTop: 30 }}>
                        {
                            props.cases.length > 0 && props.cases.map((_case) => {
                                return (
                                    <div className={curCase.id === _case.id ? 'bg-primary' : ''} style={{ padding: '3px 6px', borderRadius: 6, fontSize: 18, cursor: 'pointer' }} onClick={() => {
                                        setCurCase(_case);
                                    }}>
                                        <Tag color={_case.configTimes === 0 ? 'lightgray' : 'green'}>{_case.configTimes === 0 ? '从未配置' : `已配置${_case.configTimes}次`}</Tag>
                                        {_case.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {
                mode === "config" ? (
                    <div style={{ background: "#000", display: 'flex', flex: 1, overflowY: 'auto', flexDirection: 'row', padding: '20px' }}>
                        <div style={{ marginRight: 20, width: 100, textAlign: 'right' }}>
                            <Button type="primary" onClick={startConfig} disabled={!canConifg} loading={!canConifg} >开始配置</Button>
                            <div style={{ color: 'yellow', marginTop: 10 }}>接好线, 配完网后点击"开始配置"</div>
                            {
                                configErrorMessage && (
                                    <div style={{ background: 'red', color: "#fff", marginTop: 10, padding: 10 }}>{configErrorMessage}</div>
                                )
                            }
                        </div>
                        <Steps direction="vertical" current={configStep} percent={configProgress} status={configErrorMessage ? "error" : configStep === caseNodes.length + 1 ? 'finish' : 'process'}>
                            <Step title="配前准备" />
                            {
                                caseNodes.length > 0 && caseNodes.map((item) => {
                                    return (
                                        <Step title={(
                                            <div>
                                                <CloudFilled style={{ color: item.isOnline ? 'lightgreen' : 'lightgray', marginRight: 10 }} />
                                                <span>{item.name}</span>
                                            </div>
                                        )} />
                                    )
                                })
                            }
                            <Step title="结束" />
                        </Steps>
                    </div>
                ) : (
                    <div className="flex1 bg-white flex column" style={{ overflowY: 'scroll', justifyContent: 'center', alignItems: 'center' }}>


                        {
                            mode === "loading" && (
                                <Skeleton />
                            )
                        }
                        {
                            mode === "set-mac" && (
                                <>
                                    {/* {
                                        ("caseNodeId" in curSetMacNode) && (
                                            <Tag color="blue" style={{ marginTop: 10 }}>{curSetMacNode.caseAreaName}</Tag>

                                        )
                                    } */}
                                    {
                                        noMacNodes.length > 0 && (
                                            <div style={{fontSize: 16, fontWeight: 'bold'}}>第{caseNodes.length - noMacNodes.length + 1}个，还剩{ noMacNodes.length - 1}个</div>
                                        )
                                    }
                                    <div className="prod-node-cell" style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'top', color: "#fff" }}>
                                        {
                                            (() => {
                                                let NodeCell: any = null;
                                                switch (curSetMacNode.typeKey) {
                                                    case "plug-in_card_power":
                                                        NodeCell = Plug_inCardPowerNodeCell;
                                                        break;
                                                    case "plug-in_card_power_v2":
                                                        NodeCell = Plug_inCardPowerNodeCell2;
                                                        break;
                                                    case "general_ir_human_sensor":
                                                    case "sub_ir_human_sensor":
                                                        NodeCell = IRHumanSensorNodeCell;
                                                        break;
                                                    case "scene_and_speaker":
                                                        NodeCell = FourButtonSceneAndSpeakerNodeCell;
                                                        break;
                                                    case "curtain_control":
                                                    case "curtain_control_battery":
                                                        NodeCell = CurtainControlNodeCell;
                                                        break;
                                                    case "curtain_control_double":
                                                        NodeCell = CurtainControlDoubleNodeCell;
                                                        break;
                                                    case "curtain_motor":
                                                        NodeCell = CurtainMotorNodeCell;
                                                        break;
                                                    case "smart_control":
                                                    case "smart_socket":
                                                    case "universal_ir_remote_control":
                                                        NodeCell = SmartControlNodeCell;
                                                        break;
                                                    case "dimmable_downlight":
                                                    case "color_temp_dimmable_downlight":
                                                        NodeCell = DimmableDownlightNodeCell;
                                                        break;
                                                    case "rgb_light_strip":
                                                        NodeCell = RGBLightStripNodeCell;
                                                        break;
                                                    case "three_button_scene":
                                                        NodeCell = ThreeButtonSceneNodeCell;
                                                        break;
                                                    case "four_button_switch":
                                                        NodeCell = FourButtonSwitchNodeCell;
                                                        break;
                                                    case "three_button_switch":
                                                        NodeCell = ThreeButtonSwitchNodeCell;
                                                        break;
                                                    case "two_button_switch":
                                                        NodeCell = TwoButtonSwitchNodeCell;
                                                        break;
                                                    case "one_button_switch":
                                                        NodeCell = OneButtonSwitchNodeCell;
                                                        break;
                                                    case "four_touch_switch":
                                                        NodeCell = FourTouchSwitchNodeCell;
                                                        break;
                                                    case "three_touch_switch":
                                                        NodeCell = ThreeTouchSwitchNodeCell;
                                                        break;
                                                    case "two_touch_switch":
                                                        NodeCell = TwoTouchSwitchNodeCell;
                                                        break;
                                                    case "single_knob_switch":
                                                    case "double_knob_switch":
                                                    case "one_touch_switch":
                                                        NodeCell = OneTouchSwitchNodeCell;
                                                        break;
                                                    case "offline_speaker":
                                                        NodeCell = OfflineSpeakerNodeCell;
                                                        break;
                                                    default:
                                                        return (
                                                            <div>
                                                                -- {curSetMacNode.name} {curSetMacNode.typeKey} --
                                                            </div>
                                                        )
                                                }
                                                return (
                                                    <NodeCell
                                                        node={curSetMacNode}
                                                        // bindMode={props.bindMode}
                                                        // bindingSource={props.bindCurrentSource}
                                                        // bindingAccepts={props.bindCurrentAccpets}
                                                        // bindingTargets={props.bindCurrentTargets}
                                                        viewSource={[]}
                                                        onSetBindingSource={(app: any, accepts: string) => {
                                                            // props.setBindMode("targets");
                                                            // props.onSetCurrentSource(app, accepts);
                                                        }}
                                                        onSetBindingTarget={(app: any) => {
                                                            // props.onAddCurrentTarget("app", app);
                                                        }}
                                                        onResetBindingSource={() => {
                                                            // props.onResetCurrentSource();
                                                        }}
                                                        onAddBindingTarget={(type: "app" | "scene" | "ir-sensor", target: any) => {
                                                            // props.onAddCurrentTarget(type, target);
                                                        }}
                                                        onRemoveBindingTarget={(type: "app" | "scene" | "ir-sensor", target: any) => {
                                                            // props.onRemoveCurrentTarget(type, target);
                                                        }}
                                                    />
                                                )
                                            })()
                                        }
                                    </div>
                                    {
                                        ("caseNodeId" in curSetMacNode) ? (
                                            <div style={{ fontSize: 16}}>拿取<span style={{color: 'blue'}}>{caseNet.infoChannel}信道</span>的<span style={{ color: 'blue' }}>{NODE_KEY_NAME_MAP[curSetMacNode.typeKey]}</span>， <span style={{fontSize: 18}}>先</span>按图示<span style={{fontSize: 18}}>盖好翘板</span>, 再扫码，</div>
                                        ) : caseNet.rest_netId === 0 ? (
                                            <div style={{ fontSize: 24 }}>请扫描网关的二维码</div>
                                        ) : (
                                            <div style={{ fontSize: 16 }}>请联系管理</div>
                                        )
                                    }
                                    <div className="flex" style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                                        <div className="flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <CiCircleFilled style={{ color: caseNet.rest_netId ? "green" : "gray", marginRight: 5, fontSize: 16 }} />
                                            <div style={{ fontSize: 16 }}> {caseNet.mac || "网关未关联"}</div>

                                        </div>
                                        {
                                            !caseNet.mac && (
                                                <span style={{ color: "red", marginLeft: 5, fontSize: 16 }}>遇到两个二维码时，扫两次</span>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }


                    </div>
                )
            }


        </div>
    )
}