import { AppstoreAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, FileSearchOutlined, LinkOutlined, PlusCircleOutlined, ReloadOutlined, SettingOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Dropdown, Empty, Menu, Modal, PageHeader, Row, Table, Tabs, Tag, Tooltip, Typography, message } from "antd";
import dayjs from "dayjs";
import { createRef, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { apis } from '../../../utils/apis';
import { request } from '../../../utils/request';
import { ProjectUpdateModal } from '../Home/components/ProjectUpdateModal';
import { HexFilesUploadModal } from './components/HexFilesUploadModal';
import { HexGroupTypeKeyEditModal } from './components/HexGroupTypeKeyEditModal';
import { HexGroupsManager } from './components/HexGroupsManager';
import { HexVersionBindModal } from "./components/HexVersionBindModal";

import AddHexConfig from "./components/AddConfigModal";
import HexConfigEditor from "./components/HexConfigEdit";
import HexConfigEndpointsView from "./components/HexConfigEndpointView";
import HexVersionCreateModal2 from "./components/HexVersionCreateModal2";
import './style.less';

const { Paragraph } = Typography;
const stagePriorityColors = ["pink", "green", "purple", "blue"];

interface Props extends RouteComponentProps {
    projectKey: string;
    setLoading: (loading: boolean) => void;
    refreshing: boolean;
    setRefreshing: (refreshing: boolean) => void;
    onOpenProjectCreateModal: () => void;
    projectCates: any[];
    onRefresh: () => void;
    onProjectCateCreate: (cate: any) => void;
}

const genVersionTableColumns = (version: any, hexStages: any[], onBind: () => void, onStage: (version: any, stage: any) => void, onRemoveHexFileFormVersion: (hexFile: any, version: any) => void): any => {
    const stages = [];
    for (let i = 0; i < hexStages.length; i++) {
        const hexStage = hexStages[i];
        for (let j = 0; j < hexStage.hexGroupStages.length; j++) {
            const groupStage = hexStage.hexGroupStages[j];
            if (groupStage.hexGroupId === version.hexGroupId) {
                stages.push({
                    ...hexStage,
                    gmtModify: dayjs(groupStage.gmtModify).format("YY-MM-DD HH:mm:ss"),
                    groupStageId: groupStage.groupStageId,
                    hexVersionId: groupStage.hexVersionId,
                    hexGroupName: groupStage.hexGroupName
                })
                break;
            }
        }
    }
    return [
        {
            key: "index",
            title: "#",
            width: 30,
            render: (_: any, __: any, index: number) => {
                return (index + 1);
            }
        }, {
            title: "信道",
            width: 50,
            align: 'center',
            dataIndex: "infoChannel",
            key: "infoChannel",
            render: (infoChannel: string) => {
                return <span className="text-primary">{infoChannel}</span>
            }

        },
        {
            key: "versionName",
            width: 400,
            title: (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>版本: {version.name}</div>
                    <div style={{ fontSize: 12, color: '#666' }}>{version.description}</div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                    <div style={{ textAlign: 'right' }}>
                        {stages.length > 0 && stages.map((stage) => {
                            return (
                                <Tooltip title={version.hexGroupName + "/" + version.name + "版本当前" + (version.id === stage.hexVersionId ? "已" : "未") + "被用于" + stage.name}>
                                    <Tag
                                        onClick={() => {
                                            if (version.id !== stage.hexVersionId) {
                                                onStage(version, stage);

                                            }
                                        }}
                                        color={stagePriorityColors[stage.priority]}
                                        style={{ opacity: version.id === stage.hexVersionId ? 1 : 0.3, cursor: version.id === stage.hexVersionId ? "default" : "pointer" }}
                                    >{stage.name}</Tag>
                                </Tooltip>
                            )
                        })}
                    </div>
                    <Button type="link" onClick={onBind} style={{ marginRight: 0, paddingRight: 0 }}>
                        <LinkOutlined />
                        关联文件
                    </Button>
                </div>
            </div>),
            dataIndex: "fileName",
        }, {
            key: "gmtUpload",
            title: "上传时间",
            dataIndex: "gmtUpload",
            // align: "right",
            width: 92,
            render: (gmtUpload: string) => {
                return dayjs(gmtUpload).format('MM-DD HH:mm:ss')
            }
        }, {
            key: "actions",
            title: "操作",
            width: 42,
            render: (_: any, hexFile: any) => {
                return (
                    <Button type="link" danger onClick={() => {
                        onRemoveHexFileFormVersion(hexFile, version);
                    }}>
                        <DeleteOutlined />
                        移除
                    </Button>
                )
            }
        }]
};


const HexManagerPage = (props: Props) => {

    const [projectId, setProjectId] = useState(0);
    const [project, setProject] = useState({} as any);
    const [hexFiles, setHexFiles] = useState<any[]>([]);
    const [hexFilesGroupByUploadDate, setHexFilesGroupByUploadDate] = useState<{ date: string, hexFiles: any[] }[]>([]);
    const [hexStages, setHexStages] = useState<any[]>([]);
    const [typeKeyEditVisible, setTypeKeyEditVisible] = useState(false);
    const [currentHexGroup, setCurrentHexGroup] = useState({} as any);
    const openTypeKeyModal = (hexGroup: any) => {
        setTypeKeyEditVisible(true);
        setCurrentHexGroup(hexGroup);
    }
    const closeTypeKeyModal = () => {
        setTypeKeyEditVisible(false);
        setCurrentHexGroup({});
    }
    const onHexGroupUpdate = (hexGroup: any) => {
        fetchProjectData(projectId);
        closeTypeKeyModal();
    }


    const [hexFilesUploadVisible, setHexFilesUploadVisible] = useState(false);
    const openHexFilesUploadModal = () => {
        setHexFilesUploadVisible(true);
    }

    const closeHexFilesUploadModal = () => {
        setHexFilesUploadVisible(false);
    }

    const [hexVersionCreateVisible, setHexVersionCreateVisible] = useState(false);
    const openHexVersionCreateModal = () => {
        setHexVersionCreateVisible(true);
    }
    const closeHexVersionCreateModal = () => {
        setHexVersionCreateVisible(false);
    }

    const [hexVersionBindVisible, setHexVersionBindVisible] = useState(false);
    const [targetVersion, setTargetVersion] = useState({} as any);
    const openHexVersionBindModal = (version: any) => {
        setHexVersionBindVisible(true);
        setTargetVersion(version);
    }
    const closeHexVersionBindModal = () => {
        setHexVersionBindVisible(false);
        setTargetVersion({});
    }


    const [projectUpdateVisible, setProjectUpdateVisible] = useState(false);
    const openProjectUpdateModal = () => {
        setProjectUpdateVisible(true);
    }
    const closeProjectUpdateModal = () => {
        setProjectUpdateVisible(false);
    }

    useEffect(() => {
        if (!props.projectKey) {
            return;
        }
        const splits = props.projectKey.split('/');
        const projectId = Number(splits[splits.length - 1]);
        setProjectId(projectId);
    }, [props.projectKey]);

    useEffect(() => {
        if (!projectId) {
            return;
        }
        fetchProjectData(projectId);
    }, [projectId]);

    useEffect(() => {
        const map: Map<string, any[]> = new Map();
        for (let i = 0; i < hexFiles.length; i++) {
            const hexFile: any = hexFiles[i];
            const dateKey: string = hexFile.gmtUpload.slice(0, 10);
            if (map.has(dateKey)) {
                map.get(dateKey)?.push(hexFile);
            } else {
                map.set(dateKey, [hexFile]);
            }
        }
        const result: { date: string, hexFiles: any[] }[] = [];
        map.forEach((hexFiles, date) => {
            if (hexFiles.length > 0) {
                result.push({
                    date,
                    hexFiles,
                });
            }
        })
        setHexFilesGroupByUploadDate(result);
    }, [hexFiles]);

    const onRefresh = () => {
        if (projectId) {
            fetchProjectData(projectId);
        }
    }


    const fetchProjectData = (projectId?: number) => {
        if (!projectId) {
            return;
        }
        props.setRefreshing(true);
        request.get(apis.hexProject + "/" + projectId)
            .then((res) => {
                const project = res.data;
                if (res.data) {
                    setProject(project);
                } else {
                    setProject({});
                }
            })
            .finally(() => {
                props.setRefreshing(false);
            });
        request.get(apis.hexStage, { projectId })
            .then((res) => {
                const stages = res.data;
                // console.log(stages);
                setHexStages(stages);
            })
        request.get(apis.hexFile, { projectId, isHidden: 0})
            .then((res) => {
                const hexFiles = res.data;
                console.log("hexFiles effect");
                console.log(hexFiles);
                setHexFiles(hexFiles);
            })
    }

    const [deleteVisible, setDeleteVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const openDeleteModal = () => {
        setDeleteVisible(true);
    }
    const closeDeleteModal = () => {
        setDeleteVisible(false);
    }
    const deleteProject = () => {
        setDeleteLoading(true);
        request.delete(apis.hexProject + "/" + projectId)
            .then((res) => {
                if (res.code === 1) {
                    setProjectId(0)
                    props.onRefresh();
                    onRefresh();
                    message.info("已删除")
                    closeDeleteModal();
                }
            })
            .finally(() => {
                setDeleteLoading(false);
            })
    }

    const [groupManagerVisible, setGroupManagerVisible] = useState(false);
    const openGroupManagerModal = () => {
        setGroupManagerVisible(true);
    }
    const closeGroupManagerModal = () => {
        setGroupManagerVisible(false);
    }

    if (!projectId) {
        return (
            <div className="content-empty-wrapper">
                <Empty description={(
                    <span>请<a onClick={props.onOpenProjectCreateModal}>添加项目</a>或在左侧选择要查看的项目</span>
                )} />
            </div>
        )
    }

    const onRemoveHexFileFromVersion = (hexFileId: number) => {
        request.delete(apis.hexVersionUnbind, {
            hexFileId
        })
            .then((res) => {
                console.log(res);
                if (res.code === 1) {
                    fetchProjectData(projectId);
                } else {
                    message.warn(res.msg || res.message);
                }
            })
            .catch((e) => {
                message.warn(e.name)
            });
    }

    const onRemoveHexFile = (hexFileId: number) => {
        request.delete(apis.hexFile + `/${hexFileId}`)
            .then((res) => {
                if (res.code === 1) {
                    const _hexFiles = hexFiles.filter((hexFile) => {
                        return hexFile.id !== res.data.id;
                    });
                    fetchProjectData(projectId);
                    setHexFiles(_hexFiles);
                } else {
                    message.warn(res.msg || res.message);
                }
            })
            .catch((e) => {
                message.warn(e.name)
            });
    }



    return (
        <div className="hex-manager-page">
            <HexFilesUploadModal visible={hexFilesUploadVisible} project={project} onCancel={closeHexFilesUploadModal} onRefresh={fetchProjectData} />
            <HexVersionCreateModal2 visible={hexVersionCreateVisible} project={project} onCancel={closeHexVersionCreateModal} onRefresh={fetchProjectData} />
            <HexVersionBindModal onHexFilesRefresh={onRefresh}  visible={hexVersionBindVisible} project={project} targetVersion={targetVersion} hexFiles={hexFiles} onCancel={closeHexVersionBindModal} onUploadHexFile={openHexFilesUploadModal} onBindVersion={(hexFileIds) => {
                //数据库更新完之后, UI更新
                // const relativeHexFiles: any[] = [];
                // for (let i = 0; i < hexFiles.length; i++) {
                //     const hexFile = hexFiles[i];
                //     if(hexFileIds.includes(hexFile.id)) {
                //         hexFile.hexVersionId = targetVersion.id;
                //         hexFile.hexVersionName = targetVersion.name;
                //         relativeHexFiles.push(hexFile);
                //     }
                // }
                // targetVersion.hexFiles.push(...hexFiles);
                fetchProjectData(projectId);
                setTargetVersion({});
            }} />
            <ProjectUpdateModal
                visible={projectUpdateVisible}
                onCancel={closeProjectUpdateModal}
                onProjectUpdate={() => {
                    onRefresh();
                    props.onRefresh();
                    closeProjectUpdateModal();
                    // closeProjectUpdateModal();
                }}
                projectCates={props.projectCates} projectId={project?.id} onProjectCateCreate={props.onProjectCateCreate} />
            <HexGroupsManager visible={groupManagerVisible} onOk={closeGroupManagerModal} onCancel={closeGroupManagerModal} projectId={project?.id} onRefresh={onRefresh} />

            <Modal
                title="请确认是否删除该项目"
                centered
                width={300}
                visible={deleteVisible}
                maskClosable={false}
                onOk={() => {
                    deleteProject();
                }}
                okButtonProps={{
                    danger: true,
                    loading: deleteLoading,
                    disabled: deleteLoading
                }}
                onCancel={() => {
                    closeDeleteModal();
                }}
                cancelButtonProps={{
                    disabled: deleteLoading
                }}
            >
                删除该项目会一并删除该项目下的HEX文件等, 请确认是否删除?
            </Modal>
            <HexGroupTypeKeyEditModal
                visible={typeKeyEditVisible}
                onCancel={closeTypeKeyModal}
                onHexGroupUpdate={onHexGroupUpdate}
                hexGroup={currentHexGroup}

            />
            <PageHeader
                style={{ paddingBottom: 0 }}
                title={(
                    <>
                        {
                            project?.status ? (
                                <CloudUploadOutlined style={{ color: "green", marginRight: 8 }} />
                            ) : (
                                <CloudDownloadOutlined style={{ color: "orange", marginRight: 8 }} />
                            )
                        }
                        {project?.name}
                    </>
                )}
                subTitle={project?.cateName}
                tags={[<Tag /*  style={{position: 'relative', top: -4, left: 5}} */>
                    <UserOutlined />
                    {project?.maintainer}
                </Tag>, (
                    <Tag >
                        {project?.chipType}
                    </Tag>
                )]}
                extra={[
                    <Button onClick={() => { fetchProjectData(projectId) }}>
                        <ReloadOutlined spin={props.refreshing} />
                        刷新
                    </Button>,
                    // <Upload>
                    <Button type="primary" onClick={openHexFilesUploadModal}>
                        <UploadOutlined />
                        上传HEX文件
                    </Button>
                    // </Upload>
                    ,
                    <Dropdown trigger={["click"]} overlay={(
                        <Menu>
                            <Menu.Item onClick={() => {
                                // message.info("开发中, 尽请期待");
                                openProjectUpdateModal();
                            }}>
                                <EditOutlined />
                                编辑
                            </Menu.Item>
                            {
                                project?.status === 1 ? (
                                    <Menu.Item onClick={() => {
                                        //TODO 项目下线
                                        message.info("开发中, 尽请期待");
                                    }}>
                                        <CloudDownloadOutlined />
                                        下线
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item onClick={() => {
                                        //TODO 项目下线
                                        message.info("开发中, 尽请期待");
                                    }}>
                                        <CloudDownloadOutlined />
                                        上线
                                    </Menu.Item>
                                )
                            }

                            <Menu.Item danger onClick={openDeleteModal}>
                                <DeleteOutlined />
                                删除
                            </Menu.Item>
                        </Menu>
                    )}>
                        <Button style={{ border: 'none', padding: '0 5px' }}>
                            <EllipsisOutlined style={{ fontSize: 20, verticalAlign: 'top' }} />
                        </Button>
                    </Dropdown>

                ]}
            >
                <Paragraph>
                    {project?.description}
                </Paragraph>
            </PageHeader>
            {/* <Divider orientation="center">当前版本</Divider> */}
            <div style={{ width: 'calc(100vw - 430px)' }}>
                <Row gutter={12}>
                    {
                        hexStages.length > 0 && hexStages.map((hexStage) => {
                            return (
                                <Col span={6} className="branch-current-version-item" key={hexStage.id.toString()}>
                                    <Card style={{ height: '100%' }} title={(
                                        <div>
                                            <Tag color={stagePriorityColors[hexStage.priority]}>{hexStage.name}</Tag>
                                            {hexStage.name}版本
                                        </div>
                                    )}
                                    >
                                        {
                                            hexStage.hexGroupStages.length > 0 && hexStage.hexGroupStages.map((item: any, index: number) => {
                                                return (
                                                    <div className="group-stage-item" key={hexStage.id + '-' + index}>
                                                        <Tooltip title={"版本说明: " + (item.hexVersionDesc || "无")}>
                                                            <div className="version-name" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <span style={{ fontSize: 12 }}>
                                                                    {item.hexGroupName}：{item.hexVersionName || "未设置"}
                                                                    {
                                                                        item.isNew && (<Tag color="orange" style={{ marginLeft: 8, transform: 'scale(0.8)' }}>Latest</Tag>)
                                                                    }
                                                                </span>
                                                                <span style={{ color: 'gray', fontSize: 12 }}>{dayjs(item.gmtModify).format("YY-MM-DD HH:mm:ss")}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <section>
                <div className="more" style={{ marginTop: 0 }}>
                    <div className="versions" style={{ width: 'calc(100vw - 800px - 40px)', background: "#fff" }}>
                        <Divider orientation="center">版本管理<Button type="link" style={{ marginBottom: 10 }} onClick={openHexVersionCreateModal}><PlusCircleOutlined />添加版本</Button></Divider>

                        <Tabs tabBarExtraContent={{
                            // left: (<div style={{paddingRight: 10}}>{project.hexGroups ? project.hexGroups.length + "组 " : ""}</div>),
                            right: (<Button type="link" onClick={openGroupManagerModal}>
                                <AppstoreAddOutlined />
                                分组管理
                            </Button>)
                        }}>
                            {
                                project?.hexGroups?.length > 0 && project?.hexGroups.map((group: any) => {
                                    return (
                                        <Tabs.TabPane closable={false} key={group.id.toString()} tab={(
                                            <Dropdown key={group.id.toString()} trigger={["contextMenu"]} overlay={(
                                                <Menu>
                                                    <Menu.Item onClick={() => {
                                                        openTypeKeyModal(group);
                                                    }}>
                                                        typeKey
                                                    </Menu.Item>
                                                </Menu>
                                            )}>

                                                <span>{group.name}</span>
                                            </Dropdown>
                                        )} style={{ paddingBottom: 50 }}>
                                            {
                                                group.versions.length > 0 ? group.versions.map((version: any) => {
                                                    return (
                                                        <div key={"version-" + version.id}>
                                                            <Table
                                                                size="small"
                                                                columns={genVersionTableColumns(version, hexStages, () => {
                                                                    openHexVersionBindModal(version);
                                                                }, (_, stage) => {
                                                                    request.post(apis.hexStage, {
                                                                        hexVersionId: version.id,
                                                                        groupStageId: stage.groupStageId
                                                                    }).then((res) => {
                                                                        if (res.code === 1) {
                                                                            message.success("设置成功");
                                                                            fetchProjectData(projectId);
                                                                        } else {
                                                                            throw new Error(res.msg);
                                                                        }
                                                                    }).catch((e) => {
                                                                        message.warn(e.message);
                                                                    })
                                                                }, (hexFile: any, version) => {
                                                                    //移出hex file from version
                                                                    Modal.confirm({
                                                                        centered: true,
                                                                        title: "是否将" + hexFile.fileName + "从版本" + version.name + "中移出？",
                                                                        width: 500,
                                                                        onOk: () => {
                                                                            onRemoveHexFileFromVersion(hexFile.id);
                                                                        }
                                                                    });
                                                                })}
                                                                locale={{
                                                                    emptyText: (
                                                                        <HexConfigView
                                                                            version={version}
                                                                            hexConfig={version.hexConfig}
                                                                            onHexConfigChange={(hexConfig) => {
                                                                                console.log(version.hexConfig);
                                                                                
                                                                                console.log("onHexConfigChange", hexConfig);
                                                                                
                                                                                version.hexConfig = hexConfig;
                                                                                setProject({
                                                                                    ...project
                                                                                });
                                                                            }}
                                                                        />
                                                                    ),

                                                                }}
                                                            />
                                                            <Table
                                                                size="small"
                                                                showHeader={false}
                                                                pagination={false}
                                                                style={{ marginBottom: 34 }}
                                                                columns={genVersionTableColumns(version, hexStages, () => {
                                                                    openHexVersionBindModal(version);
                                                                }, (_, stage) => {
                                                                    request.post(apis.hexStage, {
                                                                        hexVersionId: version.id,
                                                                        groupStageId: stage.groupStageId
                                                                    }).then((res) => {
                                                                        if (res.code === 1) {
                                                                            message.success("设置成功");
                                                                            fetchProjectData(projectId);
                                                                        } else {
                                                                            throw new Error(res.msg);
                                                                        }
                                                                    }).catch((e) => {
                                                                        message.warn(e.message);
                                                                    })
                                                                }, (hexFile: any, version) => {
                                                                    //移出hex file from version
                                                                    Modal.confirm({
                                                                        centered: true,
                                                                        title: "是否将" + hexFile.fileName + "从版本" + version.name + "中移出？",
                                                                        width: 500,
                                                                        onOk: () => {
                                                                            onRemoveHexFileFromVersion(hexFile.id);

                                                                        }
                                                                    });
                                                                })}
                                                                locale={{
                                                                    emptyText: "还未关联文件",

                                                                }}
                                                                dataSource={version.hexFiles.sort((a: any, b: any) => parseInt(a.infoChannel, 16) > parseInt(b.infoChannel, 16) ? -1 : 1)}
                                                            />

                                                        </div>
                                                    )
                                                }) : (
                                                    <Empty description={(<span>该分组未创建版本, 点击<a onClick={openHexVersionCreateModal}>创建版本</a></span>)} />
                                                )
                                            }
                                        </Tabs.TabPane>
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                    <div className="files" style={{ background: "#fff", width: 390 }}>
                        <Divider orientation="center">HEX文件记录<Button type="link" style={{ marginBottom: 10 }} onClick={openHexFilesUploadModal}><UploadOutlined />上传文件</Button></Divider>
                        {
                            hexFilesGroupByUploadDate.length > 0 ? (
                                <div className="record-file-list">
                                    {
                                        hexFilesGroupByUploadDate.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="title " >{item.date}</div>
                                                    {item.hexFiles.length > 0 && item.hexFiles.map((hexFile) => {
                                                        return (
                                                            <div className="record-file-item flex" key={hexFile.id.toString()}>
                                                                {
                                                                    hexFile.hexGroupId > 0 && (
                                                                        <Tag>{hexFile.hexGroupName}</Tag>
                                                                    )
                                                                }
                                                                {
                                                                    hexFile.hexVersionId > 0 && (
                                                                        <Tag>{hexFile.hexVersionName}</Tag>
                                                                    )
                                                                }
                                                                <div className={"info-channel" + (hexFile.infoChannel ? "" : " none")}>{hexFile.infoChannel || "无"}</div>
                                                                {hexFile.fileName}
                                                                {!(hexFile.hexVersionId) && (
                                                                    <Button type="link" title="HEX文件未关联版本，可被删除" danger onClick={() => {
                                                                        Modal.confirm({
                                                                            centered: true,
                                                                            title: "是否删除HEX文件" + hexFile.fileName,
                                                                            width: 400,
                                                                            content: "此操作不可恢复，请确认是否删除该HEX文件",
                                                                            onOk: () => {
                                                                                onRemoveHexFile(hexFile.id);

                                                                            }
                                                                        });
                                                                    }}>
                                                                        <DeleteOutlined />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <Empty style={{ marginTop: 50 }} description={<span>暂无已上传的HEX文件, 点击<a onClick={openHexFilesUploadModal}>上传HEX文件</a></span>} />
                            )
                        }
                    </div>
                </div>
            </section>
            <section style={{ marginTop: 17, fontSize: 14, paddingBottom: 50, textAlign: 'center' }}>
                <span style={{ color: "gray", }}>到底了~</span></section>
        </div>
    )
}

export default HexManagerPage;



interface HexConfigViewProps {
    version: any,
    hexConfig: any,
    onHexConfigChange: (hexConfig: any) => void;
}


const HexConfigView = (props: HexConfigViewProps) => {

    const { hexConfig } = props;

    const endpoinsRef = useRef<any>();
    const configEditor = useRef<any>();
    const hexConfigRef = createRef<any>();


    const onAddHexConfig = async (hexConfig: any) => {
        const data = {
            versionId: props.version.id,
            bindMethod: hexConfig.bindMethod.key,
            groupMethod: hexConfig.groupMethod.key,
            sceneMethod: hexConfig.sceneMethod.key,
            supports: hexConfig.supports.map((support: any) => support.value),
            endpoints: hexConfig.endpoints.map((endpoint) => {
                return {
                    endpoint: Number(endpoint.endpoint),
                    appType: endpoint.appType.key,
                    bindType: endpoint.bindType.key,
                    opened: endpoint.opened,
                    requireControlLine: endpoint.requireControlLine

                }
            })
        }
        hexConfig.versionId = props.version.id;
        const res = await request.post(apis.hex + `/config`, data);
        if (res.code === 1) {
            const endpointIdMap = {};
            hexConfig.id = res.data.id;
            hexConfig.configId = res.data.id;
            for (let i = 0; i < res.data.endpoints.length; i++) {
                const endpoint = res.data.endpoints[i];
                endpointIdMap[endpoint.endpoint] = endpoint.id;
            }
            for (let i = 0; i < hexConfig.endpoints.length; i++) {
                const endpoint = hexConfig.endpoints[i];
                endpoint.id = endpointIdMap[endpoint.endpoint];
                endpoint.endpointId = endpointIdMap[endpoint.endpoint];
                endpoint.configId = res.data.id;
            }
            props.onHexConfigChange(hexConfig);
            return true;
        }
    }

    const onUpdateHexConfig = async (_hexConfig: any) => {
        const data = {
            id: _hexConfig.id,
            bindMethod: _hexConfig.bindMethod.key,
            sceneMethod: _hexConfig.sceneMethod.key,
            groupMethod: _hexConfig.groupMethod.key,
            supports: _hexConfig.supports.map((s) => s.value)
        }
        _hexConfig.endpoints = props.hexConfig.endpoints;
        _hexConfig.versionId = props.hexConfig.versionId;
        _hexConfig.projectId = props.hexConfig.projectId;
        _hexConfig.groupId = props.hexConfig.groupId;
        _hexConfig.supports = _hexConfig.supports.map((s) => ({ key: s.value, name: s.label }))
        
        const res = await request.put(apis.hex + `/config`, data);
        if(res.code === 1) {
            props.onHexConfigChange(_hexConfig);
        
            return true;
        }
    }

    const onUpdateEndpoints = (endpoints: any[]) => {
        const hexConfig = { ...props.hexConfig, endpoints: endpoints };
        props.onHexConfigChange(hexConfig);
    }

    return (
        <div style={{ color: "#888", justifyContent: 'flex-start', flex: 1, width: '100%' }} className="flex columnn">
            {
                hexConfig ? (
                    <div className="flex column" style={{ flex: 1, alignItems: 'flex-start' }}>
                        <div style={{ flex: 1 }}>
                            <span>配置信息</span>
                            <Button type="link" onClick={() => {
                                configEditor.current.open({
                                    id: hexConfig.id,
                                    bindMethod: hexConfig.bindMethod?.key,
                                    groupMethod: hexConfig.groupMethod?.key,
                                    sceneMethod: hexConfig.sceneMethod?.key,
                                    supports: hexConfig.supports.map((item) => item.key)
                                })
                            }}><SettingOutlined />修改配置</Button>
                            <Button type="link" onClick={() => {
                                endpoinsRef.current?.open(props.hexConfig);
                            }}><FileSearchOutlined />{hexConfig.endpoints.length} endpoints，查看端口列表 </Button>
                        </div>
                        <div className="flex" style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <div style={{ marginRight: 10 }}>绑定：<span className="bold" style={{ color: "#000" }}>{hexConfig.bindMethod?.name}</span></div>
                            <div style={{ marginRight: 10 }}>建组：<span className="bold" style={{ color: "#000" }}>{hexConfig.groupMethod?.name}</span></div>
                            <div style={{ marginRight: 10 }}>建场景：<span className="bold" style={{ color: "#000" }}>{hexConfig.sceneMethod?.name}</span></div>
                        </div>
                        <div className="flex" style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <div style={{ marginRight: 10 }}>支持：<span className="bold" style={{ color: "#000" }}>{hexConfig.supports.map((support) => support.name).join('、')}</span></div>
                        </div>
                    </div>
                ) : (
                    <div className="flex" style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
                        无配置信息 <Button type="link" onClick={() => {
                            hexConfigRef.current.open();
                        }}><SettingOutlined />添加配置</Button>
                        <AddHexConfig
                            ref={hexConfigRef}
                            version={props.version}
                            onHexConfigAdd={onAddHexConfig}
                        />
                    </div>
                )
            }
            <HexConfigEndpointsView
                ref={endpoinsRef}
                onEndpointsChange={onUpdateEndpoints}
            />
            <HexConfigEditor
                ref={configEditor}
                onHexConfigChange={onUpdateHexConfig}
            />
        </div>
    )
}
