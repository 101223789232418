import { CheckOutlined } from "@ant-design/icons";
import { Skeleton, Tag } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface ProductSpuProps {
    onProductSpuSelected: (spu: any) => void,
    selectedSpuId?: number
}
const ProductSpuSelector = forwardRef((props: ProductSpuProps, ref: any) => {

    const [loading, setLoading] = useState(false);
    const [productSpus, setProductSpus] = useState([] as any[]);
    const [cates, setCates] = useState([] as any[]);
    const [selectedCateId, setSelectedCateId] = useState(0);

    useEffect(() => {
        getProductSpus();
    }, [])

    const getProductSpus = async () => {
        setLoading(true);
        try {

            const res = await request.get(apis.productSpu, {
                isSmart: 1
            });
            if (res.code === 1) {
                setProductSpus(res.data);
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const cateIdSet = new Set<number>();
        const cates: any[] = [{
            id: 0,          
            name: "全部"
        }];
        for (let i = 0; i < productSpus.length; i++) {
            const spu = productSpus[i];
            if (!cateIdSet.has(spu.spuCateId)) {
                cateIdSet.add(spu.spuCateId);
                cates.push({
                    id: spu.spuCateId,
                    name: spu.spuCateName,
                });
            }
        }
        setCates(cates);
    }, [productSpus]);

    const [displaySpus, setDisplaySpus] = useState([] as any[]);
    useEffect(() => {
        if (selectedCateId === 0) {
            setDisplaySpus(productSpus);
        } else {
            const displaySpus = productSpus.filter((ps) => {
                return ps.spuCateId === selectedCateId;
            });
            setDisplaySpus(displaySpus);
        }
    }, [productSpus, selectedCateId]);

    const [selectedSpuId, setSelectedSpuId] = useState(props.selectedSpuId || 0);
    useEffect(() => {
        if(loading) {
            return ;
        }
        if (selectedSpuId) {
            const spu = productSpus.find((ps) => ps.spuId === selectedSpuId);
            props.onProductSpuSelected(spu);
        } else {
            props.onProductSpuSelected(null);
        }
    }, [selectedSpuId, loading]);


    useImperativeHandle(ref, () => {
        return {
            onCancelSpuSelect: () => {
                setSelectedSpuId(0);
            }
        }
    });

    if (loading) {
        return (
            <Skeleton active />
        )
    }

    return (
        <div className="spu-select">
            <div className="title bold" style={{ paddingBottom: 10, fontSize: 15 }}>请选择要关联的智能产品</div>
            <div className="flex" style={{ padding: '8px 0 16px 0', alignItems: 'center' }}>
                <div style={{ width: '4em' }}>分类: </div>
                <div className="flex">
                    {
                        cates.length > 0 && cates.map((c) => {
                            return (
                                <div
                                    className="cate-item"
                                    style={{ marginLeft: 20, padding: '3px 16px', background: selectedCateId === c.id ? 'rgba(0, 0, 0, .07)' : '', borderRadius: 6, cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedCateId(c.id);
                                    }}
                                >{c.name}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="spu-list" style={{ height: 'calc(100% - 150px)', overflowY: 'auto', padding: '5px 5px 5px 5px' }}>
                {
                    displaySpus.length > 0 && displaySpus.map((spu) => {
                        return (
                            <div className="spu-item"
                                style={{ position: 'relative', padding: 10, margin: '0 15px 15px 0', boxShadow: '0 3px 10px rgba(0, 0, 0, .1)', display: 'inline-block', verticalAlign: 'top', cursor: 'pointer' }}
                                onClick={() => {
                                    if (spu.spuId === selectedSpuId) {
                                        setSelectedSpuId(0);
                                    } else {
                                        setSelectedSpuId(spu.spuId);
                                    }
                                }}
                            >
                                <div className="flex">
                                    <div className="img-wrapper" style={{ width: '50px', height: '50px' }}>
                                        <img src={spu.imageUrl} alt="" style={{ height: '100%', width: '100%' }} />
                                    </div>
                                    <div style={{ width: 150, marginLeft: 10 }}>
                                        <div style={{ fontSize: 16 }}>{spu.spuName}</div>
                                        <div>{spu.spuMode}</div>
                                    </div>
                                </div>
                                <div style={{marginTop: 10}}>
                                    {
                                        Boolean(spu.isSmart) && (
                                            <Tag color={"purple"}>智能产品</Tag>
                                        )
                                    }
                                    {
                                        spu.gatewayType !== 0 && (spu.gatewayType === 1 ? (
                                            <Tag color={"green"}>网关</Tag>
                                        ) : (
                                            <Tag color={"green"}>网关模块</Tag>
                                        ))
                                    }
                                    {
                                        Boolean(spu.isRowFrame) && (
                                            <Tag color={"pink"}>连排框</Tag>
                                        )
                                    }
                                    {
                                        Boolean(spu.supportRowFrame) && (
                                            <Tag color={"blue"}>支持连排</Tag>
                                        )
                                    }
                                </div>
                                <div style={{ height: 40, color: 'gray', lineHeight: '18px', marginTop: 4 }}>
                                    {spu.spuDesc}
                                </div>
                                {
                                    selectedSpuId === spu.spuId && (
                                        <div className="flex center bg-primary" style={{ position: 'absolute', bottom: 10, right: 10, width: 20, height: 20, borderRadius: 10 }}>
                                            <CheckOutlined style={{ color: "#fff" }} />
                                        </div>
                                    )
                                }

                            </div>
                        )
                    })
                }
            </div>

        </div>
    )

});


export default ProductSpuSelector;