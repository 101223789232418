import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal, Select, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { request } from '../../../../utils/request';
import { apis } from '../../../../utils/apis';

interface Props {
    visible: boolean;
    onCancel: () => void;
    project: any;
    onRefresh: (projectId: number) => void;

}

export const HexFilesUploadModal = (props: Props) => {

    const [uploading, setUploading] = useState(false);
    const [currentHexGroup, setCurrentHexGroupId] = useState(0);

    useEffect(() => {
        if (!props.project.hexGroups) {
            return;
        }
        setCurrentHexGroupId(props.project.hexGroups[0].id);
    }, [props.project]);

    return (
        <Modal title="上传HEX文件" maskClosable={false} destroyOnClose centered visible={props.visible} onCancel={() => {
            if (!uploading) {
                props.onRefresh(props.project.id);
                props.onCancel();
            }
        }} onOk={() => {
            props.onRefresh(props.project.id);
            if (!uploading) {
                props.onRefresh(props.project.id)
                props.onCancel();
            }
        }} okButtonProps={{
            disabled: uploading
        }} cancelButtonProps={{
            disabled: uploading
        }}>


            {
                props.project.hexGroups?.length > 1 ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Alert type="warning" showIcon message={"注意，该项目有多个分组，请选择正确的HEX分组"} />
                        <Select style={{ minWidth: 100 }} value={currentHexGroup} onChange={(value) => {
                            setCurrentHexGroupId(value);
                        }}>
                            {
                                props.project.hexGroups.map((hexGroup: any, index: number) => {
                                    return (
                                        <Select.Option value={hexGroup.id} key={"hex-" + index}>
                                            {hexGroup.name}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                ) : <></>
            }
            <Upload.Dragger
                name={'file'}
                accept=".hex,.bin"
                multiple={true}
                action={apis.hexUpload}
                headers={{
                    authorization: request.getAccessToken()
                }}
                data={(file) => {
                    return {
                        lastModified: Number(file.lastModified),
                        uid: file.uid,
                        projectId: props.project.id,
                        hexGroupId: currentHexGroup
                    }
                }}
                onChange={(info) => {
                    const { status } = info.file;
                    if (status !== 'uploading') {
                    }
                    if (status === 'done') {
                        message.success(`${info.file.name} 上传成功`);
                    } else if (status === 'error') {
                        message.error(`${info.file.name} 上传失败`);
                    }
                }}

            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击这里打开文件夹，或把文件拖到这里</p>
                <p className="ant-upload-hint">
                    请上传HEX格式文件，支持一次上传多个文件
                </p>
            </Upload.Dragger>
        </Modal>
    )

}