import { ClockCircleOutlined, CloudFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { sleep } from "../../../../../../utils";
import { apis } from "../../../../../../utils/apis";
import { getNodeImageUrlByTypeKey } from "../../../../../../utils/constants";
import { request } from "../../../../../../utils/request";

interface NetNodesWaitingProps {
    caseId?: number;
    onOk: () => void;
}

let newCount = 0;
const NetNodesWaiting = (props: NetNodesWaitingProps) => {

    const [times, setTimes] = useState(1);
    const [nodes, setNodes] = useState([] as any[]);
    const [time, setTime] = useState(0);

    useEffect(() => {
        setTime(0);
        newCount = 0;
    }, [props.caseId]);

    useEffect(() => {
        if (!props.caseId) {
            return;
        }
        getNodes(props.caseId);
    }, [props.caseId, times]);


    const getNodes = async (caseId: number) => {
        const res = await request.get(apis.caseNode, { caseId: caseId }, { autoErrorTip: false });
        if (res.code === 1) {
            setNodes(res.data.caseNodes);
            let isAllOnline = true;
            let countTemp = 0;
            for (let i = 0; i < res.data.caseNodes.length; i++) {
                const node = res.data.caseNodes[i];
                if (!node.isOnline) {
                    isAllOnline = false;
                    countTemp++;
                }
            }
            if(countTemp > newCount) {
                newCount = countTemp;
            }
            if (isAllOnline) {
                for (let i = 2 * newCount; i >= newCount; i--) {
                    setTime(i);
                    await sleep(1500);
                }
                request.post(apis.caseConfigNetDisableJoinBroadcast, { caseId: props.caseId });
                for (let i = newCount - 1; i >= 1; i--) {
                    setTime(i);
                    await sleep(1000);
                }
                props.onOk();
                setTime(0);
                newCount = 0;
            } else {
                await sleep(1000);
                setTimes(times + 1);
            }
        } else {
            Modal.confirm({
                title: "出现异常",
                centered: true,
                content: res.msg,
                okText: "重新尝试",
                onOk: () => {
                    setTimes(times + 1);
                },
                cancelText: "取消",
            });
        }
    }
    return (
        <div className="net-nodes-waiting flex column center" >
            <ClockCircleOutlined spin className="" style={{ fontSize: 128, color: "lightblue", marginTop: 100 }} />
            <span className="bold" style={{ fontSize: 32, marginTop: 20, color: "lightblue" }}>正在<span style={{ color: "purple" }}>等待产品全部上线</span>, 请保持网关在线</span>
            <div style={{ width: 500, marginTop: 20 }}>
                {
                    nodes.map((caseNode) => {
                        return (
                            <div className="flex" style={{ marginBottom: 3, marginRight: 20, paddingRight: 10, }}>
                                <div className="flex column center" style={{ marginRight: 10 }}>
                                    <img src={getNodeImageUrlByTypeKey(caseNode.typeKey)} alt="" style={{ height: 18, width: 18 }} />
                                </div>
                                <div className="flex" style={{ alignItems: 'center' }}>
                                    {caseNode.isOnline ? (
                                        <span style={{ marginTop: 3 }}>
                                            <CloudFilled style={{ color: "green", fontSize: 14, marginRight: 5 }} />
                                        </span>
                                    ) : (
                                        <span style={{ marginTop: 3 }}>
                                            <CloudFilled style={{ color: "orange", fontSize: 14, marginRight: 5 }} />
                                        </span>
                                    )}
                                    <div className="bold" style={{ fontSize: 14 }}>{caseNode.name}{caseNode.deviceCode ? `（${caseNode.deviceCode}）` : ''} <span style={{ color: "gray", fontWeight: "normal" }}>（{caseNode.mac || "未烧录"}）</span></div>

                                </div>
                            </div>
                        )
                    })
                }

            </div>            {
                time > 0 && (
                    <span  style={{ fontSize: 20, color: "purple", marginTop: 20 }}>
                        倒计时:{time}
                    </span>
                ) 
            }
        </div>
    )

}

export default NetNodesWaiting;