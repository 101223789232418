
import React, { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import ScanCodeWrapper from "../../components/ScanCodeWrapper";
import { apis } from "../../utils/apis";
import { request } from "../../utils/request";
import HexSelector from "./components/HexSelector";
import HexWriter from "./components/HexWriter";

interface BurnToolProps extends RouteChildrenProps {
    style?: CSSProperties;
    simple?: boolean;
    caseId?: number;
    caseNodeId?: number;
    children?: any;
    selectable?: boolean;
    onBeforeScan?: (code: any) => Promise<any>;
    onStart?: () => void;
    onInterrupt?: () => void;
    onErase?: (device: any) => Promise<void>;
    onFinish?: (data: any) => Promise<any>;
    onEnd?: () => void;
}

const BurnTool = forwardRef((props: BurnToolProps, ref: any) => {

    const [hexFile, setHexFile] = useState<any>();

    const ref1 = useRef<any>();
    const [device, setDevice] = useState(null as any);

    const onCanScan = () => {
        ref1.current.release();
    }

    const onDisableScan = () => {
        ref1.current.disable();
    }

    const onEscape = () => {
        ref1.current.escape();
    }

    const updateDevice = (device: any) => {
        ref1.current.updateDevice(device);
    }

    useImperativeHandle(ref, () => {
        return {
            onCanScan,
            onDisableScan
        }
    })

    const onDeviceEraseHexFileName = async (device: any) => {
        const res = await request.post(apis.productDevice + `/erase/hex-file/${device.deviceCode}`, {
            deviceId: device.id
        });
        if(res.code === 1) {
            updateDevice(res.data);
            props.onErase && await props.onErase(res.data);
        }
    }
    const onDeviceReportMacAddr = async (device: any, macAddr: string) => {

        if (device.macAddr !== macAddr) {
            const res1 = await request.put(apis.productDevice + `/mac-addr/report/${device.deviceCode}`, {
                macAddr
            });
            if (res1.code === 1) {
                device.macAddr = macAddr;
                updateDevice({ ...device });
            }

        }
    }

    const onDeviceHexFileReport = async (device: any, hexFile: any) => {
        const res1 = await request.put(apis.productDevice + `/flash-finish/report/${device.deviceCode}?from=reflash`, {
            targetHexFile: hexFile
        });
        if (res1.code === 1) {
            device.hexFileName = hexFile.fileName;
            device.hexFileUuid = hexFile.uuid;
            updateDevice({ ...device });
        }

    }

    useImperativeHandle(ref, () => {
        return {
            setHexFile,
            onEscape
        }
    });

    useEffect(() => {
        console.log("burn tool hex file change", hexFile?.fileName);
        
    }, [hexFile])

    return (
        <div style={{ background: "#f2f2f2" }}>
            <HexSelector
                simple={props.simple}
                style={{ boxShadow: '0 3px 5px rgba(0, 0, 0, .2)', position: 'relative', zIndex: 9 }}
                hexFile={hexFile}
                onChange={setHexFile}
                selectable={props.selectable}
            />

            <ScanCodeWrapper
                ref={ref1}
                style={{
                    height: `calc(${props.style?.height || '100vh'} - ${props.simple ? '48px' : '130px'})`,
                    width: props.style?.width || '100vw'
                }}
                onScan={(_, device) => {
                    setDevice(device);
                }}
                onBeforeScan={props.onBeforeScan}
            >
                <HexWriter
                    // ref={ref2}
                    device={device}
                    hexFile={hexFile}
                    caseId={props.caseId}
                    caseNodeId={props.caseNodeId}
                    onCanScan={onCanScan}
                    onDisableScan={onDisableScan}
                    onEscape={onEscape}
                    style={{
                        paddingTop: 12,
                        height: `calc(${props.style?.height || '100vh'} - ${props.simple ? '60px' : '130px'} - 38px)`,
                        width: props.style?.width || '100vw'
                    }}
                    children={props.children}
                    onStart={props.onStart}
                    onInterrupt={props.onInterrupt}
                    onErase={async () => {
                        await onDeviceEraseHexFileName(device);
                    }}
                    onEnd={props.onEnd}
                    onFinish={async (device, hexFile, macAddr) => {
                        await onDeviceReportMacAddr(device, macAddr);
                        await onDeviceHexFileReport(device, hexFile)
                        return props.onFinish && await props.onFinish({
                            device,
                            hexFile
                        });
                    }}
                    
                />
            </ScanCodeWrapper>
        </div>
    )

})

export default BurnTool;
