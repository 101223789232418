import { CloudFilled, EditOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { apis } from '../../../../../../../../utils/apis';
import { request } from '../../../../../../../../utils/request';
import { SourceConnector } from '../../NodeCellConnector';

const renameFormRef = React.createRef<FormInstance>();
interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingAccepts: string;
    onRenameApp: (app: any) => void;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (item: any) => void;
    className?: string;
    viewSource: any;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const TouchNodeCell = (props: Props) => {
    const { node } = props;

    const [sourceDisabledMap, setSourceDisabledMap]: any = useState({});
    const [sourceSelectedMap, setSourceSelectedMap]: any = useState({});
    useEffect(() => {
        if (!props.node) {
            return;
        }
        if (props.bindMode === "wait") {
            let update = false;
            for (let i = 0; i < props.node.apps.length; i++) {
                const app = props.node.apps[i];
                if (sourceDisabledMap[app.id]) {
                    sourceDisabledMap[app.id] = false;
                    update = true;
                }
                if (sourceSelectedMap[app.id]) {
                    sourceSelectedMap[app.id] = false;
                    update = true;
                }
            }
            if (update) {
                setSourceDisabledMap({ ...sourceDisabledMap });
                setSourceSelectedMap({ ...sourceSelectedMap });
            }
        } else if (props.bindMode === "targets") {
            for (let i = 0; i < props.node.apps.length; i++) {
                const app = props.node.apps[i];
                if (app.id !== props.bindingSource?.id) {
                    sourceDisabledMap[app.id] = true;
                } else {
                    sourceDisabledMap[app.id] = false;
                    sourceSelectedMap[app.id] = true;
                }
            }
            setSourceDisabledMap({ ...sourceDisabledMap });
            setSourceSelectedMap({ ...sourceSelectedMap })
        } else if (props.bindMode === "view") {
            for (let i = 0; i < props.node.apps.length; i++) {
                const app = props.node.apps[i];
                if (app.id !== props.viewSource?.id) {
                    sourceDisabledMap[app.id] = true;
                } else {
                    sourceDisabledMap[app.id] = false;
                    sourceSelectedMap[app.id] = true;
                }
            }
            setSourceDisabledMap({ ...sourceDisabledMap });
            setSourceSelectedMap({ ...sourceSelectedMap })
        }
    }, [props.bindingSource, props.node, props.bindMode]);

    const [renameModalVisible, setRenameModalVisible] = useState(false);
    const [renameTemp, setRenameTemp] = useState("");
    const [renameApp, setRenameApp] = useState<any>();
    const openRenameAppModal = (app: any) => {
        setRenameModalVisible(true);
        setRenameTemp(app.name);
        setRenameApp(app);
    }
    const closeRenameAppModal = () => {
        setRenameModalVisible(false);
        setRenameTemp("");
        setRenameApp(undefined);
    }

    const onRenameApp = (data: any) => {
        if (data.name === renameApp.name) {
            closeRenameAppModal();
            return;
        }
        request.put(apis.caseNodeApp + `/${data.id}`, data)
            .then((res) => {
                if (res.code === 1) {
                    if (typeof props.onRenameApp === 'function') {
                        closeRenameAppModal();
                        props.onRenameApp(res.data);
                        message.success("已修改名称");
                    }
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }

    return (
        <div className="node-cell scene-and-speaker-node-cell">
            <Modal
                centered
                title="重命名"
                visible={renameModalVisible}
                onOk={() => {
                    renameFormRef.current?.submit();
                }}
                onCancel={closeRenameAppModal}
                width={300}
                destroyOnClose
            >
                <Form
                    ref={renameFormRef}
                    initialValues={{
                        name: renameTemp,
                        id: renameApp?.id
                    }}
                    onValuesChange={(data) => {
                        if ("name" in data) {
                            setRenameTemp(data.name)
                        }
                    }}
                    onFinish={(data) => {
                        onRenameApp(data);
                    }}
                >
                    <Form.Item hidden name="id"></Form.Item>
                    <Form.Item label="名称" name="name" rules={[{ required: true, message: "请输入名称" }]}>
                        <Input placeholder="请输入名称" autoFocus autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>
            <div className={props.className || "four-switch"}>
                {
                    props.node?.apps.length > 0 && props.node.apps.map((app: any) => {
                        if (app.endpoint === 31) {
                            return;
                        }
                        return (
                            <div className="switch-item" style={{ fontSize: 10, textAlign: 'center', verticalAlign: "top" }}>
                                <div className="flex-center flex-column flex-1" style={{ width: '100%', height: '100%' }}>
                                    <span>
                                        {app.name}
                                        <Button size="small" type="link" disabled={props.bindMode !== "wait"} onClick={() => {
                                            openRenameAppModal(app)
                                        }}>
                                            <EditOutlined />
                                        </Button>
                                    </span>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                        {
                                            !sourceDisabledMap[app.id] && (
                                                <SourceConnector
                                                    disabled={sourceDisabledMap[app.id]}
                                                    selected={sourceSelectedMap[app.id]}
                                                    connected={app.isSource}
                                                    onClick={() => {
                                                        if (app.isSource) {
                                                            if (props.bindMode === "view") {
                                                                if (typeof props.onCancelView === 'function') {
                                                                    props.onCancelView();
                                                                }
                                                            } else if (props.bindMode === "wait") {
                                                                if (typeof props.onViewBindRelations === 'function') {
                                                                    props.onViewBindRelations(app, "not general_ir_human_sensor");
                                                                }
                                                            }
                                                        } else {
                                                            if (props.bindMode === "wait") {
                                                                if (typeof props.onSetBindingSource === "function") {
                                                                    props.onSetBindingSource(app, "not general_ir_human_sensor");
                                                                }
                                                            } else if (props.bindMode === "targets") {
                                                                if (typeof props.onResetBindingSource === "function") {
                                                                    props.onResetBindingSource();
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="prod-name">
            {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )

}