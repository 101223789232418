
import { spawn, iconv } from '../../../../utils/electron'

export const flashProgrammer = async (cmd: string, onData: (message: string, type: "node" | "stdout" | "stderr") => void) => {
    return await new Promise((resolve) => {
        const child = spawn(cmd, [], {
            shell: true
        });
        let type: "none" | "stdout" | "stderr" = "stdout";
        child.stdout.setEncoding("hex");
        child.stdout.on("data", (chunk) => {
            if (type !== "stdout") {
                type = "stdout";
            }
            let temp = "";
            if (typeof chunk === 'string') {
                temp = iconv.decode(Buffer.from(chunk, "hex"), 'cp936');
            } else {
                temp = iconv.decode(Buffer.from(chunk, "binary"), 'cp936');
            }
            onData(temp, type);
        });
        child.stderr.on("data", (chunk) => {
            if (type !== "stderr") {
                type = "stderr";
            }
            let temp = "";
            if (typeof chunk === 'string') {
                temp = iconv.decode(Buffer.from(chunk, "hex"), 'cp936');
            } else {
                temp = iconv.decode(Buffer.from(chunk, "binary"), 'cp936');
            }
            onData(temp, type);
        });
        child.stdout.on("error", (error) => {
            throw error;
        });
        child.on("error", (error) => {
            throw error;
        })
        child.on("close", (code, signal) => {
            resolve({ method: "close", code, signal })
        })
        child.on("exit", (code, signal) => {
            resolve({ method: "exit", code, signal });
        })
    });
}
