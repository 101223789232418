import { useEffect, useState } from "react";
import { ControlLayout, ControlWidget } from "./Connector";
import ControlProductCellWrapper, { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";
import { Switch, SwitchCellWrapper } from "./SwitchCell";

interface Props extends SwitchCellWrapper {
    silk1: { cn?: string, en?: string };
    silk2: { cn?: string, en?: string };
    silk3: { cn?: string, en?: string };
    silk4: { cn?: string, en?: string };
    silk5: { cn?: string, en?: string };
    silk6: { cn?: string, en?: string };
}

const SixSwitchCell = (props: Props) => {

    const [sources, setSources] = useState([] as any[]);
    const [targets, setTargets] = useState([] as any[]);

    const onDataChange = (sources: any[], targets: any[]) => {
        setSources(sources);
        setTargets(targets);
    }

    const [sixTargets, satSixTargets] = useState([]);
    useEffect(() => {
        const sixTargets: any[] = [8, 9, 10, 11];
        for (let i = 0; i < sixTargets.length; i++) {
            const item = sixTargets[i];
            const target = targets.find((t) => t.endpoint === item);
            sixTargets[i] = target;
        }
        satSixTargets(sixTargets);
    }, [targets]);


    return (
        <ControlProductCellWrapper
            {...props}
            style={{background: props.backgroundColor || "#6a6867"}}
            onDataChange={onDataChange}
            controlWidgets={(
                <ControlLayout
                    layout="6-bottom|bottom|bottom-top|top|top"
                >
                    <ControlWidget
                        source={sources[0]}
                        target={sixTargets[0]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[1]}
                        target={sixTargets[1]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[2]}
                        target={sixTargets[2]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[3]}
                        target={sixTargets[3]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[4]}
                        target={sixTargets[4]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[5]}
                        target={sixTargets[5]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                </ControlLayout>
            )}
        >
            <div className="flex" style={{ height: "100%" }}>
                <Switch
                    border
                    width={79}
                    top={{...props.silk4, number: 3, endpoints: [sources[3], sixTargets[3]]}}
                    bottom={{...props.silk1, number: 0, endpoints: [sources[0], sixTargets[0]]}}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                    
                />
                <Switch
                    border
                    width={79}
                    top={{...props.silk5, number: 4, endpoints: [sources[4], sixTargets[4]]}}
                    bottom={{...props.silk2, number: 1, endpoints: [sources[1], sixTargets[1]]}}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
                <Switch
                    border
                    width={80}
                    top={{...props.silk6, number: 5, endpoints: [sources[5], sixTargets[5]]}}
                    bottom={{...props.silk3, number: 2, endpoints: [sources[2], sixTargets[2]]}}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />

            </div>
        </ControlProductCellWrapper>
    )
}

export default SixSwitchCell;
