import { Checkbox, Form, message, Modal, Table } from "antd"
import { useState,createRef, useEffect, useRef } from "react"
import { request } from '../../../../utils/request';
import { apis } from '../../../../utils/apis';
import { HexFilesCombinerModal } from "./HexFilesCombinerModal";
// import {  } from "react"

interface Props {
    visible: boolean,
    onCancel: () => void,
    hexFiles: any[],
    project: any,
    targetVersion: any,
    onUploadHexFile: () => void,
    onBindVersion: (hexFileIds: any[]) => void,
    onHexFilesRefresh: () => void,
}

export const HexVersionBindModal = (props: Props) => {


    const [selectableHexFiles, setSelectableHexFiles] = useState<any[]>([]);

    const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);


    useEffect(() => {
        //否则上传hex文件后不能更新ui
        setSelectableHexFiles(JSON.parse(JSON.stringify(props.hexFiles.filter((hexFile: any) => hexFile.hexGroupId === props.targetVersion.hexGroupId && hexFile.hexVersionId === 0))));
        
    }, [props.targetVersion, props.hexFiles]);

    useEffect(() => {
        for (let i = 0; i < selectableHexFiles.length; i++) {
            const temp = selectableHexFiles[i];
            if(!temp.isChecked) {
                setIsCheckedAll(false);
                return ;
            }
        }
        setIsCheckedAll(true);
        return ;
    }, [selectableHexFiles]);

    const checkSelectableHexFile = (hexFile: any) => {
        hexFile.isChecked = !hexFile.isChecked;
        setSelectableHexFiles([...selectableHexFiles]);
    }

    const selectAll = () => {
        if(isCheckedAll){
            for (let i = 0; i < selectableHexFiles.length; i++) {
                const temp = selectableHexFiles[i];
                temp.isChecked = false;
            }

        }else {
            for (let i = 0; i < selectableHexFiles.length; i++) {
                const temp = selectableHexFiles[i];
                temp.isChecked = true;
            }
        }
        setSelectableHexFiles([...selectableHexFiles]);
    }

    const combiner = useRef<any>();
    return (
        <Modal 
            width={800}
            title={(<span>{"对" + props.targetVersion.hexGroupName + "/" + props.targetVersion.name + "进行HEX文件关联"} <a style={{fontSize: 12}} onClick={props.onUploadHexFile}>上传HEX文件</a></span>)}  centered visible={props.visible} onCancel={props.onCancel}
            onOk={() => {
                const selectedHexFiles = selectableHexFiles.filter((item) => item.isChecked); 
                const channelVersionHexMap: any = {};
                let shouldCombine = false;
                let confuse = false;
                for (let i = 0; i < selectedHexFiles.length; i++) {
                    const item = selectedHexFiles[i];
                    let channel: string;
                    let version: string;
                    let name: string[];
                    try {
                        let [suffix, ...fileName] = item.fileName.split('.').reverse();
                        fileName = fileName.reverse().join('.');
                        console.log(fileName);
                        
                        [channel, version, ...name] = fileName.split('-').reverse();
                        if(!channel || !version ||!name) {
                            confuse = true;
                        }
                    }catch(e) {
                        console.log(e)
                        confuse = true;
                    }
                    const chanelVersion = `${channel}-${version}`;
                    const combineData = {
                        key: chanelVersion,
                        channel,
                        version,
                        name: name.reverse().join('-')
                    };
                    if(!channelVersionHexMap[chanelVersion]) {
                        channelVersionHexMap[chanelVersion] = [{
                            ...item,
                            combineData,
                        }];
                    }else {
                        channelVersionHexMap[chanelVersion].push({
                            ...item,
                            combineData
                        });
                        shouldCombine = true;
                    }
                }
                console.log("shouldCombined", shouldCombine);
                console.log(channelVersionHexMap);
                const selectedFileIds = selectedHexFiles.map((item) => item.id);
                
                // return ;    
                const submit = () => {
                    request.post(apis.hexVersionBind, {versionId: props.targetVersion.id, hexFileIds: selectedFileIds})
                    .then((res) => {
                        if(res.code === 1) {
                            message.success("关联成功");
                            props.onBindVersion(res.data.map((item: any) => item.id));
                            props.onCancel();
                        }else {
                            throw new Error(res.msg);
                        }
                    })
                    .catch((e) => {
                        message.warn(e.message);
                    })
                }
                if(!shouldCombine) {
                    Modal.confirm({
                        title: "请确认是否关联",
                        centered: true,
                        onOk: submit
                    });
                }else {
                    if(confuse) {
                        message.info('未知问题');
                    }
                    combiner.current?.open(props.project.id, props.targetVersion.hexGroupId, selectedHexFiles, channelVersionHexMap);
                }
                
            }}
        >
            <HexFilesCombinerModal 
                ref={combiner}
                onHexFilesRefresh={props.onHexFilesRefresh}
            />
            <Table 
                size="small"
                pagination={false}
                dataSource={selectableHexFiles.sort((a, b) => parseInt(a.infoChannel, 16) > parseInt(b.infoChannel, 16) ? -1 : 1)}
                columns={[{
                    title: (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox id="check-all" style={{marginRight: 5}} checked={isCheckedAll} onClick={selectAll}/>
                            <label htmlFor="check-all">全选</label>
                        </div>
                    ),
                    key: "check",
                    width: 72,
                    render: (_, hexFile) => {
                        return (
                            <Checkbox checked={hexFile.isChecked === true} onChange={() => {
                                checkSelectableHexFile(hexFile);
                            }}/>
                        )
                    }
                }, {
                    title: "#",
                    width: 30,
                    key: "index",
                    render: (_, __, index) => {
                        return index + 1;
                    }
                }, {
                    title: "信道",
                    width: 50,
                    align: 'center',
                    dataIndex: "infoChannel",
                    key: "infoChannel",
                    render: (infoChannel) => {
                        return <span className="text-primary">{infoChannel}</span>
                    }

                }, {
                    title: "文件名",
                    key: "fileName",
                    dataIndex: "fileName",
                    
                }, {
                    title: "上传时间",
                    width: 200,
                    key: "gmtUpload",
                    dataIndex: "gmtUpload",
                    
                }]}
                locale={{
                    emptyText: (
                        <span>{props.project.name}/{props.targetVersion.hexGroupName}/{props.targetVersion.name}没有可关联版本的文件, 点击<a onClick={props.onUploadHexFile}>上传HEX文件</a></span>
                    )
                }}
                
            />
        </Modal>
    )
}