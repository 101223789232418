import { Checkbox, Menu, Skeleton, Tabs } from "antd";
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { apis } from "../../../../../../../utils/apis";
import { getNodeImageUrlByTypeKey } from "../../../../../../../utils/constants";
import { request } from "../../../../../../../utils/request";
import './style.less';

interface CaseNodesRowProps {
    caseId: number;
    onSelected: (type: "net" | "node" | "finish" | "loading", data: any, addition: any) => void;
    style?: CSSProperties;
}

export const CaseNodesRow = forwardRef((props: CaseNodesRowProps, ref: any) => {

    const [loading, setLoading] = useState(true);
    const [selectedCaseNodeId, setSelectedCaseNodeId] = useState(0);
    const [areas, setAreas] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [selectedAreaId, setSelectedAreaId] = useState(0);
    const [caseNet, setCaseNet] = useState(null as any);
    const [writeRequiredOnly, setWriteRequiredOnly] = useState(true);
    const [nodes, setNodes] = useState([] as any[]);
    const [netNode, setNetNode] = useState(null as any);
    const [displayNodes, setDisplayNodes] = useState<any[]>([]);

    const caseNetRef = useRef<any>();

    const [selectable, setSelectable] = useState(true);

    useImperativeHandle(ref, () => {
        return {
            updateCaseNet: (caseNet: any) => {
                if(caseNet.mac && caseNet.hexFileName) {
                    const nextIndex = nodes.findIndex((n, i) => (!n.mac || !n.hexFileName));
                    if (nextIndex > -1) {
                        setSelectedCaseNodeId(nodes[nextIndex].id);
                    }
                }
                setCaseNet(caseNet);
            },
            updateCaseNode: (node: any) => {
                const nodeIndex = nodes.findIndex((n) => n.id === node.id);
                if (nodeIndex > -1) {
                    nodes[nodeIndex] = node;
                }
                let nextIndex = nodes.findIndex((n, i) => i > nodeIndex && (!n.mac || !n.hexFileName) && (selectedAreaId ? selectedAreaId === n.areaId : true));
                //节点烧完，优先找当前区域可烧录的节点
                if (nextIndex === -1) {
                    //当前区域没找到 去下一个区域
                    nextIndex = nodes.findIndex((n, i) => (!n.mac || !n.hexFileName) && (selectedAreaId ? n.areaId > selectedAreaId: true));
                    console.log(nextIndex);
                    
                    if(nextIndex > -1) {
                        setSelectedAreaId(nodes[nextIndex].areaId);
                    }
                }
                //如果没找到，则找下一个区域可烧录的节点
                if(nextIndex === -1) {
                    nextIndex = nodes.findIndex((n, i) => (!n.mac || !n.hexFileName));
                    if(nextIndex > -1) {
                        setSelectedAreaId(nodes[nextIndex].areaId);
                    }
                }
                if(nextIndex === -1) {
                    //找不到 就烧录网关
                    if (!caseNet?.mac || !caseNet?.hexFileName) {
                        setSelectedCaseNodeId(-caseNet.id);
                        return;
                    }
                }
                
                // console.log("updateCaseNode", nextIndex, nodes[nextIndex].id);
                if (nextIndex > -1) {
                    setSelectedCaseNodeId(nodes[nextIndex].id);
                } else {
                    setSelectedCaseNodeId(0);
                }
                setNodes([...nodes]);
                
            },
            showAll: () => {
                setSelectedAreaId(0);
                setSelectedCaseNodeId(0);
                setWriteRequiredOnly(false);
            },
            setSelectable
        }
    });

    useEffect(() => {
        if (props.caseId) {
            setWriteRequiredOnly(true);
            getData(props.caseId);
        }
    }, [props.caseId]);

    const getData = async (caseId: number) => {
        setLoading(true);
        const [nodes, caseNet, areas] = await Promise.all([
            request.get(apis.case + '/node/list', { caseId })
                .then((res) => {
                    if (res.code === 1) {
                        return res.data.sort((a, b) => a.productSpuId > b.productSpuId ? 1 : -1);
                    }
                }),
            request.get(apis.caseNet + `/${caseId}`, { caseId })
                .then((res) => {
                    if (res.code === 1) {
                        return res.data;
                    }
                }),
            request.get(apis.area, { caseId })
                .then((res) => {
                    if(res.code === 1) {
                        return res.data;
                    }
                })
        ]);
        let gatewayNodeIndex = nodes.findIndex((n) => n.id === caseNet.atCaseNodeId);
        if(gatewayNodeIndex === -1) {
            gatewayNodeIndex = nodes.findIndex((n) => n.name?.indexOf("网关") > -1);
        }
        if (gatewayNodeIndex > -1) {
            setNetNode(nodes[gatewayNodeIndex]);
        }else {
            setNetNode(null);
        }
        if (gatewayNodeIndex > 0/* 第一个以上，换顺序 */ && nodes.length > 0) {
            const temp = nodes[0];
            nodes[0] = nodes[gatewayNodeIndex];
            nodes[gatewayNodeIndex] = temp;

        }
        let _nodes: any[] = [];
        if (gatewayNodeIndex > -1) {
            _nodes = nodes.slice(0, 1).concat(nodes.slice(1, nodes.length).sort((a, b) => a.productSpuId > b.productSpuId ? 1 : -1));
        } else {
            _nodes = nodes;
        }
        setCaseNet(caseNet);
        setNodes(_nodes);
        setAreas(areas);
        setLoading(false);
    }

    useEffect(() => {
        setSelectedCaseNodeId(0);
    }, [selectedAreaId]);

    useEffect(() => {
        if(loading) {
            return ;
        }
        if(!selectedAreaId) {
            setDisplayNodes(nodes);
        }else {
            const _nodes = nodes.filter((n) => n.areaId === selectedAreaId);
            setDisplayNodes(_nodes);
        }

    }, [selectedAreaId, loading, nodes]);


    useEffect(() => {
        console.log("selectedCaseNodeId", selectedCaseNodeId);
        
        if (loading) {
            return;
        }
        if (!caseNet) {
            return;
        }
        let caseIdChanged = false;
        let _selectedCaseNodeId = selectedCaseNodeId;
        if (_selectedCaseNodeId < 0) {
            if (_selectedCaseNodeId !== -caseNet.caseId) {
                caseIdChanged = true;
            } else {
                //网关发生变化了
                // if (caseNet.mac && caseNet.hexFileName) {
                //     _selectedCaseNodeId = 0;
                // }
            }
        } else if (_selectedCaseNodeId > 0) {
            const node = displayNodes.find((cn) => cn.id === _selectedCaseNodeId);
            // console.log(selected);
            if (!node) {
                caseIdChanged = true;
            }
        }
        if (caseIdChanged) {
            _selectedCaseNodeId = 0;
        }
        if (_selectedCaseNodeId === 0) {
            //智能选取
            if (caseNet && (!caseNet.mac || !caseNet.hexFileName)) {
                //网关情况
                _selectedCaseNodeId = -caseNet.caseId;
            } else {
                //普通节点情况
                const node = displayNodes.find((cn) => !cn.mac || !cn.hexFileName);
                if (node) {
                    _selectedCaseNodeId = node.id;
                }
            }
        }
        //选中
        console.log("setSelectedCaseNodeId", _selectedCaseNodeId);
        
        setSelectedCaseNodeId(_selectedCaseNodeId);
    }, [loading, selectedCaseNodeId, displayNodes, caseNet]);


    useEffect(() => {
        if (loading) {
            props.onSelected("loading", null, null);
            return;
        }
        if (selectedCaseNodeId < 0) {
            props.onSelected("net", caseNet, netNode);
        } else if (selectedCaseNodeId > 0) {
            props.onSelected("node", displayNodes.find((cn) => cn.id === selectedCaseNodeId), caseNet);
        }
    }, [selectedCaseNodeId, loading]);

    useEffect(() => {
        if (loading || !caseNet) {
            return;
        }
        let flag = true;
        if (!caseNet.mac || !caseNet.hexFileName) {
            flag = false;
        }
        if (flag) {
            for (let i = 0; i < nodes.length; i++) {
                const n = nodes[i];
                if (!n.mac || !n.hexFileName) {
                    flag = false;
                    break;
                }
            }
        }
        if (flag) {
            props.onSelected("finish", null, null);
        }
    }, [loading, nodes, caseNet]);

    return (
        <div className="flex case-node-row" style={{}}>
            {
                loading ? (
                    <Tabs style={{ width: '100%' }}>
                        <Tabs.TabPane tab="全部">
                            <div style={{ height: 200, alignItems: 'center' }} className="flex">
                                <Skeleton active style={{ height: 100 }} />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                ) : (
                    <div className="relatvie" style={{ width: props.style?.width ? props.style.width : 'calc(100vw - 420px)' }}>
                        {
                            !selectable && (
                                <div className="absolute" style={{ height: 238, width: props.style?.width ? props.style.width : 'calc(100vw - 420px)' , zIndex: 9, backgroundColor: "rgba(255, 255, 255, .5)"}} />
                            )
                        }
                        <div className="flex" style={{ alignItems: 'center' }}>

                            <div style={{ flex: 1 }}>

                                <Menu mode="horizontal" key={"area-" + selectedAreaId} selectedKeys={["area-" + selectedAreaId]} style={{ width: '100%' }} >
                                    <Menu.Item key={"area-" + 0} onClick={() => {
                                        setSelectedAreaId(0);
                                    }}>全部</Menu.Item>
                                    {
                                        areas.length > 1 && areas.map((a) => {
                                            return (
                                                //强制：未烧录网关不可切换区域
                                                <Menu.Item disabled={!caseNet?.mac || !caseNet.hexFileName} title={(!caseNet?.mac || !caseNet.hexFileName) ? "烧录网关之后，才能按区域烧录" : undefined} key={"area-" + a.id} onClick={() => {
                                                    setSelectedAreaId(a.id);
                                                }}>
                                                    {a.name}
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </Menu>
                            </div>
                            <div style={{ width: 100 }}>
                                <Checkbox checked={writeRequiredOnly} onChange={() => {
                                    setWriteRequiredOnly(!writeRequiredOnly)
                                }}>只看未烧录</Checkbox>
                            </div>
                        </div>
                        <div>
                            <div className="flex nowrap" style={{ overflowX: 'scroll', paddingBottom: 5, height: 200, overflowY: 'hidden' }}>
                                {
                                    (
                                        //条件一：在选择只看为烧录的情况下显示为烧录
                                        writeRequiredOnly ? (!caseNet?.mac || !caseNet?.hexFileName) : true) &&
                                        //条件二：选择区域不为0时 对应其所在位置
                                        (selectedAreaId ? netNode.areaId === selectedAreaId : true) &&
                                        (caseNet?.gatewayType === 2) && (
                                            <GatewayNode
                                                ref={caseNetRef}
                                                caseNet={caseNet}
                                                netNode={netNode}
                                                className={`flex column item ${selectedCaseNodeId === -caseNet?.caseId ? 'active' : ''}`} style={{ alignItems: 'center', padding: '6px 6px 0px 6px', marginRight: 10 }}
                                                onClick={() => {
                                                    setSelectedCaseNodeId(-caseNet.caseId);
                                                }}
                                            />
                                        )
                                }
                                {
                                    displayNodes.map((cn) => {
                                        if (writeRequiredOnly) {
                                            if (cn.mac && cn.hexFileName) {
                                                return;
                                            }
                                        }
                                        return (
                                            <div className={`flex column item ${selectedCaseNodeId === cn.id ? 'active' : ''}`} style={{ alignItems: 'center', padding: '6px 6px 0px 6px', marginRight: 10 }} onClick={() => {
                                                setSelectedCaseNodeId(cn.id)
                                            }}>
                                                <div style={{ width: cn.name.length * 14 > 138 ? cn.name.length * 14 : 138, textAlign: 'center' }}>
                                                    <div>
                                                        <img src={cn.spu?.imageUrl || getNodeImageUrlByTypeKey(cn.typeKey)} alt="" style={{ height: 70, width: 70 }} />
                                                    </div>
                                                    <div style={{ fontSize: 14, fontWeight: 'bold', marginTop: 5 }}>{cn.name}</div>
                                                    <div style={{ color: cn?.deviceCode ? "green" : undefined, fontWeight: cn?.deviceCode ? "bold" : undefined }}>{cn.deviceCode || "未关联二维码"}</div>
                                                    <div style={{ color: cn?.mac ? "green" : undefined, fontWeight: cn?.mac ? "bold" : undefined }} >{cn.mac?.toUpperCase() || "未读取MAC地址"}</div>
                                                    <div title={cn.hexFileName} style={{ width: cn.name.length * 14 > 138 ? cn.name.length * 14 : 138, overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: "ellipsis",  color: cn?.hexFileName ? "green" : undefined, fontWeight: cn?.hexFileName ? "bold" : undefined }}>{cn.hexFileName || "未烧录HEX文件"}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
})

interface GatewayNodeProps {
    caseNet: any;
    netNode?: any;
    className?: string;
    style?: CSSProperties;
    onClick?: any
}

const GatewayNode = forwardRef((props: GatewayNodeProps, ref: any) => {

    const caseNet = props.caseNet;

    return (
        <div className={props.className} style={props.style} onClick={props.onClick}>
            <div className="flex column center" style={{ width: 142, textAlign: 'center' }}>
                {
                    props.netNode ? (
                        <div>
                            <img src={props.netNode.spu?.imageUrl || getNodeImageUrlByTypeKey(props.netNode.typeKey)} alt="" style={{ height: 70, width: 70 }} />
                        </div>
                    ) : (
                        <div className="flex center color-white inline-block" style={{ height: 70, width: 70, background: "#6a6967", fontSize: 20 }}>
                            网关
                        </div>
                    )
                }
                <div style={{ fontSize: 14, fontWeight: 'bold', marginTop: 5 }}>{props.netNode ? props.netNode.name + " - " : ''}网关</div>
                <div style={{ color: caseNet?.deviceCode ? "green" : undefined, fontWeight: caseNet?.deviceCode ? "bold" : undefined }}>{caseNet?.deviceCode || "未关联二维码"}</div>
                <div style={{ color: caseNet?.mac ? "green" : undefined, fontWeight: caseNet?.mac ? "bold" : undefined }}>{caseNet?.mac?.toUpperCase() || "未读取MAC地址"}</div>
                <div style={{ color: caseNet?.hexFileName ? "green" : undefined, fontWeight: caseNet?.hexFileName ? "bold" : undefined }}>{caseNet?.hexFileName || "未烧录HEX文件"}</div>
            </div>
        </div>
    )
})

