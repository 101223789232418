import { Skeleton, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface Props extends RouteChildrenProps {

}

export default function HexVersionListPage(props: Props) {
    
    // const []

    useEffect(() => {

    }, []);

    const getHexProjectList = async () => {
        const res = await request.get(apis.hexProject, { });
        if(res.code === 1) {
            return res.data;
        }
        return [];
    }

    return (
        <div className="hex-project-list-page" style={{padding: 10}}>
            <SmartTable 
                getData={getHexProjectList}
                columns={[{
                    title: "HEX项目名称",
                    dataIndex: "projectName",
                    key: "name"
                }]}
            />
        </div>
    )
}



interface SmartTableProps {
    columns: any[];
    getData: () => Promise<any[]>;
}
function SmartTable(props: SmartTableProps) {
    
    const [loading, setLoading] = useState(true);
    const [freshing, setRefreshing] = useState(false);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        if(!loading) {
            setRefreshing(true);
        }
        try {
            const data = await props.getData();
            setData(data);
        }finally{
            setLoading(false);
            setRefreshing(false);
        }
    }
    if(loading) {
        return (
            <Skeleton />
        )
    }
    return (
        <Spin spinning={freshing}> 
            <Table
                columns={props.columns} 
                dataSource={data}
            />
        </Spin>
    )
}
