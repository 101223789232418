import { PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Tabs, Tag, Tooltip } from "antd";
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import { AreaAddModal } from "./AreaLayout";

interface Props {
    disable?: boolean;
    children?: ReactNode
    onSmartProductAdd?: (smart: { spu: any } & any) => void;
    onNormalProductAdd?: (spu: any) => void;
}

// const createDatabase = () => {

// }
const ProductSelectorLayout = forwardRef((props: Props, ref: any) => {

    const [smarts, setSmarts] = useState([] as any[]);
    const [normals, setNormals] = useState([] as any[]);
    const [nodeAddable, setNodeAddable] = useState(true);
    const [tabKey, setTabKey] = useState("smart");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const [res1, res2] = await Promise.all([
            request.get(apis.caseProduct + `/list`),
            request.get(apis.productSpu, { isSmart: 0 })
        ]);
        if (res1.code === 1) {
            setSmarts(res1.data)
        }
        if (res2.code === 1) {
            setNormals(res2.data);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            onCanAddNode: () => {
                setNodeAddable(true);
            },
            onDisableAddNode: () => {
                setNodeAddable(false);
            }
        }
    });

    useEffect(() => {
        if(nodeAddable) {
            setTabKey("smart");
        }else {
            setTabKey("normal");
        }
    }, [nodeAddable]);

    return (
        <div className="product-selector-layout flex relative" >
            {
                props.disable && (
                    <div className="absolute" style={{ height: 'calc(100vh - 90px)', zIndex: 9, width: '224px', backgroundColor: "rgba(255, 255, 255, 0.5)" }} />
                )
            }
            <div style={{ height: 'calc(100vh - 90px)', padding: '0 0', background: "#fff" }}>
                <Tabs activeKey={tabKey} onChange={(key) => {
                    setTabKey(key)
                }} tabBarStyle={{ padding: '0 10px' }}>
                    {
                        nodeAddable && (
                            <Tabs.TabPane key={"smart"} tab="智能产品">
                                <div className=" no-scroll-bar" style={{ height: 'calc(100vh - 90px - 45px)', width: 224, overflowY: 'scroll' }}>
                                    {
                                        smarts.map((smart) => {
                                            return (
                                                <SpuItem
                                                    spu={smart.spu}
                                                    onAdd={(_) => {
                                                        props.onSmartProductAdd(smart);
                                                    }}
                                                />
                                            )
                                        })
                                    }

                                </div>
                            </Tabs.TabPane>
                        )
                    }
                    <Tabs.TabPane key={"normal"} tab="一般产品">
                        <div className=" no-scroll-bar" style={{ height: 'calc(100vh - 90px - 45px)', width: 224, overflowY: 'scroll' }}>
                            {
                                normals.map((normal) => {
                                    return (
                                        <SpuItem
                                            spu={normal}
                                            onAdd={props.onNormalProductAdd}
                                        />
                                    )
                                })
                            }
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div style={{ flex: 1, backgroundColor: "#f1f1f1", height: "calc(100vh - 90px)", overflowY: 'scroll' }}>
                {props.children}
            </div>
        </div>
    )
})


export default ProductSelectorLayout;



interface SpuItemProps {
    spu: any;
    onAdd?: (spu: any) => void;
}
const SpuItem = (props: SpuItemProps) => {
    return (
        <div onClick={() => {
            props.onAdd && props.onAdd(props.spu);
        }} className="product-item flex" style={{ padding: '7px 5px 7px 10px', cursor: "pointer", marginBottom: 3, alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="flex" style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                <div className="" style={{ width: 48, height: 48 }}>
                    <img src={props.spu.imageUrl} alt="" height={"100%"} width={"100%"} />
                </div>
                <div style={{ marginLeft: 5, width: 130 }}>
                    <Tooltip mouseEnterDelay={0.5} overlay={(
                        <div >
                            <div>名称：{props.spu.spuName}</div>
                            <div>型号：{props.spu.spuMode}</div>
                            {
                                props.spu.spuDesc && (
                                    <div>说明：{props.spu.spuDesc}</div>
                                )
                            }
                        </div>
                    )}>
                        <div className="bold" style={{ fontSize: 14, width: 120, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>{props.spu.spuName}</div>
                    </Tooltip>
                    <div className="flex" style={{ justifyContent: 'flex-start', transform: 'scale(0.8)', transformOrigin: 'left', marginTop: 5, }}>

                        {
                            Boolean(props.spu.isSmart) && (
                                <Tag color={"purple"}>智能产品</Tag>
                            )
                        }
                        {
                            props.spu.gatewayType !== 0 && (props.spu.gatewayType === 1 ? (
                                <Tag color={"green"}>网关</Tag>
                            ) : (
                                <Tag color={"green"}>网关模块</Tag>
                            ))
                        }
                        {
                            Boolean(props.spu.isRowFrame) && (
                                <Tag color={"pink"}>连排框</Tag>
                            )
                        }
                        {
                            Boolean(props.spu.supportRowFrame) && (
                                <Tag color={"blue"}>支持连排</Tag>
                            )
                        }
                    </div>
                </div>
            </div>
            <PlusOutlined style={{ fontSize: 16, marginRight: 5 }} />
        </div>
    )
}