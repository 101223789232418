import { CloudFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { TargetConnector } from '../NodeCellConnector'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingAccepts: string;
    bindingTargets?: any[];
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
}

export const DimmableDownlightNodeCell = (props: Props) => {
    const { node } = props;

    const [targetDisabled, setTargetDisabled] = useState(false);
    const [targetSelected, setTargetSelected] = useState(false);
    useEffect(() => {
        if(props.bindMode === "wait") {
            setTargetSelected(false);
            setTargetDisabled(true);
        }else if(props.bindMode === "targets" || props.bindMode === "view") {
            const app = props.node.apps[0];
            let expectKey = app.key;
            if(!(props.bindingAccepts.indexOf("not") > -1)) {
                expectKey = props.bindingAccepts;
            }
            if(expectKey !== app.key) {
                setTargetDisabled(true);
                return ;
            }
            if(props.bindingTargets instanceof Array && props.bindingTargets.length > 0) {
                if(props.bindingTargets[0].type === "scene") {
                    setTargetDisabled(true);
                }else {
                    setTargetDisabled(false);
                    setTargetSelected(false);
                    for (let i = 0; i < props.bindingTargets.length; i++) {
                        const bindingTarget = props.bindingTargets[i];
                        if(bindingTarget.target.id === app.id) {
                            setTargetSelected(true);
                            break;
                        }
                    }
                }
            }else {
                setTargetDisabled(false);
                setTargetSelected(false);
            }
        }
    }, [props.node, props.bindingAccepts, props.bindMode, props.bindingTargets]);

    return (
        <div className="node-cell curtain-motor-node-cell">
            <div className="only-connector">
                {
                    !targetDisabled && (
                        <TargetConnector
                            selected={targetSelected}
                            disabled={targetDisabled}
                            onClick={(_, selected) => {
                                if(props.bindMode === "targets" || props.bindMode === "view") {
                                    if(!selected) {
                                        if(typeof props.onAddBindingTarget === 'function') {
                                            props.onAddBindingTarget("app", node.apps[0])
                                        }
                                    }else {
                                        if(typeof props.onRemoveBindingTarget === 'function') {
                                            props.onRemoveBindingTarget("app", node.apps[0]);
                                        }
                                    }
                                }
                            }}
                        />
                    )
                }
            </div>
            <div className="prod-name">
            {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )

}