export const NODE_KEY_NAME_MAP = {
    "plug-in_card_power": "插卡取电",
    "plug-in_card_power_v2": "插卡取电",
    "general_ir_human_sensor": "筒灯人体存在（总）",
    "sub_ir_human_sensor": "筒灯人体存在（分）",
    "scene_and_speaker": "86型情景语音面板",
    "three_button_scene": "三键情景",
    "curtain_control": "86型三键窗帘开关",
    "six_button_switch_1c3s": "86型六键开关（上窗帘下三键）",
    "curtain_control_double": "86型六键窗帘开关",
    "curtain_control_battery": "三键窗帘开关(电池)",
    "curtain_motor": "窗帘电机",
    "smart_control": "智控模块",
    "smart_socket": "智能插座",
    "smart_socket_ac": '智能空调插座',
    "smart_socket_5hole": '智能五孔插座',
    "dimmable_downlight": "可调光驱动电源",
    "color_temp_dimmable_downlight": "双色温可调光筒灯",
    "rgb_light_strip": "RGB灯带",
    "six_button_switch": "86型六键开关",
    "four_button_switch": "86型四键开关",
    "three_button_switch": "86型三键开关",
    "two_button_switch": "86型二键开关",
    "one_button_switch": "86型一键开关",
    "dimmer_switch": "86调光开关",

    "four_touch_switch": "86型四键触摸开关",
    "three_touch_switch": "86型三键触摸开关",
    "two_touch_switch": "86型二键触摸开关",
    "one_touch_switch": "86型一键触摸开关",

    "universal_ir_remote_control": "离线万能红外",

    "single_knob_switch": "单旋钮开关",
    "double_knob_switch": "双旋钮开关",
    "offline_speaker": "离线音箱",
    "gateway": "网关模块",

    "six_button_switch_6_0": "86型开关-6-0",
    "six_button_switch_6_1": "86型开关-6-1",
    "six_button_switch_6_2": "86型开关-6-2",
    "six_button_switch_6_3": "86型开关-6-3",
    "six_button_switch_6_4": "86型开关-6-4",
    "four_button_switch_4_0": "86型开关-4-0",
    "four_button_switch_4_1": "86型开关-4-1",
    "four_button_switch_4_2": "86型开关-4-2",
    "four_button_switch_4_3": "86型开关-4-3",
    "four_button_switch_4_4": "86型开关-4-4",
    "three_button_switch_3_0": "86型开关-3-0",
    "three_button_switch_3_1": "86型开关-3-1",
    "three_button_switch_3_2": "86型开关-3-2",
    "three_button_switch_3_3": "86型开关-3-3",
    "two_button_switch_2_0": "86型开关-2-0",
    "two_button_switch_2_1": "86型开关-2-1",
    "two_button_switch_2_2": "86型开关-2-2",
    "one_button_switch_1_0": "86型开关-1-0",
    "one_button_switch_1_1": "86型开关-1-1",

}

export const DEVICE_CATES: {
    key: string,
    name: string,
    devices: {
        name: string,
        cateKey: string,  //方便添加的时候查找
        typeKey: string,
        powerType: "220v" | "battery",
        imageUrl: string,
        apps: {
            key: string,
            name: string,
            endpoint: number,
            type: string,
            isWireless: -1 | 0 | 1,
            appDeviceId?: number
        }[]
    }[]
}[] = [{
    key: "cate6",
    name: "新86机械面板系列（区分继电器）",
    devices: [
        {
            typeKey: "four_button_switch_4_4",
            name: NODE_KEY_NAME_MAP["four_button_switch_4_4"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型四键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 11,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        
        {
            typeKey: "four_button_switch_4_3",
            name: NODE_KEY_NAME_MAP["four_button_switch_4_3"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型四键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },

        
        {
            typeKey: "four_button_switch_4_2",
            name: NODE_KEY_NAME_MAP["four_button_switch_4_2"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型四键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },

        {
            typeKey: "four_button_switch_4_1",
            name: NODE_KEY_NAME_MAP["four_button_switch_4_1"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型四键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },

        {
            typeKey: "four_button_switch_4_0",
            name: NODE_KEY_NAME_MAP["four_button_switch_4_0"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型四键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },

        {
            typeKey: "three_button_switch_3_3",
            name: NODE_KEY_NAME_MAP["three_button_switch_3_3"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        
        {
            typeKey: "three_button_switch_3_2",
            name: NODE_KEY_NAME_MAP["three_button_switch_3_2"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },

        {
            typeKey: "three_button_switch_3_1",
            name: NODE_KEY_NAME_MAP["three_button_switch_3_1"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "three_button_switch_3_0",
            name: NODE_KEY_NAME_MAP["three_button_switch_3_0"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "three_button_switch_3_0",
            name: NODE_KEY_NAME_MAP["three_button_switch_3_0"] + "(窗帘)",
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "窗帘开",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘停",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘关",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }]
        },
        {
            typeKey: "two_button_switch_2_2",
            name: NODE_KEY_NAME_MAP["two_button_switch_2_2"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型二键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 3,
                type: "source",
                isWireless: 0,

                key: "button"
            }, {
                name: "灯2",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        {
            typeKey: "two_button_switch_2_1",
            name: NODE_KEY_NAME_MAP["two_button_switch_2_1"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型二键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "two_button_switch_2_0",
            name: NODE_KEY_NAME_MAP["two_button_switch_2_0"],
            powerType: "220v",
            cateKey: "cate6",
            imageUrl: "/assets/images/products/86型二键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        { 
            typeKey: "one_button_switch_1_1",
            name: NODE_KEY_NAME_MAP["one_button_switch_1_1"],
            powerType: "220v",
            imageUrl: "/assets/images/products/86型一键开关.jpg",
            cateKey: "cate6",
            apps: [{
                name: "灯1",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        { 
            typeKey: "one_button_switch_1_0",
            name: NODE_KEY_NAME_MAP["one_button_switch_1_0"],
            powerType: "220v",
            imageUrl: "/assets/images/products/86型一键开关.jpg",
            cateKey: "cate6",
            apps: [{
                name: "灯1",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "dimmer_switch",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["dimmer_switch"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型调光开关.jpg",
            apps: [{
                name: NODE_KEY_NAME_MAP["dimmer_switch"],
                endpoint: 8,
                type: "target",
                isWireless: 0,
                key: "dimmable_light",
                appDeviceId: 257,
            }]
        },
        {
            typeKey: "six_button_switch_6_4",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_4"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 11,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯5",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯6",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "six_button_switch_6_3",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_3"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯5",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯6",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "six_button_switch_6_3",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_3"] + "(窗帘)",
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "窗帘开",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘停",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘关",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }]
        },
        {
            typeKey: "six_button_switch_6_2",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_2"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯5",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯6",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "six_button_switch_6_2",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_2"] + "(窗帘)",
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                key: "relay"
            }, {
                name: "窗帘开",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘停",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘关",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }]
        },
        {
            typeKey: "six_button_switch_6_1",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_1"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯5",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯6",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "six_button_switch_6_1",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_1"] + "(窗帘)",
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "窗帘开",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘停",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘关",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }]
        },
        {
            typeKey: "six_button_switch_6_0",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_0"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯5",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯6",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "six_button_switch_6_0",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_0"] + "(单帘)",
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "窗帘开",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘停",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘关",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }]
        },
        {
            typeKey: "six_button_switch_6_0",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["six_button_switch_6_0"] + "(双帘)",
            cateKey: "cate5",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "窗帘2开",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘2停",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘2关",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘1开",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘1停",
                endpoint: 5,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }, {
                name: "窗帘1关",
                endpoint: 6,
                type: "source",
                isWireless: 0,
                key: "button-curtain"
            }]
        }
    ]

},{
    key: "cate1",
    name: "86型机械面板系列",
    devices: [
        {
            name: NODE_KEY_NAME_MAP["one_button_switch"],
            typeKey: "one_button_switch",
            powerType: "220v",
            imageUrl: "/assets/images/products/86型一键开关.jpg",
            cateKey: "cate1",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["two_button_switch"],
            typeKey: "two_button_switch",
            powerType: "220v",
            cateKey: "cate1",
            imageUrl: "/assets/images/products/86型二键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["three_button_switch"],
            typeKey: "three_button_switch",
            powerType: "220v",
            cateKey: "cate1",
            imageUrl: "/assets/images/products/86型三键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        {
            typeKey: "four_button_switch",
            name: NODE_KEY_NAME_MAP["four_button_switch"],
            powerType: "220v",
            cateKey: "cate1",
            imageUrl: "/assets/images/products/86型四键开关.jpg",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 11,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        {
            typeKey: "four_button_switch",
            name: NODE_KEY_NAME_MAP["four_button_switch"] + "(网关)",
            powerType: "220v",
            cateKey: "cate1",
            imageUrl: "/assets/images/products/86型四键开关_网关.png",
            apps: [{
                name: "灯1",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯1",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯4",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯4",
                endpoint: 11,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯2",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯2",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }, {
                name: "灯3",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "灯3",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                appDeviceId: 256,
                key: "relay"
            }]
        },
        // {
        //     name: NODE_KEY_NAME_MAP["six_button_switch"],
        //     typeKey: "six_button_switch",
        //     powerType: "220v",
        //     cateKey: "cate1",
        //     imageUrl: "/assets/images/products/86型三键开关.jpg",
        //     apps: [{
        //         name: "灯1",
        //         endpoint: 1,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯1",
        //         endpoint: 8,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "灯2",
        //         endpoint: 2,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯2",
        //         endpoint: 9,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "灯3",
        //         endpoint: 3,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯3",
        //         endpoint: 10,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "灯4",
        //         endpoint: 4,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯4",
        //         endpoint: 11,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "灯5",
        //         endpoint: 5,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯6",
        //         endpoint: 6,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }]
        // },
        {
            name: NODE_KEY_NAME_MAP["scene_and_speaker"],
            typeKey: "scene_and_speaker",
            imageUrl: "/assets/images/products/86型情景语音面板.jpg",
            powerType: "220v",
            cateKey: "cate1",
            apps: [{
                name: "明亮模式",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "明亮模式",
                endpoint: 8,
                type: "target",
                isWireless: 1,
                key: "relay",
                appDeviceId: 256
            }, {
                name: "阅读模式",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button",
            }, {
                name: "阅读模式",
                endpoint: 11,
                type: "target",
                isWireless: 1,
                key: "relay",
                appDeviceId: 256
            }, {
                name: "影音模式",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button",
            }, {
                name: "影音模式",
                endpoint: 9,
                type: "target",
                isWireless: 1,
                key: "relay",
                appDeviceId: 256
            }, {
                name: "睡眠模式",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "睡眠模式",
                endpoint: 10,
                type: "target",
                isWireless: 1,
                key: "relay",
                appDeviceId: 256
            }, {
                name: "语音",
                endpoint: 31,
                type: "source",
                isWireless: 0,
                key: "offline-speaker",
                appDeviceId: 84
            }],

        },
        // {
        //     name: NODE_KEY_NAME_MAP["curtain_control"],
        //     typeKey: "curtain_control",
        //     powerType: "220v",
        //     cateKey: "cate1",
        //     imageUrl: "/assets/images/products/86型三键开关.jpg",
        //     apps: [{
        //         name: NODE_KEY_NAME_MAP["curtain_control"],
        //         endpoint: 1,
        //         type: "source",
        //         isWireless: 0,
        //         key: "curtain_buttons"
        //     }]
        // },
        // {
        //     name: NODE_KEY_NAME_MAP["curtain_control_double"],
        //     typeKey: "curtain_control_double",
        //     powerType: "220v",
        //     cateKey: "cate1",
        //     imageUrl: "/assets/images/products/86型三键开关.jpg",
        //     apps: [{
        //         name: NODE_KEY_NAME_MAP["curtain_control_double"],
        //         endpoint: 1,
        //         type: "source",
        //         isWireless: 0,
        //         key: "curtain_buttons"
        //     }, {
        //         name: NODE_KEY_NAME_MAP["curtain_control_double"],
        //         endpoint: 2,
        //         type: "source",
        //         isWireless: 0,
        //         key: "curtain_buttons"
        //     }]
        // },
        // {
        //     name: NODE_KEY_NAME_MAP["six_button_switch_1c3s"],
        //     typeKey: "six_button_switch_1c3s",
        //     powerType: "220v",
        //     cateKey: "cate1",
        //     imageUrl: "/assets/images/products/86型三键开关.jpg",
        //     apps: [{
        //         name: "灯1",
        //         endpoint: 1,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯1",
        //         endpoint: 8,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "灯2",
        //         endpoint: 2,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯2",
        //         endpoint: 9,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "灯3",
        //         endpoint: 3,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button"
        //     }, {
        //         name: "灯3",
        //         endpoint: 10,
        //         type: "target",
        //         isWireless: 1,
        //         appDeviceId: 256,
        //         key: "relay"
        //     }, {
        //         name: "窗帘开",
        //         endpoint: 4,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button-curtain"
        //     }, {
        //         name: "窗帘停",
        //         endpoint: 5,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button-curtain"
        //     }, {
        //         name: "窗帘关",
        //         endpoint: 6,
        //         type: "source",
        //         isWireless: 0,
        //         key: "button-curtain"
        //     }]
        // },  
        {
            name: NODE_KEY_NAME_MAP["plug-in_card_power"] + "(旧版)",
            typeKey: "plug-in_card_power",
            powerType: "220v",
            imageUrl: "/assets/images/products/插卡取电.jpg",
            cateKey: "cate1",
            apps: [{
                name: "插卡取电",
                endpoint: 8,
                type: "source",
                isWireless: 0,
                key: "in_card_power"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["plug-in_card_power_v2"],
            typeKey: "plug-in_card_power_v2",
            powerType: "220v",
            imageUrl: "/assets/images/products/插卡取电.jpg",
            cateKey: "cate1",
            apps: [{
                name: "插入开、拔出关",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "in_card_power"
            }, {
                name: "仅拔出关",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "in_card_power"
            }]
        },
        {
            name: "86型人体存在（总）",
            typeKey: "general_ir_human_sensor",
            imageUrl: "",
            powerType: "220v",
            cateKey: " cate4",
            apps: [{
                name: NODE_KEY_NAME_MAP["general_ir_human_sensor"],
                endpoint: 8,
                type: "source",
                isWireless: 0,
                appDeviceId: 263,
                key: "general_ir_human_sensor"
            }]
        },
        {
            name: "86型人体存在（分）",
            typeKey: "sub_ir_human_sensor",
            powerType: "220v",
            imageUrl: "",
            cateKey: "cate4",
            apps: [{
                name: NODE_KEY_NAME_MAP["sub_ir_human_sensor"], 
                endpoint: 8,
                type: "source",
                isWireless: 0,
                appDeviceId: 263,
                key: "sub_ir_human_sensor"
            }]
        },
    ]
}, {
    key: "cate2",
    name: "智控/智能插座/灯带/调光灯",
    devices: [
        {
            name: NODE_KEY_NAME_MAP["smart_control"],
            typeKey: "smart_control",
            imageUrl: "/assets/images/products/智控模块.png",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: "智控模块",
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 256,
                key: "smart_control"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["smart_socket"],
            typeKey: "smart_socket",
            imageUrl: "/assets/images/products/智能插座.png",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: NODE_KEY_NAME_MAP["smart_socket"],
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 9,
                key: "smart_socket"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["smart_socket_ac"],
            typeKey: "smart_socket_ac",
            imageUrl: "/assets/images/products/35socket.png",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: NODE_KEY_NAME_MAP["smart_socket_ac"],
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 9,
                key: "smart_socket"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["smart_socket_5hole"],
            typeKey: "smart_socket_5hole",
            imageUrl: "/assets/images/products/智能五孔插座.jpg",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: NODE_KEY_NAME_MAP["smart_socket_5hole"],
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 9,
                key: "smart_socket"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["dimmable_downlight"],
            typeKey: "dimmable_downlight",
            imageUrl: "/assets/images/products/可调光驱动电源.png",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: "单色温可调光筒灯",
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 257,
                key: "dimmable_downlight"
            }],
        },
        {
            name: NODE_KEY_NAME_MAP["color_temp_dimmable_downlight"],
            typeKey: "color_temp_dimmable_downlight",
            imageUrl: "/assets/images/products/可调光驱动电源.png",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: "双色温可调光筒灯",
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 257,
                key: "color_temp_dimmable_downlight"
            }],
        },
        {
            name: NODE_KEY_NAME_MAP["rgb_light_strip"],
            typeKey: "rgb_light_strip",
            imageUrl: "/assets/images/products/灯带.png",
            powerType: "220v",
            cateKey: "cate2",
            apps: [{
                name: "RGB灯带",
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 258,
                key: "rgb_light_strip"
            }]
        },
    ]
}, {
    key: "cate3",
    name: "窗帘电机/卷帘电机/开窗器",
    devices: [
        {
            name: NODE_KEY_NAME_MAP["curtain_motor"],
            typeKey: "curtain_motor",
            powerType: "220v",
            cateKey: "cate3",
            imageUrl: "/assets/images/products/窗帘电机.png",
            apps: [{
                name: NODE_KEY_NAME_MAP["curtain_motor"],
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 512,
                key: "curtain"
            }]
        },
        {
            name: "卷帘",
            typeKey: "curtain_motor",
            powerType: "220v",
            imageUrl: "/assets/images/products/卷帘电机.png",
            cateKey: "cate3",
            apps: [{
                name: "卷帘",
                endpoint: 8,
                type: "target",
                isWireless: 0,
                appDeviceId: 514,
                key: "curtain"
            }]
        },
    ]
}, {
    key: "cate4",
    name: "人体存在/万能红外系列",
    devices: [{
        name: "86型人体存在（总）",
        typeKey: "general_ir_human_sensor",
        imageUrl: "",
        powerType: "220v",
        cateKey: " cate4",
        apps: [{
            name: NODE_KEY_NAME_MAP["general_ir_human_sensor"],
            endpoint: 8,
            type: "source",
            isWireless: 0,
            appDeviceId: 263,
            key: "general_ir_human_sensor"
        }]
    },
    {
        name: "86型人体存在（分）",
        typeKey: "sub_ir_human_sensor",
        powerType: "220v",
        imageUrl: "",

        cateKey: "cate4",
        apps: [{
            name: NODE_KEY_NAME_MAP["sub_ir_human_sensor"], 
            endpoint: 8,
            type: "source",
            isWireless: 0,
            appDeviceId: 263,
            key: "sub_ir_human_sensor"
        }]
    },
        {
            name: NODE_KEY_NAME_MAP["general_ir_human_sensor"],
            typeKey: "general_ir_human_sensor",
            imageUrl: "/assets/images/products/筒灯人体存在.png",
            powerType: "220v",
            cateKey: " cate4",
            apps: [{
                name: NODE_KEY_NAME_MAP["general_ir_human_sensor"],
                endpoint: 8,
                type: "source",
                isWireless: 0,
                appDeviceId: 263,
                key: "general_ir_human_sensor"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP["sub_ir_human_sensor"],
            typeKey: "sub_ir_human_sensor",
            powerType: "220v",
            imageUrl: "/assets/images/products/筒灯人体存在.png",

            cateKey: "cate4",
            apps: [{
                name: NODE_KEY_NAME_MAP["sub_ir_human_sensor"], 
                endpoint: 8,
                type: "source",
                isWireless: 0,
                appDeviceId: 263,
                key: "sub_ir_human_sensor"
            }]
        },
        {
            name: NODE_KEY_NAME_MAP['universal_ir_remote_control'],
            typeKey: "universal_ir_remote_control",
            powerType: "220v",
            imageUrl: "/assets/images/products/离线万能红外.png",
            cateKey: "cate4",
            apps: [{
                name: NODE_KEY_NAME_MAP["universal_ir_remote_control"],
                endpoint: 8,
                type: "target-uncontrollable",
                isWireless: 0,
                appDeviceId: 6,
                key: "universal_ir_remote_control"
            }]
        }
    ]
}, {
    key: "cate5",
    name: "电池触摸面板系列",
    devices: [
        {
            name: NODE_KEY_NAME_MAP["curtain_control_battery"],
            typeKey: "curtain_control_battery",
            imageUrl: "/assets/images/products/三键触摸开关.jpg",
            powerType: "battery",
            cateKey: "cate5",
            apps: [{
                name: NODE_KEY_NAME_MAP["curtain_control_battery"],
                endpoint: 8,
                type: "source",
                isWireless: 0,
                key: "curtain_buttons"
            }]
        },
        {
            typeKey: "four_touch_switch",
            name: NODE_KEY_NAME_MAP["four_touch_switch"],
            imageUrl: "/assets/images/products/四键触摸开关.jpg",
            powerType: "battery",
            cateKey: "cate5",
            apps: [{
                name: "按键I",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "按键II",
                endpoint: 4,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "按键III",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {

                name: "按键IV",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "three_touch_switch",
            name: NODE_KEY_NAME_MAP["three_touch_switch"],
            imageUrl: "/assets/images/products/三键触摸开关.jpg",

            powerType: "battery",
            cateKey: "cate5",
            apps: [{
                name: "按键I",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "按键II",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "按键III",
                endpoint: 3,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "two_touch_switch",
            name: NODE_KEY_NAME_MAP["two_touch_switch"],
            imageUrl: "/assets/images/products/二键触摸开关.jpg",
            powerType: "battery",
            cateKey: "cate5",
            apps: [{
                name: "按键I",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }, {
                name: "按键II",
                endpoint: 2,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "one_touch_switch",
            name: NODE_KEY_NAME_MAP["one_touch_switch"],
            powerType: "battery",
            imageUrl: "/assets/images/products/一键触摸开关.jpg",
            cateKey: "cate5",
            apps: [{
                name: "按键I",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "single_knob_switch",
            powerType: "battery",
            name: NODE_KEY_NAME_MAP["single_knob_switch"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/单旋钮调光面板.jpg",
            apps: [{
                name: "按键I",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
        {
            typeKey: "double_knob_switch",
            powerType: "220v",
            name: NODE_KEY_NAME_MAP["double_knob_switch"],
            cateKey: "cate5",
            imageUrl: "/assets/images/products/双旋扭调光面板.jpg",
            apps: [{
                name: "按键I",
                endpoint: 1,
                type: "source",
                isWireless: 0,
                key: "button"
            }]
        },
    ]
}]

export const SELECTABLE_DEVICES = [
    {
        name: NODE_KEY_NAME_MAP["three_button_scene"],
        typeKey: "three_button_scene",
        powerType: "220v",
        apps: [{
            name: "会客模式",
            endpoint: 1,
            type: "source",
            isWireless: 0,
            key: "button"
        }, {
            name: "继电器1",
            endpoint: 8,
            type: "target",
            isWireless: 1,
            key: "relay",
            appDeviceId: 256
        }, {
            name: "离开模式",
            endpoint: 2,
            type: "source",
            isWireless: 0,
            key: "button"
        }, {
            name: "继电器2",
            endpoint: 9,
            type: "target",
            isWireless: 1,
            key: "relay",
            appDeviceId: 256
        }, {
            name: "明亮模式",
            endpoint: 3,
            type: "source",
            isWireless: 0,
            key: "button"
        }, {
            name: "继电器3",
            endpoint: 10,
            type: "target",
            isWireless: 1,
            key: "relay",
            appDeviceId: 256
        }]
    },

    
    {
        typeKey: "offline_speaker",
        powerType: "220v",
        name: NODE_KEY_NAME_MAP['offline_speaker'],
        apps: [{
            name: "语音",
            endpoint: 31,
            type: "source",
            isWireless: 0,
            key: "offline-speaker",
            appDeviceId: 84
        }]
    }


]

export const getNodeImageUrlByTypeKey = (typeKey: string) => {
    for (let i = 0; i < DEVICE_CATES.length; i++) {
        const dc = DEVICE_CATES[i];
        for (let j = 0; j < dc.devices.length; j++) {
            const d = dc.devices[j];
            if(d.typeKey === typeKey) {
                return d.imageUrl;
            }
        }
    }
}