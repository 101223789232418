import { EditOutlined, LoadingOutlined, PhoneOutlined, QrcodeOutlined, WifiOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { apis } from '../../../../../utils/apis';
import { request } from '../../../../../utils/request';
import './style.less';

interface Props {
    customer: any;
    // case: any;
    ignoreTemplate?: boolean;
    caseId: number;
    onCaseChange: (c: any) => void;
}

export default function CaseList(props: Props) {
    const [customerLoading, setCustomerLoading] = useState(true);
    const [cases, setCases] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [caseLoading, setCaseLoading] = useState<boolean>(false);
    const [customerUser, setCustomerUser] = useState<any>(null);
    const [userLoading, setUserLoading] = useState<boolean>(false);
    const [selectedCaseId, setSelectedCaseId] = useState<number>(props.caseId || 0);

    const [displayCases, setDisplayCases] = useState([] as any[]);

    useEffect(() => {
        if (props.customer !== null) {
            setCustomerLoading(false);
            fetchData(props);
        } else {
            setCustomerLoading(true);
        }
    }, [props.customer]);


    const fetchData = async (props: Props) => {
        setLoading(true);
        await Promise.all([
            getCustomerCases(props.customer.id),
            getCustomerUser(props.customer.id),
            getNetsState(props.customer.id)
        ])
            .finally(() => {
                setLoading(false);
            });

    }

    useEffect(() => {
        if (cases.length > 0 && !selectedCaseId) {
            setSelectedCaseId(cases[0].id);
        }
    }, [cases, props.customer]);

    useEffect(() => {
        if (selectedCaseId) {
            const c = cases.find((c) => c.id === selectedCaseId);
            if (!c) {
                return;
            }
            if (c.customerId !== props.customer?.id) {
                setSelectedCaseId(0);
            }
        }
    }, [props.customer, selectedCaseId]);

    useEffect(() => {
        if (selectedCaseId > 0 && cases.length > 0) {
            const c = cases.find((c) => c.id === selectedCaseId);
            if (!c) {
                props.onCaseChange(null);
            } else {
                props.onCaseChange(c);
            }
        }
    }, [selectedCaseId, cases]);

    const getCustomerCases = async (customerId: number) => {
        if (customerId) {
            setCaseLoading(true);
            try {
                const res = await request.get(apis.case, { customerId: customerId });
                if (res.code === 1) {
                    let cases = res.data;
                    if (props.ignoreTemplate) {
                        cases = cases.filter((c) => c.caseTemplateId === 0);
                    }
                    setCases(cases);
                }
            } catch (e) {

            } finally {
                setCaseLoading(false);
            }
        }
    }


    useEffect(() => {//外部url的caseId参数变化后, 被选择的caseId也应该随之变化
        if (props.caseId > 0) {
            setSelectedCaseId(props.caseId);
        }
    }, [props.caseId]);

    useEffect(() => {
        if (!props.customer) {
            setDisplayCases([]);
            return;
        }
        const templateCases = cases.filter((c) => c.caseTemplateId !== 0);
        let normalCases = cases.filter((c) => c.caseTemplateId === 0);
        if (props.customer.type === "酒店") {
            normalCases = normalCases.sort((a, b) => a.name > b.name ? 1 : -1);
        }
        setDisplayCases(templateCases.concat(normalCases));
    }, [cases, props.customer]);

    const getCustomerUser = async (customerId: number) => {
        if (customerId) {
            setUserLoading(true);
            try {
                const res = await request.get(apis.customerUserInfo, { customerId: customerId });
                if (res.code === 1) {
                    setCustomerUser(res.data);
                }
            } catch (e) {

            } finally {
                setCaseLoading(false);
            }
        }
    }
    const [caseNetStateMap, setCaseNetStateMap] = useState<{ [caseId: string]: number }>({});
    const [stateLoading, setStateLoading] = useState(false);
    const getNetsState = (customerId: number) => {
        setStateLoading(true);
        request.get(apis.caseNetCheckActive, { customerId })
            .then((res) => {
                if (res.code === 1) {
                    // console.log(res.data);
                    const map: any = {};
                    res.data.forEach((item) => {
                        map[item.rest_netId] = item.isActive;
                    });
                    setCaseNetStateMap(map);
                }
            })
            .finally(() => {
                setStateLoading(false);
            })
    }

    return (
        <div className='case-list ' style={{ display: 'flex', flexDirection: 'column', width: 180, height: 'calc(100vh - 40px)', background: "white", padding: '0 10px' }}>
            <div style={{ padding: '10px 0' }}>
                <div className="bold">联系人</div>
                <div className='color-gray'>
                    {
                        customerLoading ? (
                            <>
                                <Skeleton.Button active size='small' style={{ height: 14, marginTop: 3 }} />
                                <span className='float-right'>
                                    <PhoneOutlined style={{ marginRight: 5 }} />
                                    <Skeleton.Button active className='float-right' size='small' style={{ height: 14, marginTop: 3, width: 80 }} />
                                </span>
                            </>
                        ) : (
                            <>
                                {props.customer?.liaisonName || "未填写"}
                                <div className='float-right'> {props.customer?.liaisonPhone || "未填写"}</div>
                            </>
                        )
                    }
                </div>

            </div>
            <div style={{ paddingBottom: 10 }}>
                <div className="bold">地址</div>
                {
                    customerLoading ? (
                        <Skeleton.Button active size="small" style={{ height: 14, marginTop: 3, width: 160 }} />
                    ) : (
                        <div className='color-gray'>{props.customer?.address || "未填写"}</div>
                    )
                }
            </div>
            {
                customerUser && (
                    <div style={{ paddingBottom: 10 }}>
                        <div className="bold">授权业主</div>
                        <div className='flex' style={{ alignItems: 'center', paddingTop: 5 }}>
                            <div className="img-wrapper" style={{ height: 18, width: 18, borderRadius: 18, overflow: 'hidden' }}>
                                <img src={customerUser.avatar} alt="" />
                            </div>
                            <div className='flex1 flex' style={{ justifyContent: 'space-between', alignContent: 'center' }}>
                                <div style={{ paddingLeft: 5 }}>{customerUser.nicknameRemark || customerUser.nickname}</div>
                                <div className='flex color-gray' style={{ alignItems: 'center' }}>
                                    {customerUser.nicknameRemark ? customerUser.nickname : "设置备注"}
                                    <EditOutlined />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (customerLoading || caseLoading) ? (
                    <Skeleton active />
                ) : (
                    <div className=' no-scroll-bar' style={{ flex: 1, overflowY: 'scroll' }}>
                        <div className="bold" style={{ marginBottom: 5 }}>方案列表</div>
                        {
                            displayCases.length > 0 && displayCases.map((c) => {
                                return (
                                    <div
                                        key={"c-" + c.id}
                                        className={'case-item ' + (c.id === selectedCaseId ? 'active' : '')}
                                        style={{ borderRadius: 3, marginBottom: 10, padding: '2px 6px 5px 6px', cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedCaseId(c.id);
                                        }}
                                    >
                                        <div className='title' style={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {/* <QrcodeOutlined style={{ marginRight: 1 }} /> */}
                                            {c.name}
                                        </div>
                                        {
                                            c.caseTemplateId === 0 && (
                                                <div className='flex'>
                                                    <div className='tag'
                                                        style={{
                                                            background: caseNetStateMap[c.rest_netId] === 1 ? '#6FCE7F' : caseNetStateMap[c.rest_netId] === 0 ? '#fc5531' : undefined,
                                                            color: caseNetStateMap[c.rest_netId] === 1 ? 'rgba(255, 255, 255, 1)' : caseNetStateMap[c.rest_netId] === 0 ? 'rgba(255, 255, 255, 1)' : undefined
                                                        }}>
                                                        {
                                                            stateLoading ? (<LoadingOutlined style={{ marginRight: 5 }} />) : (
                                                                <WifiOutlined style={{ marginRight: 5 }} />
                                                            )}
                                                        {c.rest_netId || "未关联网络"}
                                                    </div>
                                                    <div className='tag'>
                                                        {c.infoChannel || "无信道"}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}