import { RouteChildrenProps } from "react-router-dom";
import ProjectView  from "../../#project-manager/Home/components/ProjectView";

interface Props extends RouteChildrenProps<any>{

}

const HelperProjectView = (props: Props) => {
    return (
       <ProjectView 
            {...props}
            ignoreTabs={["case-list", "orders-list"]}
            customer={{id: Number(props.match.params.customerId) || 0}}
            fullScreen={true}
            onTabChange={(key) => {
                props.history.replace(`/helper/project-view/${props.match.params.customerId}/${key}`)
            }}
            onGoConfig={() => {
                props.history.replace(`/helper/project-view/${props.match.params.customerId}/auto-config`)
            }}
            configAuto
       /> 
    )
}

export default HelperProjectView;