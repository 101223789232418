import { FormInstance, Modal, Form, Input, message } from 'antd'
import React from 'react'
import { useEffect } from 'react';
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';

interface Props {
    caseId: number;
    visible: boolean;
    onCancel: () => void;
    onCaseAreaAdd: (caseArea: any) => void;
}

const createFormRef = React.createRef<FormInstance>();

export const CaseAreaCreateModal = (props: Props) => {

    useEffect(() => {
        createFormRef.current?.setFieldsValue({
            caseId: props.caseId
        })
    }, [props.caseId]);

    const onCreateArea = (data: any) => {
        request.post(apis.area, data)
            .then((res) => {
                if(res.code === 1) {
                    props.onCaseAreaAdd(res.data);
                    message.success("创建成功");
                }else {
                    message.warn(res.msg || res.message);
                }
            })
    }

    return (
        <Modal 
            title="创建区域"
            centered
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={() => {
                createFormRef.current?.submit();
            }}
            destroyOnClose
            width={400}
        >
            <Form 
                ref={createFormRef}
                labelCol={{span: 5}}
                initialValues={{
                    caseId: props.caseId
                }}
                onFinish={onCreateArea}
            >
                <Form.Item name="caseId" hidden>
                    {/* <Input type="hidden" /> */}
                </Form.Item>
                <Form.Item label="区域名称" name={"name"} rules={[{required: true, message: "请输入区域名称"}]}>
                    <Input placeholder="请输入区域名称"  autoComplete="off" autoFocus/>
                </Form.Item>
                {/* TODO 上级区域 */}
            </Form>
        </Modal>
    )

}