import { ScanOutlined } from "@ant-design/icons";
import { Form, FormInstance, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";

export const ScanWrapper = (props: {
    disabled: boolean;
    placeholder: string;
    onEnter: (code: string) => Promise<void>;
    children: any
}) => {

    const inputRef = useRef<any>();
    const formRef = useRef<FormInstance>();
    // const formRef = useRef<any>();

    const [loading, setLoading] = useState(false);

    const onEnter = async (data: any) => {
        if(!data?.code) {
            return ;
        }
        setLoading(true);
        await props.onEnter(data.code);
        setLoading(false);
    }

    useEffect(() => {
        if(!props.disabled) {
            inputRef.current?.focus();
        }
    }, [props.disabled]);

    useEffect(() => {
        if(!loading) {
            inputRef.current?.focus();
            formRef.current?.setFieldsValue({
                code: "",
            });
        }
    }, [loading]);

    return (
        <div className="flex column page-full" onClick={() => {
            inputRef.current?.focus();
        }}>
            <Spin spinning={loading} tip="载入数据中...">
                <div className="content-wrapper flex1" style={{height: 'calc(100vh - 30px)', overflowY: 'scroll'}}>
                {props.children}
                </div>
            </Spin>
            <div className="scan-input" style={{height: 30, overflow: 'hidden'}}>
                <Form ref={formRef} onFinish={onEnter}>
                    <Form.Item name="code" style={{ marginBottom: 0 }}>
                        <Input
                            ref={inputRef}
                            style={{border: 0, outline: "none"}}
                            autoFocus
                            autoComplete="off"
                            type="number"
                            prefix={(<ScanOutlined />)}
                            size={"large"}
                            placeholder={props.placeholder}
                            disabled={loading || props.disabled}
                        />
                    </Form.Item>
                </Form>
            </div>
        </div >
    )
}