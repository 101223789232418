import { PageHeader } from "antd"
import { useEffect, useState } from "react"
import { RouteChildrenProps } from "react-router-dom"
import { apis } from "../../../utils/apis"
import { request } from "../../../utils/request"
import { AppsSelector } from "../AppsMgmt/AppsSelector"

interface Props extends RouteChildrenProps {

}


export const RoleMgmt = (props: Props) => {

    const [roles, setRoles] = useState<any[]>([]);
    const [selectedRole, setSelectedRole] = useState<any>(null);

    useEffect(() => {
        request.get(apis['/upms/role/list'])
            .then((res: any) => {
                if(res.code === 1) {
                    setRoles(res.data);
                    setSelectedRole(res.data[0])
                }
            })
    }, [])

    return (
        <div className="flex">
            <div className="side">
                <div>角色列表</div>
                {
                    roles.length > 0 && roles.map((role) => {
                        return (
                            <div className={selectedRole?.key === role.key ? 'bg-primary' : ''} onClick={() => {
                                setSelectedRole(role);
                            }}>{role.name}</div>
                        )
                    })
                }
            </div>  
            <RoleDetail
                role={selectedRole}
            />

        </div>
    )
}

interface RoleProps {
    role: any
}
export const RoleDetail = (props: RoleProps) => {

    const [role, setRole] = useState(null as any);
    const [roleAppKeys, setRoleAppKeys] = useState<string[]>([]);

    useEffect(() => {
        setRole(props.role);
    }, [props.role]);

    return (
        <div style={{flex: '1 0'}}>
            <PageHeader 
                title={role?.name}
                subTitle={role?.key}
            >
                应用

                菜单
            </PageHeader>
            <AppsSelector 
            
            />
        </div>
    )
}