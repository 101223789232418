import { Breadcrumb, Button, message, PageHeader, Skeleton, Steps, Select, Row, Col, Checkbox, Table, Modal, Divider } from "antd";
import { useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import CaseNodeCellTemplateSelector from "./CaseNodeCellTemplateSelector";
import HexSelector from "./HexSelector";
import ProductSpuSelector from "./ProductSpuSelector";
import Result from "./Result";

const { Step } = Steps;

interface Props extends RouteChildrenProps {

}

export default function CaseProductAdd(props: Props) {

    const [spu, setSpu] = useState(null as any);
    const [step, setStep] = useState(0);
    const [hex, setHex] = useState(null as any);
    const [template, setTemplate] = useState(null as any);

    const [submitting, setSubmitting] = useState(false);

    const onSelectedProductSpu = (spu: any) => {
        setSpu(spu);
    }

    const spuRef = useRef<any>();
    const onNextStep = () => {
        if (step === 0) {
            if (!spu) {
                message.warn("请选择硬件产品");
                return;
            }
            setStep(1);
        } else if (step === 1) {
            if (!hex) {
                message.warn("情选择HEX软件");
                return;
            }
            setStep(2);
        }
    }

    const onPrevStep = () => {
        if (step === 1) {
            setStep(0);
        }else if(step === 2) {
            setStep(1);
        }
    }

    const hexRef = useRef<any>();
    const onSelectedHexProject = (hex: any) => {
        if (!hex) {
            return;
        }
        setHex(hex);
    }


    const onSubmit = async () => {
        if(!spu) {
            message.info("未选择产品");
            return ;
        }
        if(!hex) {
            message.info("未选择HEX");
            return ;
        }
        if(!template) {
            message.info("未选择交互控件");
            return ;
        }
        setSubmitting(true);
        try {
            const data = {
                spuId: spu.spuId,
                hexGroupId: hex.selectedGroupId,
                hexVersionId: hex.selectedVersionId,
                templateKey: template.key
            }
            const res = await request.post(apis.caseProduct + `/create`, data);
            if(res.code === 1) {
                setStep(3);
            }
        }finally {
            setSubmitting(false);
        }
    }


    return (
        <div className="case-product-add" style={{ position: 'relative', overflow: 'hidden' }}>
            <Breadcrumb style={{ padding: '10px 10px 0 10px' }}>
                <Breadcrumb.Item onClick={() => {
                    props.history.goBack();
                }}>智能产品列表</Breadcrumb.Item>
                <Breadcrumb.Item>添加产品</Breadcrumb.Item>
            </Breadcrumb>
            <div className="bg-white" style={{ margin: '10px 10px 10px 10px', height: 'calc(100vh - 40px - 50px)' }}>
                <PageHeader
                    title="创建智能产品"
                    subTitle="基于智能产品创建智能产品"
                >
                    <Steps current={step}>
                        <Step 
                            title="关联硬件产品"
                            description={(
                                <div style={{height: 50}}>
                                {
                                    spu ? '已选择: ' + spu.spuName + `(${spu.spuMode})` : step < 1 ? '请进行硬件产品关联' : (<span className="color-red">异常情况，未选择硬件产品</span>)
                                }
                                </div>
                            )}
                            
                        />
                        <Step 
                            title="关联HEX项目" 
                            description={(
                                <div style={{height: 50}}>
                                {
                                    hex ? '已选择: ' + hex.projectName : step < 2 ? '请选择HEX项目进行关联' : (<span className="color-red">异常情况，未选择HEX项目</span>)
                                }
                                </div>
                            )}/>
                        <Step 
                            title="选择交互控件" 
                            description={(
                                <div style={{height: 50}}>
                                    
                                {
                                    template ? '已选择: ' + template.name : step < 3 ? '请选择交互控件' : (<span className="color-red">异常情况，未选择交互控件</span>)
                                }
                                </div>
                            )} 
                        />
                        <Step 
                            title="完成" 
                            description={(
                                <div style={{height: 50}}>
                                    完成智能产品的创建
                                </div>
                            )}
                        />
                    </Steps>
                </PageHeader>
                <div style={{ margin: '10px 10px 10px 10px', padding: 10, height: 'calc(100vh - 40px - 50px - 120px - 50px)', overflowY: 'auto' }}>
                    {
                        step === 0 ? (
                            <ProductSpuSelector
                                ref={spuRef}
                                selectedSpuId={spu?.spuId}
                                onProductSpuSelected={onSelectedProductSpu}
                            />
                        ) : step === 1 ? (
                            <HexSelector
                                ref={hexRef}
                                selectedHexId={hex?.projectId}
                                onHexProjectSelected={onSelectedHexProject}

                            />
                        ) : step === 2 ? (
                            <CaseNodeCellTemplateSelector
                                spu={spu}
                                hex={hex}
                                selectedTemplateKey={template?.key}
                                onChange={setTemplate}
                            />
                        ) : (
                            <Result
                                spu={spu}
                                hex={hex} 
                                template={template}

                            />
                        )
                    }
                </div>
            </div>
            <div className="footer flex center" style={{ position: 'absolute', bottom: 0, left: 10, right: 10, zIndex: 3, background: '#fff', height: 64, boxShadow: '0 3px 10px rgba(0, 0, 0, .2)' }}>
                {
                    step === 0 && spu && (
                        <div className="flex">
                            <Button style={{ marginRight: 20 }} onClick={() => {
                                spuRef.current?.onCancelSpuSelect();
                                setSpu(null);
                            }}>取消</Button>
                            <Button type="primary" onClick={onNextStep}>下一步</Button>
                            <div style={{ width: 150 }}>
                                <Button type="link">已选择:{spu.spuName}</Button>
                            </div>
                        </div>
                    )
                }
                {
                    step === 1 && (
                        <div className="flex">
                            <Button onClick={onPrevStep} style={{ marginRight: 20 }}>上一步</Button>
                            {
                                hex && (
                                    <div className="flex">
                                        <Button type="primary" onClick={onNextStep}>下一步</Button>
                                        <div style={{ width: 150 }}>
                                            <Button type="link">已选择:{hex.projectName}</Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                {
                    step === 2 && (
                        <div className="flex">
                            <Button onClick={onPrevStep} style={{ marginRight: 20 }}>上一步</Button>
                            {
                                template && (
                                    <div className="flex">
                                        <Button type="primary" disabled={submitting} loading={submitting} onClick={onSubmit}>提交</Button>
                                        <div style={{width: 150}}>
                                            <Button type="link">已选择：{template.name}</Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                {
                    step === 3 && (
                        <div className="flex">
                        <Button type="primary" style={{marginRight: 10}} ghost onClick={() => {
                            setStep(0)
                            setSpu(null);
                            setHex(null);
                            setTemplate(null);
                            
                        }}>继续创建</Button>
                            <Button onClick={() => {
                                props.history.replace("/product/case-product");
                            }}>返回智能产品列表</Button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}