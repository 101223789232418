import { CloudFilled, EditOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { SwitchNodeCell } from './components/SwitchNodeCell';

// const renameFormRef = React.createRef<FormInstance>();
interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingTargets: any[];
    bindingAccepts: string;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onNodeAppUpdate?: (nodeApp: any) => void;
    endpointPairMap: {[endpoint: number]: number};
    onRenameApp: (app: any) => void;
    className?: string;
    viewSource: any;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const SceneSwitchNodeCell = (props: Props) => {

    return (
        <SwitchNodeCell 
            {...props}
            endpointPairMap={props.endpointPairMap}
        />
    )

}