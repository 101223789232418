import { CSSProperties, useEffect } from "react";
import NormalProductCell, { ProductCellProps } from "./ProductCell";

export interface ControlProductCellWrapperProps extends ProductCellProps {
    // hexConfig: {
    //     id?: number;
    editable?: boolean;
    endpoints?: {
        endpoint: number;
        appType: { key: string, name: string, isTarget?: boolean },
        bindType: { key: string, name: string, only?: string, exclude?: string[] },
        requireControlLine: 0 | 1;
    }[]
    // }
    style?: CSSProperties;
    bindSourceEndpoint?: number;
    bindTargetEndpoints?: number[];
    bindType?: { key: string, name: string, only?: string, exclude?: string[] };
    onDataChange?: (sources: any[], targets: any[]) => void;
    onSourceClick?: (endpoint: any) => void;
    onTargetClick?: (endpoint: any) => void;
    onControlLineClick?: (endpoint: any) => void;
    onEndpointNameChange?: (name: string, endpoint: any) => void;
}

const ControlProductCellWrapper = (props: ControlProductCellWrapperProps) => {  

    useEffect(() => {
        let sources: any[] = [];
        let targets: any[] = [];
        const endpoints = props.endpoints || [];
        for (let i = 0; i < endpoints.length; i++) {
            const endpoint: any = endpoints[i];
            const spuSupport = props.spu.endpoints?.find((se) => se === endpoint.endpoint);
            if (!endpoint.requireControlLine) {
                endpoint.isWireless = 0
            } else {
                endpoint.isWireless = -1;
            }
            if (!spuSupport) {
                endpoint.disabled = true;
                endpoint.isWireless = 0;
            }
            if(!endpoint.appType) {
                console.log(endpoint)
                console.log(endpoint.appType)
                console.log(endpoint.appTypeKey)

            }
            if (endpoint.appType.isTarget && spuSupport) {
                targets.push(endpoint);
            }
            if (endpoint.bindType.key !== 'none' && spuSupport) {
                sources.push(endpoint)
            }
        }
        props.onDataChange(sources, targets);
    }, [props.spu, props.endpoints])

    return (
        <NormalProductCell
            {...props}
        >
            <div className="flex column center" style={{ justifyContent: 'space-between', height: "100%", width: "100%", overflow: 'hidden', ...(props.style || {}) }}>
                {props.children}
            </div>
        </NormalProductCell>
    )
}


export default ControlProductCellWrapper;