import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

const combineName = (version: string, channel: string, items: any[]) => {
    let name = "";
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        name += (i === 0 ? '' : '&') + item.combineData.name;
    }
    name += `-${version}-${channel}.tar.gz`;
    return name;
}



export const HexFilesCombinerModal = forwardRef((props: any, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [files, setFiles] = useState<any[]>([])
    const [map, setMap] = useState<Map<string, any>>(null);
    const [combinedMap, setCombinedMap] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [projectId, setProjectId] = useState(0);
    const [groupId, setGroupId] = useState(0);


    useImperativeHandle(ref, () => {
        return {
            open: (projectId: number, groupId: number, files: any, map: any) => {
                setProjectId(projectId);
                setGroupId(groupId);
                setFiles(files);
                setMap(map);
                setVisible(true);
            }
        }
    });

    const close = () => {
        setVisible(false);
        setFiles([]);
        setMap(null);
    }

    useEffect(() => {
        if (visible) {
            const map = new Map();
            setLoading(true);
            request.get(apis.hexFile, { projectId, isCombined: 0, hexVersionId: 0 })
                .then((res) => {
                    const hexFiles = res.data;
                    for (let i = 0; i < hexFiles.length; i++) {
                        const file = hexFiles[i];
                        map[file.fileName] = {
                            loading: false,
                            status: true,
                        };
                    }
                    setCombinedMap(map);
                    setLoading(false);
                })
        }
    }, [visible])

    return (
        <Modal
            title="合并器"
            centered
            visible={visible}
            onCancel={close}
            width={800}
            bodyStyle={{ minHeight: 300 }}
            okText="开始合并"
            onOk={async () => {
                for(const channelVersion in map) {
                    const [channel, versionName] = channelVersion.split('-');
                    const items = map[channelVersion];
                    const name = combineName(versionName, channel, items);
                    combinedMap[name] = {
                        loading: true,
                        status: false,
                    }
                    setCombinedMap({...combinedMap});
                    await request.post(apis.hexFile + '/combine', {
                        name: name,
                        infoChannel: channel,
                        hexProjectId: projectId,
                        hexGroupId: groupId,
                        hexFileIds: items.map((item) => item.id).join(',')
                    });
                    combinedMap[name] = {
                        loading: false,
                        status: true,
                    }
                    setCombinedMap({...combinedMap});
                    props.onHexFilesRefresh();
                    close();
                }
            }}
        >
            <Spin spinning={loading}>
                <div>
                    {
                        (() => {
                            let children: any[] = [];
                            if (!map) {
                                return;
                            }
                            let index = 0;
                            for (const channelVersion in map) {
                                const [channel, versionName] = channelVersion.split('-');
                                const items = map[channelVersion];
                                const name = combineName(versionName, channel, items);
                                children.push(
                                    <div key={"children-" + index} className="flex" style={{ background: items.length < 2 ? 'rgba(255, 100, 100, 0.1)' : undefined, borderBottom: '1px solid rgba(0, 0,0, .1)', paddingTop: (index && 10), paddingBottom: 6, justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="flex" style={{ flex: 1, alignItems: 'center' }}>
                                            <div className="flex" style={{ alignItems: 'center' }}>
                                                <div style={{ width: 72 }}>
                                                    信道：<span className="bold" style={{ fontSize: 14 }}>{channel}</span>
                                                </div>
                                                <div style={{ width: 72 }}>
                                                    版本：<span className="bold" style={{ fontSize: 14 }}>{versionName}</span>
                                                </div>
                                            </div>
                                            <div style={{}}>
                                                {
                                                    items.map((data) => {
                                                        const { combineData, ...hexFile } = data;
                                                        return (
                                                            <div>
                                                                <span className="bold" style={{ fontSize: 14 }}>{combineData.name}</span>
                                                                <span style={{ marginLeft: 5 }}>（{hexFile.fileName}）</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div style={{ color: items.length < 2 ? 'red' : combinedMap[name]?.loading ? 'blue' : combinedMap[name]?.status ? "green" : 'gray', paddingRight: 10}}>
                                            状态： {items.length < 2 ? '不可合并' : combinedMap[name]?.loading ? (<><LoadingOutlined color="blue" spin/> 正在合并...</>) : combinedMap[name]?.status ? '已合并' : '待合并'}
                                        </div>
                                    </div>
                                );
                                index++;
                            }
                            return children;
                        })()
                    }
                </div>
            </Spin>
        </Modal>
    )


})