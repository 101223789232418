import { DeleteOutlined, FieldNumberOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TemplateComponent } from "../../../#case-manager/components/CaseNodeCell/template";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

interface SmartProps {
    smart: any;
    onSmartRemove: (node: any) => void;
    onStartBind: (node: any, app: any, bindType: any) => void;
    onEdit?: () => void;
    onSelectArea: () => void;
}
export const SmartProduct = forwardRef((props: SmartProps, ref: any) => {

    const [loading, setLoading] = useState(true);
    const [node, setNode] = useState<any>(props.smart);
    const [bindType, setBindType] = useState(null);
    const [bindSource, setBindSource] = useState(null);
    const [bindTargets, setBindTargets] = useState([] as any[]);
    const [endpoints, setEndpoints] = useState([] as any[]);

    useEffect(() => {
        if (!node.hexConfig) {
            return;
        }
        for (let i = 0; i < node.hexConfig.endpoints.length; i++) {
            const endpoint = node.hexConfig.endpoints[i];
            const app = node.apps.find((a: any) => a.endpoint === endpoint.endpoint);
            if (app) {
                endpoint.name = app.name;
                endpoint.isWireless = app.isWireless;
                //关联nodeId
                endpoint.caseNodeId = node.id;
                endpoint.caseAppId = app.id;
            }
        }
        setEndpoints(node.hexConfig.endpoints);
    }, [node])

    useImperativeHandle(ref, () => {
        return {
            startBind: (source: { caseNodeId: number, endpoint: number }, bindType: any) => {
                setBindSource(source);
                setBindType(bindType);
            }
        }
    });

    useEffect(() => {
        if (!props.smart.create) {
            request.post(apis.case + `/node/add`, {
                caseId: props.smart.caseId,
                areaId: props.smart.areaId,
                caseProductId: props.smart.id,
            })
                .then((res) => {
                    if (res.code === 1) {
                        res.data.create = true;
                        props.smart.id = res.data.id;
                        res.data.silkScreens = {};
                        setNode(res.data);
                        console.log(res.data);

                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setNode(props.smart);
            setLoading(false);
        }
    }, [props.smart]);

    const onDelete = () => {
        props.onEdit && props.onEdit();
        Modal.confirm({
            title: "请确认是否删除",
            content: "删除后不可恢复",
            centered: true,
            onOk: () => {
                if (node.create) {
                    request.delete(apis.case + `/node/${node.id}`)
                        .then((res) => {
                            if (res.code === 1) {
                                props.onSmartRemove(node);
                            }
                        })
                }
            }
        })
    }


    return (
        <Spin spinning={loading} >
            <div className="bg-white " style={{ width: 240, margin: '0 8px', marginBottom: 10, height: '100%' }}>
                <div>
                    <TemplateComponent
                        editable={true}
                        templateKey={node.templateKey}
                        spu={node.spu}
                        silk1={node.silkScreens[0]}
                        silk2={node.silkScreens[1]}
                        silk3={node.silkScreens[2]}
                        silk4={node.silkScreens[3]}
                        silk5={node.silkScreens[4]}
                        silk6={node.silkScreens[5]}
                        endpoints={endpoints}
                        bindSourceEndpoint={node.id === bindSource?.caseNodeId ? bindSource?.endpoint : undefined}
                        bindTargetEndpoints={bindTargets.map((t) => t.endpoint)}

                        onEndpointNameChange={async (name: string, endpoint: any) => {
                            const app = node.apps.find((a) => a.endpoint === endpoint.endpoint);
                            if (!app) {
                                return;
                            }
                            let nameTemp = endpoint.name;
                            endpoint.name = name;
                            setEndpoints([...endpoints]);
                            const res = await request.put(apis.caseNodeApp + `/${app.id}`, {
                                name: name
                            });
                            if (res.code !== 1) {
                                endpoint.name = nameTemp;
                                setEndpoints([...endpoints]);
                            }
                        }}
                        onSourceClick={(endpoint) => {
                            console.log("source click", endpoint);
                            const app = node.apps.find((a) => a.endpoint === endpoint.endpoint)
                            props.onStartBind(node, app, endpoint.bindType);
                        }}
                        onTargetClick={(endpoint) => {
                            console.log("target click", endpoint);
                            bindTargets.push(endpoint);
                            console.log(bindTargets);

                            setBindTargets([...bindTargets]);
                        }}
                        onControlLineClick={(endpoint) => {
                            console.log("control line", endpoint);

                        }}
                        onSilkChange={(number, position, cnText, endpoints) => {
                            request.post(apis.caseSilkScreen + `/set`, {
                                caseNodeId: node.id,
                                number: number,
                                position: position,
                                cnText: cnText,
                                endpoints: endpoints
                            })
                                .then((res) => {
                                    if (res.code === 1) {
                                        console.log(res.data);
                                        node.silkScreens[res.data.number] = res.data;
                                        setNode({ ...node })
                                    }
                                })

                        }}
                    />
                </div>
                <div className="flex center" style={{ marginTop: 3, justifyContent: 'space-between' }}>

                    <div className="flex" style={{ alignItems: 'center', paddingLeft: 5 }}>
                        <span><FieldNumberOutlined />{node.id}</span>
                        <Button type="link" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props.onSelectArea();
                        }}>{node.areaName || "未设置"}</Button>
                    </div>
                    <div>
                        {
                            node.create && (
                                <Button type="link" danger onClick={onDelete}>
                                    <DeleteOutlined />
                                </Button>

                            )
                        }
                    </div>
                </div>
            </div>
        </Spin>
    )
})
