import { PageHeader, Tag, Switch } from 'antd'
import React, { useEffect, useState } from 'react'

interface Props {
    title: string;
    keyword: string;
    targets: any[];
    configs: {[key: string]: boolean}
    onSelected: (keyword: string, target: any) => void; 
    onRemove: (keyword: string, target: any) => void;
}

export const OfflineSpeakConfigOptions = (props: Props) => {

    const [hasSelected, setHasSelected] = useState(false);

    useEffect(() => {
        const hasSelected = Object.keys(props.configs).find((item) => {
            if(item.indexOf(props.keyword) > -1) {
                if(props.configs[item]) {
                    return true;
                }
            }
            return false;
        });
        setHasSelected(hasSelected ? true : false);
    }, [props.keyword, props.targets, props.configs])

    return (
        <div className="keyword-item">
            <PageHeader
                title={props.title}
                extra={(
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Switch size="small" checked={hasSelected} onChange={() => {
                            setHasSelected(!hasSelected);
                        }} style={{marginRight: 5}}/>
                        <span>展开</span>
                    </div>
                )}
            >

                <div className="options" style={{display: hasSelected ? "block" : "none"}}>
                    {
                        props.targets.length > 0 && props.targets.map((target) => {
                            const isSelected = props.configs[`${props.keyword}-${target.type}-${target.type === "scene" ? target.target.caseSceneId : target.target.appId}`];
                            return (
                                <div 
                                    onClick={() => {
                                        if(isSelected) {
                                            props.onRemove(props.keyword, target);
                                        }else {
                                            props.onSelected(props.keyword, target);
                                        }
                                    }} 
                                    className="inline-block"
                                    style={{ backgroundColor: isSelected ? "blue" : "", padding: '3px 10px', border: '1px solid rgba(255, 255, 255, .3)', borderRadius: 5, marginRight: 10, marginBottom: 10, cursor: "pointer", }}>
                                    {target.target.caseAreaName}{target.type === "scene" ? "情景" : ""}：
                                    {target.target.nodeName ? target.target.nodeName + "-" + target.target.appName: target.target.caseSceneName}
                                </div>
                            )
                        })
                    }
                </div> 
            </PageHeader>
        </div>
    )
}