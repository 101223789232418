import { DownOutlined, EditOutlined, MinusOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Skeleton, Table } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface SpuDrawerProps {
    // spus: any;
    case: any

}

const ProductsDrawer = forwardRef((props: SpuDrawerProps, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const open = () => {
        setVisible(true);

    }
    const close = () => {
        setVisible(false);
    }
    const [spus, setSpus] = useState([] as any[]);
    const [normals, setNormals] = useState([] as any[]);
    const [smarts, setSmarts] = useState([] as any[]);

    useEffect(() => {
        if(!visible) {
            return ;
        }
        if (props.case?.caseId) {
            request.get(apis.case + `/spu/list`, {
                caseId: props.case.caseId
            })
                .then((res) => {
                    if (res.code === 1) {
                        setSpus(res.data);
                    }
                })
        }
    }, [props.case, visible])


    useImperativeHandle(ref, () => {
        return {
            open,
            close,
            onAdd: onAddOne
        }
    });

    const onAddOne = (c: any, spu: any) => {
        request.post(apis.case + `/spu/add/one`, {
            caseId: c.caseId,
            spuId: spu.spuId,
        })
            .then((res) => {
                if (res.code === 1) {
                    const item = {
                        ...res.data,
                        spu: {
                            ...spu
                        },
                    };
                    console.log(res.data);
                    
                    console.log(spus);
                    
                    const index = spus.findIndex((spu) => spu.id === item.id);
                    if (index > -1) {
                        //保留些本地额外添加的一些参数
                        spus[index] = {
                            ...spus[index],
                            ...item
                        };
                    } else {
                        spus.push(item);
                    }
                    setSpus([...spus]);
                }
            })
    }


    const onReduceOne = (c: any, spu: any) => {
        request.post(apis.case + `/spu/reduce/one`, {
            caseId: c.caseId,
            spuId: spu.spuId,
        })
            .then((res) => {
                if (res.code === 1) {
                    const item = {
                        ...res.data,
                        spu,
                    };
                    const index = spus.findIndex((spu) => spu.id === item.id);
                    if (index > -1) {
                        //保留些本地额外添加的一些参数
                        spus[index] = {
                            ...spus[index],
                            ...item
                        };
                    } else {
                        spus.push(item);
                    }
                    setSpus([...spus]);
                }
            })
    }

    useEffect(() => {
        const normals: any[] = [];
        const smarts: any[] = [];
        for (let i = 0; i < spus.length; i++) {
            const spu = spus[i];
            if (spu.spu.isSmart) {
                if (spu.quantity !== 0) {
                    smarts.push(spu);
                }
            } else {
                if (spu.quantity !== 0) {
                    normals.push(spu);
                }
            }
        }
        setNormals(normals);
        setSmarts(smarts);

    }, [spus]);


    const [showSmarts, setShowSmarts] = useState(true);
    return (
        <Drawer
            title="产品统计"
            style={{ height: '100vh !important' }}
            visible={visible}
            width={800}
            onClose={close}
            mask={false}
        >
            {
                loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <Table
                            pagination={false}
                            style={{ margin: '10px 16px' }}
                            dataSource={normals}
                            columns={[{
                                title: "产品",
                                dataIndex: "spu",
                                width: 240,
                                render: (spu) => {
                                    return (
                                        <div className="flex">
                                            <div className="flex center" style={{ height: 45, width: 45, background: "#f2f2f2", cursor: "pointer" }}>
                                                <img src={spu.imageUrl} alt="" height={"100%"} width={"100%"} />
                                            </div>
                                            <div className="column" style={{ fontSize: 14, marginLeft: 10 }}>
                                                <div className="bold">{spu.spuName}</div>
                                                <div>{spu.spuMode}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            }, {
                                title: "数量",
                                key: "quantity",
                                dataIndex: "quantity",
                                align: 'center',
                                width: 148,
                                render: (quantity: any, record: any) => {
                                    return (
                                        <div className="flex center">
                                            <Button onClick={() => {
                                                onReduceOne(props.case, record.spu);
                                            }}>
                                                <MinusOutlined />
                                            </Button>
                                            <Input placeholder="数量" value={quantity} style={{ width: 42, textAlign: 'center', fontWeight: 'bold', fontSize: 14, border: 0 }} />
                                            <Button onClick={() => {
                                                onAddOne(props.case, record.spu);
                                            }}>
                                                <PlusOutlined />
                                            </Button>
                                        </div>
                                    )
                                }
                            }, {
                                title: "备注",
                                dataIndex: "remark",
                                align: 'right',
                                render: (remark, spu: any) => {
                                    return spu.remarkEdit ? (
                                        <Input.TextArea
                                            placeholder="可在此备注信息"
                                            onBlur={() => {
                                                console.log();

                                            }}
                                            maxLength={100}
                                            showCount

                                        />
                                    ) : (
                                        <span>{remark}<Button type="link" style={{ margin: '0 0 5px 0', padding: '0 0 5px 0' }}><EditOutlined />编辑</Button></span>
                                    )
                                }
                            }]}
                            footer={() => (
                                <div className="" style={{textAlign: 'center'}}>
                                    <Button type="link" onClick={() => {
                                        setShowSmarts(!showSmarts)
                                    }}>
                                        展开智能产品
                                        {
                                            showSmarts ? (
                                                <DownOutlined />
                                            ) : (
                                                <UpOutlined />
                                            )
                                        }
                                    </Button>
                                </div>
                            )}
                        />
                        {
                            showSmarts && (
                                <Table
                                    pagination={false}
                                    style={{ margin: '10px 16px' }}
                                    dataSource={smarts}
                                    showHeader={false}
                                    columns={[{
                                        title: "产品",
                                        dataIndex: "spu",
                                        width: 240,
                                        render: (spu) => {
                                            return (
                                                <div className="flex">
                                                    <div className="flex center" style={{ height: 45, width: 45, background: "#f2f2f2", cursor: "pointer" }}>
                                                        <img src={spu.imageUrl} alt="" height={"100%"} width={"100%"} />
                                                    </div>
                                                    <div className="column" style={{ fontSize: 14, marginLeft: 10 }}>
                                                        <div className="bold">{spu.spuName}</div>
                                                        <div>{spu.spuMode}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }, {
                                        title: "数量",
                                        key: "quantity",
                                        dataIndex: "quantity",
                                        align: 'center',
                                        width: 148,
                                        render: (quantity: any, record: any) => {
                                            return (
                                                <div className="flex center">
                                                    {/* <Button onClick={() => {
                                                onReduceOne(props.case, record.spu);
                                            }}>
                                                <MinusOutlined />
                                            </Button> */}
                                                    <Input placeholder="数量" value={quantity} style={{ width: 42, textAlign: 'center', fontWeight: 'bold', fontSize: 14, border: 0 }} />
                                                    {/* <Button onClick={() => {
                                                onAddOne(props.case, record.spu);
                                            }}>
                                                <PlusOutlined />
                                            </Button> */}
                                                </div>
                                            )
                                        }
                                    }, {
                                        title: "备注",
                                        dataIndex: "remark",
                                        align: 'right',
                                        render: (remark, spu: any) => {
                                            return spu.remarkEdit ? (
                                                <Input.TextArea
                                                    placeholder="可在此备注信息"
                                                    onBlur={() => {
                                                        console.log();

                                                    }}
                                                    maxLength={100}
                                                    showCount

                                                />
                                            ) : (
                                                <span>{remark}<Button type="link" style={{ margin: '0 0 5px 0', padding: '0 0 5px 0' }}><EditOutlined />编辑</Button></span>
                                            )
                                        }
                                    }]}
                                />
                            )
                        }
                    </>
                )
            }
        </Drawer>
    )
})

export default ProductsDrawer;