import { Form, Modal, Spin } from "antd";
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";

interface ModalFormProps {
    title: any;
    centered?: boolean;
    maskClosable?: boolean;
    width?: number;
    labelCol?: {span: number};
    onSubmit: (data: any) => Promise<boolean | undefined | null | void>;
    children?: any;
    layout?: "inline" | "horizontal" | "vertical";
    // initialValues?: any;
}
const ModalForm = forwardRef((props: ModalFormProps, ref: ForwardedRef<{
    open: () => void
}>) => {

    const [visible, setVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState(undefined as any)
    const formRef = useRef<any>();

    const [sale, setSale] = useState(true);
    const open = (initialValues?: any) => {
        setVisible(true);
        setInitialValues(initialValues || undefined);
    }
    const close = () => {
        setVisible(false);
    }

    useImperativeHandle(ref, () => {
        return {
            open: open,

        }
    });

    const onSubmit = (data: any) => {
        setSubmitting(true);
        props.onSubmit(data)
            .then(res => {
                if(res) {
                    close();
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
            

    }
    return (
        <Modal
            title={props.title}
            visible={visible}
            centered={props.centered}
            maskClosable={props.maskClosable}
            onCancel={close}
            width={props.width}
            onOk={() => {
                formRef.current?.submit();
            }}
            okButtonProps={{
                disabled: submitting,
                loading: submitting,
            }}
            okText={submitting ? "正在提交..." : "提交"}
            cancelButtonProps={{
                disabled: submitting
            }}
            destroyOnClose
        >
            <Spin spinning={submitting}>
            <Form
                ref={formRef}
                labelCol={props.labelCol}
                onFinish={onSubmit}
                layout={props.layout}
                initialValues={initialValues}
            >
                {props.children}
            </Form>
                
            </Spin>
        </Modal>
    )

});

export default ModalForm;