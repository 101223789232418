
import './style.less';
import pkg from '../../../package.json';
import { Tag } from 'antd';

interface Props {
    collapsed?: boolean,
    toolsChildren?: any,
    // menusChildren: any,
    children: any
}

export const Sider = (props: Props) => {
    return (
        <div className="sider" style={{ display: props.collapsed ? "none" : 'flex' }}>
            {
                props.toolsChildren && (
                    <div className="tools">
                        {props.toolsChildren}
                    </div>
                )
            }
            <div className="menus flex">
                {/* <div className="menu-wrapper"> */}
                {props.children}
                {/* </div> */}
            </div>
        </div>
    )
}