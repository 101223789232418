import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";
import AddEndpointModal from "./AddEndpointModal";
import HexConfigEndpointEdit from "./HexConfigEndpointEdit";

interface HexConfigEndpointsViewProps {
    onEndpointsChange: (endpoints: any) => void
}
const HexConfigEndpointsView = forwardRef((props: HexConfigEndpointsViewProps, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [hexConfig, setHexConfig] = useState(null as any);
    const [endpoints, setEndpoints] = useState<any[]>([]);
    const [displayEndpoints, setDisplayEndpoints] = useState<any[]>([]);

    const open = (hexConfig: any) => {
        setVisible(true);
        setHexConfig(hexConfig);
        setEndpoints(hexConfig?.endpoints || []);
    }

    const close = () => {
        setVisible(false);
        setHexConfig(null);
    }

    useImperativeHandle(ref, () => {
        return {
            open
        }
    }
    )

    useEffect(() => {

    }, [])

    const editEndpointRef = useRef<any>();

    useEffect(() => {
        const sort = endpoints.sort((a, b) => Number(a.endpoint) > Number(b.endpoint) ? 1 : -1);
        setDisplayEndpoints(sort)
    }, [endpoints]);

    const addEndpointRef = useRef<any>();
    const onEndpointAdd = async (endpoint: any) => {
        const data = {
            configId: hexConfig.id,
            endpoint: Number(endpoint.endpoint),
            appType: endpoint.appType.key,
            bindType: endpoint.bindType.key,
            requireControlLine: endpoint.requireControlLine,
            opened: Number(endpoint.opened)
        }
        const res = await request.post(apis.hex + `/config/endpoint`, data);
        if (res.code === 1) {
            endpoint.id = res.data.id;
            endpoint.endpointId = res.data.id;
            endpoint.opened = Number(endpoint.opened);
            const endpoints = [...hexConfig.endpoints, endpoint];
            setEndpoints(endpoints)
            props.onEndpointsChange(endpoints);
            return true;
        }
    }

    const onEndpointUpdate = async (endpoint: any) => {

        endpoint.id = endpoint.endpointId;
        return request.put(apis.hex + `/config/endpoint`, {
            endpointId: endpoint.endpointId,
            endpoint: endpoint.endpoint,
            appType: endpoint.appType.key,
            bindType: endpoint.bindType.key,
            requireControlLine: endpoint.requireControlLine,
            opened: Boolean(endpoint.opened),
        })
            .then((res) => {
                if (res.code === 1) {
                    console.log(res.data);
                    const index = endpoints.findIndex((e) => e.endpointId === res.data.id);
                    endpoints[index] = endpoint;
                    setEndpoints([...endpoints]);
                    props.onEndpointsChange(endpoints);
                    return true;
                }
            })
    }
    

    return (
        <Modal
            title="Endpoints列表"
            visible={visible}
            onCancel={close}
            centered
            footer={[]}
            width={700}
        >
            <HexConfigEndpointEdit
                ref={editEndpointRef}
                onEndpointChange={onEndpointUpdate}
            />
            <AddEndpointModal
                ref={addEndpointRef}
                onEndpointAdd={onEndpointAdd}
            />
            <Table
                size="small"
                pagination={false}
                dataSource={displayEndpoints}
                style={{ minHeight: 100 }}
                columns={[{
                    title: "E",
                    key: "endpoint",
                    width: 30,
                    dataIndex: "endpoint",
                    align: 'center'
                }, {
                    title: "应用",
                    key: "appType",
                    width: 100,
                    dataIndex: "appType",
                    render: (appType: any) => {
                        return appType ? appType.name : (<span style={{color: 'red'}}>应用类型为空!</span>);
                    }
                }, {
                    title: "绑定",
                    key: "bindType",
                    dataIndex: "bindType",
                    render: (bindType: any) => {
                        return bindType ? bindType.name : (<span style={{color: 'red'}}>绑定类型为空!</span>);
                    }
                }, {
                    title: "控制线",
                    key: "requireControlLine",
                    dataIndex: "requireControlLine",
                    render: (requireControlLine: 0 | 1) => {
                        return requireControlLine ? "控制线" : ""
                    }
                }, {
                    align: 'center',
                    title: "通信",
                    dataIndex: "opened",
                    key: "opened",
                    width: 50,
                    render: (opened: any) => {
                        return Boolean(opened) && (
                            <span style={{ color: "green" }}>可通信</span>
                        )
                    }
                }, {
                    title: (
                        <Button type="link" onClick={() => {
                            addEndpointRef.current?.open();
                        }}>
                            <PlusOutlined />
                            添加endpoint
                        </Button>
                    ),
                    key: 'action',
                    align: 'center',
                    render: (_, endpoint: any, index) => {
                        return (
                            <>
                                <Button type="link" ghost onClick={() => {
                                    editEndpointRef.current?.open({
                                        endpointId: endpoint.id,
                                        appType: endpoint.appType.key,
                                        bindType: endpoint.bindType.key,
                                        endpoint: endpoint.endpoint,
                                        opened: Boolean(endpoint.opened),
                                        configId: endpoint.configId,
                                    })
                                }}>
                                    <EditOutlined />
                                    编辑
                                </Button>
                                <Button type="link" danger onClick={() => {
                                    Modal.confirm({
                                        title: "请确定是否删除",
                                        centered: true,
                                        onOk: () => {
                                            request.delete(apis.hex + `/config/endpoint`, {
                                                endpointId: endpoint.id,
                                            })
                                                .then((res) => {
                                                    if (res.code === 1) {
                                                        const endpoints = hexConfig.endpoints.filter((e) => e.id !== res.data);
                                                        setEndpoints(endpoints);
                                                        props.onEndpointsChange(endpoints);
                                                        message.info("已删除");
                                                    }
                                                })
                                        }
                                    })
                                }}>
                                    <DeleteOutlined />删除
                                </Button>
                            </>
                        )
                    }
                }]}
            />
        </Modal>
    )

})

export default HexConfigEndpointsView;