import { DownOutlined, DropboxOutlined, EditOutlined, EllipsisOutlined, FileImageFilled, FileSearchOutlined, OrderedListOutlined, PlusOutlined, QuestionCircleFilled, QuestionCircleOutlined, QuestionOutlined, UpOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu, message, PageHeader, Skeleton, Space, Table, TableColumnsType, Tag, Tooltip, Upload } from "antd";
import { useEffect, useRef, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import ProductCreateModal from "../ProductCreateModal/index2";
import ProductEditModal from "../ProductEditModal/index2";

interface ProductListPageProps {

}

const ProductListPage = (props: ProductListPageProps) => {

    const [loading, setLoading] = useState(false);
    const [productList, setProductList] = useState<any[]>([]);
    useEffect(() => {
        getProductList();
    }, []);

    const getProductList = async () => {
        setLoading(true);
        try {
            const res = await request.get(apis.productSpu);
            if (res.code === 1) {
                setProductList(res.data);
            }
        } finally {
            setLoading(false);
        }

    }

    const addSpuRef = useRef<any>();
    const editSpuRef = useRef<any>();

    const expandedRowRender = () => {
        const columns: TableColumnsType<any> = [
            {
                title: (
                    <span style={{ marginLeft: 15 }}>智能产品</span>
                ),
                key: 'product-name',
                width: 315,
            },
            {
                title: '关联HEX',
                key: "hex",
                width: 200,
            },
            {
                title: "跟随版本更新",
                key: "follow-latest",
            },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: () => (
                    <Space size="middle">
                        <a>Pause</a>
                        <a>Stop</a>
                        <Dropdown overlay={(
                            <Menu>
                                <Menu.Item></Menu.Item>
                            </Menu>
                        )}>
                            <a>
                                <DownOutlined />
                            </a>
                        </Dropdown>
                    </Space>
                ),
            },
        ];

        const data = [];
        for (let i = 0; i < 2; ++i) {
            data.push({
                key: i.toString(),
                date: '2014-12-24 23:12:00',
                name: 'This is production name',
                upgradeNum: 'Upgraded: 56',
            });
        }
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };


    const onSpuUpdate = async (data: any) => {
        console.log(data);
        const product = productList.find((p) => p.spuId === data.id);
        product.spuName = data.name;
        product.spuMode = data.mode;
        product.isSmart = data.isSmart;
        product.chipType = data.chipType;
        product.gatewayType = data.gatewayType;
        product.isRowFrame = data.isRowFrame;
        product.supportRowFrame = data.supportRowFrame;
        product.endpoints = data.endpoints.split(',').map((e) => Number(e));
        setProductList([...productList]);
    }



    return (
        <div className="product-list-page">
            {
                loading ? (
                    <div style={{ padding: 10 }}>
                        <Skeleton active />
                    </div>
                ) : (
                    <div style={{}}>
                        <PageHeader
                            title="产品列表"
                            extra={[(
                                <Button type="primary" onClick={() => {
                                    addSpuRef.current?.open();
                                }}>
                                    <PlusOutlined />
                                    新增产品
                                </Button>
                            )]}
                        >

                        </PageHeader>
                        <ProductCreateModal
                            ref={addSpuRef}

                        />
                        <Table
                            expandable={{
                                columnWidth: 130,
                                expandedRowRender,
                                childrenColumnName: "actions",
                                expandIconColumnIndex: 4,
                                expandIcon: (props) => {
                                    return (
                                        <div>
                                            <div>
                                                <Button type="link">
                                                    <PlusOutlined />
                                                    新增智能产品
                                                </Button>
                                            </div>
                                            <div>
                                                <Button type="link" onClick={(e) => {
                                                    props.onExpand(props.record, e);
                                                }}>
                                                    <FileSearchOutlined />
                                                    查看智能产品{props.expanded ? (<DownOutlined />) : (<UpOutlined />)}
                                                </Button>
                                            </div>

                                        </div>
                                    )
                                }
                            }}

                            pagination={false}
                            dataSource={productList}
                            style={{ marginLeft: 10 }}
                            scroll={{ y: window.innerHeight - 40 - 50 - 45 }}
                            columns={[{
                                title: "#",
                                key: "index",
                                width: 30,
                                align: 'center',
                                render: (_, __, index) => {
                                    return index + 1;
                                }
                            }, {
                                title: "产品信息",
                                key: "product",
                                width: 300,
                                render: (_, spu) => {
                                    return (
                                        <div>
                                            <div className="flex">
                                                <Upload
                                                    name="file"
                                                    listType="picture"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    headers={{
                                                        'Authorization': request.getAccessToken(),
                                                    }}
                                                    data={{ spuId: spu.spuId }}
                                                    action={apis.productImage + `/upload`}
                                                    beforeUpload={(file) => {
                                                        if (file.size > 5 * 1024 * 1024) {
                                                            message.warn('图片过大');
                                                            return false;
                                                        }
                                                        return true;
                                                    }}
                                                    onChange={(res) => {
                                                        if (res.file.status === "done") {
                                                            spu.imageUrl = (res.file.response as any)?.data;
                                                            setProductList([...productList]);
                                                        }
                                                    }}
                                                >
                                                    {spu.imageUrl ? <img src={spu.imageUrl} alt="产品图片" style={{ width: 50, height: 50 }} /> : (
                                                        <div className="flex center" style={{ height: 50, width: 50, background: "#f2f2f2", cursor: "pointer" }}>
                                                            <FileImageFilled />
                                                        </div>
                                                    )}
                                                </Upload>
                                                <div className="column" style={{ fontSize: 14, marginLeft: 10 }}>
                                                    <div className="bold">{spu.spuName}</div>
                                                    <div>{spu.spuMode}</div>
                                                </div>
                                            </div>
                                            {
                                                spu.spuDesc && (
                                                    <div style={{ marginTop: 5, color: "gray" }}>说明：{spu.spuDesc}</div>
                                                )
                                            }
                                            {
                                                spu.specs.length > 0 && (
                                                    <div style={{ marginTop: 5 }}>
                                                        <div>多规格</div>
                                                        {
                                                            spu.specs.map((spec) => {
                                                                return (
                                                                    <div>{spec.specName}包括：{spec.specValues.map((sv) => sv.specValue).join('、')}</div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                )
                                            }
                                        </div>
                                    )
                                }
                            }, {
                                title: "特性",
                                render: (_, spu: any) => {
                                    return (
                                        <div>
                                            {
                                                Boolean(spu.isSmart) && (
                                                    <Tooltip overlay={(
                                                        <span>支持Endpoints: {spu.endpoints?.join(',')}</span>
                                                    )}>
                                                    <Tag color={"purple"}>智能产品</Tag>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                spu.gatewayType !== 0 && (spu.gatewayType === 1 ? (
                                                    <Tag color={"green"}>网关</Tag>
                                                ) : (
                                                    <Tag color={"green"}>网关模块</Tag>
                                                ))
                                            }
                                            {
                                                Boolean(spu.isRowFrame) && (
                                                    <Tag color={"pink"}>连排框</Tag>
                                                )
                                            }
                                            {
                                                Boolean(spu.supportRowFrame) && (
                                                    <Tag color={"blue"}>支持连排</Tag>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            },
                            //  {
                            //     title: "支持endpoint",
                            //     width: 120,
                            //     dataIndex: "endpoints",
                            //     render: (endpoints) => {
                            //         return endpoints.join(",")
                            //     }
                            // }, 
                            {
                                title: "状态",
                                key: "enabled",
                                width: 80,
                                dataIndex: "enabled",
                                render: (enabled) => {
                                    return (
                                        <div title="不得创建新订单" style={{color: enabled ? "green" : "orange"}}>
                                            {
                                                enabled ? (
                                                    <>
                                                    上架中
                                                    </>
                                                ) : (
                                                    <>
                                                    已下架
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            }, {
                                title: "操作",
                                key: "actions",
                                width: 100,
                                render: (_, spu: any) => {
                                    return (
                                        <div>
                                            <Dropdown overlay={(<Menu>
                                                    <Menu.Item icon={(<EditOutlined />)} onClick={() => {
                                                        editSpuRef.current?.open(spu)
                                                    }}>编辑</Menu.Item>
                                                    <Menu.Item danger icon={(<DropboxOutlined />)}>下架</Menu.Item>
                                                </Menu>)}>

                                                <Button size="small">
                                                    <EllipsisOutlined />
                                                    更多
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    )
                                }
                            }]}
                        />

                        <ProductEditModal
                            ref={editSpuRef}
                            onSpuUpdate={onSpuUpdate}
                        />
                    </div>
                )
            }
        </div>
    )

}

export default ProductListPage;


