import { LoadingOutlined } from "@ant-design/icons";
import { Button, Cascader, Dropdown, Form, Menu, Select, Tag } from "antd";
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { InfoChannelSelector2 } from "../../#case-manager/CaseManager/components/CaseEditor/components/InfoChannelSelector";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

/**
 * Hex选择期
 */
 interface HexSelectorProps {
    limit?: {
        infoChannel?: string;//指定的信道
        groupId?: number;    //指定的HEX Group的ID
    },
    selectable?: boolean;
    style?: CSSProperties,
    hexFile?: {
        fileId: number
        fileName: string
        gmtUpload: string
        hexGroupId: number
        hexVersionId: number
        id: number
        infoChannel: string
        projectId: number
        status: number
        uuid: string
    };
    onChange?: (hexFile: any) => void;
    simple?: boolean;
}
const stagePriorityColors = ["pink", "green", "purple", "blue"];
const HexSelector = forwardRef(function (props: HexSelectorProps, ref: any) {

    useImperativeHandle(ref, () => {
        return {

        }
    });

    const [group, setGroup] = useState(null as any);
    const onHexGroupChange = (_: number, data: any) => {
        setGroup(data);
    }

    const [stage, setStage] = useState(null as any);
    const onStageChange = (_: number, data: any) => {
        setStage(data);
    }


    const [hexFiles, setHexFiles] = useState<any[]>([]);
    const [hexFile, setHexFile] = useState<any>(props.hexFile);
    const onHexFileChange = (_: number, data: any) => {
        if(data?.fileId === hexFile?.fileId) {
            return ;
        }
        console.log("onChange", hexFile?.fileId, '->', data?.fileId);
        setHexFile(data);
        if(props.onChange) {
            props.onChange(data);
        }
    }

    useEffect(() => {
        setHexFile(props.hexFile);
        console.log("hex selector props hex change", props.hexFile?.fileName);
        
    }, [props.hexFile]);

    useEffect(() => {
        console.log("hex selector hex change", hexFile?.fileName);
        
    }, [hexFile])

    const fileSelectorRef = useRef<any>();

    return (
        <div className="flex column" style={{ alignItems: 'flex-start', background: '#fff', padding: '10px', boxShadow: 'rgba(0, 0, 0, .2)', ...(props.style || {})}}>
            <div className="flex" style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', height: props.simple ? 0 : undefined, overflow: 'hidden'}}>
                <div className="flex center" >
                    <Form layout="inline" >
                        <Form.Item label="工具" >
                            <div className="logo" style={{borderRadius: 2, overflow: 'hidden', cursor: 'pointer', marginRight: 12}} title="当前仅支持TI">
                                <img src="/assets/images/TI_flash_program.png" height="26px"/>
                            </div>
                        </Form.Item>
                        <Form.Item label="项目">
                            <HexProjectGroupSelector
                                selectable={props.selectable}
                                value={hexFile?.hexGroupId || 0}
                                onChange={onHexGroupChange}
                            />
                        </Form.Item>
                        <Form.Item label="版本">
                            <HexStageVersionSelector
                                selectable={props.selectable}
                                hexProjectGroupId={group?.value || hexFile?.hexGroupId ||  0}
                                hexVersionId={hexFile?.hexVersionId}
                                value={stage?.id}
                                onChange={onStageChange}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    {   
                        (window as any).inElectron ? (
                            <div className="flex center">
                                <div style={{ height: 12, width: 12, borderRadius: 6, background: "green", marginRight: 5 }} />
                                可正常烧录
                            </div>

                        ) : (
                            <div className="flex center">
                                <div style={{ height: 12, width: 12, borderRadius: 6, background: "gray", marginRight: 5 }} />
                                浏览器环境下不能进行烧录操作
                            </div>
                        )
                    }
                </div>
            </div>
            <Form layout="inline" style={{ margin: props.simple ? 0 : '15px 0 10px 0', height: props.simple ? 0 : undefined, overflow: 'hidden' }}>
                <Form.Item label="信道">
                    <HexFilesSelector
                        selectable={props.selectable}
                        ref={fileSelectorRef}
                        versionId={stage?.hexVersionId}
                        onHexFilesChange={setHexFiles}
                        value={hexFile?.id || 0}
                        onChange={onHexFileChange}
                    />
                </Form.Item>
            </Form>
            <Form layout="inline">
                <Form.Item label="烧录" style={{ marginBottom: 0 }}>
                    {hexFile ? (
                        <Dropdown disabled={props.selectable === false} trigger={["click"]} overlay={(
                            <Menu selectedKeys={[hexFile?.uuid]}>
                                {
                                    hexFiles.map((hf) => {
                                        return (
                                            <Menu.Item key={hf.uuid} onClick={() => {
                                                fileSelectorRef.current?.setHexFileId(hf.id);
                                            }} icon={(<span className="bold">{hf.infoChannel}</span>)}>
                                                {hf.fileName}
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        )}
                        >
                            <Button type="link" className="bold" style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>{hexFile.fileName}</Button>
                        </Dropdown>
                    ) : (
                        <span style={{ color: 'gray' }}>未选择HEX文件</span>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
});

export default HexSelector;

interface HexProjectGroupSelectorProps {
    selectable?: boolean;
    value?: number; //groupId
    onChange?: (groupId: number, data: any) => void;  //回调
    
}
const HexProjectGroupSelector = forwardRef((props: HexProjectGroupSelectorProps, ref: React.ForwardedRef<any>) => {

    const [groupLoading, setGroupLoading] = useState(true);
    const [projectGroups, setProjectGroups] = useState<any[]>([]);
    const [cascaderValues, setCascaderValues] = useState<number[]>([]);
    const [selectedGroupId, setSeletedGroupId] = useState(props.value || 0);

    const getProjectGroups = async () => {
        setGroupLoading(true);
        try {
            const res = await request.get(apis.hexProjectCascader);
            if (res.code === 1) {
                setProjectGroups(res.data);
                setGroupLoading(false);
            }
        } catch(e) {
            setGroupLoading(false);
        }
    }

    useEffect(() => {
        getProjectGroups();
    }, []);

    
    //递归查找groupId路径
    const recursionGroupPath = (children: any[], groupId: number, temp?: number[]) => {
        if(!temp) {
            temp = [];
        }
        for (let i = 0; i < children.length; i++) {
            const item = children[i];
            temp.push(item.value);
            if(item.isLeaf && item.value === groupId) {
                return temp;
            }else {
                temp.pop();
            }
        }
        for (let i = 0; i < children.length; i++) {
            const item = children[i];
            if(!item.isLeaf && item.children) {
                temp.push(item.value)
                const res = recursionGroupPath(item.children, groupId, temp);
                if(res instanceof Array) {
                    return  res;
                }
                temp.pop();
            }
        }
    }
    
    useEffect(() => {
        if(groupLoading) {
            return ;
        }
        if (props.value) {
            console.log(projectGroups);
            
            console.log("group props.value", props.value);
            const values = recursionGroupPath(projectGroups, props.value);
            console.log("group", values);
            setCascaderValues(values);
        }
    }, [props.value, groupLoading, projectGroups]);


    const recursionGroup = (children: any[], groupId: number) => {
        
        for (let i = 0; i < children.length; i++) {
            const item = children[i];
            if (item.children) {
                
                const res = recursionGroup(item.children, groupId);
                if(res) {
                    return res;
                }
            }else {
                if(item.value === groupId) {
                    return item;
                }
            }
        }
    }

    useEffect(() => {
        if(groupLoading || selectedGroupId === 0) {
            return ;
        }
        if(!props.onChange) {
            return ;
        }
        const group = recursionGroup(projectGroups, selectedGroupId);
        if(group) {
            props.onChange(group.value, group);
        }
    }, [selectedGroupId, groupLoading, projectGroups])

    return (
        <Cascader
            style={{ width: 300 }}
            value={cascaderValues}
            options={projectGroups}
            placeholder={groupLoading ? (<><LoadingOutlined spin/>正在获取...</>) : "请选择HEX项目"}
            disabled={groupLoading || props.selectable === false}
            showArrow={false}
            allowClear={false}
            displayRender={(_, options) => {
                return (
                    <div className="flex">
                        {
                            options.map((option, index) => {
                                if(!option) {
                                    return (
                                        <span key={"option-" + index} style={{color: 'red'}}>出现错误</span>
                                    )
                                }
                                return (
                                    <>
                                        <div key={"option-" + index} className="" style={{ fontWeight: option.isLeaf ? 'bold' : undefined, color: option.isLeaf ? '#000' : '#888' }}>
                                            {option.label}
                                        </div>
                                        {
                                            index !== options.length - 1 && (
                                                <div style={{ margin: '0 5px' }}>/</div>
                                            )
                                        }
                                    </>

                                )
                            })
                        }
                    </div>
                )

            }}
            onChange={(values: number[], options: any[]) => {
                setSeletedGroupId(values[values.length - 1]);
                setCascaderValues(values);
            }}
        />
    )
});

interface HexStageVersionSelectorProps {
    selectable?: boolean;
    hexProjectGroupId: number;
    hexVersionId?: number;
    value?: number;
    onChange?: (value: number, stageVersion: any) => void;
}
const HexStageVersionSelector = forwardRef((props: HexStageVersionSelectorProps, ref: React.ForwardedRef<any>) => {
    

    const [versionLoading, setVersionLoading] = useState(true);
    const [selectedStageId, setSelectedStageId] = useState(props.value || undefined);
    const [stageVersions, setStageVersions] = useState<any[]>([]);

    useEffect(() => {
        getGroupStageVersion(props.hexProjectGroupId);
    }, [props.hexProjectGroupId]);
    
    const getGroupStageVersion = async (groupId: number) => {
        if(!groupId) {
            if(stageVersions.length !== 0) {
                setStageVersions([]);
            }
            setVersionLoading(false);
            return ;
        }
        setVersionLoading(true);
        try {
            const res = await request.get(apis.hexStage + `/version/list`, {
                groupId
            });
            if (res.code === 1) {
                setStageVersions(res.data);
            }
        } finally {
            setVersionLoading(false);
        }
    }

    useEffect(() => {
        // console.log(stageVersions, props.hexProjectGroupId, props.hexVersionId, props.value);
        
        let stageId = 0;
        if(props.value) {
            const stage = stageVersions.find((sv) => sv.id === props.value);
            if(stage) {
                stageId = stage.id;
            }
        }else if(props.hexVersionId) {
            const stage = stageVersions.find((sv) => sv.hexVersionId === props.hexVersionId);
            if(stage) {
                stageId = stage.id;
            }
        }else if(stageVersions.length > 0) {
            stageId = stageVersions[0].id;
        }
        setSelectedStageId(stageId);
    }, [stageVersions, props.hexProjectGroupId, props.hexVersionId, props.value]);

    useEffect(() => {
        if(props.onChange) {
            const stage = stageVersions.find((sv) => sv.id === selectedStageId);
            props.onChange(selectedStageId, stage);
        }
    }, [selectedStageId]);
    
    useImperativeHandle(ref, () => {
        return {
            refresh: (groupId: number) => {
                getGroupStageVersion(groupId);
            }
        }
    });

    return (
        <Select
            disabled={versionLoading || stageVersions.length === 0 || props.selectable === false}
            placeholder={versionLoading ? (<><LoadingOutlined spin/>正在获取...</>) : stageVersions.length === 0 ? "无可选项" : "选择版本"}
            style={{ width: 120 }}
            value={props.value || undefined}
            showArrow={false}
            allowClear={false}
            onChange={(id: number) => {
                setSelectedStageId(id);
            }}
        >
            {
                stageVersions.map((sv) => {
                    return (
                        <Select.Option key={sv.id.toString()} value={sv.id}>
                            <Tag color={stagePriorityColors[sv.priority]} style={{ opacity: 0.5 }}>{sv.name}</Tag>
                            {sv.versionName}
                        </Select.Option>
                    )
                })
            }
        </Select>
    )
});


interface HexFilesProps {
    selectable?: boolean;
    versionId: number;
    value?: number;
    defaultInfoChannel?: string;    //默认的信道
    onHexFilesChange: (hexFiles: any[]) => void;
    onChange?: (id: number, data: any) => void;
}
const HexFilesSelector = forwardRef((props: HexFilesProps, ref: React.ForwardedRef<any>) => {

    const [fileLoading, setFileLoading] = useState(true);
    const [hexFiles, setHexFiles] = useState<any[]>([]);
    const [selectedHexFileId, setSelectedFileId] = useState(props.value || 0);
    const [selectedHexFile, setSelectedFile] = useState(null as any);
    const [selectedInfoChannel, setSelectedInfoChannel] = useState(props.defaultInfoChannel);

    useEffect(() => {
        getHexVersionFiles(props.versionId);
    }, [props.versionId]);

    const getHexVersionFiles = async (versionId: number) => {
        if(!versionId) {
            if(hexFiles.length !== 0) {
                setHexFiles([]);
            }
            setFileLoading(false);
            return ;
        }
        setFileLoading(true);
        try {
            const res = await request.get(apis.hexVersion + `/${versionId}/hex-files`);
            if (res.code === 1) {
                setHexFiles(res.data);
                if(!props.value && res.data > 0) {
                    setSelectedFileId(res.data[0].id);
                    setSelectedFile(res.data[0]);
                }
                setFileLoading(false);
            }
        } finally {
            setFileLoading(false);
        }
    }

    useEffect(() => {
        props.onHexFilesChange(hexFiles);
    }, [hexFiles])
    
    useImperativeHandle(ref, () => {
        return {
            setHexFileId: setSelectedFileId
        }
    });

    useEffect(() => {
        if((props.value || 0) !== selectedHexFileId) {
            setSelectedFileId(props.value || 0); 
        }
    }, [props.value]);

    
    useEffect(() => {
        //手动选择的情况 （自动选择会有问题）
        if(props.selectable === false) {
            return ;
        }
        if(fileLoading) {
            return ;
        }
        let file: any;
        let _selectedHexFileId = selectedHexFileId;
        if(props.value) {
            if(props.value === _selectedHexFileId) {
                return ;
            }
        }
        if(_selectedHexFileId) {
            file = hexFiles.find((hf) => hf.id === _selectedHexFileId);
        }
        if(!file) {
            _selectedHexFileId = 0;
        }
        if(!_selectedHexFileId) {
            if(hexFiles.length > 0) {
                file = hexFiles.find((hf) => hf.infoChannel === selectedInfoChannel);
                if(!file) {
                    file = hexFiles[0];
                }
                _selectedHexFileId = file.id;
            }
        }
        setSelectedFile(file);
        setSelectedFileId(_selectedHexFileId);
        props.onChange && props.onChange(_selectedHexFileId, file);
    }, [selectedHexFileId, selectedInfoChannel, hexFiles, fileLoading, props.selectable]);


    useEffect(() => {
        if(selectedHexFile) {
            console.log("set selected info chaneel", selectedHexFile.infoChannel);
            setSelectedInfoChannel(selectedHexFile.infoChannel);
        }
    }, [selectedHexFile]);

    const onChange = (hexFile: any) => {
        setSelectedFile(hexFile);
        setSelectedFileId(hexFile?.id || 0)
        props.onChange(hexFile?.id || 0, hexFile)
    }

    useEffect(() => {
        console.log("★props.value", props.value);
        console.log("★selectedHexFileId", selectedHexFileId);
        
    }, [props.value, selectedHexFileId])
    
    return (
        <InfoChannelSelector2
            mode="edit"
            value={selectedHexFile?.infoChannel}
            has={hexFiles.map((hf) => hf.infoChannel)}
            selectable={props.selectable}
            onChange={(value) => {
                const hexFile = hexFiles.find((item) => item.infoChannel === value);
                onChange(hexFile);
            }}
        />
    )
});