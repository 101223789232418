import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Radio, Select, Tabs, Tag } from "antd";
import { forwardRef, useEffect, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import ModalForm from "../../../components/ModalForm";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import CustomerList from "./components/CustomerList";
import ProjectView from "./components/ProjectView";
import './style.less';

interface Props extends RouteChildrenProps<{customerId?: string, step?: string, project?: string}> {
}

export default function ProjectManagerHomePage(props: Props) {

    const [customer, setCustomer] = useState(null as any);
    const [selectedCustomerType, setSelectedCustomerType] = useState(null as any);

    const addCustomerRef = useRef<any>();

    const onAddCustomer = async (customer: any) => {
        const res = await  request.post(apis.caseCustomer, customer);
        if(res.code === 1) {
            // console.log(res.data);
            customerListRef.current?.add(res.data);
            return true;
        }
    }

    const [simple, setSimple] = useState(false);

    const customerListRef = useRef<any>();
    
    return (
        <div className="project-manager-home-page" style={{ margin: '0 auto'}}>
            <div className="filter bg-white" style={{padding: '16px 10px'}} >
                <div className="flex" style={{justifyContent: 'space-between'}}>
                    <Form layout="inline">
                        <Form.Item label="行业">
                            <Radio.Group value={selectedCustomerType} onChange={(e) => {
                                setSelectedCustomerType(e.target.value)
                                customerListRef.current?.setSelectedCustomerType(e.target.value);
                            }} >
                                <Radio.Button value={null}>所有</Radio.Button>
                                <Radio.Button value={"个人"}>个人</Radio.Button>
                                <Radio.Button value={"别墅"}>别墅</Radio.Button>
                                <Radio.Button value={"企业"}>企业</Radio.Button>
                                <Radio.Button value={"酒店"}>酒店</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {/* <Form.Item label="城市">
                            <Select value={"*"}>
                            <Select.Option value={"*"}>全部城市</Select.Option>
                                <Select.Option value={"个人"}>南昌</Select.Option>
                                <Select.Option value={"别墅"}>九江</Select.Option>
                                <Select.Option value={"酒店"}>上饶</Select.Option>
                                <Select.Option value={"企业"}>郑州</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="状态">
                            <Radio.Group value={"*"}>
                                <Radio.Button value={"*"}>全部项目</Radio.Button>
                                <Radio.Button value={"0"}>创建中/待提交</Radio.Button>
                                <Radio.Button value={"1"}>已提交/待生产</Radio.Button>
                                <Radio.Button value={"2"}>已生产/待安装</Radio.Button>
                            </Radio.Group>
                        </Form.Item> */}
                    </Form>
                    <div>
                        <Button type="primary" ghost onClick={() => {
                            addCustomerRef.current?.open();
                        }}>
                            <PlusOutlined />
                            创建客户
                        </Button>
                        <AddCustomerModal 
                            ref={addCustomerRef}
                            onAddCustomer={onAddCustomer}
                        />
                    </div>
                </div>
            </div>
            <div style={{}} className="flex">
                <CustomerList
                    ref={customerListRef}
                    customerId={props.match.params.customerId ? Number(props.match.params.customerId) : 0}
                    onCustomerChange={(customer) => {
                        setCustomer(customer)
                        if(customer) {
                            let baseUrl = `/project/${customer.id}`;
                            if(props.match.params.step) {
                                baseUrl += `/${props.match.params.step}`;
                            }
                            props.history.replace(baseUrl);
                        }
                    }}
                />
                <ProjectView 
                    {...props}
                    customer={customer}
                    // simple={simple}
                    setSimple={setSimple}
                />
            </div>
        </div>
    )

}

interface AddCustomerProps {
    onAddCustomer: (data: any) => Promise<any>
}
const AddCustomerModal = forwardRef((props: AddCustomerProps, ref: any) => {

    return (
        <ModalForm
            ref={ref}
            title="添加客户"
            maskClosable={false}
            labelCol={{span: 4}}
            onSubmit={props.onAddCustomer}
        >
             <Form.Item label="客户名称" name="name" rules={[{required: true, message: "请输入客户名称"}]}>
                <Input placeholder="请输入客户名称" autoComplete="off" autoFocus/>
            </Form.Item>
            <Form.Item label="客户类型" name="type" rules={[{required: true, message: "请选择客户类型"}]}>
                <Radio.Group >
                    <Radio key={"person"} value={"个人"}>个人</Radio>
                    <Radio key={"villa"} value={"别墅"}>别墅</Radio>
                    <Radio key={"company"} value={"企业"}>企业</Radio>
                    <Radio key={"hotel"} value={"酒店"}>酒店</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="详细地址" name="address" rules={[{required: true, message: "请输入地址"}]}>
                <Input.TextArea placeholder="请输入详细地址"/>
            </Form.Item>
            <Form.Item label="联系人" name="liaisonName">
                <Input placeholder="请输入联系人" autoComplete="off"/>
            </Form.Item>
            <Form.Item label="联系方式" name="liaisonPhone">
                <Input placeholder="请输入联系方式" autoComplete="off"/>
            </Form.Item>
        </ModalForm>
    )
});
