import { PlusOutlined } from "@ant-design/icons";
import { Form, FormInstance, Modal, Input, Select, Divider, Button, message, Radio } from "antd"
import React from "react"
import { useState } from "react";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";
import { GroupInput } from './GroupInput';

interface Props {
    visible: boolean;
    projectCates: any[];
    onProjectCreate: (project: any) => void;
    onProjectCateCreate: (projectCate: any) => void;
    onCancel: () => void;
}

const formRef = React.createRef<FormInstance>();

export const ProjectCreateModal = (props: Props) => {

    const [newCateNameTemp, setNewCateNameTemp] = useState("");

    return (
        <Modal 
            centered 
            title="创建HEX项目" 
            visible={props.visible} 
            onOk={() => {
                formRef.current?.submit();
            }}
            destroyOnClose
            
            onCancel={props.onCancel}
        >
            <Form
                ref={formRef}
                labelCol={{ span: 6 }}
                initialValues={{
                    hexGroups: [],
                    maintainer: "cq"
                }}
                
                onFinish={(data) => {
                    for (let i = 0; i < data.hexGroups.length; i++) {
                        const temp = data.hexGroups[i];
                        if(!temp.name) {
                            message.warn("还有分组没填");
                            return;
                        }
                    }
                    request.post(apis.hexProject, data)
                        .then((res) => {
                            if(res.code === 1) {
                                message.success("创建项目成功");
                                props.onProjectCreate(res.data);
                            }else {
                                message.warn("创建失败");
                            }
                        }).catch((e) => {
                            message.warn("异常");
                        })
                }}
            >
                <Form.Item
                    label="项目名称"
                    name="name"
                    rules={[{ required: true, message: "请输入项目名称" }]}
                >
                    <Input placeholder="请输入项目名称" />
                </Form.Item>
                <Form.Item
                    label="项目描述"
                    name="description"
                >
                    <Input.TextArea placeholder="请输入项目介绍" />
                </Form.Item>
                <Form.Item
                    label="项目分类"
                    name="cateId"
                >
                    <Select
                        style={{ width: 240 }}
                        placeholder="选择分类"
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={newCateNameTemp} onChange={(e) => {
                                        setNewCateNameTemp(e.target.value);
                                        
                                    }} />
                                    <Button type="link" onClick={() => {
                                        const newCate: any = {name: newCateNameTemp};
                                        request.post(apis.hexProjectCate, newCate)
                                            .then((res: any) => {
                                                if(res.code === 1) {
                                                    message.success("创建分类成功");
                                                    newCate.id = res.data.id;
                                                    setNewCateNameTemp(""); //清空文本
                                                    props.onProjectCateCreate(newCate);
                                                }else {
                                                    throw new Error(res.msg);
                                                }
                                            }).catch((e) => {
                                                message.warn("异常");
                                            })
                                    }}>
                                        <PlusOutlined /> 新建分类
                                    </Button>
                                </div>
                            </div>
                        )}
                    >
                        {props.projectCates.length > 0 && props.projectCates.map((cate, index) => (
                            <Select.Option key={"cate-" + index} value={cate.id}>{cate.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="芯片型号"
                    name="chipType"
                >
                    <Radio.Group>
                        <Radio value={"zigbee-TI"} >Zigbee TI CC2530</Radio>
                        <Radio value={"zigbee-Telink"} >Zigbee Telink TLSR825x</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="维护人员"
                    
                    rules={[{ required: true, message: "请输入维护人员姓名" }]}
                    name="maintainer"
                >
                    <Input placeholder="请输入维护人员姓名" />
                </Form.Item>
                <Form.Item
                    label="HEX文件分组"
                    name="hexGroups"

                >
                    <GroupInput />
                </Form.Item>

            </Form>
        </Modal >
    )

}

// const ChipTypeSelector = (props: {
//     value?: string,
//     onChange: (value: string) => void 
// }) => {
//     return (
//     )
// }