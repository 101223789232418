import { useEffect } from "react";
import { Dropdown, Layout, Menu, Popover } from 'antd';
import { Route, RouteChildrenProps, Switch } from "react-router-dom";
import { useState } from "react";
import { PageHome2 as CaseHomePage } from '../#case-manager/Home';
import { HomePage as HexHomePage } from '../#hex-manager/Home';
import { HomePage as ProductHomePage } from '../#product-manager/Home/index2';

import './style.less';
import { LogoutOutlined, ToolFilled } from "@ant-design/icons";
import { Welcome } from "../Welcome";
import MainHeader from "./components/MainHeader";
import ProjectManagerHomePage from "../#project-manager/Home";
import CaseEditorPage from "../#project-manager/CaseEditor";
import UPMSHome from "../#upms/Home";

interface Props extends RouteChildrenProps {

}

export const MainPage = (props: Props) => {

    return (
        <Layout className="app-container">
            <MainHeader {...props}></MainHeader>
            <div className="app-content">
                <Switch>
                    <Route path="/" exact component={Welcome} />
                    <Route path="/project/:customerId/case/:caseId/create" exact component={CaseEditorPage} />
                    <Route path="/project/:customerId?/:step?/:caseId?" exact component={ProjectManagerHomePage} />
                    <Route path="/customer/:customerId?/:case?/:caseId?" component={CaseHomePage} />
                    {/* <Route path="/hex" component={HexHomePage} /> */}
                    <Route path="/product" component={ProductHomePage} />
                    <Route path="/upms" component={UPMSHome}></Route>
                </Switch>
            </div>
        </Layout>
    )
}