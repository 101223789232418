import { Button, Modal, Form, Input, message, Tag } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './index.less';
import { FormInstance } from 'antd/lib/form';

interface Props {
    value?: any[];
    onChange?: (value: any[]) => void;
    deletable?: boolean;
    footer?: any;

}

export const SpecSelect = (props: Props) => {

    const initialSpecValues: { id: number, name: string, values: { id: number, spec: number, name: string }[] }[] = [];
    const [specValues, setSpecValues] = useState(props.value || initialSpecValues);
    const specMap: Map<number, { id: number, name: string, isNew?: boolean, values: { id: number, spec: number, name: string }[] }> = new Map();
    for (let i = 0; i < specValues.length; i++) {
        const sv = specValues[i];
        specMap.set(sv.id, sv);
    }
    const specValuesMap: Map<number, { id: number, spec: number, name: string }> = new Map();
    for (let i = 0; i < specValues.length; i++) {
        const sv = specValues[i];
        for (let j = 0; j < sv.values.length; j++) {
            const value = sv.values[j];
            specValuesMap.set(value.id, value);
        }
    }
    useEffect(() => {
        console.log(props.value, specValues, props.value === specValues);
        
        setSpecValues(props.value);
    }, [props.value]);

    useEffect(() => {
        if (typeof props.onChange === 'function') {
            if(specValues !== props.value) {
                props.onChange(specValues);
            }
        }
    }, [specValues]);

    const [specCreateModalVisible, setSpecCreateModalVisible] = useState(false);
    const openSpecCreateModal = () => {
        setSpecCreateModalVisible(true);
    }
    const closeSpecCreateModal = () => {
        setSpecCreateModalVisible(false);
    }
    const specCreateRef = createRef<FormInstance>();

    const [specValueCreateModalVisible, setSpecValueCreateModalVisible] = useState(false);
    const [specValueCreateSpec, setSpecValueCreateSpec] = useState(0);
    const openSpecValueCreateModal = (specValue: number) => {
        setSpecValueCreateSpec(specValue);
        setSpecValueCreateModalVisible(true);
    }
    const closeSpecValueCreateModal = () => {
        setSpecValueCreateSpec(0);
        setSpecValueCreateModalVisible(false);
    }
    const specValueCreateRef = createRef<FormInstance>();

    const handleChange = (newValue: number[]) => {

    }

    return (
        <>
        <div className="spec-select">

            <div className="spec">
                {
                    specValues.map((sv, index) => {
                        return (
                            <div key={"spec-value-" + index} className="spec-item">
                                <div className='spec-name '>
                                    {sv.name}
                                    {/* {
                                        sv.isNew && (
                                            <Tag color="red">新</Tag>
                                        )
                                    } */}
                                    {
                                        (props.deletable !== false || sv.isNew) && (
                                            <MinusCircleOutlined
                                                onClick={() => {
                                                    const nsv = [];
                                                    for (let i = 0; i < specValues.length; i++) {
                                                        const _sv = specValues[i];
                                                        if (_sv.id !== sv.id) {
                                                            nsv.push(_sv);
                                                        }
                                                    }
                                                    setSpecValues(nsv);
                                                }}
                                                style={{ position: "relative", top: -3, fontSize: 12, left: 3, color: 'gray', cursor: "pointer" }}
                                            />
                                        )
                                    }
                                </div>
                                <div className="spec-values">
                                    <div className="spec-value-item">
                                        {
                                            sv.values.map((item: any) => {
                                                return (
                                                    <span className="spec-value" onClick={() => {
                                                        const nv = [];
                                                        for (let i = 0; i < sv.values.length; i++) {
                                                            const v = sv.values[i];
                                                            if (v.id !== item.id) {
                                                                nv.push(v);
                                                            }
                                                        }
                                                        sv.values = nv;
                                                        setSpecValues([...specValues]);
                                                    }}>
                                                        <span style={{ cursor: 'pointer', display: 'inline-block'}}>
                                                            {item.name}
                                                            {/* {
                                                                item.isNew && (
                                                                    <Tag color="red">新</Tag>
                                                                )
                                                            } */}
                                                        </span>
                                                        {
                                                            (props.deletable !== false || item.isNew) && (
                                                                <MinusCircleOutlined style={{ position: "relative", top: -3, fontSize: 12, left: 3, color: 'gray', cursor: "pointer" }} />
                                                            )
                                                        }
                                                    </span>
                                                )
                                            })
                                        }
                                        <span>
                                            <Button size="small" onClick={() => {
                                                openSpecValueCreateModal(sv.id)
                                            }}>
                                                <PlusOutlined />
                                            添加
                                        </Button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
            </div>
            <div style={{ paddingTop: 8, paddingBottom: 3, paddingLeft: 3 }}>
                <Button disabled={props.deletable === false} size="small" onClick={() => {
                    if (specValues.length === 3) {
                        message.warn("最多添加三种规格");
                        return;
                    }
                    openSpecCreateModal();
                }}>
                    <PlusOutlined />
                    新增规格
                </Button>
            </div>


            <Modal
                centered
                title="新增规格"
                visible={specCreateModalVisible}
                destroyOnClose
                onCancel={closeSpecCreateModal}
                onOk={() => {
                    specCreateRef.current?.submit();
                }}
            >
                <Form
                    ref={specCreateRef}
                    labelCol={{ span: 4 }}
                    onFinish={(data) => {
                        for (let i = 0; i < specValues.length; i++) {
                            const sv = specValues[i];
                            if (sv.name === data.name) {
                                message.warn("已存在该规格");
                                return;
                            }
                        }
                        let max = 1;
                        for (let i = 0; i < specValues.length; i++) {
                            const s = specValues[i];
                            if (s.id > max) {
                                max = s.id;
                            }
                        }
                        const newSpec = {
                            id: max + 1,
                            name: data.name,
                            isNew: true,
                            values: [],
                        }
                        setSpecValues([...specValues, newSpec]);
                        closeSpecCreateModal();
                    }}
                >
                    <Form.Item
                        label="规格名称"
                        name="name"
                        rules={[{ required: true, message: '请输入规格名称' }]}
                    >
                        <Input placeholder="请输入规格名称" autoFocus autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                centered
                title={"新增" + `“${specValueCreateSpec === 0 ? "" : specMap.get(specValueCreateSpec)?.name}”` + "规格值"}
                visible={specValueCreateModalVisible}
                destroyOnClose
                onCancel={closeSpecValueCreateModal}
                onOk={() => {
                    specValueCreateRef.current?.submit();
                }}
            >
                <Form
                    ref={specValueCreateRef}
                    labelCol={{ span: 4 }}
                    onFinish={(data) => {
                        for (let i = 0; i < specValues.length; i++) {
                            const sv = specValues[i];

                            if (sv.id === specValueCreateSpec) {
                                for (let j = 0; j < sv.values.length; j++) {
                                    const v = sv.values[j];
                                    if (v.name === data.name) {
                                        message.warn("已存在该规格值");
                                        return;
                                    }
                                }
                                let max = 1;
                                for (let i = 0; i < sv.values.length; i++) {
                                    const v = sv.values[i];
                                    if (v.id > max) {
                                        max = v.id
                                    }
                                }
                                sv.values.push({
                                    id: max + 1,
                                    name: data.name,
                                    spec: sv.id,
                                    isNew: true,
                                });
                                setSpecValues([...specValues]);
                                closeSpecValueCreateModal();
                            }
                        }
                    }}
                >
                    <Form.Item
                        label="规格值"
                        name="name"
                        rules={[{ required: true, message: '请输入规格值' }]}
                        help={specValueCreateSpec > 0 && "已有：" + (specMap.get(specValueCreateSpec)?.values.map((s) => s.name).join('、') || "无")}
                    >
                        <Input placeholder="请输入规格值" autoFocus autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
        {props.footer}
        </>
    )
}
