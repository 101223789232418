import React, { createRef, useEffect, useState } from 'react'
import { ApiOutlined, CloseOutlined, DeleteOutlined, DeleteRowOutlined, EditOutlined, FormOutlined, MinusOutlined, PlusOutlined, SolutionOutlined } from '@ant-design/icons'

import { CurtainControlNodeCell } from '../widgets/node-widgets/CurtainControlNodeCell'
import { CurtainControlDoubleNodeCell } from '../widgets/node-widgets/CurtainControlDoubleNodeCell'
import { CurtainMotorNodeCell } from '../widgets/node-widgets/CurtainMotorNodeCell'
import { FourButtonSwitchNodeCell } from '../widgets/node-widgets/FourButtonSwitchNodeCell'
import { ThreeButtonSwitchNodeCell } from '../widgets/node-widgets/ThreeButtonSwitchNodeCell'
import { FourButtonSceneAndSpeakerNodeCell } from '../widgets/node-widgets/FourButtonSceneAndSpeakerNodeCell'
import { Plug_inCardPowerNodeCell } from '../widgets/node-widgets/Plug_inCardPowerNodeCell'
import { Plug_inCardPowerNodeCell2 } from '../widgets/node-widgets/Plug_inCardPowerNodeCell2'
import { SceneNodeCell } from '../widgets/node-widgets/SceneNodeCell'
import { SmartControlNodeCell } from '../widgets/node-widgets/SmartControlNodeCell'
import { OneButtonSwitchNodeCell } from '../widgets/node-widgets/OneButtonSwitchNodeCell'
import { TwoButtonSwitchNodeCell } from '../widgets/node-widgets/TwoButtonSwitchNodeCell'
import { ThreeButtonSceneNodeCell } from '../widgets/node-widgets/ThreeButtonSceneNodeCell'
import { IRHumanSensorNodeCell } from '../widgets/node-widgets/IRHumanSensorNodeCell'
import { DimmableDownlightNodeCell } from '../widgets/node-widgets/DimmableDownlightNodeCell'
import { RGBLightStripNodeCell } from '../widgets/node-widgets/RGBLightStripNodeCell'
import { OneTouchSwitchNodeCell } from '../widgets/node-widgets/OneTouchSwitchNodeCell'
import { TwoTouchSwitchNodeCell } from '../widgets/node-widgets/TwoTouchSwitchNodeCell'
import { ThreeTouchSwitchNodeCell } from '../widgets/node-widgets/ThreeTouchSwitchNodeCell'
import { FourTouchSwitchNodeCell } from '../widgets/node-widgets/FourTouchSwitchNodeCell'
import { OfflineSpeakerNodeCell } from '../widgets/node-widgets/OfflineSpeakerNodeCell'
import { Dropdown, FormInstance, Menu, Modal, Popover, Form, Input, message, Drawer, Tabs, Button, Table, Tag } from 'antd'
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';
import { DEVICE_CATES, SELECTABLE_DEVICES } from '../../../../../../utils/constants'

import { FourButtonSwitchNodeCell_v2 } from '../widgets/node-widgets/v2/FourButtonSwitchNodeCell_v2'
import { ThreeButtonSwitchNodeCell_v2 } from '../widgets/node-widgets/v2/ThreeButtonSwitchNodeCell_v2'
import { TwoButtonSwitchNodeCell_v2 } from '../widgets/node-widgets/v2/TwoButtonSwitchNodeCell_v2'
import { OneButtonSwitchNodeCell_v2 } from '../widgets/node-widgets/v2/OneButtonSwitchNodeCell_v2'

import { CaseSceneUpdateModal } from './CaseSceneUpdateModal'
import { SixButtonSwitchNodeCell } from '../widgets/node-widgets/SixButtonSwitchNodeCell'
import { SixButtonSwitch1C3SNodeCell } from '../widgets/node-widgets/SixButtonSwitch1C3SNodeCell'

interface Props {
    case: any;
    mode: "edit" | "view" | "config",
    viewType: "cell" | "table",
    setMode: (mode: "edit" | "view" | "config") => void,
    caseAreas: any[];
    caseNodes: any[];
    caseScenes: any[];
    caseRelations: any[];
    selectedCaseAreaId: number;
    setSelectedCaseAreaId: (areaId: number) => void;
    openCaseAreaCreateModal: () => void;
    onCreateCaseNode: (caseNode: any) => void;
    openMacEditModal: (caseNode: any) => void;
    openNodeNameEditModal: (caseNode: any) => void;
    onRemoveCaseNode: (caseNode: any) => void;

    bindMode: "view" | "targets" | "wait"
    bindCurrentSource: any;
    bindCurrentAccpets: string;
    bindCurrentTargets: any[];

    setBindMode: (bindMode: "view" | "targets" | "wait") => void;
    onSetCurrentSource: (source: any, accepts: string) => void;
    onResetCurrentSource: () => void;
    onAddCurrentTarget: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onRemoveCurrentTarget: (type: "app" | "scene" | "ir-sensor", target: any) => void;

    onUpdateNodeApp: (nodeApp: any) => void;
    openCreateSceneModal: () => void;

    onUpdateCaseArea: (caseArea: any) => void;
    onRemoveCaseArea: (caseArea: any) => void;
    onUpdateCaseScene: (caseScene: number) => void;
    onRemoveCaseScene: (caseSceneId: any) => void;
    onViewTargets: (targets: any[], type?: "app" | "scene" | "ir-sensor") => void;
    viewSource: any;
    onSetViewSource: (source: any) => void;
    setBindCurrentAccepts: (accepts: string) => void;

    onRefresh: () => void;
}

const updateFormRef = createRef<any>();
const deviceCodeRef = createRef<any>();
const scanRef = createRef<any>();

export const CaseAreaDevicesEditor = (props: Props) => {

    const [areaUpdateVisible, setAreaUpdateVisible] = useState(false);
    const [areaNameTemp, setAreaNameTemp] = useState("");
    const [areaIdTemp, setAreaIdTemp] = useState(0);
    const openAreaUpdateModal = (area: any) => {
        setAreaUpdateVisible(true);
        setAreaNameTemp(area.name);
        setAreaIdTemp(area.id);
    }
    const closeAreaUpdateModal = () => {
        setAreaUpdateVisible(false);
        setAreaNameTemp("");
        setAreaIdTemp(0);
    }

    const onUpdateCaseArea = (data: any) => {
        request.put(apis.area + `/${data.id}`, {
            name: data.name,
        })
            .then((res) => {
                if (res.code === 1) {
                    props.onUpdateCaseArea(res.data);
                    closeAreaUpdateModal();
                } else {
                    message.warn(res.msg || res.message);
                }
            })
            .finally(() => {

            })
    }


    const onRemoveCaseArea = (caseArea: any) => {
        Modal.confirm({
            centered: true,
            title: "请确认是否删除" + caseArea.name,
            content: "删除区域将删除区域下的节点, 不可恢复, 请确认是否删除该区域",
            onOk: () => {
                request.delete(apis.area + `/${caseArea.id}`, {})
                    .then((res) => {
                        // console.log();
                        if (res.code === 1) {
                            props.onRemoveCaseArea(res.data);
                        } else {
                            message.warn(res.msg || res.message);
                        }
                    })
            },
            onCancel: () => {

            }
        })
    }

    const [sceneUpdateModalVisible, setSceneUpdateModalVisible] = useState(false);
    const [updateSceneId, setUpdateSceneId] = useState(0);
    const openSceneUpdateModal = (sceneId: number) => {
        setSceneUpdateModalVisible(true);
        setUpdateSceneId(sceneId);
    }
    const closeSceneUpdateModal = () => {
        setSceneUpdateModalVisible(false);
        setUpdateSceneId(0);
    }


    const onViewBindRelations = (app: any, accepts: string) => {
        const relation = props.caseRelations.find((item) => { return item.sourceAppId === app.id });
        props.onViewTargets(relation.target, relation.targetType);
        props.onSetViewSource(app);
        props.setBindMode("view");
        props.setBindCurrentAccepts(accepts)

    }


    const [deviceCodeModal, setDeviceCodeModal] = useState(false);
    const [caseNodeTemp, setCaseNodeTemp] = useState({} as any);
    const openDeviceCodeModal = (caseNode: number) => {
        setDeviceCodeModal(true);
        setCaseNodeTemp(caseNode);
    }
    const closeDeviceCodeModal = () => {
        setDeviceCodeModal(false);
        setCaseNodeTemp({});
    }

    const [caseNodes, setCaseNodes] = useState(props.caseNodes);
    useEffect(() => {
        setCaseNodes(props.caseNodes || [])
    }, [props.caseNodes]);


    const onEnterDeviceCode =(data: any) => {
        console.log(caseNodeTemp);
        
        request.put(apis.caseNode + `/${caseNodeTemp.caseNodeId || 0}`, {
            deviceCode: data.deviceCode,
            caseId: props.case.caseId,
        }).then((res) => {
            if(res.code === 1) {
                const caseNode = caseNodes.find((item) => item.caseNodeId === caseNodeTemp.caseNodeId);
                caseNode.deviceCode = data.deviceCode;
                caseNode.mac = res.data.mac;
                setCaseNodes([...caseNodes]);
                closeDeviceCodeModal();
                const next = caseNodes.find(item => !item.deviceCode);
                if(next || !props.case.caseNet?.caseNetMac) {
                    openDeviceCodeModal(next || {});
                    deviceCodeRef.current?.setFieldsValue({
                        deviceCode: ""
                    });
                    scanRef.current?.focus();
                }else {
                    message.success("已经全部完成关联")
                }
                
            }else {
                if(res.msg.indexOf("该码为网关") > -1) {
                    message.success(res.data)
                    deviceCodeRef.current?.setFieldsValue({
                        deviceCode: ""
                    });
                    const caseNode = caseNodes.find((item) => item.caseNodeId === caseNodeTemp.caseNodeId);
                    setCaseNodes([...caseNodes]);
                    closeDeviceCodeModal();
                    const next = caseNodes.find(item => !item.deviceCode);
                    if(next ) {
                        openDeviceCodeModal(next || {});
                        deviceCodeRef.current?.setFieldsValue({
                            deviceCode: ""
                        });
                        scanRef.current?.focus();
                    }else {
                        message.success("已经全部完成关联")
                    }
                }else { 
                    message.warn(res.msg || res.message);
                    deviceCodeRef.current?.setFieldsValue({
                        deviceCode: ""
                    });
                }
            }
        }).catch((e) => {
            console.log(e);
            alert(JSON.stringify(e))
            message.warn("请求失败");
        })
    }


    useEffect(() => {
        if(props.mode === "edit") {
            if(props.selectedCaseAreaId === 0) {
                if(props.caseAreas.length > 0) {
                    props.setSelectedCaseAreaId(props.caseAreas[0].id)
                }else {
                    props.setSelectedCaseAreaId(-1);
                }
            }
        }
    }, [props.caseAreas, props.mode]);

    useEffect(() => {
        if(props.caseAreas.length > 0) {
            const target = props.caseAreas.find((item) => item.id === props.selectedCaseAreaId);
            if(!target) {
                if(props.mode === 'view') {
                    props.setSelectedCaseAreaId(0); 
                }else {
                    props.setSelectedCaseAreaId(props.caseAreas[0].id);
                }
            }
        }
    }, [props.caseAreas])

    return (
        <div className="area-devices">
            {/* <Modal
                centered
                title='修改区域名称'
                visible={areaUpdateVisible}
                onOk={() => {
                    updateFormRef.current?.submit();
                }}
                onCancel={closeAreaUpdateModal}
                width={300}
                destroyOnClose
            >
                <Form
                    ref={updateFormRef}
                    initialValues={{
                        id: areaIdTemp,
                        name: areaNameTemp
                    }}
                    onFinish={(data) => {
                        onUpdateCaseArea(data)
                    }}
                >
                    <Form.Item hidden name="id"></Form.Item>
                    <Form.Item label="区域名称" name={"name"} rules={[{ required: true, message: "请输入区域名称" }]}>
                        <Input placeholder="请输入区域名称" autoFocus autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal> */}
            <Modal
                centered
                title={(
                    <span>
                        <Tag color={"blue"}>{caseNodeTemp.caseAreaName}</Tag>
                        {caseNodeTemp.name}
                        <div style={{color: "gray", fontSize: 12}}> {caseNodeTemp.apps?.filter((a: any) => a.endpoint >= 8).map((a: any) => a.name).join('、')}</div>
                    </span>
                )}
                visible={deviceCodeModal}
                onOk={() => {
                    deviceCodeRef.current?.submit();
                }}
                onCancel={closeDeviceCodeModal}
                width={400}
            >

                <Form
                    ref={deviceCodeRef}
                    onFinish={onEnterDeviceCode}
                >
                    <Form.Item hidden name="id"></Form.Item>
                    <Form.Item label="设备码" name={"deviceCode"} rules={[{ required: true, message: "请输入设备码" }, { len: 10 }]}>
                        <Input ref={scanRef} placeholder="请输入设备码" autoFocus autoComplete="off" />
                    </Form.Item>
                    <Form.Item>
                        <span style={{color: "yellow", marginLeft: "5em"}}>如果有两个二维码的，两个二维码都要扫</span>
                    </Form.Item>
                </Form>
            </Modal>
            <Drawer
                // title={"产品列表"}
                closable={false}
                mask={false}
                visible={props.mode === "edit"}
                placement="top"
                onClose={() => {
                    props.setMode("view")
                }}
                drawerStyle={{height: 165}}
            >
                <Tabs type="card" tabBarExtraContent={{
                    left: (
                        <div style={{padding: '0 8px 0 8px', color: "#444", fontSize: 12, position: 'relative'}}>添加产品</div>
                    ),
                    right: (
                        <Button style={{ border: 0, borderRadius: 0, background: "orange" }} type="primary" onClick={() => {
                            props.setMode("view")
                        }}>
                            <EditOutlined />
                            退出编辑模式
                        </Button>
                    )}}>
                    {
                        DEVICE_CATES.map((item, index) => {
                            return (
                                <Tabs.TabPane tab={item.name} key={"tab-" + index}>
                                    <div className="prod-bar">
                                        {
                                            item.devices.map((item) => {
                                                return (
                                                    <div className="prod-item" onClick={() => {
                                                        const data = {
                                                            caseId: props.case.caseId,
                                                            caseAreaId: props.selectedCaseAreaId,
                                                            ...item
                                                        }
                                                        props.onCreateCaseNode(data);
                                                    }}>
                                                        <div className="img-wrapper">
                                                            <img src={item.imageUrl} style={{width: 70, height: 70}} alt="" />
                                                        </div>
                                                        <span>{item.name}</span>
                                                        
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tabs.TabPane>
                            )
                        })
                    }

                </Tabs>

            </Drawer>
            
            <CaseSceneUpdateModal
                sceneId={updateSceneId}
                visible={sceneUpdateModalVisible}
                onCancel={closeSceneUpdateModal}
                caseId={props.case.caseId}
                nodes={props.caseNodes}
                onSceneUpdate={props.onUpdateCaseScene}
            />
            <div className="area-list flex column" style={{}}>
                {
                    props.caseAreas.length === 0 && (
                        <div className="first-area-tip" style={{ background: 'rgba(0, 0, 0, .8)', fontSize: 16 }}>

                            点击<a href="javascript:void(0)" onClick={props.openCaseAreaCreateModal}>创建第一个区域</a>
                        </div>
                    )
                }
                {
                    props.mode !== 'edit' && (
                        <div 
                            className={"area-item" + (props.selectedCaseAreaId === 0 ? ' active' : '')}
                            style={{color: props.selectedCaseAreaId === 0 ? '#fff' : undefined}} onClick={() => {
                            props.setSelectedCaseAreaId(0);
                        }}>
                            全部
                        </div>
                    )
                }
                {
                    props.caseAreas.length > 0 && props.caseAreas.map((item) => {
                        return (
                            // <Dropdown trigger={["contextMenu"]} overlay={(
                            //     <Menu>
                            //         <Menu.Item onClick={() => {
                            //             openAreaUpdateModal(item)
                            //         }}>
                            //             <EditOutlined />
                            //             修改名称
                            //         </Menu.Item>
                            //         <Menu.Item
                            //             danger
                            //             disabled={props.caseAreas?.length < 2}
                            //             title={props.caseAreas?.length < 2 ? "至少保留一个区域" : undefined}
                            //             onClick={() => {
                            //                 onRemoveCaseArea(item);
                            //             }}
                            //         >
                            //             <DeleteOutlined />
                            //             删除区域
                            //         </Menu.Item>
                            //     </Menu>
                            // )}>
                                <div
                                    className={"area-item" + (props.selectedCaseAreaId === item.id ? ' active' : '')}
                                    style={{color: props.selectedCaseAreaId === item.id  ? '#fff' : undefined}}
                                    onClick={() => {
                                        props.setSelectedCaseAreaId(item.id);
                                    }}
                                >{item.name}</div>
                            // </Dropdown>
                        )
                    })
                }
                {
                    props.case?.caseParentTemplateId === 0 && (
                        <div className="area-item" onClick={props.openCaseAreaCreateModal}>
                            <PlusOutlined />
                            添加区域
                        </div>
                    )
                }
            </div>
            <div className="diagram">
                {/* <div className="prod-bar" style={{ padding: '0 10px' }}>
                    {
                        SELECTABLE_DEVICES.map((item) => {
                            return (
                                <div className="prod-item" onClick={() => {
                                    const data = {
                                        caseId: props.case.caseId,
                                        caseAreaId: props.selectedCaseAreaId,
                                        ...item
                                    }
                                    props.onCreateCaseNode(data);
                                }}>
                                    <PlusOutlined />
                                    添加{item.name}
                                </div>
                            )
                        })
                    }
                </div> */}
                <div className="prod-list">
                {
                    props.viewType === "table" ? (
                        <Table 
                            style={{margin: "5px 10px 0 10px"}}
                            // size="small"
                            pagination={false}
                            dataSource={caseNodes.filter((item) => { return props.selectedCaseAreaId === 0 ? true : item.caseAreaId === props.selectedCaseAreaId})}
                            columns={[{
                                title: "#",
                                key: "#",
                                width: 30,
                                render: (_, __, index) => {
                                    return (index + 1)
                                }                                
                            }, {
                                title: "节点名",
                                key: "name",
                                dataIndex: "name",
                                render: (name, node) => {
                                    return (
                                        <div>
                                            <div>
                                            <Tag color="blue">{node.caseAreaName}</Tag> <span>{node.name}</span>
                                            </div>
                                            <div style={{fontSize: 12, color: "gray", marginTop: 5}}>
                                                {node.apps.filter((a: any) => a.endpoint >= 8).map((a: any) => a.name).join('、')}
                                            </div>
                                        </div>
                                    )
                                }
                            }, {
                                title: "短地址",
                                align: 'center',
                                width: 80,
                                key: "restShortAddr",
                                dataIndex: "restShortAddr"
                            }, {
                                title: "Node ID",
                                width: 80,
                                align: 'center',
                                key: "restNodeId",
                                dataIndex: "restNodeId"
                            }, {
                                title: "MAC地址",
                                key: "mac",
                                dataIndex: "mac",
                                align: 'center',
                                width: 170,
                                render: (mac: string, record) => {
                                    return mac ? (
                                        <>
                                            <div>{mac}</div>
                                            <div>{parseInt("0x" + mac.split(' ').join(''), 16)}</div>
                                            
                                        </>
                                    ) : (
                                        <Button type="link" onClick={() => {
                                            props.openMacEditModal(record);
                                        }}>编辑</Button>
                                    )
                                }
                            }, {
                                title: "设备码",
                                key: "deviceCode",
                                dataIndex: "deviceCode",
                                align: 'center',
                                width: 80,
                                render: (deviceCode, record) => {
                                    return <>
                                    <div>{deviceCode.replace('HFD', '')}</div>
                                        <Button type="link" onClick={() => {
                                            openDeviceCodeModal(record)
                                        }}>编辑</Button>
                                    </>
                                }
                            }, {
                                title: "操作",
                                key: "action",
                                width: 80,
                                align: 'center',
                                render: () => {
                                    return (
                                        <Button type="ghost" danger>删除</Button>
                                    )
                                }
                            }
                            ]}
                        />
                    ) :  caseNodes.length > 0 && caseNodes.map((item, index) => {
                        if(props.selectedCaseAreaId !== 0) {
                            if (item.caseAreaId !== props.selectedCaseAreaId) {
                                return;
                            }
                        }
                        return (
                            // <Dropdown key={"-" + index} trigger={["contextMenu", "click"]} overlay={(
                            //     <Menu>
                            //         <Menu.Item key="delete" icon={(<SolutionOutlined />)} onClick={() => {
                            //             // props.openMacEditModal(item);
                            //         }}>
                                        
                            //             查询信息
                            //         </Menu.Item>
                            //         <Menu.Item key="delete" icon={(<FormOutlined />)} style={{ marginRight: 5 }} onClick={() => {
                            //             props.openNodeNameEditModal(item)
                            //         }}>
                                        
                            //             重命名
                            //         </Menu.Item>
                            //         <Menu.Item key="delete" icon={(<DeleteOutlined />)} style={{ marginRight: 5 }} danger onClick={() => {
                            //             props.onRemoveCaseNode(item.caseNodeId);
                            //         }}>
                            //             删除产品
                            //         </Menu.Item>
                            //     </Menu>
                            // )}>
                                <div className="prod-node-cell" style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'top' }}>
                                    {
                                        (() => {
                                            let NodeCell: any = null;
                                            switch (item.typeKey) {
                                                case "plug-in_card_power":
                                                    NodeCell = Plug_inCardPowerNodeCell;
                                                    break;
                                                case "plug-in_card_power_v2":
                                                    NodeCell = Plug_inCardPowerNodeCell2;
                                                    break;
                                                case "general_ir_human_sensor":
                                                case "sub_ir_human_sensor":
                                                    NodeCell = IRHumanSensorNodeCell;
                                                    break;
                                                case "scene_and_speaker":
                                                    NodeCell = FourButtonSceneAndSpeakerNodeCell;
                                                    break;
                                                case "curtain_control":
                                                case "curtain_control_battery":
                                                    NodeCell = CurtainControlNodeCell;
                                                    break;
                                                case "curtain_control_double":
                                                    NodeCell = CurtainControlDoubleNodeCell;
                                                    break;
                                                case "dimmer_switch":
                                                case "curtain_motor":
                                                    NodeCell = CurtainMotorNodeCell;
                                                    break;
                                                case "smart_control":
                                                case "smart_socket":
                                                case "smart_socket_ac":
                                                case "smart_socket_5hole":
                                                case "universal_ir_remote_control":
                                                    NodeCell = SmartControlNodeCell;
                                                    break;
                                                case "dimmable_downlight":
                                                case "color_temp_dimmable_downlight":
                                                    NodeCell = DimmableDownlightNodeCell;
                                                    break;
                                                case "rgb_light_strip":
                                                    NodeCell = RGBLightStripNodeCell;
                                                    break;
                                                case "three_button_scene":
                                                    NodeCell = ThreeButtonSceneNodeCell;
                                                    break;
                                                case "four_button_switch":
                                                    NodeCell = FourButtonSwitchNodeCell;
                                                    break;
                                                case "three_button_switch":
                                                    NodeCell = ThreeButtonSwitchNodeCell;
                                                    break;
                                                case "two_button_switch":
                                                    NodeCell = TwoButtonSwitchNodeCell;
                                                    break;
                                                case "one_button_switch":
                                                    NodeCell = OneButtonSwitchNodeCell;
                                                    break;
                                                case "four_touch_switch":
                                                    NodeCell = FourTouchSwitchNodeCell;
                                                    break;
                                                case "three_touch_switch":
                                                    NodeCell = ThreeTouchSwitchNodeCell;
                                                    break;
                                                case "two_touch_switch":
                                                    NodeCell = TwoTouchSwitchNodeCell;
                                                    break;
                                                case "single_knob_switch":
                                                case "double_knob_switch":
                                                case "one_touch_switch":
                                                    NodeCell = OneTouchSwitchNodeCell;
                                                    break;
                                                case "offline_speaker":
                                                    NodeCell = OfflineSpeakerNodeCell;
                                                    break;
                                                case "four_button_switch_4_4":
                                                case "four_button_switch_4_3":
                                                case "four_button_switch_4_2":
                                                case "four_button_switch_4_1":
                                                case "four_button_switch_4_0":
                                                    NodeCell = FourButtonSwitchNodeCell_v2;
                                                    break;
                                                case "three_button_switch_3_3":
                                                case "three_button_switch_3_2":
                                                case "three_button_switch_3_1":
                                                case "three_button_switch_3_0":
                                                    NodeCell = ThreeButtonSwitchNodeCell_v2;
                                                    break;
                                                case "two_button_switch_2_2":
                                                case "two_button_switch_2_1":
                                                case "two_button_switch_2_0":
                                                    NodeCell = TwoButtonSwitchNodeCell_v2;
                                                    break;
                                                case "one_button_switch_1_1":
                                                case "one_button_switch_1_0":
                                                    NodeCell = OneButtonSwitchNodeCell_v2;
                                                    break;
                                                case "six_button_switch_6_0":
                                                case "six_button_switch_6_1":
                                                case "six_button_switch_6_2":
                                                case "six_button_switch_6_3":
                                                case "six_button_switch_6_4":
                                                case "six_button_switch":
                                                    NodeCell = SixButtonSwitchNodeCell;
                                                    break;
                                                case "six_button_switch_1c3s":
                                                    NodeCell = SixButtonSwitch1C3SNodeCell;
                                                    break;
                                                default:
                                                    return (
                                                        <div>
                                                            -- {item.name} {item.typeKey} --
                                                        </div>
                                                    )
                                            }
                                            return (
                                                <NodeCell
                                                    node={item}
                                                    bindMode={props.bindMode}
                                                    bindingSource={props.bindCurrentSource}
                                                    bindingAccepts={props.bindCurrentAccpets}
                                                    bindingTargets={props.bindCurrentTargets}
                                                    viewSource={props.viewSource}
                                                    onSetBindingSource={(app: any, accepts: string) => {
                                                        props.setBindMode("targets");
                                                        props.onSetCurrentSource(app, accepts);
                                                    }}
                                                    onSetBindingTarget={(app: any) => {
                                                        props.onAddCurrentTarget("app", app);
                                                    }}
                                                    onResetBindingSource={() => {
                                                        props.onResetCurrentSource();
                                                    }}
                                                    onAddBindingTarget={(type: "app" | "scene" | "ir-sensor", target: any) => {
                                                        props.onAddCurrentTarget(type, target);
                                                        }}
                                                    onRemoveBindingTarget={(type: "app" | "scene" | "ir-sensor", target: any) => {
                                                        props.onRemoveCurrentTarget(type, target);
                                                    }}
                                                    onNodeAppUpdate={props.onUpdateNodeApp}
                                                    onRenameApp={props.onUpdateNodeApp}
                                                    onViewBindRelations={onViewBindRelations}
                                                    onCancelView={() => {
                                                        props.setBindMode("wait");
                                                        props.onViewTargets([]);
                                                    }}
                                                />
                                            )
                                        })()
                                    }
                                </div>
                            // </Dropdown>
                        )
                    })
                }
                    {
                       
                    }

                    <div style={{ height: 100, paddingTop: 20, textAlign: 'center' }}>
                        到底了~
                    </div>
                </div>
            </div>

            <div className="scene-edit">

                <div className="scene-list" style={{paddingBottom: 80}}>
                    
                    <div className="add-scene" style={{backgroundColor: "rgba(0, 0, 0, .03)"}} onClick={props.openCreateSceneModal}>
                        <div className="flex-center" style={{ height: 100, color: "#333" }}>
                            <PlusOutlined style={{  }} />
                            添加场景
                        </div>
                    </div>
                    {
                        props.caseScenes.length > 0 && props.caseScenes.map((caseScene, index) => {
                            return (
                                <SceneNodeCell
                                    scene={caseScene}
                                    bindMode={props.bindMode}
                                    bindingAccepts={props.bindCurrentAccpets}
                                    bindingSource={props.bindCurrentSource}
                                    bindingTargets={props.bindCurrentTargets}
                                    onAddBindingTarget={(type: "app" | "scene" | "ir-sensor", target: any) => {
                                        props.onAddCurrentTarget(type, target);
                                    }}
                                    onRemoveBindingTarget={(type: "app" | "scene" | "ir-sensor", target: any) => {
                                        props.onRemoveCurrentTarget(type, target);
                                    }}
                                    onSceneRemove={props.onRemoveCaseScene}
                                    onUpdateScene={() => {
                                        openSceneUpdateModal(caseScene.caseSceneId);
                                    }}

                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}