import { Spin, Tag, message } from 'antd';
import { useEffect, useState } from 'react';
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';
import { OfflineSpeakConfigOptions } from '../widgets/OfflineSpeakConfigOptions';

interface Props {
    caseNodes: any[];
    caseScenes: any[];
    offlineSpeakers: any[];
}

export const CaseOfflineSpeakersConfig = (props: Props) => {

    const [curOs, setCurOs] = useState<any>({});
    const [curConfig, setCurConfig] = useState<any>({});
    const [targets, setTargets] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if ("id" in curOs) {
            setLoading(true)
            request.get(apis.caseOfflineSpeakerConfig, {
                appId: curOs.id
            })
                .then((res) => {
                    if (res.code === 1) {
                        const _curConfig: any = {};
                        const configs = res.data;
                        for (let i = 0; i < configs.length; i++) {
                            const config = configs[i];
                            if (config.type === "scene") {
                                _curConfig[`${config.keyword}-${config.type}-${config.caseTargetSceneId}`] = true;
                            } else {
                                _curConfig[`${config.keyword}-${config.type}-${config.caseTargetAppId}`] = true;
                            }
                        }
                        setCurConfig(_curConfig);
                    } else {
                        setCurConfig({});
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            setCurConfig({});
        }
    }, [curOs]);

    useEffect(() => {
        let targets: any[] = [];
        let sameAreaCount = 0;
        for (let i = 0; i < props.caseNodes.length; i++) {
            const node = props.caseNodes[i];
            for (let j = 0; j < node.apps.length; j++) {
                const app = node.apps[j];
                if(app.isWireless === 1) {
                    continue ;
                }
                if (app.type?.indexOf("target") > -1) {
                    const target = {
                        type: "app",
                        target: {
                            caseNodeId: node.caseNodeId,
                            nodeName: node.name,
                            caseAreaId: node.caseAreaId,
                            caseAreaName: node.caseAreaName,
                            endpoint: app.endpoint,
                            appName: app.name,
                            appType: app.type,
                            appId: app.id,
                            isWireless: app.isWireless
                        }
                    }
                    targets.push(target);
                    if (curOs.caseAreaId === app.caseAreaId) {
                        const temp = targets[sameAreaCount];
                        targets[sameAreaCount] = target;
                        targets[targets.length - 1] = temp;
                        sameAreaCount++;

                    }
                }
            }
        }
        for (let i = 0; i < props.caseScenes.length; i++) {
            const scene = props.caseScenes[i];
            const target = {
                type: "scene",
                target: {
                    caseSceneId: scene.caseSceneId,
                    caseSceneName: scene.caseSceneName,
                    caseAreaId: scene.caseAreaId,
                    caseAreaName: scene.caseAreaName
                }
            }
            targets.push(target);
            if (curOs.caseAreaId === scene.caseAreaId) {
                const temp = targets[sameAreaCount];
                targets[sameAreaCount] = target;
                targets[targets.length - 1] = temp;
                sameAreaCount++;
            }
        }
        setTargets(targets)
    }, [props.caseNodes, props.caseScenes, curOs]);

    useEffect(() => {
        const curOfflineSpeaker = props.offlineSpeakers.find((item) => item.id === curOs.id);
        if (curOfflineSpeaker) {
            setCurOs(curOfflineSpeaker);
        } else {
            if (props.offlineSpeakers.length > 0) {
                setCurOs(props.offlineSpeakers[0])
            }else {
                setCurOs({})
            }
        }
    }, [props.offlineSpeakers]);
    


    const onConfigOfflineSpeaker = (keyword: string, target: any) => {
        const data = { appId: curOs.id, keyword, target };
        request.post(apis.caseOfflineSpeakerConfig, data)
            .then((res) => {
                if (res.code === 1) {
                    const config = res.data;
                    if (config.type === "scene") {
                        for (const key in curConfig) {
                            if (key.indexOf(config.keyword) > -1) {
                                curConfig[key] = false;
                            }
                        }
                        curConfig[`${config.keyword}-${config.type}-${config.caseTargetSceneId}`] = true;
                        setCurConfig({ ...curConfig });
                    } else {
                        const sceneKey = Object.keys(curConfig).find((key) => key.indexOf(config.keyword) > -1 && key.indexOf("scene") > -1);
                        if (sceneKey) {
                            curConfig[sceneKey] = false;
                        }
                        curConfig[`${config.keyword}-${config.type}-${config.caseTargetAppId}`] = true;
                        setCurConfig({ ...curConfig });
                    }
                } else {
                    message.warn(res.msg || res.messgae)
                }
            })

    }

    const onRemoveOfflineSpeakerConfig = (keyword: string, target: any) => {
        const data = { appId: curOs.id, keyword, target };
        request.delete(apis.caseOfflineSpeakerConfig + `?appId=${curOs.id}`, data)
            .then((res) => {
                if (res.code === 1) {
                    /**
                     * caseTargetAppId: 139
                    *  caseTargetSceneId: 0
                        keyword: "灯"
                        offlineSpeakerId: 4
                        type: "app"
                     */
                    const config = res.data;
                    if (config.type === "app") {
                        curConfig[`${config.keyword}-${config.type}-${config.caseTargetAppId}`] = false;
                    } else if (config.type === "scene") {
                        curConfig[`${config.keyword}-${config.type}-${config.caseTargetSceneId}`] = false;
                    }
                    setCurConfig({ ...curConfig });
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }


    return (
        <div style={{ display: 'flex' }}>
            <div className="offline-speaker-list" style={{ minWidth: 200 }}>
                {
                    props.offlineSpeakers.length > 0 && props.offlineSpeakers.map((offlineSpeaker, index) => {
                        return (
                            <div
                                className={"offline-speaker-item " + (curOs.id === offlineSpeaker.id ? "active" : "")}
                                onClick={() => {
                                    setCurOs(offlineSpeaker);
                                }}
                            >
                                <Tag color="orange">{offlineSpeaker.caseAreaName}</Tag>
                                <span>{offlineSpeaker.caseNodeName} - {offlineSpeaker.name}</span>
                            </div>
                        )
                    })
                }
            </div>
            {
                ("id" in curOs) && (
                    <Spin spinning={loading} style={{width: "100%"}}>
                        <div className="content" style={{ flex: 1 }}>
                            <OfflineSpeakConfigOptions
                                keyword="灯"
                                title="开灯/关灯（免唤醒）"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="廊灯"
                                title="打开廊灯/关闭廊灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="顶灯/主灯"
                                title="打开顶灯/关闭顶灯;打开主灯/关闭主灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="壁灯"
                                title="打开壁灯/关闭壁灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="射灯"
                                title="打开射灯/关闭射灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="筒灯"
                                title="打开筒灯/关闭筒灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="吊灯"
                                title="打开吊灯/关闭吊灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="左阅读灯/左床头灯"
                                title="打开左阅读灯/关闭左阅读灯; 打开左床头灯/关闭左床头灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="右阅读灯/右床头灯"
                                title="打开右阅读灯/关闭右阅读灯; 打开右床头灯/关闭右床头灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="阅读灯/床头灯"
                                title="打开阅读灯/关闭阅读灯; 打开床头灯/关闭床头灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="沙发灯"
                                title="打开沙发灯/关闭沙发灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="灯带"
                                title="打开灯带/关闭灯带"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="背景灯"
                                title="打开背景灯/关闭背景灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="阳台灯"
                                title="打开阳台灯/关闭阳台灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="镜灯"
                                title="打开镜灯/关闭镜灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="卫浴灯/浴灯/卫生间灯"
                                title="打开卫浴灯/关闭卫浴灯;打开浴灯/关闭浴灯;打开卫生间灯/关闭卫生间灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="排气扇"
                                title="打开排气扇/关闭排气扇"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="客厅灯"
                                title="打开客厅灯/关闭客厅灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="房间灯"
                                title="打开房间灯/关闭房间灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                             <OfflineSpeakConfigOptions
                                keyword="卧室灯"
                                title="打开卧室灯/关闭卧室灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                             <OfflineSpeakConfigOptions
                                keyword="窗帘"
                                title="打开窗帘/关闭窗帘"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                               keyword="窗纱"
                               title="打开窗纱/关闭窗纱"
                               targets={targets}
                               onSelected={onConfigOfflineSpeaker}
                               onRemove={onRemoveOfflineSpeakerConfig}
                               configs={curConfig}
                           />
                           <OfflineSpeakConfigOptions
                              keyword="幕布"
                              title="打开幕布/关闭幕布"
                              targets={targets}
                              onSelected={onConfigOfflineSpeaker}
                              onRemove={onRemoveOfflineSpeakerConfig}
                              configs={curConfig}
                          />
                          <OfflineSpeakConfigOptions
                             keyword="风扇"
                             title="打开风扇/关闭风扇"
                             targets={targets}
                             onSelected={onConfigOfflineSpeaker}
                             onRemove={onRemoveOfflineSpeakerConfig}
                             configs={curConfig}
                         />
                         <OfflineSpeakConfigOptions
                            keyword="柜灯"
                            title="打开柜灯/关闭柜灯"
                            targets={targets}
                            onSelected={onConfigOfflineSpeaker}
                            onRemove={onRemoveOfflineSpeakerConfig}
                            configs={curConfig}
                        />
                            <OfflineSpeakConfigOptions
                                keyword="万能红外"
                                title="打开空调/关闭空调;打开电视/关闭电视;"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="睡眠模式"
                                title="睡眠模式(免唤醒词)"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig} 
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="起床模式"
                                title="起床模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="观影模式"
                                title="观影模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="离开模式"
                                title="离开模式(免唤醒词)"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="会客模式"
                                title="会客模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="用餐模式"
                                title="用餐模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="回家模式"
                                title="回家模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="浪漫模式"
                                title="浪漫模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="温馨模式"
                                title="温馨模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="阅读模式"  
                                title="阅读模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="上班模式"
                                title="上班模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="下班模式"
                                title="下班模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="娱乐模式"
                                title="娱乐模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="会议模式"
                                title="会议模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="K歌模式"
                                title="K歌模式"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                            <OfflineSpeakConfigOptions
                                keyword="休闲灯"
                                title="休闲灯"
                                targets={targets}
                                onSelected={onConfigOfflineSpeaker}
                                onRemove={onRemoveOfflineSpeakerConfig}
                                configs={curConfig}
                            />
                        </div>
                    </Spin>
                )
            }
        </div>
    )

}