import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Modal, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { apis } from '../../../../../../../utils/apis';
import { request } from '../../../../../../../utils/request';
import { TargetConnector } from '../NodeCellConnector'

interface Props {
    scene: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;

    bindingAccepts: string;
    bindingTargets: any[];
    onSetBindingSource?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onUpdateScene: (scene: any) => void;
    onSceneRemove: (sceneId: number) => void;
}

export const SceneNodeCell = (props: Props) => {

    const [targetDisabled, setTargetDisabled] = useState(false);
    const [targetSelected, setTargetSelected] = useState(false);

    useEffect(() => {
        if (props.bindMode === "wait") {
            setTargetSelected(false);
            setTargetDisabled(true);
        } else if (props.bindMode === "targets" || props.bindMode === "view") {
            const scene = props.scene;
            let expectKey = "scene";
            if (!(props.bindingAccepts.indexOf("not") > -1)) {
                expectKey = props.bindingAccepts;
            }
            if (expectKey !== "scene") {
                setTargetDisabled(true);
                return;
            }
            if (props.bindingTargets instanceof Array && props.bindingTargets.length > 0) {
                if (props.bindingTargets[0].type === "app") {
                    setTargetDisabled(true);
                } else {
                    setTargetDisabled(true);
                    if (props.bindingTargets[0].target.caseSceneId === scene.caseSceneId) {
                        setTargetSelected(true);
                        setTargetDisabled(false);
                    } else {
                        setTargetSelected(false);
                    }
                }
            } else {
                setTargetDisabled(false);
                setTargetSelected(false);
            }
        }
    }, [props.scene, props.bindMode, props.bindingTargets]);

    const onRemoveScene = () => {
        Modal.confirm({
            title: "删除场景",
            centered: true,
            content: "删除场景会删除与场景相关的内容, 不可恢复,请确认是否删除",
            width: 300,
            onOk: () => {
                request.delete(apis.caseScene + `/${props.scene.caseSceneId}`, {})
                    .then((res) => {
                        if (res.code === 1) {
                            message.info("已删除")
                            props.onSceneRemove(props.scene.caseSceneId);
                        } else {
                            message.warn(res.msg || res.message);
                        }
                    })
            }
        })
    }
    return (
        <Dropdown trigger={["contextMenu"]} overlay={(
            <Menu>
                <Menu.Item onClick={() => {
                    props.onUpdateScene(props.scene)
                }}>
                    <EditOutlined />
                    修改场景
                </Menu.Item>
                <Menu.Item key="delete" danger onClick={onRemoveScene}>
                    <DeleteOutlined />
                    删除场景
                </Menu.Item>
            </Menu>
        )}>
            <div className="scene-item" onClick={(e) => {
                props.onUpdateScene(props.scene);
            }}>
                <div className="flex-center" style={{ height: 110, position: 'relative', flexDirection: 'column' }}>
                    {
                        props.scene.caseAreaName ? (
                            <Tag color="blue" style={{ position: 'absolute', top: 3, left: 3 }}>{props.scene.caseAreaName}</Tag>
                        ) : (
                            <Tag color="blue" style={{ position: 'absolute', top: 3, left: 3 }}>多区域</Tag>
                        )
                    }
                    <div className="scene-name flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
                        {
                            !targetDisabled && (
                                <TargetConnector
                                    disabled={targetDisabled}
                                    selected={targetSelected}
                                    onClick={(_, selected) => {
                                        if (props.bindMode === "targets" || props.bindMode === "view") {
                                            if (!selected) {
                                                if (typeof props.onAddBindingTarget === 'function') {
                                                    props.onAddBindingTarget("scene", props.scene)
                                                }
                                            } else {
                                                if (typeof props.onRemoveBindingTarget === 'function') {
                                                    console.log("remove");

                                                    props.onRemoveBindingTarget("scene", props.scene);
                                                }
                                            }
                                        }
                                    }}
                                />

                            )
                        }
                        <span>{props.scene.caseSceneName}</span>
                    </div>

                    {
                        Boolean(props.scene.restGroupId) && (
                            <div style={{ height: 20, textAlign: 'center' }} className="small-font">

                                {
                                    props.scene.restGroupId && (
                                        <span>Group ID: {props.scene.restGroupId} </span>
                                    )
                                }
                                {
                                    props.scene.restSceneId && (
                                        <span>Scene ID: {props.scene.restSceneId}</span>
                                    )
                                }
                            </div>

                        )
                    }
                </div>
            </div>
        </Dropdown>
    )

}