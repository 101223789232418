import { CloudFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { SourceConnector, TargetConnector } from '../NodeCellConnector'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    viewSource: any;
    bindingSource: any;
    bindingAccepts: string;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const Plug_inCardPowerNodeCell = (props: Props) => {

    const { node } = props;
    const [sourceDisabled, setSourceDisabled] = useState(false);
    const [sourceSelected, setSourceSelected] = useState(false);

    useEffect(() => {
        const app = node.apps[0];
        if(props.bindMode === "wait") {
            setSourceSelected(false);
            setSourceDisabled(false);
        }else if(props.bindMode === "targets" || props.bindMode === "view") {
            if(app.id !== (props.bindMode === "targets" ? props.bindingSource : props.viewSource)?.id) {
                setSourceDisabled(true);
            }else {
                setSourceDisabled(false);
                setSourceSelected(true);
            }
        }
    }, [props.bindingSource, props.node, props.bindMode]);



    return (
        <div className="node-cell plug_in-card-power-node-cell">
            <div className="only-connector">
                <SourceConnector
                    selected={sourceSelected}
                    disabled={sourceDisabled}
                    connected={node.apps[0].isSource}
                    onClick={() => {
                        if (node.apps[0].isSource) {
                            if (props.bindMode === "view") {
                                if (typeof props.onCancelView === 'function') {
                                    props.onCancelView();
                                }
                            } else if (props.bindMode === "wait") {
                                if (typeof props.onViewBindRelations === 'function') {
                                    props.onViewBindRelations(node.apps[0], "not general_ir_human_sensor");
                                }
                            }
                        }else {
                            if (props.bindMode === "wait") {
                                if (typeof props.onSetBindingSource === 'function') {
                                    props.onSetBindingSource(node.apps[0], "not general_ir_human_sensor");
                                }
                            }else if(props.bindMode === "targets") {
                                if(typeof props.onResetBindingSource === "function") {
                                    props.onResetBindingSource();
                                }
                            }
                        }
                    }}
                />
            </div>
            <div className="prod-name">
            {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )
}