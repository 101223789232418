import { Checkbox, Form, Select } from "antd";
import { forwardRef, useEffect, useState } from "react";
import ModalForm from "../../../../components/ModalForm";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

interface HexConfigEditProps {

    onHexConfigChange: (hexConfig: any) => Promise<any>
}

const HexConfigEditor = forwardRef((props: HexConfigEditProps, ref: any) => {

    useEffect(() => {
        getConfigData()
    }, []);

    const [bindMethods, setBindMethods] = useState<any[]>([]);
    const [groupMethods, setGroupMethods] = useState<any[]>([]);
    const [sceneMethods, setSceneMethods] = useState<any[]>([]);
    const [availableSupports, setAvailableSupports] = useState<any[]>([]);
    const getConfigData = async () => {
        const [res1, res2, res3, res4] = await Promise.all([
            request.get(apis.case + `/node/bind/methods`),
            request.get(apis.case + `/node/group/methods`),
            request.get(apis.case + `/node/scene/methods`),
            request.get(apis.case + `/node/supports`)
        ]);
        setBindMethods(res1.data);
        setGroupMethods(res2.data);
        setSceneMethods(res3.data);
        setAvailableSupports(res4.data.map((item) => ({ label: item.name, value: item.key })));
    }

    const onSubmit = async (data: any) => {
        data.bindMethod = bindMethods.find((bm) => bm.key === data.bindMethod);
        data.groupMethod = groupMethods.find((bm) => bm.key === data.groupMethod);
        data.sceneMethod = sceneMethods.find((bm) => bm.key === data.sceneMethod);
        data.supports = (data.supports || []).map((supportKey: string) => availableSupports.find((as) => as.value === supportKey));
        return await props.onHexConfigChange(data);
    }

    return (
        <ModalForm
            ref={ref}
            title="编辑配置信息"
            onSubmit={onSubmit}
            centered
        >
            <Form.Item name="id" hidden />
            <Form.Item name="bindMethod" label="配置方法-绑定" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                <Select
                    style={{ width: 300 }}
                >
                    {
                        bindMethods.map((bm) => {
                            return (
                                <Select.Option value={bm.key}>{bm.name}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="groupMethod" label="配置方法-建组" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                <Select
                    style={{ width: 300 }}
                >
                    {
                        groupMethods.map((bm) => {
                            return (
                                <Select.Option value={bm.key}>{bm.name}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="sceneMethod" label="配置方法-建场景" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                <Select
                    style={{ width: 300 }}
                >
                    {
                        sceneMethods.map((sm) => {
                            return (
                                <Select.Option value={sm.key}>{sm.name}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="supports" label="支持功能">
                <Checkbox.Group
                    options={availableSupports}
                />
            </Form.Item>
        </ModalForm>
    )

});

export default HexConfigEditor;