import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, PageHeader, Row, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { TemplateComponent, templates } from "../../#case-manager/components/CaseNodeCell/template";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface Props extends RouteChildrenProps {

}

export default function CaseProductList(props: Props) {

    const [productList, setProductList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any[]>([])

    useEffect(() => {
        getData();
    }, []);
 
    const getData = async () => {
        setLoading(true);
        try {
            const res = await request.get(apis.caseProduct + `/list`);
            if (res.code === 1) {
                setProductList(res.data);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (productList.length > 0) {
            const data: any[] = [];
            const cates = templates.map((t) => ({ key: t.key, name: t.name, children: [] }));
            for (let i = 0; i < cates.length; i++) {
                const cate = cates[i];
                cate.children = productList.filter((p) => p.templateKey === cate.key);
                if (cate.children.length > 0) {
                    data.push(cate);
                }
            }
            console.log(data);
            
            setData(data);

        }
    }, [productList])

    return (
        <div>
            <PageHeader
                title="智能产品管理"
                subTitle="以下智能产品支持添加到方案中"
                extra={([
                    <Button
                        type="primary"
                        icon={(<PlusOutlined />)}
                        onClick={() => {
                            props.history.push('/product/add');
                        }}
                    >创建智能产品</Button>
                ])}
            >

            </PageHeader>
            <div className="" style={{height: 'calc(100vh - 100px)', overflowY: "auto"}}>
            {
                loading ? (
                    <Skeleton active />
                ) :
                    data.map((d) => {
                        return (
                            <div>
                                <div className="bold" style={{padding:"5px 16px"}}>{d.name}</div>
                                <Row style={{ padding: '0 10px' }}>
                                    {
                                        d.children.length > 0 && d.children.map((pl: any) => {
                                            return (
                                                <div className="" style={{ textAlign: 'center', marginBottom: 20, marginLeft: 6, marginRight: 6 }}>
                                                    {/* <span>hello</span> */}
                                                    <TemplateComponent
                                                        templateKey={pl.templateKey}
                                                        spu={pl.spu}
                                                        endpoints={[]}
                                                        silk1={{ cn: "私印1", en: "Silk Screen1" }}
                                                        silk2={{ cn: "私印2", en: "Silk Screen2" }}
                                                        silk3={{ cn: "私印3", en: "Silk Screen3" }}
                                                        silk4={{ cn: "私印4", en: "Silk Screen4" }}
                                                        silk5={{ cn: "私印5", en: "Silk Screen5" }}
                                                        silk6={{ cn: "私印6", en: "Silk Screen6" }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        )
                    })
            }
            </div>
        </div>
    )

}