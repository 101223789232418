import React from 'react'
import { Modal, Form, FormInstance, Input, message } from 'antd';
import { createRef } from 'react';
import { request } from '../../../../utils/request';
import { apis } from '../../../../utils/apis';

interface Props {
    hexGroup: any,
    visible: boolean,
    onCancel: () => void;
    onHexGroupUpdate: (hexGroup: any) => void;
}

const formRef = createRef<FormInstance>();

export const HexGroupTypeKeyEditModal = (props: Props) => {

    const onUpdateHexGroupTypeKey = ({ typeKey }: { typeKey: string }) => {
        request.put(apis.hexGroup + `/${props.hexGroup.id}`, {
            typeKey: typeKey
        })
        .then((res) => {
            if(res.code === 1) {
                props.onHexGroupUpdate(res.data);
            }else {
                message.warn(res.msg || res.message);
            }
        })
        .catch((e) => {
            message.warn(e.message);
        })
    }

    return (
        <Modal 
            title="修改typeKey(开发专用)"
            maskClosable={false}
            centered
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={() => {
                formRef.current?.submit();
            }}
            destroyOnClose
        >
            <Form 
                ref={formRef} 
                onFinish={onUpdateHexGroupTypeKey}
                initialValues={{
                    typeKey: props.hexGroup?.typeKey
                }}    
            >
                <Form.Item label="typeKey" name="typeKey" rules={[{required: true, message: "请输入typeKey"}]}>
                    <Input placeholder="请输入typeKey"/>
                </Form.Item>
            </Form>
        </Modal>
    )


}