import { ClockCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { sleep } from "../../../../../../utils";
import { apis } from "../../../../../../utils/apis";
import { request } from "../../../../../../utils/request";

interface PermitJoinProps {
    caseId?: number;
    onOk: () => void;
}

const PermitJoin = (props: PermitJoinProps) => {

    const [times, setTimes] = useState(1);

    useEffect(() => {
        if (!props.caseId) {
            return;
        }
        permitJoin(props.caseId);
    }, [props.caseId, times]);


    const permitJoin = async (caseId: number) => {
        const res = await request.post(apis.caseConfigNetPermitJoin, { caseId: caseId }, { autoErrorTip: false });
        if (res.code === 1) {
            if (res.data?.netId > 0) {
                props.onOk();
            } else {
                await sleep(1000);
                setTimes(times + 1);
                //超时情况
            }
        } else {

            Modal.confirm({
                title: "出现异常",
                centered: true,
                content: res.msg,
                okText: "重新尝试",
                onOk: () => {
                    setTimes(times + 1);
                },
                cancelText: "取消",
            });
        }
    }

    return (
    <div className="flex center column" style={{ height: 'calc(100vh - 300px)' }}>
        <ClockCircleOutlined spin className="" style={{ fontSize: 128, color: "lightblue" }} />
        <span className="bold" style={{ fontSize: 32, marginTop: 20, color: "lightblue" }}>正在<span style={{ color: "purple" }}>开启允许加入</span>, 请保持网关在线</span>
        <div style={{ minHeight: 30, color: "lightblue", width: 600, display: 'flex', flexWrap: 'wrap' }}>
            {
                new Array(times).fill(0).map((item) => {
                    return (
                        <span> ● </span>
                    )
                })
            }
        </div>
    </div>
    )

}

export default PermitJoin;