import { message } from 'antd';
import React, { useEffect } from 'react'

interface Props {
    mode: "view" | "edit" | "config",
    value?: any,
    onChange?: (value: any) => void
}

const infoChannels: string[] = ["B", "C", "D", "E", "F", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "1A"].reverse();

export const InfoChannelSelector = (props: Props) => {

    return (
        <div className="wrapper flex" style={{alignItems: 'center', color: '#fff'}}>
            {
                infoChannels.filter((item) => props.mode === 'view' ? item === props.value : true).map((item) => {
                    return (
                        <div className={"info-channel-item flex center " + (item === props.value ? " active" : "")} style={{margin: 5}} onClick={() => {
                            // if(props.case.submit && !props.case.outboundFinished) {
                            //     message.warn("方案已提交,且出货未完成,不可修改信道");
                            //     return ;
                            // }
                            if(typeof props.onChange === 'function') {
                                props.onChange(item)
                            }
                        }}>{item}</div>
                    )
                })
            }
        </div>
    )

}


interface InfoChannelProps {
    mode: "view" | "edit" | "config",
    loading?: boolean;
    has?: string[];
    value?: string;
    selectable?: boolean;
    onChange?: (value: string) => void
}

export const InfoChannelSelector2 = (props: InfoChannelProps) => {

    return (
        <div className="wrapper flex" style={{alignItems: 'center'}}>
            {
                infoChannels.map((item) => {
                    return (
                        <div 
                            key={item}
                            title={props.selectable === false ? '禁止切换' : props.has && !props.has.includes(item) ? `不存在${item}信道` : undefined}
                            className={`info-channel-item2 flex center ${item === props.value ? "active" : ""} ${props.has ? props.has.includes(item) ? '' : 'none' : ''} ${props.selectable === false ? 'disabled' : ''}`} 
                            onClick={() => {
                                if(props.has.includes(item) && !(props.selectable === false)) {
                                    if(typeof props.onChange === 'function') {
                                        props.onChange(item)
                                    }
                                }
                            }}
                        >{item}</div>
                    )
                })
            }
        </div>
    )

}
