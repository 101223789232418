import { Card, message, Modal, Popover, Table } from "antd";
import { useEffect } from "react";
import { LeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { request } from '../../../utils/request';
import { apis } from '../../../utils/apis';
import { ScanWrapper } from '../components/ScanWrapper'

// import './style.less';
import dayjs from "dayjs";


interface  Props extends RouteChildrenProps {

}

export const PageBackout= (props: Props) => {


    const [scanDisabled, setScanDisabled] = useState(false);
    const [list, setList] = useState([] as any[]);

    const onBackout = async (deviceCode: string) => {
        if(!deviceCode) {
            return ;
        }
        const res = await request.post(apis.productDevice + `/fallback`, { deviceCode });
        if(res.code === 1) {
            list.unshift(deviceCode);
            setList([...list]);
        }
    }

    return (
        <div className="page-inbound2">
            <ScanWrapper placeholder="扫描二维码入库" disabled={scanDisabled} onEnter={onBackout}>
            <div style={{padding: '10px 12px', fontSize: 14, cursor: 'pointer', background: "#fff", marginBottom: 10}} onClick={() => {
                props.history.goBack();
            }}>
                    <LeftOutlined />
                    返回
                </div>
            {
                list.length === 0 ? (
                    <div className="flex center bold" style={{color: "gray", fontSize: 18}}>扫码退库</div>
                ) : (
                    <div className="" style={{textAlign: 'center'}}>
                    {
                        list.map((item, index) => {
                            return (
                                <div className={index === 0 ? 'bold bg-primary' : undefined} style={{fontSize: index === 0 ? 20 : 16, lineHeight: '42px'}}>{item} 已回退</div>
                            )
                        })
                    }
                    </div>
                )
            }
            </ScanWrapper>
        </div>
    )
}