import { Descriptions, Table } from "antd";
import { useEffect } from "react";
import './print-template-style.less';

interface OrderA4TemplateProps {
    customer: any;
    data: any;
}

const OrderCaseSpuListA4Template = (props: OrderA4TemplateProps) => {

    useEffect(() => {
        console.log(props.data);

    }, [props.data])

    return (
        <div className="a4-template bg-white landscape" style={{ width: "calc(29.7cm)", minHeight: '20cm', cursor: "grab", boxSizing: "content-box" }}>
            <div style={{ padding: 10 }}>

                <Descriptions
                    title={(
                        <div className="flex" style={{ alignItems: 'center', marginBottom: 20 }}>
                            <img src={"/favicon.ico"} className="relative" style={{ top: 0, height: 16, width: 16 }} />
                            <span style={{ marginLeft: 5 }}>华飞物联 - 订单附件：清单</span>
                        </div>
                    )}
                    style={{ fontSize: 14 }}
                    bordered
                    extra={(
                        <span style={{ position: 'relative', top: -10 }}>订单号：HFDHD20220927001</span>
                    )}
                    labelStyle={{ background: "#eee" }}
                >
                    {/* <Descriptions.Item label="客户名称" labelStyle={{ width: 100 }}>{props.customer?.name}</Descriptions.Item>
                    <Descriptions.Item label="制单员" labelStyle={{ width: 100 }}>某某某</Descriptions.Item>
                    <Descriptions.Item label="业务员" labelStyle={{ width: 100 }}>某某某</Descriptions.Item>
                    <Descriptions.Item label="客户地址" span={5}>
                        <span>{props.customer?.address}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="提交日期" span={1.5}>2022年9月20日</Descriptions.Item>
                    <Descriptions.Item label="交货日期" span={1.5}>2022年9月20日</Descriptions.Item>
                    <Descriptions.Item label="订单备注" span={5}>
                    </Descriptions.Item> */}
                </Descriptions>
            </div>
            <div style={{ padding: '10px 8px' }}>
            <Table
                    pagination={false}
                    size="small"
                    dataSource={props.data.data || []}
                    bordered
                    columns={[
                        {
                            dataIndex: "name",
                            key: "name",
                            className: "eee",
                        },
                         ...(props.data.spus || []).map((spu) => {
                                return {
                                    dataIndex: "spu-" + spu.spuId,
                                    title: (<div style={{minWidth: '1.3em', fontSize: 12}}>{spu.spuName}</div>),
                                    key: "spu" + spu.spuId,
                                    align: 'center',
                                    render: (quantity) => {
                                        return <span className="bold">{quantity}</span>
                                    }
                                }
                            })
                        ]}
                />
            </div>
        </div >
    )


}

export default OrderCaseSpuListA4Template;