import { Button, Form, FormInstance, Input, message } from 'antd';
import React, { useState, createRef, useEffect } from 'react';
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';

interface Props {
    caseId: number
}

const formRef = createRef<FormInstance>();

export const CaseDuerosCuidConfig = (props: Props) => {
    
    const [cuid, setCuid] = useState("");
    const [initCuid, setInitCuid] = useState("");
    
    useEffect(() => {
        if(!props.caseId) {
            formRef.current?.setFieldsValue({
                cuid: ""
            })
            setCuid("");
            setInitCuid("");
            return ;
        }
        request.get(apis.caseExtraConfigGetDuerosSpeakerCuid, {
            caseId: props.caseId
        })
            .then((res) => {
                if(res.code === 1) {
                    setInitCuid(res.data.content);
                    formRef.current?.setFieldsValue({
                        cuid: res.data.content
                    })
                }else {
                    message.warn(res.msg || res.message)
                }
            })
    }, [props.caseId]);

    const onSubmit = (data: { cuid: string }) => {
        request.post(apis.caseExtraConfigSetDuerosSpeakerCuid, {
            caseId: props.caseId,
            cuid: data.cuid,
        }).then((res) => {
            if(res.code === 1) {
                setInitCuid(res.data.content);
                message.success("已设置");
            }else {
                message.warn(res.msg || res.message);
            }
        })
    }

    return (
        <div className="case-dueros-cuid-config">
            <Form ref={formRef} style={{width: 500}} layout="inline" size="small" onValuesChange={(temp) => {
                if("cuid" in temp) {
                    setCuid(temp.cuid);
                }
            }} onFinish={onSubmit}>
                <Form.Item label={"音箱CUID"} name="cuid">
                    <Input placeholder="如需配置小度音箱, 请输入音箱的cuid"/>
                </Form.Item>
                <Form.Item >
                    <Button htmlType="submit" type="primary" disabled={!cuid || cuid === initCuid} size="small">确认</Button>
                </Form.Item>
            </Form>
        </div>
    )

}