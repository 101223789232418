import { Form, FormInstance, Input, message, Modal } from "antd"
import React from "react"
import { useState } from "react";
import { useRef } from "react"
import { request } from '../../../../../../utils/request';
import { apis } from '../../../../../../utils/apis';
import { InfoChannelSelector } from '../../../../CaseManager/components/CaseEditor/components/InfoChannelSelector';
interface Props {
    visible: boolean;
    case: any;
    onCancel: () => void;
    onCaseUpdate: (_case: any) => void;
}

export const UpdateCaseModal = (props: Props) => {

    const formRef = useRef<FormInstance>();

    const [loading, setLoading] = useState(false);

    
    const onCaseUpdate = async (data: any) => {
        setLoading(true);
        let update: any = {};
        if(data.name !== props.case.name) {
            update.name = data.name;
        }
        if(data.infoChannel !== props.case.rest_infoChannel) {
            update.infoChannel = data.infoChannel;
        }
        const res = await request.put(apis.caseTemplateInstance + `/${data.caseId}`, update);
        if(res.code === 1) {
            res.data.rest_infoChannel = data.infoChannel;
            props.onCaseUpdate(res.data);
            message.success("修改成功");
        }
        setLoading(false);
    }

    return (
        <Modal
            title={"修改" + props.case?.name + '房型的房间'}
            centered
            visible={props.visible}
            onOk={() => {
                formRef.current?.submit()
            }}
            okButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            onCancel={props.onCancel}
            cancelButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            width={540}
            destroyOnClose
        >
            <Form
                ref={formRef}
                labelCol={{span: 3}}
                initialValues={{
                    caseId: props.case?.id,
                    infoChannel: props.case?.rest_infoChannel,
                    name: props.case?.name
                }}
                onFinish={onCaseUpdate}
            >
                <Form.Item name="caseId" hidden />
                <Form.Item label="房间号" name="name" rules={[{required: true, message: "请输入房型名称"}]}>
                    <Input placeholder="请输入房型名称" autoComplete="off" autoFocus/>
                </Form.Item>
                <Form.Item label="信道" name="infoChannel" rules={[{required: true, message: "请选择信道"}]}>
                    <InfoChannelSelector mode="edit"/>
                </Form.Item>
            </Form>
        </Modal>
    )

}