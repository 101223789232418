import { AimOutlined, CheckOutlined, CloseOutlined, EditOutlined, ExceptionOutlined, LoadingOutlined, PlusOutlined, RadarChartOutlined, SyncOutlined, WarningFilled, WifiOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Menu, Modal, Result, Skeleton, Tabs, message } from 'antd';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { apis, host } from "../../../../../utils/apis";
import { request } from "../../../../../utils/request";
import NodeCell from "../../../CaseManager/components/CaseEditor/widgets/node-widgets/components/NodeCell";
import './style.less';

interface Props extends RouteChildrenProps<{
    customerId?: string,
    caseId?: string,
}> {
    case: any;
    loading: boolean;
    notFound: boolean;
}

export default function CaseView(props: Props) {

    const [area, setArea] = useState(null as any);

    const [areaSelectorVisible, setAreaSelectorVisible] = useState(false);
    const [selectedAreaId, setSelectedAreaId] = useState(0);
    const openAreaSelector = (currentAreaId?: number) => {
        setAreaSelectorVisible(true);
        setSelectedAreaId(currentAreaId || 0);
    }
    const closeAreaSelector = () => {
        setAreaSelectorVisible(false);
        setSelectedAreaId(0);
    }

    const caseNodeViewRef = useRef<any>();

    return (
        
        <div className="bg-white" style={{ marginLeft: 1, padding: 10, paddingRight: 0, height: 'calc(100vh - 40px)' }}>
        <Skeleton loading={props.loading}>
            {
                    
                props.notFound ? (
                    <div style={{ height: '100%', width: '100%' }} className="flex center">
                        <Result
                            status="404"
                            title="未找到客户或方案，请在左侧重新选择"
                        />
                    </div>
                ) : (
                    <Tabs type="card">
                        <Tabs.TabPane key={"case-config"} tab="配置方案">
                            <div className="case-view">
                                <AreasLayout
                                    {...props}
                                    case={props.case}
                                    header={(
                                        <CaseNetView
                                            case={props.case}
                                        />
                                    )}
                                    onAreaChange={(area) => {
                                        const { customerId, caseId } = props.match.params;
                                        setArea(area);
                                        if (area) {
                                            props.history.replace(`/customer/${customerId}/case/${caseId}/area/${area.id}`);
                                        } else {
                                            props.history.replace(`/customer/${customerId}/case/${caseId}`);
                                        }
                                    }}


                                    //区域选择
                                    areaSelectorVisible={areaSelectorVisible}
                                    onCloseAreaSelector={closeAreaSelector}
                                    selectedAreaId={selectedAreaId}
                                    onAreaSelected={(area) => {
                                        caseNodeViewRef.current?.onAreaSelected(area);
                                        closeAreaSelector();
                                    }}
                                >
                                    <CaseNodesView
                                        ref={caseNodeViewRef}
                                        case={props.case}
                                        areaId={area?.id || 0}
                                        onOpenAreaSelctor={openAreaSelector}
                                    />
                                </AreasLayout>
                            </div>

                        </Tabs.TabPane>
                        <Tabs.TabPane key={"order"} tab="订单管理">

                        </Tabs.TabPane>
                    </Tabs>

                )
            }
            </Skeleton>
        </div>
    )

}


interface CaseScenesAndNodesViewProps {
    case: any;
    areaId: number;
    onOpenAreaSelctor: (areaId: number) => void
}

const CaseNodesView = forwardRef((props: CaseScenesAndNodesViewProps, ref) => {

    const [loading, setLoading] = useState(false);
    const [caseNodes, setCaseNodes] = useState([] as any[]);
    const [caseScenes, setCaseScenes] = useState([] as any[]);
    const [displayCaseNodes, setDisplayCaseNodes] = useState([] as any[]);
    const [displayCaseScenes, setDisplayCaseScenes] = useState([] as any[]);



    useEffect(() => {
        if (props.case?.id) {
            getCaseNodes(props.case.id);
            getCaseScenes(props.case.id);
        }
    }, [props.case]);

    const getCaseNodes = async (caseId: number) => {
        if (!caseId) {
            setCaseNodes([]);
            return;
        }
        setLoading(true);
        try {
            const res = await request.get(apis.caseNode, { caseId });
            if (res.code === 1) {
                if (res.data) {
                    setCaseNodes(res.data.caseNodes);
                }
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const getCaseScenes = async (caseId: number) => {
        if (!caseId) {
            setCaseScenes([]);
        }
        try {
            const res = await request.get(apis.caseScene, { caseId });
            if (res.code === 1) {
                if (res.data) {
                    setCaseScenes(res.data);
                }
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (props.areaId) {
            setDisplayCaseNodes(caseNodes.filter((cn) => cn.caseAreaId === props.areaId));
            setDisplayCaseScenes(caseScenes.filter((cs) => cs.caseAreaId === props.areaId));
        } else {
            setDisplayCaseNodes(caseNodes);
            setDisplayCaseScenes(caseScenes);
        }
    }, [props.areaId, caseNodes, caseScenes]);

    const [currentNode_updateArea, setCurrentNode_updateArea] = useState(null);
    const openAreaSelector = (caseNode: any) => {
        setCurrentNode_updateArea(caseNode);
        props.onOpenAreaSelctor(caseNode.caseAreaId);
    }
    const [areaUpdating, setAreaUpdating] = useState(false);
    useImperativeHandle(ref, () => ({
        onAreaSelected: (area: any) => {
            if (!area) {
                return;
            }
            setAreaUpdating(true);
            try {
                console.log(currentNode_updateArea);

                request.put(apis.caseNode + `/${currentNode_updateArea.caseNodeId}`, { areaId: area.id })
                    .then((res) => {
                        if (res.code === 1) {
                            const caseNode = caseNodes.find((c) => c.caseNodeId === currentNode_updateArea.caseNodeId);
                            caseNode.caseAreaId = area.id;
                            caseNode.caseAreaName = area.name;
                            setCaseNodes([...caseNodes]);
                            message.info("已修改区域")
                        }
                    })
            } catch (e) {

            } finally {
                setAreaUpdating(false)
            }
        }
    }));

    const onNodeConfig = async (caseNode: any) => {

    }

    const onNodeOpenPermitJoin = async (caseNode: any) => {

    }

    return (
        <div className="case-scenes-and-nodes" style={{ flexWrap: 'wrap', height: "calc(100vh - 40px - 200px - 20px)", overflowY: 'scroll', paddingTop: 10 }}>
            <div className="scene-list" style={{ marginBottom: 10 }}>
                {
                    displayCaseScenes.length > 0 && displayCaseScenes.map((scene) => {
                        return (
                            <div style={{ display: 'inline-block', borderRadius: 3, boxShadow: '0 3px 10px rgba(0, 0, 0, .15)', color: "#888", margin: '0 5px 10px 5px', padding: '10px 0 0 0' }}>
                                <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 10, padding: '0 20px' }}>
                                    <div className="img-wrapper" style={{ height: 22, width: 22 }}>
                                        <img src={scene.iconUrl} alt="" style={{ height: '100%', width: '100%' }} />
                                    </div>
                                    <span style={{ fontSize: 14 }}>{scene.caseSceneName}</span>
                                </div>
                                <div className="flex center">
                                    <div className='tag'>
                                        组ID：<span className="bold">{scene.restGroupId} </span>
                                    </div>
                                    <div className='tag'>
                                        场景ID：<span className="bold">{scene?.restSceneId} </span>
                                    </div>
                                </div>
                                <div className="flex" style={{ borderTop: '1px solid #f2f2f7', marginTop: 10, width: 180 }}>
                                    <div className="" style={{ flex: 1, lineHeight: '32px', textAlign: 'center', borderRight: '1px solid #f2f2f7', cursor: 'pointer' }}>查看与编辑</div>
                                    <div className="" style={{ flex: 1, lineHeight: '32px', textAlign: 'center', cursor: 'pointer' }}>开启场景</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="nodes">
                {
                    displayCaseNodes.length > 0 && displayCaseNodes.map((caseNode, index) => {
                        return (
                            <div style={{ display: 'inline-block', border: '1px solid #aaa', padding: 10, marginRight: 10, marginBottom: 10 }}>
                                <div className="flex">

                                    <div className="case-node-info" style={{ display: 'inline-block' }}>
                                        <div className="case-node-item flex" style={{ alignItems: 'center' }}>
                                            <div className="label">所在区域：</div>
                                            <div className="value">
                                                <Button
                                                    type="link"
                                                    disabled={currentNode_updateArea?.caseNodeId === caseNode.caseNodeId && areaUpdating}
                                                    onClick={() => {
                                                        openAreaSelector(caseNode);
                                                    }}
                                                >
                                                    {caseNode.caseAreaName}
                                                    {
                                                        currentNode_updateArea?.caseNodeId === caseNode.caseNodeId && areaUpdating ? (
                                                            <LoadingOutlined />
                                                        ) : (
                                                            <EditOutlined />
                                                        )
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="case-node-item">
                                            <div className="label">节点ID：</div>
                                            <div className="value">{caseNode.restNodeId}</div>
                                        </div>
                                        <div className="case-node-item">
                                            <div className="label">短地址：</div>
                                            <div className="value">{caseNode.restShortAddr}</div>
                                        </div>
                                        <div className="case-node-item">
                                            <div className="label">长地址：</div>
                                            <div className="value">{caseNode.ieeeAddr}</div>
                                        </div>
                                    </div>
                                    <div className="case-node-actions flex column" style={{ flex: 1, textAlign: 'right' }}>
                                        <div>
                                            <Button size="small" onClick={() => {
                                                request.post(apis.caseConfigNode, {
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        message.info("ok");
                                                    })
                                            }}>单配</Button>
                                        </div>
                                        <div>
                                        {
                                            caseNode.typeKey === 'curtain_motor' && (
                                                <Button size="small" onClick={() => {
                                                    request.post(apis.caseConfig + `/curtain-motor/reverse`, {
                                                        caseNodeId: caseNode.caseNodeId
                                                    }).then((res) => {
                                                        if(res.code === 1) {
                                                            message.info("oK")
                                                        }
                                                    })
                                                }}>反向</Button>
                                            )
                                        }
                                        </div>
                                        <div>
                                            <Button size="small" onClick={() => {
                                                request.post(apis.case + '/node/config/cancel/lock', {
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        if(res.code === 1) {
                                                            if(res.data === "ok") {
                                                                message.info("成功！");
                                                            }else {
                                                                message.warning("未成功!")
                                                            }
                                                        }
                                                    })
                                            }}>取消插卡取电锁</Button>
                                        </div>
                                        <div>
                                            <Button size="small" onClick={() => {
                                                request.post(apis.case + '/node/config/enable/hotel-mode', {
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        if(res.code === 1) {
                                                            if(res.data === "ok") {
                                                                message.info("成功！");
                                                            }else {
                                                                message.warning("未成功!")
                                                            }
                                                        }
                                                    })

                                            }}>酒店模式</Button>
                                        </div>
                                        
                                        <div>
                                            <Button size="small" onClick={() => {
                                                request.post(apis.case + '/node/config/cancel/hotel-mode', {
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        if(res.code === 1) {
                                                            if(res.data === "ok") {
                                                                message.info("成功！");
                                                            }else {
                                                                message.warning("未成功!")
                                                            }
                                                        }
                                                    })

                                            }}>取消酒店模式</Button>
                                        </div>
                                        {
                                            caseNode.typeKey.indexOf("curtain_motor") > -1 && (
                                                <div>
                                                    <Button size="small" onClick={() => {
                                                        request.post(apis.case + '/node/config/enable/group-255', {
                                                            caseNodeId: caseNode.caseNodeId
                                                        })
                                                            .then((res) => {
                                                                if(res.code === 1) {
                                                                    if(res.data === "ok") {
                                                                        message.info("成功！");
                                                                    }else {
                                                                        message.warning("未成功!")
                                                                    }
                                                                }
                                                            })
                                                    }}>
                                                        加组255
                                                    </Button>
                                                </div>
                                            )
                                        }
                                        {/* <div>
                                            <Button size="small" disabled onClick={() => {

                                            }}>打允</Button>
                                        </div>
                                        <div>
                                            <Button size="small" disabled onClick={() => {

                                            }}>关允</Button>
                                        </div> */}
                                        <div>
                                            <Button size="small" onClick={() => {
                                                request.post(apis.caseConfigNodeReset, {
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送")
                                                        }
                                                    })
                                            }}>复位</Button>
                                        </div>
                                        
                                        {
                                            <Button type="default" size="small" onClick={() => {
                                                request.post(apis.case+ `/node/config/enable/switch-occupancy-report`, {
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        if(res.code === 1) {
                                                            message.info("完成");
                                                        }
                                                    })
                                            }}>
                                                激活Occupance Report
                                            </Button>
                                        } 
                                        {
                                            <Button type="default" size="small" onClick={() => {
                                                request.post(apis.case+ `/node/config/disable/switch-occupancy-report`, {
                                                    
                                                    caseNodeId: caseNode.caseNodeId
                                                })
                                                    .then((res) => {
                                                        if(res.code === 1) {
                                                            message.info("完成");
                                                        }
                                                    })
                                            }}>
                                                取消Occupance Report
                                            </Button>

                                        }
                                    <div></div>
                                    </div>
                                </div>
                                <NodeCell
                                    caseNode={caseNode}
                                />
                            </div>

                        )
                    })
                }
            </div>
        </div>
    )

})


interface CaseNetViewProps {
    case: any;
}

function CaseNetView(props: CaseNetViewProps) {

    /**
     * 根据rest_netId判断是否关联网络
     */
    const [loading, setLoading] = useState(false);
    const [caseNet, setCaseNet] = useState({} as any);
    const [netStatLoading, setNetStatLoading] = useState(false);
    const [netState, setNetState] = useState(0 as (0 | 1));
    const [netBorderLight, setNetBorderLight] = useState(0 as (-1 | 0 | 1));

    useEffect(() => {
        return () => {
            if (keepInterval) {
                clearInterval(keepInterval);
            }
        }
    }, [])

    useEffect(() => {
        if (keepInterval) {
            clearInterval(keepInterval);
        }
        getCaseNet(props.case?.id);
        console.log("case change", props.case);
        
    }, [props.case]);

    const getCaseNet = async (caseId: number) => {
        if (!caseId) {
            setCaseNet({});
            return;
        }
        setLoading(true);
        try {
            const res = await request.get(apis.caseNet + `/${caseId}`, { caseId });
            if (res.code === 1) {
                if (res.data) {
                    setCaseNet(res.data);
                    setNetState(res.data.lastNetStat);
                }else {
                    setCaseNet({});
                    
                }
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (caseNet?.rest_netId) {
            getNetStat(caseNet.id);
        }
    }, [caseNet]);

    const getNetStat = async (caseNetId: number) => {
        try {
            setNetStatLoading(true);
            const res = await request.get(apis.caseNetCheckActive, { caseNetId: caseNetId });
            if (res.code === 1) {
                setNetState(res.data.isActive)
            } else {

            }
        } catch (e) {

        } finally {
            setNetStatLoading(false);
        }
    }

    const onRefreshNetStat = async () => {
        if (caseNet?.id) {
            getNetStat(caseNet.id);
        }
    }


    const [keepRefreshNetStat, setKeepRefreshNetStat] = useState(false);
    const [keepInterval, setKeepInterval] = useState(null);

    useEffect(() => {
        if (!caseNet?.id) {
            return;
        }
        if (keepRefreshNetStat) {
            const frame = async () => {
                const res = await request.get(apis.caseNetCheckActive, { caseNetId: caseNet.id });
                console.log(res.data);
                if (res.code === 1) {
                    if (res.data.isActive) {
                        setNetBorderLight(1)
                    } else {
                        setNetBorderLight(-1);
                    }
                }
            }
            frame();
            const interval = setInterval(frame, 12 * 1000);
            setKeepInterval(interval);
        } else {
            if (keepInterval) {
                clearInterval(keepInterval);
            }
        }
    }, [keepRefreshNetStat]);

    return (
        <div>
            {
                loading ? (
                    <Skeleton active />
                ) : (
                    <div className="flex" style={{ marginTop: 20 }}>
                        <div style={{ padding: '0 10px' }}>
                            <div className={`flex center ${netBorderLight === 1 ? 'green-shining' : netBorderLight === -1 ? 'red-shining' : 'bg-white'}`} style={{ boxShadow: '0 3px 10px rgba(0, 0, 0, .15)', height: 48, width: 48, padding: 3, borderRadius: 8 }}>
                                <div className="flex column center bg-white" style={{ height: '100%', width: '100%', borderRadius: 6, padding: 2 }}>
                                    <span style={{ flex: 2, position: 'relative', top: 3 }} >
                                        {
                                            caseNet?.mac ? (<>
                                                {
                                                    netStatLoading ? (
                                                        <SyncOutlined spin className={netState ? 'color-green' : 'color-red'} style={{ fontSize: 24 }} />
                                                    ) : (
                                                        <WifiOutlined
                                                            className={caseNet?.rest_netId ? netState ? 'color-green' : 'color-red' : 'color-gray'}
                                                            style={{ fontSize: 24, cursor: 'pointer' }}
                                                            onClick={onRefreshNetStat}
                                                        />
                                                    )
                                                }
                                            </>
                                            ) : (
                                                <ExceptionOutlined style={{ fontSize: 24 }} />
                                            )

                                        }
                                    </span>
                                    <span className="flex center" style={{ height: 16, fontSize: 12, transform: "scale(0.6)", whiteSpace: 'nowrap', color: 'gray', cursor: 'pointer' }} >

                                        {
                                            caseNet?.mac ? (<>
                                                {
                                                    caseNet.rest_netId ? (
                                                        <>
                                                            {
                                                                keepRefreshNetStat ? (
                                                                    <div className="flex center" onClick={() => {
                                                                        setKeepRefreshNetStat(false);
                                                                    }}>
                                                                        {/* <LoadingOutlined style={{fontSize: 12, marginRight: 2}}/> */}
                                                                        <span>检测中..</span>
                                                                        <CloseOutlined />
                                                                    </div>
                                                                ) : (
                                                                    <div onClick={() => {
                                                                        setKeepRefreshNetStat(true);
                                                                    }}>
                                                                        持续检测
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <div className="flex center">网关未上线</div>
                                                    )
                                                }
                                            </>
                                            ) : (
                                                <div className="flex center">未关联网关</div>
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="info-list">
                            <div className="">
                                <div className="flex" style={{ marginBottom: 5 }} >
                                    <span className="bold" style={{ fontSize: 14, marginLeft: 5, marginRight: 20 }}>
                                        {caseNet?.mac || "未关联网关，无MAC地址"}
                                    </span>
                                    <Dropdown disabled={!caseNet?.rest_netId} trigger={["click"]} overlay={(
                                        <Menu>
                                            <Menu.Item disabled>开关网关指示灯</Menu.Item>
                                            <Menu.Item onClick={() => {
                                                request.post(apis.caseConfigNetPermitJoin, { caseId: caseNet.caseId })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送");
                                                        }
                                                    })
                                            }}>开启网关允许加入</Menu.Item>
                                            <Menu.Item onClick={() => {
                                                request.post(apis.caseConfigNetDisablePermitJoin, { caseId: caseNet.caseId })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送");
                                                        }
                                                    })
                                            }}>关闭网关允许加入</Menu.Item>
                                        </Menu>
                                    )}>
                                        <Button icon={(<AimOutlined />)} size="small" style={{ marginRight: 10 }}>网关操作</Button>
                                    </Dropdown>
                                    <Dropdown disabled={!caseNet?.rest_netId} trigger={["click"]} overlay={(
                                        <Menu>
                                            <Menu.Item onClick={() => {
                                                request.post(apis.caseConfigNetPermitJoinBroadcast, { caseId: caseNet.caseId })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送");
                                                        }
                                                    })
                                            }}>广播开启允许加入（180s）</Menu.Item>
                                            <Menu.Item onClick={() => {
                                                request.post(apis.caseConfigNetPermitJoinBroadcast, { caseId: caseNet.caseId, duration: 255 })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送");
                                                        }
                                                    })
                                            }}>广播开启允许加入（永久）</Menu.Item>
                                            <Menu.Item onClick={() => {
                                                request.post(apis.caseConfigNetDisableJoinBroadcast, { caseId: caseNet.caseId })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送");
                                                        }
                                                    })
                                            }}>广播关闭允许加入</Menu.Item>
                                            <Menu.Item style={{ color: 'orange' }} onClick={() => {
                                                request.post(host + `/case-config/plugin-card/active-lock/broadcast`, {
                                                    caseId: props.case?.id
                                                })
                                                    .then((res) => {
                                                        if (res.code === 1) {
                                                            message.info("已发送")
                                                        } else {
                                                            message.warn(res.msg);
                                                        }
                                                    });
                                            }}>广播激活插卡取电锁</Menu.Item>
                                            <Menu.SubMenu
                                                title={(<span style={{ color: 'red' }}>危险操作</span>)}
                                                icon={(<WarningFilled style={{ color: 'red' }} />

                                                )}>
                                                <Menu.Item danger disabled>！！广播复位！！</Menu.Item>
                                            </Menu.SubMenu>
                                        </Menu>
                                    )}>
                                        <Button icon={(<RadarChartOutlined />)} size="small" style={{ marginRight: 10 }}>广播操作</Button>
                                    </Dropdown>
                                    <Dropdown trigger={["click"]} overlay={(
                                        <Menu>
                                            <Menu.Item style={{ color: 'orange' }} onClick={() => {
                                                request.post(apis.caseConfigPrepare, {
                                                    caseId: props.case?.id
                                                }).then((res) => {
                                                    if (res.code === 1) {
                                                        message.info("已更新")
                                                    }
                                                });
                                            }}>更新配置信息（可能会导致组ID和场景ID发生变化）</Menu.Item>
                                        </Menu>
                                    )}>
                                        <Button icon={(<RadarChartOutlined />)} size="small">智能配置</Button>
                                    </Dropdown>
                                </div>
                                <div className='flex'>
                                    <div className='tag'>
                                        网络号：<span className="bold">{caseNet?.rest_netId || "未关联网络"}</span>
                                    </div>
                                    <div className='tag'>
                                        长地址：
                                        <span className={`bold ${caseNet?.mac && !caseNet?.ieeeAddr ? 'color-red' : ''}`}>{caseNet?.ieeeAddr || "未关联网络"} </span>
                                    </div>
                                    <div className='tag'>
                                        节点号：<span className={`bold ${caseNet?.mac && caseNet?.rest_nodeId === 0 ? 'color-red' : ''}`}>{caseNet?.rest_nodeId}</span>
                                    </div>
                                    <div className='tag'>
                                        信道：<span className="bold">{caseNet?.infoChannel || "无信道"} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )


}

interface AreasLayoutProps extends RouteChildrenProps {
    case: any;
    children: ReactNode;
    header: ReactNode;
    onAreaChange: (area: any) => void;


    //区域选择器
    selectedAreaId?: number;
    areaSelectorVisible: boolean;
    onAreaSelected: (area: any) => void;
    onCloseAreaSelector: () => void;
}
function AreasLayout(props: AreasLayoutProps) {

    const [loading, setLoading] = useState(false);
    const [areas, setAreas] = useState([] as any[]);
    const [selectedAreaId, setSelectedAreaId] = useState(0);


    useEffect(() => {
        getCaseAreas(props.case?.id);
    }, [props.case]);

    const getCaseAreas = async (caseId: number) => {
        if (!caseId) {
            setAreas([]);
            return;
        }
        setLoading(true);
        try {
            const res = await request.get(apis.area, { caseId });
            if (res.code === 1) {
                setAreas(res.data);
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!selectedAreaId) {
            props.onAreaChange(null);
        } else {
            const area = areas.find((a) => a.id === selectedAreaId);
            props.onAreaChange(area);
        }
    }, [selectedAreaId]);

    return (
        <div className="areas-layout-props">
            {props.header}
            <div className="areas " style={{ margin: '20px 0 20px 0', borderTop: '10px solid #f2f2f7', padding: '20px 10px 0 10px' }}>
                <div className={`area-item ${0 === selectedAreaId ? 'bg-primary' : ''}`} key="all" onClick={() => {
                    setSelectedAreaId(0)
                }}>全部</div>
                {
                    areas.length > 0 && areas.map((area, index) => {
                        return (
                            <div key={"key-" + index} className={`area-item ${area.id === selectedAreaId ? 'bg-primary' : ''}`} onClick={() => {
                                setSelectedAreaId(area.id)
                            }}>
                                {area.name}
                            </div>
                        )
                    })
                }

                <div className="area-item area-add">
                    <PlusOutlined /> 添加区域
                </div>

            </div>
            <AreaSelector
                areas={areas}
                visible={props.areaSelectorVisible}
                onAreaSelected={props.onAreaSelected}
                onCancel={props.onCloseAreaSelector}
                selectedAreaId={props.selectedAreaId}
            />
            {props.children}
        </div>
    )
}


interface AreaSelectorProps {
    areas: any[];
    visible: boolean;
    onCancel: () => void;
    selectedAreaId?: number;
    onAreaSelected: (area: any) => void
    loading?: boolean;
}
export function AreaSelector(props: AreaSelectorProps) {

    const [selectedAreaId, setSelectedAreaId] = useState(props.selectedAreaId || 0);

    useEffect(() => {
        if (props.areas.length > 0 && !selectedAreaId) {
            setSelectedAreaId(props.areas[0].id);
        }
    }, [props.areas]);


    return (
        <div className="area-selector">
            <Modal
                visible={props.visible}
                title="选择区域"
                onOk={() => {
                    const area = props.areas.find((a) => a.id === selectedAreaId);
                    props.onAreaSelected(area);
                }}
                onCancel={props.onCancel}
                okButtonProps={{
                    disabled: props.loading,
                    loading: props.loading
                }}
                closable={!props.loading}
                centered
            >
                <List
                    size="small"
                    header={<div>区域列表</div>}
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource={props.areas}
                    renderItem={item => (
                        <List.Item style={{ cursor: 'pointer' }} onClick={() => {
                            setSelectedAreaId(item.id);
                        }}>
                            <div className="flex" style={{ alignItems: 'center', lineHeight: '36px' }}>
                                <span>{item.name}</span>

                                {
                                    selectedAreaId === item.id && (
                                        <Button type="link">
                                            <CheckOutlined style={{ fontSize: 12 }} />
                                        </Button>
                                    )
                                }

                            </div>
                        </List.Item>
                    )}
                />
            </Modal>
        </div>
    )

}