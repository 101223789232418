import { Empty, Menu } from 'antd';
import { useState } from 'react'
import { Route, RouteChildrenProps, RouteComponentProps, Switch } from 'react-router-dom'

import './style.less';
import CustomerList from './components/CustomerList';
import CaseList from './components/CaseList';
import CaseView from './components/CaseView';
// import './style2.less';

interface Props extends RouteComponentProps<{ customerId?: string, caseId?: string }>{

}

export const PageHome2 = (props: Props) => {

    const [customer, setCustomer] = useState(null as any);
    const [notFound, setNotFound] = useState(false);
    const [_case, setCase] = useState(null as any);
    const [loading, setLoading] = useState(true);

    return (
        <div className="page page-home2">
            <CustomerList
                customerId={Number(props.match.params.customerId || 0)}
                onCustomerChange={(customer) => {
                    if(customer) {
                        let path = '/customer'
                        if(customer.id) {
                            path += `/${customer.id}`;
                        }
                        if(!notFound && props.match.params.caseId) {    //!notFound: 在浏览器地址栏输入找不到的caseId后， 重新选择客户， 依然是notFound，未解决此问题，条件加上了!notFound
                            path += `/case/${props.match.params.caseId}`
                        }
                        props.history.replace(path);
                        setNotFound(false);
                    }else {
                        setLoading(false);  //获取客户就没获取到,加载结束
                        setNotFound(true);
                    }
                    setCustomer(customer);
                }}
            />
            <div className="page-content flex" style={{margin: 0}}>
                {
                    customer && (
                        <CaseList
                            customer={customer}
                            caseId={Number(props.match.params.caseId || 0)}
                            onCaseChange={(c) => {
                                if(c) {
                                    setCase(c);
                                    props.history.replace(`/customer/${c.customerId}/case/${c.id}`);  
                                    setNotFound(false);  
                                }else {
                                    setNotFound(true); 
                                }
                                setLoading(false);
                            }}
                        />
                    )
                }
                <div className='case-view flex1'>
                    <Switch>
                        <Route path="/customer/:customerId/:case?/:caseId?" render={(props: Props) => {
                            return (
                                <CaseView 
                                    {...props}
                                    case={_case} 
                                    loading={loading}
                                    notFound={notFound}
                                />
                            )
                        }}/>
                        <Route path="*" render={() => {
                            return (
                                <div className="content-empty-wrapper" style={{height: 'calc(100vh - 40px)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                    <Empty description={(
                                        <span>请在左侧选择要查看的客户方案</span>
                                    )} />
                                </div>
                            )
                        }} />
                    </Switch>
                </div>
            </div>
        </div >
    )
}

