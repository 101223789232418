import { CloudFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { SourceConnector, TargetConnector } from '../NodeCellConnector'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    viewSource: any;
    bindingTargets: any[];
    bindingAccepts: string;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onNodeAppUpdate?: (nodeApp: any) => void;
    onViewTargetOf?: (nodeApp: any) => void;
    onRenameApp: (app: any) => void;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
    
}

export const IRHumanSensorNodeCell = (props: Props) => {

    const { node } = props;
    const [sourceDisabled, setSourceDisabled] = useState(false);
    const [sourceSelected, setSourceSelected] = useState(false);

    const [targetDisabled, setTargetDisabled] = useState(true);
    const [targetSelected, setTargetSelected] = useState(false);

    useEffect(() => {
        const app = node.apps[0];
        if(props.bindMode === "wait") {
            setSourceSelected(false);
            setSourceDisabled(false);
            setTargetDisabled(true);

        }else if(props.bindMode === "targets" || props.bindMode === "view") {
            if(app.id !== (props.bindMode === "targets" ? props.bindingSource : props.viewSource)?.id) {
                setSourceDisabled(true);
            }else {
                setSourceDisabled(false);
                setSourceSelected(true);
            }

            if(app.key === props.bindingAccepts) {   
                setTargetDisabled(false);
            }else {
                setTargetDisabled(true);
            }
            if(props.bindingTargets.length > 0 && props.bindingTargets[0].type === "ir-sensor") {
                if(app.id === props.bindingTargets[0].target.id) {
                    setTargetSelected(true);
                    return ;
                }
            }
            setTargetSelected(false);
            
        }
    }, [props.bindingSource, props.bindingTargets, props.node, props.bindMode]);



    return (
        <div className="node-cell plug_in-card-power-node-cell">
            <div className="only-connector">
                {
                    !sourceDisabled && (

                        <SourceConnector
                        selected={sourceSelected}
                        disabled={sourceDisabled}
                        connected={node.apps[0].isSource}
                        onClick={() => {
                            if (node.apps[0].isSource) {
                                if (props.bindMode === "view") {
                                    if (typeof props.onCancelView === 'function') {
                                        props.onCancelView();
                                    }
                                } else if (props.bindMode === "wait") {
                                    if (typeof props.onViewBindRelations === 'function') {
                                        if(node.apps[0].key === "sub_ir_human_sensor") {
                                            props.onViewBindRelations(node.apps[0], "general_ir_human_sensor");
                                        }else {
                                            props.onViewBindRelations(node.apps[0], "not general_ir_human_sensor")
                                        }
                                    }
                                }
                            }else {
                            if (props.bindMode === "wait") {
                                if (typeof props.onSetBindingSource === 'function') {
                                    if(node.apps[0].key === "sub_ir_human_sensor") {
                                        props.onSetBindingSource(node.apps[0], "general_ir_human_sensor");
                                    }else {
                                        props.onSetBindingSource(node.apps[0], "not general_ir_human_sensor")
                                    }
                                }
                            }else if(props.bindMode === "targets") {
                                if(typeof props.onResetBindingSource === "function") {
                                    props.onResetBindingSource();
                                }
                            }
                        }
                        }}
                    />
                    )
                }
                {
                    node.apps[0].key === "general_ir_human_sensor" && !targetDisabled && (
                        <TargetConnector 
                            disabled={targetDisabled}
                            selected={targetSelected}
                            onClick={(disabled?: boolean, selected?: boolean) => {
                                if(props.bindMode === "targets" || props.bindMode === "view") {
                                    if(!selected) {
                                        if(typeof props.onAddBindingTarget === 'function') {
                                            props.onAddBindingTarget("ir-sensor", node.apps[0]);
                                        }
                                    }else {
                                        if(typeof props.onRemoveBindingTarget === 'function') {
                                            props.onRemoveBindingTarget("ir-sensor", node.apps[0]);
                                        }
                                    }
                                }
                            }}
                        />
                    )
                }
            </div>
            <div className="prod-name">
            {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )
}