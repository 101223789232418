import { ClockCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd";
import { useEffect, useState } from "react"
import { sleep } from "../../../../../../utils";
import { apis } from "../../../../../../utils/apis";
import { request } from "../../../../../../utils/request";

interface NetJoinProps {
    caseId?: number;
    onOk: (netId: number) => void;
}

export const NetJoin = (props: NetJoinProps) => {

    const [netTimes, setNetTimes] = useState(1);
    
    useEffect(() => {
        if(!props.caseId) {
            return ;
        }
        reqNet(props.caseId);
    }, [props.caseId, netTimes]);

    const reqNet = async (caseId: number) => {
        const res = await request.post(apis.caseNet + `/join`, { caseId }, { autoErrorTip: false });
        if(res.code === 1) {
            if(!res.data) {
                await sleep(1000);
                setNetTimes(netTimes + 1);
            }else {
                props.onOk(res.data);
            }
        }else {
             Modal.confirm({
                title: "出现异常",
                centered: true,
                content: res.msg,
                okText: "重新尝试",
                onOk: () => {
                    setNetTimes(netTimes + 1);
                },
                cancelText: "取消",
            });
        }
    }

    return (
        <div className="flex center column" style={{height: 'calc(100vh - 300px)'}}>
            <ClockCircleOutlined spin className="" style={{fontSize: 128, color: "lightblue" }}/>
            <span className="bold" style={{fontSize: 32, marginTop: 20, color: "lightblue"}}>先将网关等产品<span style={{color: "purple"}}>通电</span>, 然后给网关<span style={{color: "purple"}}>配网</span></span>
            <div style={{minHeight: 30, color: "lightblue", width: 600, display: 'flex', flexWrap: 'wrap'}}>
            {
                new Array(netTimes).fill(0).map((item) => {
                    return (
                        <span> ● </span>
                    )
                })
            }
            </div>
        </div>
    )

}