import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { apis } from "../../../../../utils/apis";
import { request } from "../../../../../utils/request";

import { Sider } from '../../../../../components/Sider';
import { Menu, Skeleton, Tag } from "antd";
import { RightOutlined } from "@ant-design/icons";

interface Props {
    customerId: number,
    onCustomerChange: (customer: any) => void,
    simple?: boolean
}
/**
 * 客户列表V2组件， 仅做客户及客户分类的展示
 * @param props 
 */
const CustomerList = forwardRef((props: Readonly<Props>, ref: any) => {

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([] as any[]);
    const [selectedCustomerType, setSelectedCustomerType] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(props.customerId || 0);
    const [sourceCustomers, setSourceCustomers] = useState([] as any[]);
    // const 
    useEffect(() => {
        init();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            add: (customer: any) => {
                customers.unshift(customer);
                setSourceCustomers([...customers]);
                setSelectedCustomerId(customer.id);
            },
            setSelectedCustomerType
        }
    });

    const init = async () => {
        try {
            setLoading(true);
            const res = await request.get(apis.customer);
            if (res.code === 1) {
                setSourceCustomers(res.data);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log("类型", selectedCustomerType);
        
        if(!selectedCustomerType) {
            setCustomers(sourceCustomers);
        }else {
            const _customers = sourceCustomers.filter((c) => c.type === selectedCustomerType);
            setCustomers(_customers);
        }
    }, [sourceCustomers, selectedCustomerType]);


    useEffect(() => {
        if(customers.length === 0) {
            return ;
        }
        let customer = customers.find((c) => c.id === selectedCustomerId);
        if(!customer) {
            customer = customers[0];
        }
        props.onCustomerChange(customer);
    }, [selectedCustomerId, customers]);


    useEffect(() => {//外部url的caseId参数变化后, 被选择的caseId也应该随之变化
        if(props.customerId > 0) {
            setSelectedCustomerId(props.customerId);
        }
    }, [props.customerId]);
    
    return (
        <div className="no-scroll-bar" style={{padding: 10, width: props.simple ? 200 : 220, height: "calc(100vh - 100px)", overflowY: 'scroll'}}>
            {
                customers.map((customer, index) => {
                    return (
                        <div 
                            key={"customer-" + customer.id}
                            className={`bg-white customer-item ${customer.id === Number(props.customerId) ? 'active' : ''}`}
                            style={{marginBottom: 10, padding: 10}}
                            onClick={() => {
                                setSelectedCustomerId(customer.id)
                            }}
                        >
                            <div className="flex" style={{alignItems: 'center'}}>
                                <div className="bold" style={{fontSize: 14, position: 'relative'}}>
                                    <Tag style={{transform: 'scale(0.7)', position: 'absolute', left: -10, top: 3 }} color="blue">{customer.type}</Tag> 
                                    <span style={{marginLeft: 30}}>{customer.name}</span>
                                    
                                </div>
                                
                            </div>
                            {
                                !props.simple && (
                                    <div>
                                        <div style={{marginTop: 5}}>地址：{customer.address}</div>
                                        {/* <div className="flex" style={{marginTop: 5}}>
                                            <div style={{flex: 1}}>方案数：<span className="bold" style={{color: "#000"}}>10</span></div>
                                            <div style={{flex: 1}}>已完成：<span className="bold" style={{color: "#000"}}>5</span></div>
                                        </div>
                                        <div className="flex" style={{marginTop: 5}}>
                                            <div style={{flex: 1}}>创建人：<span className="bold" style={{color: "#000"}}>徐斌</span></div>
                                            <div style={{flex: 1}}> 业务员：<span className="bold" style={{color: "#000"}}>徐斌</span></div>
                                        </div> */}
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
})

export default CustomerList;