
import { Modal } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {  CaseManager } from '../../../../CaseManager';
interface Props {
    template: any,
    onCancel: () => void,
}

export const CaseView = (props: Props) => {

    // const [_case, setCase] = useState(null);
    // const [loading, setLoading] = useState(false);
    
    // useEffect(() => {

    // }, [props.template])


    return (

        <div className="case-view">
            <Modal
                visible={props.template ? true : false}
                centered
                destroyOnClose
                width={window.innerWidth - 100}
                onCancel={props.onCancel}
                footer={[]}
                bodyStyle={{height: window.innerHeight * 0.8 > 600 ? window.innerHeight * 0.8 : 600}}
            >
                {/* @ts-ignore */}
                <CaseManager caseId={props.template?.templateCaseId || props.template?.id} collapsed={true} setCollapsed={() => {}}/>
            </Modal>
        </div>
    )
}