import { Layout, Dropdown, Skeleton, Spin, Empty, Menu } from "antd"
import { useEffect, useState } from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import HexManagerPage from "../HexManager/index2";
import { BarsOutlined, CodeOutlined, EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { ProjectCreateModal } from './components/ProjectCreateModal';
import { request } from "../../../utils/request";
import { apis } from "../../../utils/apis";
import { CatesManager } from "./components/CatesManager";

// import './style.less';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

interface Props extends RouteComponentProps {

}

type MenuItem = {
    key: string,
    title?: string,
    subMenus?: MenuItem[]
}

const HomeFileManagerPage = (props: Props) => {

    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [menus, setMenus] = useState<MenuItem[]>([]);
    const [projectCates, setProjectCates] = useState<any>([]);
    const [projectCreateModalVisible, setProjectCreateModalVisible] = useState(false);
    const [openedSubMenus, setOpenedSubMenus] = useState<string[]>([]);
    const [selectedKeys, setSelectKeys] = useState<string[]>([]);
    const [allProjectKeys, setAllProjectKeys] = useState<string[]>([]);

    const openProjectCreateModal = () => {
        setProjectCreateModalVisible(true);
    }

    const closeProjectCreateModal = () => {
        setProjectCreateModalVisible(false);
    }

    useEffect(() => {
        onRefresh();
    }, []);

    useEffect(() => {
        if (selectedKeys[0] !== props.location.pathname) {
            props.history.push(selectedKeys[0]);
        }
    }, [selectedKeys]);

    const onRefresh = () => {

        request.get(apis.hexProject)
            .then((res) => {
                const projects = res.data;
                initMenu(projects);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const initMenu = (projects: any[]) => {
        let menus: MenuItem[] = [];
        let cates: any[] = [];
        for (let i = 0; i < projects.length; i++) {
            const temp = projects[i];
            if ("cateId" in temp) {
                const projectKey = "/product/hex-file/" + temp.projectId;
                const cateKey = "cate-" + temp.cateId;
                let cateTemp = menus.find((menu) => menu.key === cateKey);
                if (!cateTemp) {
                    cateTemp = {
                        key: cateKey,
                        title: temp.cateName,
                        subMenus: []
                    };
                    menus.push(cateTemp);
                    cates.push({
                        id: temp.cateId,
                        name: temp.cateName
                    });
                }
                if ("projectId" in temp) {
                    const projectTemp: MenuItem = {
                        key: projectKey,
                        title: temp.projectName
                    };
                    if (cateTemp.subMenus instanceof Array) {
                        cateTemp.subMenus.push(projectTemp);
                    }
                    allProjectKeys.push(projectKey);
                }
            } else {
                const projectKey = "/product/hex-file/" + temp.projectId;
                menus.push({
                    key: projectKey,
                    title: temp.projectName,
                });
                allProjectKeys.push(projectKey);
            }
        }
        menus = menus.sort((a, b) => {
            return a.key > b.key ? 1 : -1;
        }).sort((a, b) => {
            if (a.key.indexOf("cate") === 0 && b.key.indexOf("cate") === -1) {
                return -1;
            }
            return 1;
        });
        setMenus(menus);
        setProjectCates(cates);
        //展开子菜单
        const subMenuKeys = menus.filter((menu) => menu.key.indexOf("cate") > -1).map((menu) => menu.key);
        setOpenedSubMenus(subMenuKeys);
        if (allProjectKeys.includes(props.location.pathname)) {
            setSelectKeys([props.location.pathname]);
        }
    }

    const [catesManagerVisible, setCatesManagerVisible] = useState(false);
    const openCatesManager = () => {
        setCatesManagerVisible(true);
    }
    const closeCatesManager = () => {
        setCatesManagerVisible(false);
    }


    const onProjectCateCreate = (cate: any) => {
        projectCates.push(cate);
        setProjectCates([...projectCates]);
        //更新菜单
        const notCateMenuIndex = menus.findIndex((menu) => menu.key.indexOf("cate") === -1);
        const cateKey = "cate-" + cate.id;
       
        if (notCateMenuIndex > -1) {
            menus.push( null as any );
            for (let i = menus.length - 1; i >= notCateMenuIndex + 1; i--) {
                menus[i] = menus[i - 1];
            }
            menus[notCateMenuIndex] = {
                key: cateKey,
                title: cate.name,
                subMenus: []
            }
        } else {
            //无分类 但有不分类的元素
            menus.push({
                key: cateKey,
                title: cate.name,
                subMenus: []
            });
        }
        setMenus([...menus]);
        //展开刚刚创建的子菜单
        openedSubMenus.push(cateKey);
        setOpenedSubMenus([...openedSubMenus]);
        
    }


    if (loading) {
        return (
            <Skeleton />
        )
    }
    return (
        <div style={{height: 'calc(100vh - 40px)'}}>
            <CatesManager visible={catesManagerVisible} onRefresh={onRefresh} onCancel={closeCatesManager} onOk={closeCatesManager} />
            <Layout style={{height: '100%'}}>
                <Sider theme="light"  style={{width: 'calc(100vw - 400px)', background: "#fff", overflowY: 'scroll'}}>
                    <div className="title">
                        <span>HEX项目管理</span>
                        <div>
                            <PlusOutlined title="添加项目" onClick={openProjectCreateModal} style={{ marginRight: 8 }} />
                            <Dropdown trigger={["click"]} overlay={(
                                <Menu>
                                    <Menu.Item onClick={openCatesManager}>管理分类</Menu.Item>
                                </Menu>
                            )}>
                                <EllipsisOutlined />
                            </Dropdown>
                        </div>
                    </div>
                    <div className="menu-wrapper">
                        <Menu mode="inline" openKeys={openedSubMenus} selectedKeys={selectedKeys} onOpenChange={(openKeys) => {
                            setOpenedSubMenus(openKeys as string[]);
                        }}>
                            {
                                menus.length > 0 && menus.map((menu) => {
                                    if (menu.subMenus instanceof Array) {
                                        return (
                                            <SubMenu key={menu.key} icon={(<BarsOutlined />)} title={menu.title}>
                                                {
                                                    menu.subMenus.length > 0 && menu.subMenus.map((menu) => {
                                                        return (
                                                            <Menu.Item 
                                                                icon={(<CodeOutlined />)} 
                                                                key={menu.key} 
                                                                onClick={() => setSelectKeys([menu.key])}
                                                                title={menu.title}
                                                            >{menu.title}</Menu.Item>
                                                        )
                                                    })
                                                }
                                            </SubMenu>
                                        )
                                    } else {
                                        return (
                                            <Menu.Item key={menu.key} icon={(<CodeOutlined />)} onClick={() => setSelectKeys([menu.key])}>
                                                {menu.title}
                                            </Menu.Item>
                                        )
                                    }
                                })
                            }
                        </Menu>
                    </div>

                    {/* <div className="footer">
                        <div className="sys-version">HEX管理子平台 V1.0</div>
                    </div> */}
                </Sider>
                <Spin spinning={refreshing} tip="项目内容加载中...">
                    <Content style={{ padding: 0, paddingRight: 0, width: 'calc(100vw - 400px)', height: 'calc(100vh - 40px)', overflowY: 'scroll' }}>
                        <Switch>
                            <Route path="/product/hex-file/:fileId" render={(props) => (
                                <HexManagerPage 
                                    {...props} 
                                    projectCates={projectCates} 
                                    onProjectCateCreate={onProjectCateCreate} 
                                    projectKey={selectedKeys[0]} 
                                    setLoading={setLoading} 
                                    setRefreshing={setRefreshing} 
                                    onOpenProjectCreateModal={openProjectCreateModal} 
                                    refreshing={refreshing} 
                                    onRefresh={onRefresh} 
                                />
                            )} />
                            <Route path="*" render={() => {
                                return (
                                    <div className="flex center" style={{ height: 'calc(100vh - 40px)'}}>
                                        <Empty description={(
                                            <span>请<a onClick={openProjectCreateModal}>添加项目</a>，或在左侧选择要查看的项目</span>
                                        )} />
                                    </div>
                                )
                            }} />
                        </Switch>

                    </Content>
                </Spin>

            </Layout>
            <ProjectCreateModal
                visible={projectCreateModalVisible}
                onCancel={closeProjectCreateModal}
                projectCates={projectCates}

                onProjectCateCreate={onProjectCateCreate}
                onProjectCreate={(project) => {
                    closeProjectCreateModal();
                    //更新左侧菜单
                    const projectKey = '/hex/' + project.id;
                    if (project.cateId === 0) {
                        menus.push({
                            key: projectKey,
                            title: project.name
                        });
                    } else {
                        const menu = menus.find((m) => m.key === "cate-" + project.cateId);
                        if (menu && (menu.subMenus instanceof Array)) {
                            menu.subMenus.push({
                                key: projectKey,
                                title: project.name
                            });
                        }
                    }
                    //聚焦到新建的项目
                    allProjectKeys.push(projectKey);
                    setSelectKeys([projectKey]);
                }}
            />
        </div>
    )
}

export default HomeFileManagerPage;