import ElectronStore from "electron-store";
import electron from "electron";
import { ChildProcessWithoutNullStreams, SpawnOptionsWithoutStdio } from "child_process";
import { Options } from "iconv-lite";
import { MakeDirectoryOptions, PathLike } from "fs";
import { extract } from "tar";
import stream from "stream";
import _fs from 'fs';


export const app: electron.App = (window as any).electron?.app;
export const ipcRenderer: electron.IpcRenderer = (window as any).electron?.ipcRenderer;

export const store: ElectronStore = (window as any).store ? new (window as any).store() : null;


export const spawn: (
    (command: string, args?: ReadonlyArray<string>, options?: SpawnOptionsWithoutStdio) => ChildProcessWithoutNullStreams 
) = (window as any).spawn;

export const iconv: {
    decode: (buffer: Buffer, encoding: string, options?: Options) => string;

	encode: (content: string, encoding: string, options?: Options) => Buffer;
} = (window as any).iconv;

export const existsSync: (
    (path: PathLike) => boolean
) = (window as any).fs?.existsSync;


export const mkdirSync: (
    (path: PathLike, options?: number | string | MakeDirectoryOptions | null) => void
) = (window as any).fs?.mkdirSync;


// type TarExtract = (
//     options: ExtractOptions,
//     fileList?: ReadonlyArray<string>,
//     callback?: (err?: Error) => void,
// ) => stream.Writable;
export const tarExtra: typeof extract= (window as any).tar?.x;
export const fs: typeof _fs = (window as any).fs;