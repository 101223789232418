import React from 'react'

import { CaseDuerosCuidConfig } from './CaseDuerosCuidConfig';

interface Props {
    case: any
}

const extraConfigs = [{
    key: "dueros-speaker-cuid",
    name: "小度音箱cuid配置"
}]

export const CaseExtraConfig = (props: Props) => {

    return (
        <div>
            {
                extraConfigs.map((item) => {
                    return (
                        <div>
                            <div className="" style={{fontSize: 16, marginBottom: 10}}>{item.name}</div>
                            {(() => {
                                let Comp: any;
                                switch(item.key) {
                                    case "dueros-speaker-cuid":
                                        Comp = CaseDuerosCuidConfig;
                                        break;
                                }
                                if(Comp) {
                                    return <Comp caseId={props.case?.caseId}/>
                                }
                            })()}
                        </div>
                    )
                })
            }
        </div>
    )

}