import { CloseOutlined, DeleteOutlined, EditOutlined, FieldNumberOutlined, NodeIndexOutlined, NumberOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Skeleton, Spin } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { TemplateComponent } from "../../../#case-manager/components/CaseNodeCell/template";
import { AreaSelector } from "../../../#case-manager/Home/components/CaseView";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";
import { AreaAddModal } from "../AreaLayout";
import { SmartProduct } from "./SmartProduct";
// import './style.less';

interface Props {
    case?: any;
    area?: any;
    onEdit?: () => void;
    onCanAddNode: () => void;
    onDisableAddNode: () => void;
    onSmartsChange: (smarts: any[]) => void;
}

const SmartProducts = forwardRef((props: Props, ref: any) => {

    const [smarts, setSmarts] = useState([] as any[]);
    const [loading, setLoading] = useState(true);
    const [refs] = useState({} as any);
    const [areas, setAreas] = useState([] as any[]);
    const [selectedAreaId, setSelectedAreaId] = useState(0);
    const [noAreaSmarts, setNoAreaSmarts] = useState([] as any[]);


    //节点修改区域
    const [areaSelectorVisible, setAreaSelectorVisible] = useState(false);
    const [nodeTemp, setNodeTemp] = useState(null);
    const [areaSelectorLoading, setAreaSelectorLoading] = useState(false);
    const openAreaSelector = (node: any) => {
        setAreaSelectorVisible(true);
        setNodeTemp(node);
    }
    const closeAreaSelector = () => {
        setAreaSelectorVisible(false);
        setNodeTemp(null);
    }

    //区域修改名称
    
    const [areaUpdateVisible, setAreaUpdateVisible] = useState(false);
    const [areaNameTemp, setAreaNameTemp] = useState("");
    const [areaIdTemp, setAreaIdTemp] = useState(0);
    const updateFormRef = useRef<any>();
    const openAreaUpdateModal = (area: any) => {
        setAreaUpdateVisible(true);
        setAreaNameTemp(area.name);
        setAreaIdTemp(area.id);
    }
    const closeAreaUpdateModal = () => {
        setAreaUpdateVisible(false);
        setAreaNameTemp("");
        setAreaIdTemp(0);
    }


    useEffect(() => {
        if (props.case) {
            getData(props.case.caseId);
        } else {
            if (smarts.length !== 0) {
                setSmarts([]);
            }
            if (areas.length !== 0) {
                setAreas([]);
            }
        }
    }, [props.case]);

    const getData = async (caseId: number) => {
        setLoading(true);
        const [smarts, areas] = await Promise.all([
            request.get(apis.case + "/node/list", {
                caseId: caseId
            })
                .then((res) => {
                    if (res.code === 1) {
                        for (let i = 0; i < res.data.length; i++) {
                            const item = res.data[i];
                            item.create = true;
                            item.index = i;
                        }
                        return res.data;
                    }
                }),
            request.get(apis.area, {
                caseId
            })
                .then((res) => {
                    if (res.code === 1) {
                        return res.data;
                    }
                })
        ]);
        setSmarts(smarts);
        setAreas(areas);
        setLoading(false);
    }

    useEffect(() => {
        if (loading) {
            return;
        }
        if (areas.length === 0) {
            return;
        }
        if (areas.length === 0) {
            // console.log();
            areaAddRef.current?.open(props.case?.caseId, "请先添加第一个区域");
        }
    }, [areas, loading]);


    useEffect(() => {
        if (loading) {
            return;
        }
        const noAreaNodes = smarts.filter((s) => s.areaId === -1);
        setNoAreaSmarts(noAreaNodes);
    }, [smarts, loading]);


    useEffect(() => {
        if (!selectedAreaId && areas.length > 0) {
            setSelectedAreaId(areas[0].id);
        }
    }, [areas]);

    useEffect(() => {
        if (selectedAreaId > 0) {
            props.onCanAddNode();
        } else {
            props.onDisableAddNode();
        }
    }, [selectedAreaId]);

    useImperativeHandle(ref, () => {
        return {
            onAdd: (c: any, smart: any) => {
                if (!selectedAreaId) {
                    message.info("未选择区域");
                    return;
                }
                if (smart.spu.gatewayType !== 0) {
                    let hasGateway = smarts.find((s) => s.spu.gatewayType !== 0);
                    if (hasGateway) {
                        message.warn("已存在网关或含网关的产品");
                        return;
                    }
                }
                const area = areas.find((a) => a.id === selectedAreaId);
                if (!area) {
                    message.info("区域不存在");
                    return;
                }
                const _smart = {
                    ...smart,
                    caseId: c.caseId,
                    areaId: selectedAreaId,
                    areaName: area.name,
                    silkScreens: {},
                    uuid: Date.now(),
                    hexConfig: {
                        endpoints: []
                    },
                    index: smarts.length === 0 ? 0 : smarts[smarts.length - 1].index + 1,
                }
                smarts.push(_smart);
                setSmarts([...smarts]);
            },

        }
    });

    useEffect(() => {
        props.onSmartsChange(smarts);
    }, [smarts]);

    const areaAddRef = useRef<any>();
    
    const onStartBind = (node: any, app: any, bindType: any) => {
        for (const index in refs) {
            const ref = refs[index];
            ref.startBind(
                {
                    caseNodeId: node.id,
                    endpoint: app.endpoint
                },
                bindType
            )
        }
    }

    return (
        <div className="flex" style={{ flexWrap: 'wrap', padding: '0 8px' }}>
            <AreaAddModal
                ref={areaAddRef}
                onAreaAdd={(area) => {
                    areas.push(area);
                    setAreas([...areas]);
                    setSelectedAreaId(area.id);
                }}
            />
            <AreaSelector
                areas={areas}
                visible={areaSelectorVisible}
                loading={areaSelectorLoading}
                onAreaSelected={(area) => {
                    setAreaSelectorLoading(true);
                    request.put(apis.caseNode + `/${nodeTemp.id}`, { areaId: area.id })
                        .then((res) => {
                            if (res.code === 1) {
                                const smart = smarts.find((s) => s.id === nodeTemp.id);
                                smart.areaId = area.id;
                                smart.areaName = area.name;
                                setSmarts([...smarts]);
                            }
                        })
                        .finally(() => {
                            setAreaSelectorLoading(false);
                        })
                    closeAreaSelector();
                }}
                onCancel={closeAreaSelector}
                selectedAreaId={nodeTemp?.areaId}
            />

            <Modal
                centered
                title='修改区域名称'
                visible={areaUpdateVisible}
                onOk={() => {
                    updateFormRef.current?.submit();
                }}
                onCancel={closeAreaUpdateModal}
                width={300}
                destroyOnClose
            >
                <Form
                    ref={updateFormRef}
                    initialValues={{
                        id: areaIdTemp,
                        name: areaNameTemp
                    }}
                    onFinish={(data) => {
                        request.put(apis.area + `/${data.id}`, {
                            name: data.name,
                        })
                            .then((res) => {
                                if (res.code === 1) {
                                    const area = areas.find((a) => a.id === data.id);
                                    area.name = data.name;
                                    for (let i = 0; i < smarts.length; i++) {
                                        const smart = smarts[i];
                                        if(smart.areaId === data.id) {
                                            smart.areaName = data.name
                                        }
                                    }
                                    setSmarts([...smarts]);
                                    setAreas([...areas]);
                                    closeAreaUpdateModal();
                                }
                            })
                            .finally(() => {

                            })
                    }}
                >
                    <Form.Item hidden name="id"></Form.Item>
                    <Form.Item label="区域名称" name={"name"} rules={[{ required: true, message: "请输入区域名称" }]}>
                        <Input placeholder="请输入区域名称" autoFocus autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>
            {
                loading ? (
                    <Skeleton active />
                ) : (
                    <div className="flex column" style={{ width: '100%' }}>
                        {
                            areas.map((area, index) => {
                                const _smarts = smarts.filter((s) => s.areaId === area.id);
                                return (
                                    <div onClick={() => {
                                        if (area.id !== selectedAreaId) {
                                            setSelectedAreaId(area.id);
                                        }
                                    }} style={{ border: area.id === selectedAreaId ? "2px solid #1890ff" : "2px solid #ddd", margin: "8px 5px 5px 5px", borderRadius: 3, width: "100%", minHeight: '400px' }}>
                                        <div
                                            className="bold flex"
                                            style={{ justifyContent: 'space-between', fontSize: 18, padding: "8px 8px 5px 8px", color: area.id === selectedAreaId ? "#1890ff" : undefined, cursor: 'pointer' }}

                                        >
                                            <span>

                                                {area.name}
                                                <EditOutlined style={{marginLeft: 8}} onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    openAreaUpdateModal(area);
                                                }}/>
                                                {
                                                    area.id === selectedAreaId ? (
                                                        <CloseOutlined style={{ marginLeft: 10, opacity: 0 }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            setSelectedAreaId(0);
                                                        }} />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </span>
                                            <DeleteOutlined style={{ color: "red" }} onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                Modal.confirm({
                                                    title: "删除区域",
                                                    centered: true,
                                                    content: "请确认是否删除区域, 删除区域不会删除产品",
                                                    onOk: () => {
                                                        request.delete(apis.area + `/${area.id}`, {})
                                                            .then((res) => {
                                                                // console.log();
                                                                if (res.code === 1) {
                                                                    // props.onRemoveCaseArea(res.data);
                                                                    const _areas = areas.filter((a) => {
                                                                        return a.id !== area.id;
                                                                    });
                                                                    if (selectedAreaId === area.id) {
                                                                        setSelectedAreaId(0);
                                                                    }
                                                                    for (let i = 0; i < smarts.length; i++) {
                                                                        const smart = smarts[i];
                                                                        smart.areaId = -1;
                                                                        smart.areaName = null;
                                                                        setSmarts([...smarts])
                                                                    }
                                                                    setAreas(_areas);
                                                                }
                                                            })
                                                    }
                                                })

                                            }} />
                                        </div>
                                        <div className="flex" style={{ flexWrap: 'wrap' }}>
                                            {
                                                _smarts.map((s, idx) => {
                                                    return (
                                                        <SmartProduct
                                                            ref={(ref) => {
                                                                refs[s.index] = ref;
                                                            }}
                                                            key={"spu-" + index}
                                                            onEdit={props.onEdit}
                                                            smart={s}
                                                            onSmartRemove={(smart: any) => {
                                                                const _smarts = smarts.filter((s) => s.id !== smart.id);
                                                                setSmarts(_smarts);
                                                            }}
                                                            onStartBind={(node: any, app: any, bindType: any) => {
                                                                for (const index in refs) {
                                                                    const ref = refs[index];
                                                                    ref.startBind(
                                                                        {
                                                                            caseNodeId: node.id,
                                                                            endpoint: app.endpoint
                                                                        },
                                                                        bindType
                                                                    )
                                                                }
                                                            }}
                                                            onSelectArea={() => {
                                                                openAreaSelector(s);
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div style={{ textAlign: 'left', marginTop: 10 }}>
                            <Button type="link" style={{ fontSize: 18 }} onClick={(() => {
                                areaAddRef.current?.open(props.case?.caseId, areas.length === 0 ? '请先添加第一个区域' : '');
                            })}>添加区域 <PlusOutlined /> </Button>
                        </div>
                        {
                            noAreaSmarts.length > 0 && (
                                <div style={{ backgroundColor: "rgba(255, 192, 203, .2)", marginTop: 30, padding: '10px' }}>

                                    <div style={{ padding: '5px 5px', fontSize: 16, color: 'orangered' }}>未设置区域</div>
                                    <div className="flex" style={{ padding: '5px 0 10px 0', flexWrap: 'wrap' }}>

                                        {
                                            noAreaSmarts.map((smart, index) => {
                                                return (
                                                    <SmartProduct
                                                        ref={(ref) => {
                                                            refs[smart.index] = ref;
                                                        }}
                                                        key={"spu-" + index}
                                                        onEdit={props.onEdit}
                                                        smart={smart}
                                                        onSmartRemove={(smart: any) => {
                                                            const _smarts = smarts.filter((s) => s.id !== smart.id);
                                                            setSmarts(_smarts);
                                                        }}
                                                        onStartBind={onStartBind}
                                                        onSelectArea={() => {
                                                                openAreaSelector(smart);
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
});

export default SmartProducts;
