import { EditOutlined, EllipsisOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Form, Input, Menu, message, PageHeader, Table, Tag } from "antd";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import DoubleClickToCopy from "../../../components/DoubleClickToCopy";
import ModalForm from "../../../components/ModalForm";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import { AddUserModal } from "./AddUserModal";
import { UpdatePasswordModal } from "./UpdatePassswordModal";
import { UserAppsModal } from "./UserAppsModal";

interface Props extends RouteChildrenProps {

}

export default function UserMgmt(props: Props) {


    const [list, setList] = useState<any[]>();
    useEffect(() => {
        request.get(apis["/upms/user/list"])
            .then((res) => {
                if (res.code === 1) {
                    setList(res.data);
                }
            })
    }, [])


    const [columns] = useMemo(() => {
        const columns: any[]/* table columns */ = [
            {
                key: "id",
                dataIndex: "id",
                align: "center",
                width: 50,
            },
            {
                key: "user",
                title: "用户",
                dataIndex: "id",
                width: 356,
                render: (_, user: any) => {
                    let displayName = user.nickName;
                    if (user.remarkName) {
                        displayName += `(${user.remarkName})`
                    }
                    return (
                        <>
                            <div className="flex" style={{alignItems: 'center'}}>
                                <Avatar src={user.avatar} size={30} icon={<UserOutlined />} style={{marginRight: 12}}/>
                                <div>

                                    <div>
                                        {displayName}
                                        <Button type="link" title="编辑备注">
                                            <EditOutlined />
                                        </Button>
                                        {
                                            user.isNew && (
                                                <Tag>刚刚添加</Tag>
                                            )
                                        }
                                    </div>
                                    <DoubleClickToCopy style={{ color: "lightgray" }}>{user.uuid}</DoubleClickToCopy>
                                </div>
                            </div>
                        </>
                    )
                }
            },
            {
                key: "phoneNumber",
                title: "手机号",
                dataIndex: "phoneNumber",
                width: 200,
            },
            {
                key: "email",
                title: "邮箱",
                dataIndex: "email",
                width: 200,
                render(email: string) {
                    return (
                        <>{email || (<span style={{ color: "lightgrey" }}>暂未设置邮箱</span>)}</>
                    )
                }
            },
            {
                key: "actions",
                render: (_, user: any) => {
                    return (
                        <>
                            <Button type="link" disabled>编辑</Button>
                            <Dropdown trigger={["click"]} overlay={(
                                <Menu>
                                    <Menu.Item onClick={() => {
                                        //查看用户关于
                                        viewUserAppsRef.current?.open(user);
                                    }}>查看用户应用及角色</Menu.Item>
                                    <Menu.Item danger onClick={() => {
                                        //冻结用户
                                        updatePasswordRef.current?.open({
                                            uuid: user.uuid
                                        });
                                    }}>修改密码</Menu.Item>
                                    <Menu.Item danger onClick={() => {
                                        //冻结用户
                                    }}>冻结该用户</Menu.Item>
                                </Menu>
                            )}>
                                <Button type="link">
                                    更多
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        </>
                    )
                },
            }
        ]
        return [columns]
    }, []);

    const addUserRef = useRef<any>();
    const updatePasswordRef = useRef<any>();
    const viewUserAppsRef = useRef<any>();

    return (
        <>

            <AddUserModal
                ref={addUserRef}
                onUserAdd={(data) => {
                    data.isNew = true;
                    list.unshift(data);
                    setList([...list])
                }}
            />
            <UpdatePasswordModal
                ref={updatePasswordRef}
                onPasswordUpdated={() => {
                }}
            />
            <UserAppsModal
                ref={viewUserAppsRef}

            />
            <PageHeader
                title="用户管理"
                extra={(
                    <Button type="primary" ghost onClick={() => {
                        addUserRef.current?.open();
                    }}>
                        <PlusOutlined />
                        添加用户
                    </Button>
                )}
            >
                <Table
                    pagination={false}
                    size="small"
                    columns={columns}
                    dataSource={list}
                />
            </PageHeader>
        </>
    )

}


