import { BranchesOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import './style.less';

interface ConnectorProps {
    type: "source" | "target"
    checked?: boolean;
    endpoint: any;
    nameDisable?: boolean;
    onConnectorClick?: () => void;
    onControlLineClick?: () => void;
    onEndpointNameChange?: (name: string) => void;
}

const Connector = (props: ConnectorProps) => {

    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        if (props.endpoint.disabled) {
            setOpacity(0.5)
        } else {
            setOpacity(1);
        }
    }, [props.endpoint]);

    const inputRef = useRef<any>();

    const [mode, setMode] = useState<"view" | "edit">("view");
    const [temp, setTemp] = useState("");

    const startEdit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setTemp(props.endpoint?.name);
        setMode("edit");
    }

    const endEdit = () => {
        if (temp === props.endpoint?.name) {
            setMode("view");
            return;
        }
        props.onEndpointNameChange && props.onEndpointNameChange(temp);
        setMode("view");
        setTemp("");
    }
    return (
        <>

            {
                Boolean(props.endpoint.isWireless) && (
                    <>

                        <Tooltip overlay={(
                            props.endpoint.isWireless > 0 ? '不接控制线' : '接控制线'
                        )}>
                            <div
                                className={`${props.endpoint.isWireless > 0 ? 'wireless' : 'not-wireless'}`}
                                style={{ margin: '0 5px' }}
                                onClick={props.onControlLineClick}
                            >
                                控
                            </div>
                        </Tooltip>
                    </>
                )
            }
            <Tooltip overlay={(
                "Endpoint: " + props.endpoint.endpoint
            )}>
                <div
                    className={`relative connector ${props.type} ${props.checked ? 'checked' : ''}`}
                    style={{ width: 15, height: 15, borderRadius: 15, opacity, cursor: "pointer" }}
                    onClick={props.onConnectorClick}
                >
                    {
                        props.type === 'target' && props.nameDisable !== true && (mode === 'edit' ? (
                            <input
                                ref={inputRef}
                                placeholder='所控名称'
                                value={temp}
                                onBlur={endEdit}
                                autoFocus
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onChange={(e) => {
                                    setTemp(e.target.value);
                                }}
                                className='absolute placeholder-white'
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        endEdit();
                                    }
                                }}
                                style={{
                                    height: 16,
                                    padding: 0,
                                    backgroundColor: "rgba(0, 0, 0, .5)",
                                    color: "#eee",
                                    margin: 0,
                                    outline: "none",
                                    border: 0,
                                    width: 72,
                                    lineHeight: "16px",
                                    textAlign: 'center',
                                    fontSize: 16,
                                    fontFamily: "隶书",
                                    position: "absolute",
                                    left: -30,
                                    top: 15
                                }}

                            />
                        ) : (
                            <div className='absolute' style={{
                                width: 72,
                                backgroundColor: "rgba(0, 0, 0, .4)",
                                left: -30,
                                textAlign: 'center',
                                top: 15,
                                cursor: "pointer", 
                                fontSize: 16, 
                                color: '#eee',
                                lineHeight: "16px", fontFamily: '隶书', whiteSpace: "nowrap", textOverflow: "ellipsis" }} onClick={startEdit}>{props.endpoint?.name}</div>
                        ))
                    }
                </div>
            </Tooltip>
        </>
    )
}

export default Connector;


interface ControlWidgetProps {
    source: any;
    target: any;
    nameDisable?: boolean;  //是否禁止编辑名字
    sourceChecked?: boolean;
    targetChecked?: boolean;
    sourceHide?: boolean;
    targetHide?: boolean;
    sourceEndpoint?: number;
    targetEndpoints?: number[];
    targetOnly?: boolean;
    onSourceClick?: (endpoint: any) => void;
    onTargetClick?: (endpoint: any) => void;
    onControlLineClick?: (endpoint: any) => void;
    onEndpointNameChange?: (name: string, endpoint: any) => void;
}

export const ControlWidget = (props: ControlWidgetProps) => {
    // console.log(props);

    useEffect(() => {
        // console.log(props.targetEndpoints);
        // console.log(props.targetEndpoints?.includes(props.target.endpoint));


    }, [props.targetEndpoints])

    return (
        <div className="flex center" style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            {
                !props.sourceHide && props.source && !props.targetOnly && (
                    <Connector
                        type="source"
                        checked={props.sourceChecked}
                        endpoint={props.source}
                        nameDisable={props.nameDisable}
                        onConnectorClick={() => {
                            props.onSourceClick && props.onSourceClick(props.source)
                        }}
                        onControlLineClick={() => {
                            props.onControlLineClick && props.onControlLineClick(props.source)
                        }}
                    />
                )
            }
            {
                !props.targetHide && props.target && (
                    <Connector
                        type="target"
                        endpoint={props.target}
                        checked={props.targetChecked}
                        nameDisable={props.nameDisable}
                        onConnectorClick={() => {
                            props.onTargetClick && props.onTargetClick(props.target)
                        }}
                        onControlLineClick={() => {
                            props.onControlLineClick && props.onControlLineClick(props.target)
                        }}
                        onEndpointNameChange={(name: string) => {
                            props.onEndpointNameChange && props.onEndpointNameChange(name, props.target)
                        }}
                    />
                )
            }
        </div>
    )
}

const layouts = ["1-bottom", "2-bottom|top", "2-bottom|bottom", "3-bottom|bottom|bottom", "4-left-top|bottom|right-bottom|top", "6-bottom|bottom|bottom-top|top|top"] as const;
interface ControlLayoutProps {
    // layout?: "1-bottom"
    // | "2-bottom|top"
    // | "2-bottom|bottom"
    // | "3-bottom|bottom|bottom"
    // | "4-left-top|bottom|right-bottom|top"
    // | "6-bottom|bottom|bottom-top|top|top",
    layout?: typeof layouts[number];
    children?: ReactNode | ReactNode[]
}
export const ControlLayout = (props: ControlLayoutProps) => {

    const [isArray, setIsArray] = useState(props.children instanceof Array);
    const [layout, setLayout] = useState(props.layout);

    useEffect(() => {
        setIsArray(props.children instanceof Array);
    }, [props.children]);

    useEffect(() => {
        if (props.layout) {
            setLayout(props.layout);
            return;
        }
        if (!props.children) {
            return;
        }
        let indexOf = "1";
        if (props.children instanceof Array && props.children.length > 1) {
            indexOf = props.children.length.toString();
        }
        indexOf += "-";
        const layout: any = layouts.find((l) => l.indexOf(indexOf) === 0);
        setLayout(layout);
    }, [props.layout, props.children]);

    if (!layout) {
        return (
            <></>
        );
    }
    return (
        <div
            className="control-layout flex"
            style={Object.assign({}, { height: '100%' }, {
                '1-bottom': { flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 24 },
                '2-bottom|top': { flexDirection: 'column' }
            }[layout])}
        >
            {
                layout === "1-bottom" && (
                    isArray ? props.children[0] : props.children
                )
            }
            {
                isArray && layout === "2-bottom|top" && (
                    <>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 24 }}>
                            {props.children[1]}
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                            {props.children[0]}
                        </div>
                    </>
                )
            }
            {
                isArray && layout === "2-bottom|bottom" && (
                    <>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                            {props.children[0]}
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                            {props.children[1]}
                        </div>
                    </>
                )
            }
            {
                isArray && layout === "3-bottom|bottom|bottom" && (
                    <>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                            {props.children[0]}
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                            {props.children[1]}
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                            {props.children[2]}
                        </div>
                    </>
                )
            }
            {
                isArray && layout === "4-left-top|bottom|right-bottom|top" && (
                    <>
                        <div className="flex column" style={{ flex: 1 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 26 }}>
                                {props.children[0]}
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                                {props.children[1]}
                            </div>
                        </div>
                        <div className="flex column" style={{ flex: 1 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 26 }}>
                                {props.children[3]}
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                                {props.children[2]}
                            </div>
                        </div>
                    </>
                )
            }
            {
                isArray && layout === "6-bottom|bottom|bottom-top|top|top" && (
                    <>
                        <div className="flex column" style={{ flex: 1 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 26 }}>
                                {props.children[3]}
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                                {props.children[0]}
                            </div>
                        </div>
                        <div className="flex column" style={{ flex: 1 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 26 }}>
                                {props.children[4]}
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                                {props.children[1]}
                            </div>
                        </div>
                        <div className="flex column" style={{ flex: 1 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 26 }}>
                                {props.children[5]}
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 24 }}>
                                {props.children[2]}
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
} 