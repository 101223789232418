import { ControlOutlined, CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SolutionOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Form, Input, Menu, Modal, Skeleton, Tag, message } from "antd";
import { forwardRef, useEffect, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { InfoChannelSelector } from "../../../../../#case-manager/CaseManager/components/CaseEditor/components/InfoChannelSelector";
import { AddCaseFloorModal } from "../../../../../#case-manager/Home/components/HotelCustomer/components/AddCaseFloorModal";
import { AddCaseModal } from "../../../../../#case-manager/Home/components/HotelCustomer/components/AddCaseModal";
import { AddCaseTemplateModal } from "../../../../../#case-manager/Home/components/HotelCustomer/components/AddCaseTemplateModal";
import { HotelDeliverQrcode } from "../../../../../#case-manager/Home/components/HotelCustomer/components/HotelDeliverQrcode";
import { UpdateCaseModal } from "../../../../../#case-manager/Home/components/HotelCustomer/components/UpdateCaseModal";
import { UpdateCaseTemplateModal } from "../../../../../#case-manager/Home/components/HotelCustomer/components/UpdateCaseTemplateModal";
import ModalForm from "../../../../../../components/ModalForm";
import { apis } from "../../../../../../utils/apis";
import { request } from "../../../../../../utils/request";

interface AddCaseProps extends RouteChildrenProps {
    customer: any;
}
const AddCase = (props: AddCaseProps) => {


    if (props.customer?.type === '酒店') {
        return (
            <HotelAddCase
                customer={props.customer}
            />
        )
    } else {
        return (
            <NormalAddCase
                {...props}
                customer={props.customer}
            // caseList={props.c}

            />

        )
    }
};


export default AddCase;


interface HotelAddCaseProps {
    customer: any;
}
const HotelAddCase = (props: HotelAddCaseProps) => {


    // const [customerId, setCustomerId] = useState(0);
    const [customer, setCustomer] = useState((props.customer || {}) as any);
    const [templates, setTemplates] = useState([] as any[]);
    const [curTemplate, setCurTemplate] = useState(null as any);
    const [curCustomer, setCurcustomer] = useState(null as any);
    const [selectedTemplate, setSelectedTemplate] = useState(null as any);
    const [floors, setFloors] = useState([] as any[]);

    const [cases, setCases] = useState([] as any[]);
    const [groupCasesMap, setGroupCasesMap] = useState(new Map<number, any[]>());
    const [curCase, setCurCase] = useState(null as any);
    const [loading, setLoading] = useState(false);


    // useEffect(() => {
    //     setCustomerId(props.match.params.customerId ? Number(props.match.params.customerId) : 0);
    // }, [props.match.params.customerId]);

    // useEffect(() => {
    //     if (customerId === 0) {
    //         setCustomer({});
    //     } else {
    //         getCustomer(customerId);
    //     }
    // }, [customerId]);



    useEffect(() => {
        if (props.customer?.id) {
            getCustomer(props.customer.id);
        }
    }, [props.customer])

    const getCustomer = async (customerId: number) => {
        setLoading(true);
        try {
            const res = await request.get(apis.caseCustomer + `/${customerId}`);
            if (res.code === 1) {
                setCustomer(res.data);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }

    }

    useEffect(() => {
        setTemplates(customer.templates?.length > 0 ? customer.templates : []);
        setFloors(customer.caseFloors?.length > 0 ? customer.caseFloors : []);
        setCases(customer.cases?.length > 0 ? customer.cases : [])

    }, [customer]);


    useEffect(() => {
        const map = new Map();
        for (let i = 0; i < cases.length; i++) {
            const _case = cases[i];
            if (map.has(_case.caseFloorId)) {
                map.get(_case.caseFloorId).push(_case);
            } else {
                map.set(_case.caseFloorId, [_case]);
            }
        }

        setGroupCasesMap(map);
    }, [cases]);

    const [addTemplateVisible, setAddTemplateVisible] = useState(false);
    const [addFloorVisible, setAddFloorVisible] = useState(false);
    const [addCaseVisible, setAddCaseVisible] = useState(false);
    const [addCaseTemplate, setAddCaseTemplate] = useState(false);
    const [addCaseFloorId, setCaseFloorId] = useState(0);

    const startAddCase = (template: any, groupId: number) => {
        setAddCaseTemplate(template);
        setAddCaseVisible(true);
        setCaseFloorId(groupId);
    }


    useEffect(() => {
        if (templates.length === 1) {
            setSelectedTemplate(templates[0]);
        } else {
            setSelectedTemplate(null);
        }
    }, [templates]);

    const [updateCaseVisible, setUpdateCaseVisible] = useState<any>();
    const [updateCase, setUpdateCase] = useState({} as any);
    const openUpdateCase = (_case: any) => {
        setUpdateCase(_case);
        setUpdateCaseVisible(true);
    }
    const closeUpdateCase = () => {
        setUpdateCaseVisible(false);
        setUpdateCase({});
    }

    const removeCaseInstance = (_case: any) => {
        Modal.confirm({
            centered: true,
            title: "请确认是否删除",
            okButtonProps: {
                danger: true
            },
            okText: "确认删除",
            onOk: () => {
                request.delete(apis.caseTemplateInstance + `/${_case.id}`)
                    .then((res) => {
                        if (res.code === 1) {
                            const caseId = res.data;
                            const _cases = cases.filter((_case) => _case.id !== caseId);
                            message.info("房间：" + _case.name + "，已删除");

                            setCases(_cases);
                        }
                    })
            }
        })
    }


    const [updateTemplateVisible, setUpdateTemplateVisible] = useState(false);
    const [updateTemplate, setUpdateTemplate] = useState({} as any);
    const openUpdateTemplateModal = (template: any) => {
        setUpdateTemplate(template);
        setUpdateTemplateVisible(true);
    }
    const closeUpdateTemplateModal = () => {
        setUpdateTemplateVisible(false);
        setUpdateTemplate({});
    }
    const removeCaseTemplate = (template: any) => {

        Modal.confirm({
            centered: true,
            title: "请确认是否删除",
            content: (
                <span style={{ color: "yellow" }}>
                    删除房型会把其房间一同删除，请确认是否删除该房型
                </span>
            ),
            okButtonProps: {
                danger: true
            },
            okText: "确认删除",
            onOk: () => {
                request.delete(apis.caseTemplate + `/${template.id}`)
                    .then((res) => {
                        if (res.code === 1) {
                            const templateId = res.data;
                            const _templates = templates.filter((_t) => _t.id !== templateId);
                            const _cases = cases.filter((_c) => _c.caseParentTemplateId !== template.templateCaseId);
                            if (selectedTemplate?.id === templateId) {
                                setSelectedTemplate({});
                            }
                            setCases(_cases);
                            setTemplates(_templates);
                            message.info("房型：" + template.name + "，已删除");
                        }
                    })
            }
        })
    }

    const [hotelQrcodeVisible, setHotelQrcodeVisible] = useState(false);


    const [roomTypeCloneModalVisible, setRoomTypeCloneModalVisible] = useState(false);
    const [cloning, setCloning] = useState(false);
    const [roomTypeCloneData, setRoomTypeCloneData] = useState({
        id: 0,
        name: "",
        desc: ""
    });
    const openRoomTypeCloneModal = (data: any) => {
        setRoomTypeCloneModalVisible(true);
        setRoomTypeCloneData(data);
    }
    const closeRoomTypeCloneModal = () => {
        setRoomTypeCloneModalVisible(false);
        setRoomTypeCloneData({
            id: 0,
            name: "",
            desc: ""
        });
    }

    if (loading) {
        return (
            <Skeleton active />
        )
    }
    return (
        <div className="page-hotel-customer" style={{ padding: '0 10px' }}>
            <div className="case-customer">
                <HotelDeliverQrcode
                    customer={customer}
                    visible={hotelQrcodeVisible}
                    onCancel={() => {
                        setHotelQrcodeVisible(false);
                    }}
                />
                <Modal
                    title="克隆新房型"
                    // centered
                    visible={roomTypeCloneModalVisible}
                    onCancel={closeRoomTypeCloneModal}
                    onOk={() => {
                        if (!roomTypeCloneData.name) {
                            message.warn('请输入房型名称');
                            return;
                        }
                        setCloning(true)
                        request.post(apis.caseTemplate + `/${roomTypeCloneData.id}/clone`, {
                            name: roomTypeCloneData.name,
                            desc: roomTypeCloneData.desc
                        }).then((res) => {
                            if (res.code === 1) {

                                message.success("已克隆");
                                templates.push(res.data);
                                setTemplates([...templates]);
                                closeRoomTypeCloneModal();
                            }
                        })
                            .finally(() => {
                                setCloning(false);
                            })

                    }}
                    okText={cloning ? "正在克隆..." : "克隆"}
                    okButtonProps={{
                        loading: cloning,
                        disabled: cloning,
                    }}
                    cancelButtonProps={{
                        loading: cloning,
                        disabled: cloning,

                    }}
                >
                    <Form>
                        <Form.Item label="新房型名称">
                            <Input placeholder="请输入新房型名称" value={roomTypeCloneData.name} onChange={(e) => {
                                roomTypeCloneData.name = e.target.value;
                                setRoomTypeCloneData({ ...roomTypeCloneData })
                            }} />
                        </Form.Item>
                        <Form.Item label="新房型描述">
                            <Input placeholder="请输入描述" value={roomTypeCloneData.desc} onChange={(e) => {
                                roomTypeCloneData.desc = e.target.value;
                                setRoomTypeCloneData({ ...roomTypeCloneData })
                            }} />
                        </Form.Item>
                    </Form>
                </Modal>
                {/* <PageHeader
                    title={(
                        <div className="flex center" style={{ alignItems: 'center' }}>
                            <QrcodeOutlined style={{ fontSize: 24, marginRight: 5, marginTop: 1, cursor: 'pointer' }} onClick={() => {
                                setHotelQrcodeVisible(true);
                            }} />
                            <span style={{ fontSize: 24 }}>{customer.name}</span>
                        </div>
                    )}
                    extra={[
                        <Button type="primary" ghost onClick={() => {
                            Modal.confirm({
                                centered: true,
                                title: "提交各房间方案",
                                content: (
                                    <span style={{ color: "yellow" }}>将提交：{cases.filter((c) => c.submit === 0).map((c) => c.name).join('、')}，请确认好各房间的信道、设备数量</span>
                                ),
                                okText: "提交",
                                onOk: () => {
                                    request.post(apis.caseCustomerSubmit + `/${customer?.id}`)
                                        .then((res) => {
                                            if (res.code === 1) {
                                                message.success("已提交！");
                                            }
                                        })
                                }
                            })
                        }}>
                            <SolutionOutlined />
                            提交
                        </Button>
                    ]}
                >
                    <Descriptions size="small" column={3}>
                        <Descriptions.Item label="客户类型"><Tag color="blue">{customer.type}</Tag></Descriptions.Item>
                        <Descriptions.Item label="联系人">
                            <a>{customer.liaisonName}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="联系方式">{customer.liaisonPhone}</Descriptions.Item>
                    </Descriptions>
                </PageHeader> */}
                <div className="" style={{ fontSize: 20, padding: "10px 0" }}>{customer.name} - 房型，{customer.templates?.length}个</div>
            </div>
            <div className="case-templates flex" style={{ flexWrap: 'wrap', width: 'calc(100vw - 220px)' }}>
                <AddCaseTemplateModal
                    customer={customer}
                    visible={addTemplateVisible}
                    onCancel={() => {
                        setAddTemplateVisible(false);
                    }}
                    onCaseTemplateCreate={(template) => {
                        templates.push(template);
                        setTemplates([...templates]);
                        setAddTemplateVisible(false);
                    }}
                />
                {/* <CaseView
                    template={curTemplate || curCase}
                    onCancel={() => {
                        setCurTemplate(null);
                        setCurCase(null);
                    }}
                /> */}
                <UpdateCaseTemplateModal
                    visible={updateTemplateVisible}
                    template={updateTemplate}
                    onCancel={closeUpdateTemplateModal}
                    onCaseTemplateUpdate={(template) => {
                        for (let i = 0; i < templates.length; i++) {
                            const _template = templates[i];
                            if (template.id === _template.id) {
                                templates[i] = template;
                                message.success("已修改");
                                setTemplates([...templates]);
                                break;
                            }
                        }
                        closeUpdateTemplateModal();
                    }}
                />
                {
                    templates?.length > 0 && templates.map((template, index) => {
                        return (
                            <Dropdown trigger={["contextMenu"]} overlay={(
                                <Menu>
                                    <Menu.Item onClick={() => {
                                        openRoomTypeCloneModal({
                                            id: template.id,
                                            name: "",
                                            desc: ""
                                        });
                                    }} icon={(<CopyOutlined />)}>

                                        克隆房型
                                    </Menu.Item>
                                    <Menu.Item onClick={() => {
                                        openUpdateTemplateModal(template);
                                    }} icon={(<EditOutlined />)}>

                                        修改名称
                                    </Menu.Item>
                                    <Menu.Item danger onClick={() => {
                                        removeCaseTemplate(template);
                                    }} icon={(<DeleteOutlined />)}>

                                        删除房型
                                    </Menu.Item>
                                </Menu>
                            )}>

                                <div className="inline-block" style={{
                                    height: 105,
                                    margin: '0 10px 10px 0px',
                                }}>
                                    <div
                                        key={"template-" + index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            padding: '6px 16px',
                                            border: template.id === selectedTemplate?.id ? '2px solid rgba(255, 255, 255, .3)' : '2px solid rgba(0, 0, 0, .05)',
                                            borderRadius: 5,
                                            height: 105,
                                            // width: 200,
                                            fontSize: 18,
                                            background: template.id === selectedTemplate?.id ? "#1890ff" : "rgba(0, 0, 0, .03)",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            if (template === selectedTemplate) {
                                                setSelectedTemplate(null);
                                            } else {
                                                setSelectedTemplate(template);
                                            }
                                        }}
                                    >
                                        <div>
                                            <div style={{ color: template.id === selectedTemplate?.id ? '#fff' : "#333" }}>{template.name}</div>
                                            <div style={{ color: template.id === selectedTemplate?.id ? '#eee' : "#666", fontSize: 14 }}>{template.desc}</div>
                                        </div>
                                        <div>
                                            <Button
                                                type="link"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    window.open(`/#/project/${props.customer.id}/case/${template.templateCaseId}/create`);

                                                }}
                                                icon={(<SolutionOutlined />)}
                                                style={{ color: template.id === selectedTemplate?.id ? 'white' : undefined }}
                                            >房型方案</Button>
                                        </div>
                                    </div>

                                </div>
                            </Dropdown>
                        )
                    })
                }

                <div className="inline-block" style={{ height: 105, margin: '0 10px 10px 0' }}>

                    <div onClick={() => {
                        setAddTemplateVisible(true)
                    }} className="flex center column"
                        style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', border: '2px solid rgba(0, 0, 0, .2)', borderRadius: 5, color: 'rgba(0, 0, 0, .5)', height: 105, width: 105 }}>
                        <PlusOutlined style={{ fontSize: 24, color: "rgba(0, 0, 0, .3)" }} />
                        <span style={{ marginTop: 5, fontSize: 16 }}>添加房型</span>
                    </div>
                </div>
            </div>
            <div className="case-floors">
                <AddCaseFloorModal
                    visible={addFloorVisible}
                    customer={customer}
                    onCancel={() => {
                        setAddFloorVisible(false);
                    }}
                    onCaseFloorCreate={(floor) => {
                        floors.push(floor);
                        setFloors([...floors]);
                        setAddFloorVisible(false);
                    }}
                />
                <AddCaseModal
                    template={addCaseTemplate}
                    visible={addCaseVisible}

                    floorId={addCaseFloorId}
                    onCancel={() => {
                        setAddCaseVisible(false);
                        setAddCaseTemplate(null);
                    }}
                    onCaseCreate={(_case: any) => {
                        console.log(_case);
                        cases.push(_case);
                        setCases([...cases]);
                        setAddCaseVisible(false);
                        setAddCaseTemplate(null);
                    }}

                />
                <UpdateCaseModal
                    visible={updateCaseVisible}
                    case={updateCase}
                    onCancel={closeUpdateCase}
                    onCaseUpdate={(_case) => {
                        for (let i = 0; i < cases.length; i++) {
                            const __case = cases[i];
                            if (_case.id === __case.id) {
                                cases[i] = _case;
                                setCases([...cases]);
                                closeUpdateCase();
                                break;
                            }
                        }
                    }}
                />
                <div className="" style={{ fontSize: 20, padding: "10px 0 0 0" }}>{customer.name} - 房间，{cases?.length}个</div>
                {
                    groupCasesMap.size > 1 && groupCasesMap.has(0) && (
                        <Divider orientation={"left"}>未分组</Divider>
                    )
                }
                {
                    groupCasesMap.has(0) && (
                        <div style={{ display: 'flex', padding: '0 0 20px 0', }}>

                            {
                                groupCasesMap.get(0).map((_case, index) => {
                                    return (
                                        <Dropdown key={"0-" + index} trigger={["contextMenu"]} overlay={(
                                            <Menu>
                                                <Menu.Item onClick={() => {
                                                    openUpdateCase(_case);
                                                }}>
                                                    <EditOutlined />
                                                    修改房间
                                                </Menu.Item>
                                                <Menu.Item danger onClick={() => {
                                                    removeCaseInstance(_case);
                                                }}>
                                                    <DeleteOutlined />
                                                    删除房间
                                                </Menu.Item>
                                            </Menu>
                                        )}>
                                            <div onClick={() => {
                                                window.open(`/#/project/${props.customer.id}/case/${_case.id}/create`);
                                            }} style={{ fontSize: 24, flex: 'none', height: 90, padding: '0 20px', cursor: 'pointer', /* backgroundColor: _case.caseParentTemplateId === selectedTemplate?.templateCaseId ? "#1890ff" : undefined, */ color: _case.caseParentTemplateId === selectedTemplate?.templateCaseId ? "#1890ff" : undefined, border: _case.caseParentTemplateId === selectedTemplate?.templateCaseId ? '2px solid #1890ff' : '2px solid rgba(255, 255, 255, .1)', borderRadius: 12, background: 'rgba(255, 255, 255, .3)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                {_case.name}
                                              
                                                <div style={{ fontSize: 12 }}>
                                                    信道：{_case.rest_infoChannel}；网络号：{_case.rest_netId}
                                                </div>
                                            </div>
                                        </Dropdown>
                                    )
                                })
                            }

                        </div>
                    )
                }
                {
                    floors?.length > 0 && floors.map((group, index) => {
                        return (
                            <div key={"group-" + index} >
                                <Divider orientation="left">{group.name}</Divider>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {
                                        groupCasesMap.has(group.id) && groupCasesMap.get(group.id).map((_case) => {
                                            const template = templates.find((t) => t.templateCaseId === _case.caseParentTemplateId);
                                            return (
                                                <Dropdown trigger={["contextMenu"]} overlay={(
                                                    <Menu>
                                                        <Menu.Item onClick={() => { 
                                                            openUpdateCase(_case);
                                                        }}>
                                                            <EditOutlined />
                                                            修改房间
                                                        </Menu.Item>
                                                        <Menu.Item danger onClick={() => {
                                                            removeCaseInstance(_case);
                                                        }}>
                                                            <DeleteOutlined />
                                                            删除房间
                                                        </Menu.Item>
                                                    </Menu>
                                                )}>
                                                    <div onClick={() => {
                                                        window.open(`/#/project/${props.customer.id}/case/${_case.id}/create`);
                                                    }}
                                                        style={{
                                                            fontSize: 24,
                                                            flex: 'none',
                                                            padding: '0 10px',
                                                            margin: "0 10px 10px 0",
                                                            cursor: 'pointer',
                                                            /* backgroundColor: _case.caseParentTemplateId === selectedTemplate?.templateCaseId ? "#1890ff" : undefined, */
                                                            color: _case.caseParentTemplateId === selectedTemplate?.templateCaseId ? "#1890ff" : undefined,
                                                            border: _case.caseParentTemplateId === selectedTemplate?.templateCaseId ? '2px solid #1890ff' : '2px solid rgba(0, 0, 0, .03)',
                                                            borderRadius: 3,
                                                            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                                                            backgroundColor: "rgba(0, 0, 0, .03)",
                                                            // position: 'relative'
                                                        }}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            {_case.name}
                                                            {
                                                                template && (
                                                                    <div style={{ position: 'relative', top: -18, height: 20 }}>
                                                                        <Tag style={{}}>{template.name}</Tag>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                          <div style={{fontSize: 12}}>

                                                {
                                                    _case.hasHotelDueros === 1 && (
                                                        <span>小度房间号：{_case.duerosHotelRoomName}</span>
                                                    )
                                                }
                                                </div>
                                                        <div style={{ fontSize: 12, color: 'gray', minWidth: 140, textAlign: 'center' }}>
                                                            信道：{_case.rest_infoChannel}；网络号：{_case.rest_netId}
                                                        </div>
                                                    </div>
                                                </Dropdown>

                                            )
                                        })
                                    }
                                    {
                                        selectedTemplate && (
                                            <div onClick={() => {
                                                startAddCase(selectedTemplate, group.id);
                                            }} style={{ height: 70, padding: '0 10px', cursor: 'pointer', borderRadius: 12, marginRight: 10, background: 'rgba(255, 255, 255, .3)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <PlusOutlined style={{ color: '#1890ff', fontSize: 20 }} />
                                                <Button type="link">
                                                    <span style={{ color: "white", fontSize: 16 }}>添加<span style={{ color: "#1890ff" }}>{selectedTemplate.name}</span>房间</span>
                                                </Button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                <Divider orientation="left">
                    <Button type="link" icon={(<PlusOutlined />)} onClick={() => {
                        setAddFloorVisible(true);
                    }}>添加楼层</Button>
                </Divider>
            </div>
        </div>
    )
}


interface NormalAddCaseProps extends RouteChildrenProps {
    customer: any;
    // caseList: any[];
}
const NormalAddCase = (props: NormalAddCaseProps) => {


    const [caseList, setCaseList] = useState<any[]>([]);

    const addCaseRef = useRef<any>();
    const updateCaseRef = useRef<any>();


    const onAddCase = async (data: any) => {
        const res = await request.post(apis.case, data);
        if (res.code === 1) {
            window.open(`/#/project/${props.customer?.id}/case/${res.data.id}/create`);
            caseList.push(res.data);
            setCaseList([...caseList]);
            return true;
        }
    }

    const onUpdateCase = async (data: any) => {
        const _caseIndex = caseList.findIndex((c) => c.id === data.caseId);
        caseList[_caseIndex].name = data.name;
        caseList[_caseIndex].infoChannel = data.infoChannel;
        setCaseList([...caseList]);
    }

    useEffect(() => {
        if (props.customer?.id) {
            request.get(apis.case, { customerId: props.customer.id })
                .then((res) => {
                    if (res.code === 1) {
                        setCaseList(res.data)
                    }
                })
        }
    }, [props.customer]);


    const removeCaseInstance = (_case: any) => {
        Modal.confirm({
            centered: true,
            title: "请确认是否删除",
            okButtonProps: {
                danger: true
            },
            okText: "确认删除",
            onOk: () => {
                request.delete(apis.caseTemplateInstance + `/${_case.id}`)
                    .then((res) => {
                        if (res.code === 1) {
                            const caseId = res.data;
                            const _cases = caseList.filter((_case) => _case.id !== caseId);
                            message.info("房间：" + _case.name + "，已删除");
                            setCaseList(_cases);
                        }
                    })
            }
        })
    }

    return (
        <div style={{ padding: 10 }}>
            <div>
                <Button icon={(<PlusOutlined />)} onClick={() => {
                    addCaseRef.current?.open({
                        customerId: props.customer?.id
                    });
                }}>新增方案</Button>
                <AddCaseModal2
                    ref={addCaseRef}
                    customer={props.customer}
                    onAddCase={onAddCase}
                />
                <UpdateCaseModal2 
                    ref={updateCaseRef}
                    onCaseUpdate={onUpdateCase}
                />
            </div>
            <div>
                {
                    caseList.map((c) => {
                        return (
                            <div className="flex" style={{ alignItems: 'center', backgroundColor: "rgba(0, 0, 0, 0.03)", border: "1px solid rgba(0, 0, 0, .03)", padding: '4px 12px', borderRadius: 3, marginTop: 10 }}>
                                <div className="flex" style={{ flex: 1, alignItems: 'center' }}>
                                    <div>
                                        <div className="bold" style={{ fontSize: 18 }}>{c.name}</div>
                                        <div style={{ fontSize: 12, color: 'gray', minWidth: 140 }}>
                                            信道：<Button type="link">{c.infoChannel || '无'}</Button>；网络号：{c.rest_netId}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <Button type="link"
                                            onClick={() => {
                                                updateCaseRef.current?.open({
                                                    caseId: c.id,
                                                    name: c.name,
                                                    infoChannel: c.infoChannel
                                                })
                                            }}
                                        ><EditOutlined />修改</Button>
                                        <Button type="link"
                                            onClick={() => {
                                                window.open(`/#/customer/${props.customer?.id}/case/${c.id}`);
                                            }}
                                            
                                        ><ControlOutlined /> 控制中心</Button>
                                    </div>
                                </div>
                                <div style={{ width: 240, textAlign: 'right' }}>
                                    <Button type="link" onClick={() => {
                                        // window.open();
                                        props.history.push(`/project/${props.customer?.id}/case/${c.id}/create`)
                                    }}><EditOutlined /> 编辑</Button>
                                    <Button type="link" onClick={() => {
                                        window.open(`/#/project/${props.customer?.id}/case/${c.id}/create`);
                                    }}><CopyOutlined /> 克隆</Button>
                                    <Button type="link" danger onClick={() => {
                                        removeCaseInstance(c);
                                    }}><DeleteOutlined /> 删除</Button>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}


interface AddCaseModalProps {
    customer: any;
    onAddCase: (c: any) => Promise<any>;

}
const AddCaseModal2 = forwardRef((props: AddCaseModalProps, ref: any) => {
    return (
        <ModalForm
            ref={ref}
            title={(
                <div>
                    新增方案
                    <span style={{ fontSize: 12, color: "lightgray" }}>（{props.customer?.name}）</span>
                </div>
            )}
            maskClosable={false}
            labelCol={{ span: 4 }}
            onSubmit={props.onAddCase}
        >
            <Form.Item hidden name={"customerId"}></Form.Item>
            <Form.Item label="方案名称" name="name" rules={[{ required: true, message: "请输入客户名称" }]}>
                <Input placeholder="请输入方案名称" autoComplete="off" autoFocus />
            </Form.Item>
            <Form.Item label="方案描述" name="desc">
                <Input.TextArea placeholder="如需特殊说明，可在此输入" autoComplete="off" />
            </Form.Item>
        </ModalForm>
    )
});


interface UpdateCaseProps {
    onCaseUpdate: (c: any) => void;
}
const UpdateCaseModal2 = forwardRef((props: UpdateCaseProps, ref: any) => {

    const onSubmit = async (data: any) => {
        const res = await request.put(apis.case + `/${data.caseId}`, data)
        if(res.code === 1) {
            props.onCaseUpdate(data);
            return true;
        }
    }

    return (
        <ModalForm
            ref={ref}
            title="修改方案"
            onSubmit={onSubmit}
        >
            <Form.Item name="caseId" hidden />
            <Form.Item label="房间号" name="name" rules={[{ required: true, message: "请输入房型名称" }]}>
                <Input placeholder="请输入房型名称" autoComplete="off" autoFocus />
            </Form.Item>
            <Form.Item label="信道" name="infoChannel" rules={[{ required: true, message: "请选择信道" }]}>
                <InfoChannelSelector mode="edit" />
            </Form.Item>
        </ModalForm>
    )
})