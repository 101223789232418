import { RightOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { CaseView } from "../../#case-manager/Home/components/HotelCustomer/components/CaseView";
import CaseLayout from "./CaseLayout";
import ProductsDrawer from "./ProductsDrawer";
import ProductSelectorLayout from "./ProductSelectorLayout";
import Scenes from "./Scenes";
import SmartProducts from "./SmartProducts";
import './style.less';

interface CaseEditorProps extends RouteChildrenProps<{ customerId: string, caseId: string }> {

}

export default function CaseEditorPage(props: CaseEditorProps) {

    const [c, setCase] = useState(null as any);
    const [cV2, setCaseV2] = useState(null as any);
    const productsSelector = useRef<any>();
    const scenesRef = useRef<any>();

    const onCaseChange = (c: any) => {
        setCase(c);
    }

    useEffect(() => {
        let titleElem = document.getElementsByTagName("title")[0];
        if(c) {
            titleElem.innerHTML = `${c.caseTemplateId === 0 ? c.customerType === '酒店' ? '房间：' : '' : '房型：'}${c.caseName} - ${c.customerName}`;
        }
        return () => {
            titleElem.innerText = "华飞物联管理平台";
        }
    }, [c]);

    const smartProductsRef = useRef<any>();
    const onSmartProductAdd = (smart: any) => {
        spusDrawerRef.current?.close();
        smartProductsRef.current?.onAdd(c, smart);
    }

    const spusDrawerRef = useRef<any>();
    const onNormalProductAdd = (spu: any) => {
        spusDrawerRef.current?.onAdd(c, spu);
        spusDrawerRef.current?.open();
    }

    return (<>
        <CaseLayout
            {...props}
            caseId={Number(props.match.params.caseId) || 0}
            onCaseChange={onCaseChange}
            extra={(
                <Button type="default" onClick={() => {
                    spusDrawerRef.current?.open();
                }}>
                    产品列表
                    <RightOutlined style={{ position: 'relative', top: 0.5 }} />
                </Button>
            )}
        >
            <ProductSelectorLayout
                ref={productsSelector}
                onSmartProductAdd={onSmartProductAdd}
                onNormalProductAdd={onNormalProductAdd}
            >
                    <div className="flex" style={{ paddingBottom: 100 }}>
                        <div className="products" style={{ flex: 1 }}>
                            <SmartProducts
                                ref={smartProductsRef}
                                case={c}
                                onCanAddNode={() => {
                                    productsSelector.current?.onCanAddNode();
                                }}
                                onDisableAddNode={() => {
                                    productsSelector.current?.onDisableAddNode();
                                }}
                                onEdit={() => {
                                    spusDrawerRef.current?.close();
                                }}
                                onSmartsChange={(nodes) => {
                                    scenesRef.current?.setNodes(nodes);
                                }}
                            />
                            <ProductsDrawer
                                ref={spusDrawerRef}
                                case={c}
                            />
                        </div>
                        {/* <div className="scenes" style={{}}>
                            <Scenes
                                ref={scenesRef}
                                case={c}
                                
                            />
                        </div> */}
                        <div className="footer bg-white flex" style={{ alignItems: 'center', position: 'absolute', bottom: 0, width: 'calc(100vw - 224px)', height: 50, boxShadow: '0 3px 10px rgba(0, 0, 0, .2)' }}>
                            <Button type="link" onClick={() => {
                                setCaseV2({ id: c.caseId, ...c});
                            }}>V2.0方案</Button>
                            <CaseView
                                template={cV2}
                                onCancel={() => {
                                    setCaseV2(null);
                                }}
                            
                            />
                        </div>
                    </div>

                {/* </AreasLayout> */}
            </ProductSelectorLayout>
        </CaseLayout>
    </>
    )

}
