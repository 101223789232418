import React, { useEffect, useState } from 'react'
import { SceneSwitchNodeCell } from './SceneSwitchNodeCell';

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingTargets: any[];
    bindingAccepts: string;
    onRenameApp: (app: any) => void;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (item: any) => void;
    viewSource: any;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}
const endpointPairMap: any = {
    1: 8,
    8: 1,
    2: 9,
    9: 2,
    3: 10,
    10: 3,
    4: 11,
    11: 4,
    31: 31
}

export const FourButtonSceneAndSpeakerNodeCell = (props: Props) => {
    
    return (
        <SceneSwitchNodeCell 
            className="four-switch"
            {...props}
            endpointPairMap={endpointPairMap}
        />
    )
}