import { Table } from 'antd';
import Modal from 'antd/lib/modal/Modal'
import React, { useEffect, useState } from 'react'
import { NODE_KEY_NAME_MAP } from '../../../../../../utils/constants';

interface Props {
    visible: boolean;
    onCancel: () => void;
    caseNodes: any[];
    caseAreas: any[];
}

export const CaseOverviewModal = (props: Props) => {


    const [columns, setColumns] = useState([] as any[]);
    const [tableData, setTableData] = useState([] as any[]);
    

    useEffect(() => {
        const { caseAreas: areas, caseNodes: nodes } = props;
        const keysCountMap: Map<string, number> = new Map();
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            keysCountMap.set(node.typeKey, (keysCountMap.get(node.typeKey) || 0) + 1);
        }
        const countMap: Map<string, number> = new Map();
        for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            const keysCountMap: Map<string, number> = new Map();
            for (let j = 0; j < nodes.length; j++) {
                const node = nodes[j];
                if(node.caseAreaId === area.id) {
                    keysCountMap.set(node.typeKey, (keysCountMap.get(node.typeKey) || 0) + 1);
                }
            }
            keysCountMap.forEach((count, typeKey) => {
                const key = `area-${area.id}:${typeKey}`;
                countMap.set(key, (countMap.get(typeKey) || 0) + count); 
            });
        }
        const _columns: any[] = [{
            key: "#",
            title: "#",
            render: (_: any, __: any, index: number) => {
                return (index + 1)
            }
        }, {
            key: "name",
            title: "设备名称",
            dataIndex: "name",
            // render: (_: any, __: any, index: number) => {
            //     return (index + 1)
            // }
        }];
        for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            _columns.push({
                key: `area-${area.id}`,
                title: area.name,
                align: 'center',
                dataIndex: `area-${area.id}`,
                // render: (_: any, record: any) => {
                //     return countMap.get(`area-${area.id}:node-${record.id}`) || "-"
                // }
            })
        }
        _columns.push({
            key: "total",
            title: "总计",
            dataIndex: "area-total",
        })
        setColumns(_columns);
        const _tableData: any[] = [];
        keysCountMap.forEach((count, key:  any) => {
            const temp: any = {
                name: (NODE_KEY_NAME_MAP as any)[key],
            }
            for (let j = 0; j < areas.length; j++) {
                const area = areas[j];
                temp[`area-${area.id}`] = countMap.get(`area-${area.id}:${key}`) || "-";
                
            }
            temp[`area-total`] = count;
            _tableData.push(temp);
        });
        
        setTableData(_tableData);
    }, [props.caseAreas, props.caseNodes]);

    return (
        <Modal
            title="设备清单"
            centered
            visible={props.visible}
            onOk={props.onCancel}
            onCancel={props.onCancel}
            width={columns.length * 60}
        >
            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                <Table
                    pagination={false}
                    size={"small"}
                    columns={columns}
                    dataSource={tableData}
                />
            </div>
        </Modal>
    )


}