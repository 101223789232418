import { CloudFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { SourceConnector, TargetConnector } from '../NodeCellConnector'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    viewSource: any;
    bindingSource: any;
    bindingAccepts: string;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const Plug_inCardPowerNodeCell2 = (props: Props) => {

    const { node } = props;
    const [sourceDisabled, setSourceDisabled] = useState(false);
    const [sourceSelected, setSourceSelected] = useState(false);
    const [sourceDisabled1, setSourceDisabled1] = useState(false);
    const [sourceSelected1, setSourceSelected1] = useState(false);

    useEffect(() => {
        const app = node.apps[0];
        const app1 = node.apps[1];
        if (props.bindMode === "view") {
            if (app.id !== props.viewSource?.id) {
                setSourceDisabled(true);
            } else {
                setSourceDisabled(false);
                setSourceSelected(true);
            }
        } else if (props.bindMode === "wait") {
            setSourceSelected(false);
            setSourceDisabled(false);
        } else if (props.bindMode === "targets") {
            if (app.id !== props.bindingSource?.id) {
                setSourceDisabled(true);
            } else {
                setSourceDisabled(false);
                setSourceSelected(true);
            }
        }

        if (props.bindMode === "view") {
            if (app1.id !== props.viewSource?.id) {
                setSourceDisabled1(true);
            } else {
                setSourceDisabled1(false);
                setSourceSelected1(true);
            }
        } else if (props.bindMode === "wait") {
            setSourceSelected1(false);
            setSourceDisabled1(false);
        } else if (props.bindMode === "targets") {
            if (app1.id !== props.bindingSource?.id) {
                setSourceDisabled1(true);
            } else {
                setSourceDisabled1(false);
                setSourceSelected1(true);
            }
        }
    }, [props.bindingSource, props.node, props.bindMode]);

    return (
        <div className="node-cell curtain-control-node-cell">

            <div className="one-switch" style={{padding: '0'}}>
                {/* <div className="switch-item" style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                    <span>开</span>
                    <span>开</span>
                </div> */}
                <div className="switch-item" style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                        <span>拔卡关</span>
                        <div style={{ height: 20, marginTop: 5 }}>
                            {
                                !sourceDisabled1 && (
                                    <SourceConnector
                                        selected={sourceSelected1}
                                        disabled={sourceDisabled1}
                                        connected={node.apps[1].isSource}
                                        onClick={() => {
                                            if (node.apps[1].isSource) {
                                                if (props.bindMode === "view") {
                                                    if (typeof props.onCancelView === 'function') {
                                                        props.onCancelView();
                                                    }
                                                } else if (props.bindMode === "wait") {
                                                    if (typeof props.onViewBindRelations === 'function') {
                                                        props.onViewBindRelations(node.apps[1], "not scene");
                                                    }
                                                }
                                            } else {
                                                if (props.bindMode === "wait") {
                                                    if (typeof props.onSetBindingSource === 'function') {
                                                        props.onSetBindingSource(node.apps[1], "not scene");
                                                    }
                                                } else if (props.bindMode === "targets") {
                                                    if (typeof props.onResetBindingSource === "function") {
                                                        props.onResetBindingSource();
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                        <div style={{ height: 20, marginBottom: 5 }}>
                            {
                                !sourceDisabled && (
                                    <SourceConnector
                                        selected={sourceSelected}
                                        disabled={sourceDisabled}
                                        connected={node.apps[0].isSource}
                                        onClick={() => {
                                            if (node.apps[0].isSource) {
                                                if (props.bindMode === "view") {
                                                    if (typeof props.onCancelView === 'function') {
                                                        props.onCancelView();
                                                    }
                                                } else if (props.bindMode === "wait") {
                                                    if (typeof props.onViewBindRelations === 'function') {
                                                        props.onViewBindRelations(node.apps[0], "not scene");
                                                    }
                                                }
                                            } else {
                                                if (props.bindMode === "wait") {
                                                    if (typeof props.onSetBindingSource === 'function') {
                                                        props.onSetBindingSource(node.apps[0], "not scene");
                                                    }
                                                } else if (props.bindMode === "targets") {
                                                    if (typeof props.onResetBindingSource === "function") {
                                                        props.onResetBindingSource();
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                )
                            }
                        </div>
                        <span>插卡开+拔卡关</span>
                        </div>
                </div>
                {/* <div className="switch-item" style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                    <span>停</span>
                    <span>停</span>
                </div> */}
            </div>
            <div className="prod-name">
                {
                    props.node.isOnline && (
                        <CloudFilled />
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )
}