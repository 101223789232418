import { Form, FormInstance, Input, Modal } from "antd"
import React from "react"
import { useState } from "react";
import { useRef } from "react"
import { request } from '../../../../../../utils/request';
import { apis } from '../../../../../../utils/apis';

interface Props {
    visible: boolean;
    customer: any,
    onCancel: () => void;
    onCaseTemplateCreate: (template: any) => void;
}

export const AddCaseTemplateModal = (props: Props) => {

    const formRef = useRef<FormInstance>();

    const [loading, setLoading] = useState(false);

    
    const onCaseTemplateCreate = async (data: any) => {
        
        setLoading(true);
        console.log(props.customer);
        
        const res = await request.post(apis.caseTemplate, {
            customerId: props.customer.id,
            ...data
        });
        if(res.code === 1) {
            props.onCaseTemplateCreate(res.data);
        }
        setLoading(false);
    }

    return (
        <Modal
            title="创建房型"
            centered
            visible={props.visible}
            onOk={() => {
                formRef.current?.submit()
            }}
            okButtonProps={{    
                disabled: loading,
                loading: loading,
            }}
            onCancel={props.onCancel}
            cancelButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            width={400}
            destroyOnClose
        >
            <Form
                ref={formRef}
                labelCol={{span: 5}}
                onFinish={onCaseTemplateCreate}
            >
                <Form.Item label="房型名称" name="name" rules={[{required: true, message: "请输入房型名称"}]}>
                    <Input placeholder="请输入房型名称" autoComplete="off" autoFocus/>
                </Form.Item>
             
                <Form.Item label="说明" name="desc" rules={[{message: "如需说明一些情况, 请写在这里"}]}>
                    <Input.TextArea placeholder="请输入说明" autoComplete="off"/>
                </Form.Item>
            </Form>
        </Modal>
    )

}