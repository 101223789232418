import { LoadingOutlined, PartitionOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, message, Modal, Divider, Select, Radio, Checkbox } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { SpecSelect } from '../Home/components/SpecSelect';
import { SkuList } from '../Home/components/SkuList';
import { request } from '../../../utils/request';
import { apis } from '../../../utils/apis';
import { specValuesToSkus } from '../../../utils';

interface Props {
    // visible: boolean,
    // onCancel: () => void,
    // cates: any[],
    onRefresh?: () => Promise<void>,
    onSpuCreate?: (spu: any) => void,
}

const spuCreateFormRef = React.createRef<any>();
const tagCreateInputRef = React.createRef<any>();
const cateCreateInputRef = React.createRef<any>();
const unitCreateInputRef = React.createRef<any>();

const ProductCreateModal = forwardRef((props: Props, ref: any) => {

    // const [spuCates, setSpuCates] = useState<any[]>([]);
    const [spuTags, setSpuTags] = useState<any[]>([]);
    const [spuUnits, setSpuUnits] = useState<any[]>([]);
    const [cates, setCates] = useState<any[]>([]);

    const [createLoading, setCreateLoading] = useState(false);
    const [cateCreateLoading, setCateCreateLoading] = useState(false);
    const [unitCreateLoading, setUnitCreateLoading] = useState(false);
    const [tagCreateLoading, setTagCreateLoading] = useState(false);

    const init = async () => {
        const [res1, res2, res3] = await Promise.all([
            request.get(apis.productSpuTag),
            request.get(apis.productSpuUnit),
            request.get(apis.productSpuCate)
        ]);
        // const { data: tags } = await 
        setSpuTags(res1.data);
        // const { data: units } = await 
        setSpuUnits(res2.data);
        setCates(res3.data)
    }


    useEffect(() => {
        init();
    }, []);

    const [tagCreateTemp, setTagCreateTemp] = useState("");
    const [unitCreateTemp, setUnitCreateTemp] = useState("");
    const [cateCreateTemp, setCateCreateTemp] = useState("");
    //以下两项都会对sku产生影响
    const [spuNameTemp, setSpuNameTemp] = useState("");
    const [specValueTemp, setSpecValueTemp] = useState<any[]>([]);

    const [visible, setVisible] = useState(false);

    const open = () => {
        setVisible(true);
    }
    const close = () => {
        setVisible(false);
    }

    useImperativeHandle(ref, () => {
        return {
            open,
            
        }
    });

    return (
        <Modal 
            maskClosable={false}
            visible={visible} 
            centered 
            title="新增产品" 
            onOk={() => {
                spuCreateFormRef.current?.submit();
            }}
            onCancel={close}
            destroyOnClose
            bodyStyle={{height: 700, overflowY: 'auto'}}
        >
            <Form
                style={{}}
                ref={spuCreateFormRef}
                initialValues={{
                    name: '',
                    desc: '',
                    // unit: this.spuUnits.length > 0 ? this.spuUnits[0].id : undefined,
                    // cate: this.spuCates.length > 0 ? this.spuCates[0].id : undefined,
                    tags: [],
                    displayOrderNo: 1,
                    goodsSpecification: [],
                    agentLevelPrice: [],
                    goodsImages: [],
                    specValues: [],
                    skus: [],
                    // isSmart: 1,
                    // gatewayType: 0,
                    // isRowFrame: 0,
                    // supportRowFrame: 0,
                }}
                onValuesChange={(changedValues) => {
                    if (Object.keys(changedValues).includes('name')) {
                        setSpuNameTemp(changedValues.name);
                        specValuesToSkus(specValueTemp, (skus: any[]) => {
                            spuCreateFormRef.current?.setFieldsValue({
                                skus: skus,
                            });
                        });
                    }
                    if (Object.keys(changedValues).includes('specValues')) {
                        spuCreateFormRef.current?.setFieldsValue({
                            specValues: changedValues.specValues,
                        });
                        specValuesToSkus(changedValues.specValues, (skus: any[]) => {
                            spuCreateFormRef.current?.setFieldsValue({
                                skus: skus,
                            });
                        });
                    }
                    if (Object.keys(changedValues).includes('specValues')) {
                        setSpecValueTemp(changedValues.specValues);
                    }

                }}
                onFinish={(data) => {
                    const specDto = [];
                    for (let i = 0; i < data.specValues.length; i++) {
                        const specValue = data.specValues[i];
                        specDto.push({
                            specName: specValue.name,
                            specValues: specValue.values.map((value: any) => ({ value: value.name })),
                        });
                    }
                    const skuDto: any = {};
                    for (let i = 0; i < data.skus.length; i++) {
                        const sku = data.skus[i];
                        const key = sku.names.join(',');
                        const enabled = Number(sku.selected);
                        skuDto[key] = { enabled }
                    }
                    const dto = {
                        name: data.name,
                        desc: data.desc,
                        imageId: 0,
                        mode: data.mode,
                        // code: string;
                        spuCateId: data.cate,
                        spuUnitId: data.unit,
                        spuTagIds: data.tags,
                        specs: specDto,
                        skus: skuDto,
                        updateable: 1,
                        deletable: 1,
                        enabled: 1,
                        isSmart: data.isSmart,
                        gatewayType: data.gatewayType,
                        isRowFrame: data.isRowFrame,
                        supportRowFrame: data.supportRowFrame,
                        endpoints: data.endpoints?.length > 0 ? data.endpoints.join(',') : ''
                    }
                    setCreateLoading(true);
                    request.post(apis.productSpu, dto).then((res) => {
                        if (res.code === 1) {
                            message.success("创建成功！");
                            close();
                            props.onRefresh && props.onRefresh().then(() => {
                                props.onSpuCreate(res.data);
                            });
                        } else {
                            throw new Error(res.msg);
                        }
                    }).catch(e => {
                        message.warn(e);
                    }).finally(() => {
                        setCreateLoading(false);
                    });
                }}
                labelCol={{ span: 4 }}
            >
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{
                        required: true,
                        message: "请输入商品名称"
                    }]}
                >
                    <Input placeholder="请输入商品名称" autoFocus autoComplete="off" />
                </Form.Item>
                <Form.Item
                    label="型号"
                    name="mode"
                    rules={[{
                        required: true,
                        message: "请输入商品型号"
                    }]}
                >
                    <Input placeholder="长度小于20" autoComplete="off" />
                </Form.Item>
                
                <Form.Item
                    label="分类"
                    name="cate"
                    rules={[{
                    required: true,
                    message: "请选择商品分类"
                    }]}
                >
                    <Select
                        dropdownRender={menu => {
                            return (
                                <div>
                                    {menu}
                                    <Divider />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input ref={cateCreateInputRef} style={{ flex: 'auto' }} value={cateCreateTemp} onChange={(e) => {
                                            setCateCreateTemp(e.target.value);
                                        }} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (!cateCreateTemp) {
                                                    message.info("请输入分类名");
                                                } else {
                                                    setCateCreateLoading(true);
                                                    request.post(apis.productSpuCate, {
                                                        name: cateCreateTemp,
                                                    }).then((res) => {
                                                        if (res.code === 1) {
                                                            props.onRefresh && props.onRefresh()
                                                            setCateCreateTemp("");
                                                            message.success("已创建");
                                                        } else {
                                                            throw new Error(res.msg);
                                                        }
                                                    }).catch((e) => {
                                                        message.warn(e.message);
                                                    }).finally(() => {
                                                        setCateCreateLoading(false);
                                                    });
                                                }
                                            }}
                                        >
                                            {
                                                cateCreateLoading ? (
                                                    <LoadingOutlined spin />
                                                ) : (
                                                    <PlusOutlined />
                                                )
                                            }
                                            添加分类
                                        </a>
                                    </div>
                                </div>
                            )
                        }}
                    >
                        {
                            cates.length > 0 && cates.map((cate, index) => {
                                return (
                                    <Select.Option key={"goods-cate-" + index} value={cate.id}>{cate.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="单位"
                    name="unit"
                    rules={[{
                        required: true,
                        message: "请选择产品单位"
                    }]}
                >
                    <Select
                        dropdownRender={menu => {
                            return (
                                <div>
                                    {menu}
                                    <Divider />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input ref={unitCreateInputRef} style={{ flex: 'auto' }} onChange={(e) => {
                                            setUnitCreateTemp(e.target.value);
                                        }} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (!unitCreateTemp) {
                                                    message.info("请输入单位名");
                                                } else {
                                                    request.post(apis.productSpuUnit, {
                                                        name: unitCreateTemp,
                                                    }).then((res) => {
                                                        if (res.code === 1) {
                                                            setUnitCreateTemp("");
                                                            init();
                                                        } else {
                                                            throw new Error(res.msg);
                                                        }
                                                    }).catch((e) => {
                                                        message.warn(e.message);
                                                    });
                                                }
                                            }}
                                        >
                                            {
                                                tagCreateLoading ? (
                                                    <LoadingOutlined spin />
                                                ) : (
                                                    <PlusOutlined />
                                                )
                                            }
                                            添加单位
                                        </a>
                                    </div>
                                </div>
                            )
                        }}
                    >
                        {
                            spuUnits.length > 0 && spuUnits.map((unit, index) => {
                                return (
                                    <Select.Option key={"spu-unit-" + index} value={unit.id}>{unit.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="标签"
                    name="tags"
                >
                    <Select
                        mode="multiple"
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input ref={tagCreateInputRef} style={{ flex: 'auto' }} onChange={(e) => {
                                        setTagCreateTemp(e.target.value);
                                    }} />
                                    <a
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={() => {
                                            if (!tagCreateTemp) {
                                                message.info("请输入标签名");
                                            } else {
                                                request.post(apis.productSpuTag, {
                                                    name: tagCreateTemp,
                                                }).then((res) => {
                                                    if (res.code === 1) {
                                                        setTagCreateTemp("");
                                                        const data: any = res.data;
                                                        const oldTags = spuCreateFormRef.current?.getFieldValue("tags");
                                                        spuTags.push(data);
                                                        setSpuTags([...spuTags]);
                                                        oldTags.push(data.id);
                                                        spuCreateFormRef.current?.setFieldsValue({
                                                            tags: [...oldTags],
                                                        });
                                                        tagCreateInputRef.current?.setValue("");
                                                    } else {
                                                        throw new Error(res.msg);
                                                    }
                                                }).catch((e) => {
                                                    message.warn(e.message);
                                                });
                                            }
                                        }}
                                    >
                                        {
                                            tagCreateLoading ? (
                                                <LoadingOutlined spin />
                                            ) : (
                                                <PlusOutlined />
                                            )
                                        }
                                        添加标签
                                    </a>
                                </div>
                            </div>
                        )}
                    >
                        {
                            spuTags.length > 0 && spuTags.map((tag, index) => {
                                return (
                                    <Select.Option key={"tag-" + index} value={tag.id}>{tag.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="desc"
                    rules={[{ max: 100, message: "商品简述控制在100字以内" }]}
                >
                    {/* @ts-ignore */}
                    <Input.TextArea placeholder="在此处填写产品的一些说明" autoComplete="off" />
                </Form.Item>
                <Form.Item
                    label="规格"
                    name="specValues"
                >
                    <SpecSelect footer={(
                        <></>
                    )} />
                </Form.Item>
                <Form.Item
                    label="对应单品"
                    name="skus"
                >
                    <SkuList spuName={spuNameTemp} />
                </Form.Item>
                <Divider >特性设置</Divider>
                <Form.Item label="是否智能" name="isSmart" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={1}>智能产品</Radio>
                        <Radio value={0}>非智能产品</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="芯片类型" name="chipType" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={"none"}>无</Radio>
                        <Radio value={"zigbee-TI"}>Zigbee TI</Radio>
                        <Radio value={"zigbee-Telink"}>Zigbee Telink</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否网关" name="gatewayType" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={0}>非网关</Radio>
                        <Radio value={1}>纯网关</Radio>
                        <Radio value={2}>网关模块</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="连排框" name="isRowFrame" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={1}>是连排框</Radio>
                        <Radio value={0}>非连排框</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="支持连排" name="supportRowFrame" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={1}>支持</Radio>
                        <Radio value={0}>不支持</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="支持端口" name="endpoints">
                    <Checkbox.Group
                        options={[{
                            label: 1,
                            value: 1,
                        }, {
                            label: 2,
                            value: 2,
                        },{
                            label: 3,
                            value: 3,
                        },{
                            label: 4,
                            value: 4,
                        },{
                            label: 5,
                            value: 5,
                        },{
                            label: 6,
                            value: 6,
                        },{
                            label: 7,
                            value: 7,
                        },{
                            label: 8,
                            value: 8,
                        },{
                            label: 9,
                            value: 9,
                        },{
                            label: 10,
                            value: 10,
                        },{
                            label: 11,
                            value: 11,
                        },{
                            label: 31,
                            value: 31,
                        }]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
})

export default ProductCreateModal;