import { WifiOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export interface ProductCellProps {
    spu?: {
        imageUrl: string;
        spuName: string;
        spuMode: string;
        endpoints?: number[];
        gatewayType?: number;
    };
    imagePrimary?: boolean;
    imageUrl?: string;
    controlWidgets?: any;
    children?: any;
}

const NormalProductCell = (props: ProductCellProps) => {

    return (
        <div className="normal-product inline-block bg-white" style={{ border: '1px solid rgba(0, 0, 0, .2)', minWidth: 240 }}>
            <div className="flex column">
                {
                    props.spu && (
                        <div className="flex" style={{ alignItems: 'center', justifyContent: 'space-between', padding: '8px 5px 5px 5px' }}>
                            <div style={{ paddingLeft: 6 }}>
                                <div className="bold" style={{ fontSize: 14 }}>{props.spu.spuName}</div>
                                <div style={{ textAlign: 'left' }}>{props.spu.spuMode}</div>
                            </div>
                            <div className="img-wrapper" style={{ height: 42, width: 42, background: "#f2f2f2" }}>
                                <img src={props.spu.imageUrl} style={{ height: "100%", width: "100%" }} />
                            </div>
                        </div>
                    )
                }

                <div className="relative" style={{ marginTop: 3, height: 240, width: 240, background: "#f2f2f2" }}>
                    <div className="absolute" style={{ height: '100%', width: "100%", backgroundColor: "rgba(0, 0, 0, 0)" }}>
                        {props.controlWidgets}
                    </div>
                    {
                        props.spu?.gatewayType > 0 && (
                            <Gateway />
                        )
                    }
                    {
                        props.imagePrimary ? (
                            <img src={props.imageUrl || props.spu?.imageUrl} alt="" style={{ width: '100%', height: "100%" }} />
                        ) : props.children
                    }
                </div>
            </div>
        </div>
    )
}

export default NormalProductCell;


interface GatewayProps {

}

const Gateway = (props: GatewayProps) => {
    return (
        <Tooltip overlay={(
            <div>网关</div>
        )}>
            <div className="absolute flex center" style={{ width: 18, height: 18, borderRadius: 18, top: 120 - 9, left: 120 - 9, zIndex: 9, backgroundColor: "lightblue", cursor: 'pointer' }}>
                <WifiOutlined style={{ color: "#fff", position: 'relative', top: 1 }} />
            </div>
        </Tooltip>
    )
}