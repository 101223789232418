import { CopyOutlined } from "@ant-design/icons";
import { Button, message, PageHeader, Table, Tag } from "antd";
import { useEffect, useMemo, useState } from "react"
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import copy from 'copy-to-clipboard';

export function AppsMgmt() {

    const [apps, setApps] = useState<any[]>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {

        const res: any = await request.get(apis["/upms/apps/list"]);
        if (res.code === 1) {
            setApps(res.data);
        }
    }

    const [columns] = useMemo(() => {
        const columns = [
            {
                key: "name",
                dataIndex: "name",
                title: 'App',
                render: (name: string, record: any) => {
                    return (
                        <>
                            <div className="bold" style={{ fontSize: 14 }}>
                                {
                                    record.tenantChannelName && (
                                        <Tag color="purple">{record.tenantChannelName}</Tag>
                                    )
                                }
                                {name}
                            </div>
                            <div title="双击复制" className="color-gray" style={{ marginLeft: 20 }}>
                                <span style={{userSelect: 'none', cursor: "copy"}} onDoubleClick={() => {
                                    copy(record.key);
                                    message.info("已复制");
                                }}>{record.key}</span>
                                
                            </div>
                        </>
                    )
                }
            },
            {
                key: "platform",
                dataIndex: "platform",
                title: 'platform'
            },
            {
                key: "scope",
                dataIndex: "scope",
                title: 'scope'
            },
            {
                key: "children",
                dataIndex: "children",
                title: 'children',
                render: (children: any[]) => {
                    return children?.map((child) => {
                        return (
                            <>
                                <div style={{padding: '3px 0'}}>
                                    {
                                        child.tenantChannelName && (
                                            <Tag color="purple">{child.tenantChannelName}</Tag>
                                        )
                                    }
                                    <span className="bold">{child.name}</span>
                                    <span style={{ color: "gray", marginLeft: 5, userSelect: 'none', cursor: "copy" }} title="双击复制" onDoubleClick={() => {
                                        copy(child.key);
                                        message.info("已复制")
                                    }}> {child.key} </span>
                                </div>
                            </>
                        )
                    })
                }
            },
        ]
        return [
            columns
        ]
    }, [])


    return (
        <div>
            <PageHeader 
                title="客户端应用管理"
            ></PageHeader>
            <Table
                dataSource={apps}
                columns={columns}
                pagination={false}

            />
        </div>
    )
}