import { CloudFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { SourceConnector } from '../NodeCellConnector';

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingAccepts: string;
    viewSource: any;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (item: any) => void;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const CurtainControlNodeCell = (props: Props) => {


    const { node } = props;
    const [sourceDisabled, setSourceDisabled] = useState(false);
    const [sourceSelected, setSourceSelected] = useState(false);

    useEffect(() => {
        const app = node.apps[0];
        if (props.bindMode === "view") {
            if(app.id !== props.viewSource?.id) {
                setSourceDisabled(true);
            }else {
                setSourceDisabled(false);
                setSourceSelected(true);
            }
        } else if (props.bindMode === "wait") {
            setSourceSelected(false);
            setSourceDisabled(false);
        } else if (props.bindMode === "targets") {
            if (app.id !== props.bindingSource?.id) {
                setSourceDisabled(true);
            } else {
                setSourceDisabled(false);
                setSourceSelected(true);
            }
        }
    }, [props.bindingSource, props.node, props.bindMode]);

    return (
        <div className="node-cell curtain-control-node-cell">
            <div className="three-switch">
                <div className="switch-item">开</div>
                <div className="switch-item" style={{display: 'flex', alignItems: 'center', justifyContent: "space-between", flexDirection: "column", padding: '10pX 0'}}>
                    <div style={{height: 20}}/>
                    <span>关</span>
                <div style={{height: 20}}>
                {
                    !sourceDisabled && (
                        <SourceConnector
                            selected={sourceSelected}
                            disabled={sourceDisabled}
                            connected={node.apps[0].isSource}
                            onClick={() => {
                                if (node.apps[0].isSource) {
                                    if (props.bindMode === "view") {
                                        if (typeof props.onCancelView === 'function') {
                                            props.onCancelView();
                                        }
                                    } else if (props.bindMode === "wait") {
                                        if (typeof props.onViewBindRelations === 'function') {
                                            props.onViewBindRelations(node.apps[0], "curtain");
                                        }
                                    }
                                }else {
                                    if (props.bindMode === "wait") {
                                        if (typeof props.onSetBindingSource === 'function') {
                                            props.onSetBindingSource(node.apps[0], "curtain");
                                        }
                                    } else if (props.bindMode === "targets") {
                                        if (typeof props.onResetBindingSource === "function") {
                                            props.onResetBindingSource();
                                        }
                                    }
                                }
                            }}
                        />
                    )
                }
            </div></div>
                <div className="switch-item">停</div>
            </div>
           
            <div className="prod-name">
                {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )

}