import { ClockCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { apis } from "../../../../../../utils/apis";
import { request } from "../../../../../../utils/request";

interface CaseConfigPrepareProps {
    caseId?: number;
    onOk: () => void;
}

const CaseConfigPrepare = (props: CaseConfigPrepareProps) => {

    const [times, setTimes] = useState(1);

    useEffect(() => {
        if (!props.caseId) {
            return;
        }
        prepare(props.caseId);
    }, [props.caseId, times]);


    const prepare = async (caseId: number) => {
        const res = await request.post(apis.caseConfigPrepare, { caseId: caseId }, { autoErrorTip: false });

        if (res.code === 1) {
            props.onOk();
        } else {
            Modal.confirm({
                title: "出现异常",
                centered: true,
                content: res.msg,
                okText: res.msg.indexOf("配置信息已冻结") === 0 ? "继续配置" : "重新尝试",
                onOk: () => {
                    if(res.msg.indexOf("配置信息已冻结") === 0) {
                        props.onOk();
                    }else {
                        setTimes(times + 1);
                    }
                },
                cancelText: "取消",
            });
        }
    }

    return (
        <div className="flex center column" style={{ height: 'calc(100vh - 300px)' }}>
            <ClockCircleOutlined spin className="" style={{ fontSize: 128, color: "lightblue" }} />
            <span className="bold" style={{ fontSize: 32, marginTop: 20, color: "lightblue" }}>正在进行<span style={{ color: "purple" }}>配前准备</span></span>
        </div>
    )

}

export default CaseConfigPrepare;