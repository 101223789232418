import { PlusOutlined } from "@ant-design/icons";
import { Form, FormInstance, Modal, Input, Select, Divider, Button, message } from "antd"
import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

interface Props {
    visible: boolean;
    projectCates: any[];
    onProjectUpdate: (project: any) => void;
    onProjectCateCreate: (projectCate: any) => void;
    onCancel: () => void;
    projectId: number;
}

const formRef = React.createRef<FormInstance>();

export const ProjectUpdateModal = (props: Props) => {

    const [newCateNameTemp, setNewCateNameTemp] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if(props.visible && props.projectId) {
            fetchData(props.projectId);
        }
    }, [props.projectId, props.visible]);

    const fetchData = (projectId: number) => {
        setLoading(true);
        request.get(apis.hexProject + `/${projectId}`)
            .then((res) => {
                console.log(res);
                if(res.code === 1) {
                    formRef.current?.setFieldsValue(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Modal 
            centered 
            title="修改项目" 
            visible={props.visible} 
            onOk={() => {
                formRef.current?.submit();
            }}
            okButtonProps={{
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading
            }}
            destroyOnClose
            onCancel={props.onCancel}
        >
            <Form
                ref={formRef}
                labelCol={{ span: 6 }}
                initialValues={{
                    hexGroups: []
                }}
                onFinish={(data) => {
                    request.put(apis.hexProject + `/${data.id}`, data)
                        .then((res) => {
                            if(res.code === 1) {
                                message.success("修改项目成功");
                                props.onProjectUpdate(res.data);
                                // props.onRefresh();
                            }else {
                                message.warn("修改失败");
                            }
                        }).catch((e) => {
                            message.warn("异常");
                        })
                }}
            >
                <Form.Item
                    name="id"
                >

                </Form.Item>
                <Form.Item
                    label="项目名称"
                    name="name"
                    rules={[{ required: true, message: "请输入项目名称" }]}
                >
                    <Input placeholder="请输入项目名称" />
                </Form.Item>
                <Form.Item
                    label="项目描述"
                    name="description"
                >
                    <Input.TextArea placeholder="请输入项目介绍" />
                </Form.Item>
                <Form.Item
                    label="项目分类"
                    name="cateId"
                >
                    <Select
                        style={{ width: 240 }}
                        placeholder="选择分类"
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={newCateNameTemp} onChange={(e) => {
                                        setNewCateNameTemp(e.target.value);
                                        
                                    }} />
                                    <Button type="link" onClick={() => {
                                        const newCate: any = {name: newCateNameTemp};
                                        request.post(apis.hexProjectCate, newCate)
                                            .then((res: any) => {
                                                if(res.code === 1) {
                                                    message.success("创建分类成功");
                                                    newCate.id = res.data.id;
                                                    setNewCateNameTemp(""); //清空文本
                                                    props.onProjectCateCreate(newCate);
                                                }else {
                                                    throw new Error(res.msg);
                                                }
                                            }).catch((e) => {
                                                message.warn("异常");
                                            })
                                    }}>
                                        <PlusOutlined /> 新建分类
                                    </Button>
                                </div>
                            </div>
                        )}
                    >
                        <Select.Option key={"cate-" + 0} value={0} style={{color: "gray"}}>-- 不分类 --</Select.Option>
                        {props.projectCates.length > 0 && props.projectCates.map((cate, index) => (
                            <Select.Option key={"cate-" + index} value={cate.id}>{cate.name}</Select.Option>
                        ))}

                    </Select>
                </Form.Item>
                <Form.Item
                    label="维护人员"
                    rules={[{ required: true, message: "请输入维护人员姓名" }]}
                    name="maintainer"
                >
                    <Input placeholder="请输入维护人员姓名" />
                </Form.Item>
                {/* <Form.Item
                    label="HEX文件分组"
                    name="hexGroups"

                >
                    <GroupInput />
                </Form.Item> */}

            </Form>
        </Modal >
    )

}