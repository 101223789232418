import { useEffect, useState } from "react";
import { ControlLayout, ControlWidget } from "./Connector";
import ControlProductCellWrapper, { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";
import { Switch, SwitchCellWrapper } from "./SwitchCell";

interface Props extends SwitchCellWrapper {
    silk1: { cn?: string, en?: string };
    silk2: { cn?: string, en?: string };
}

const TwoSwitchCell = (props: Props) => {

    const [sources, setSources] = useState([] as any[]);
    const [targets, setTargets] = useState([] as any[]);

    const onDataChange = (sources: any[], targets: any[]) => {
        setSources(sources);
        setTargets(targets);
    }

    return (
        <ControlProductCellWrapper
            {...props}
            onDataChange={onDataChange}
            style={{background: props.backgroundColor || "#6a6867"}}
            controlWidgets={(
                <ControlLayout
                    layout="2-bottom|bottom"
                >
                    <ControlWidget
                        source={sources[0]}
                        target={targets[0]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                    <ControlWidget
                        source={sources[1]}
                        target={targets[1]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                </ControlLayout>
            )}
        >
            <div className="flex" style={{height: "100%"}}>

                <Switch
                    border
                    width={119}
                    bottom={{ ...props.silk1, number: 0, endpoints: [sources[0], targets[0]]}}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
                <Switch
                    width={120}
                    bottom={{...props.silk2, number: 1, endpoints: [sources[1], targets[1]]}}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
                
            </div>
        </ControlProductCellWrapper>
    )
}

export default TwoSwitchCell;

