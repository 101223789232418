import { Menu } from "antd";
import { Link, Redirect, Route, RouteChildrenProps, Switch } from "react-router-dom";
import { AppsMgmt } from "../AppsMgmt";
import { RoleMgmt } from "../RoleMgmt";
import TenantMgmt from "../TenantMgmt";
import UserMgmt from "../UserMgmt";

interface Props extends  RouteChildrenProps {

}

const menus = [
    {
        key: "/upms",
        exact: true,
        menu: false,
        render: (props) => {
            console.log(props.location.pathname, "redirect, render");
            console.log(props);
            if(props.location.pathname === '/upms') {
                return (
                    <Redirect to={"/upms/apps"}/>
                )
            }
        }
    },
    {
        key: "/upms/apps",
        title: "应用管理",
        exact: true,
        path: "/upms/apps",
        component: AppsMgmt
    },
    {
        key: "/upms/role",
        title: "角色管理",
        exact: true,
        path: "/upms/role",
        component: RoleMgmt
    },
    {
        key: "/upms/user",
        title: "用户管理",
        exact: true,
        path: "/upms/user",
        component: UserMgmt
    },
    {
        key: "/upms/tenant",
        title: "租户管理",
        exact: true,
        path: "/upms/tenant",
        component: TenantMgmt
    }
]

export default function Home(props: Props) {

    return (
        <div className="flex" style={{height: "calc(100vh - 40px)", border: '1px solid #f00'}}>


            <div className="side" style={{width :200}}>
                <div>UPMS</div>
                <Menu selectedKeys={[props.location.pathname]}>
                {
                    menus.map((item) => {
                        if(item.menu === false) {
                            return ;
                        }
                        return (
                                <Menu.Item key={item.key}>
                                <Link key={item.key} to={item.path}>{item.title}</Link></Menu.Item>
                            
                        )
                    })
                }
        
                </Menu>


            </div>
            <div style={{flex: 1}}>
            {
                menus.map((item) => {
                    return (
                        <Route key={item.key} path={item.path} exact={item.exact} component={item.component} render={item.render} />
                    )
                })
            }
            </div>
        </div>
    )

}