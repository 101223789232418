import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Input, message, Form, FormInstance } from 'antd';
import React, { createRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { request } from '../../../../utils/request';
import { apis } from '../../../../utils/apis';
// import { HexGroupsManager } from ''

interface Props {
    projectId: number;
    visible: boolean;
    onRefresh: () => void;
    onOk: () => void;
    onCancel: () => void;
}

let createFormRef = createRef<FormInstance>();

export const HexGroupsManager = (props: Props) => {
 
    const [ hexGroups, setHexGroups ] = useState<any[]>([]);

    useEffect(() => {
        if(props.projectId) {
            request.get(apis.hexGroup + `?projectId=${props.projectId}`)
                .then((res) => {
                    if(res.code === 1) {
                        setHexGroups(res.data);
                    }
                })
                // .finally(
        }
    }, [props.projectId]);

    const [renameVisible, setRenameVisible] = useState(false);
    const [renameTemp, setRenameTemp] = useState("");
    const [renameId, setRenameId] = useState(0);
    const openRenameModal = (currentGroupId: number, currentName: string) => {
        setRenameVisible(true);
        setRenameId(currentGroupId);
        setRenameTemp(currentName);
    }
    const closeRenameModal = () => {
        setRenameVisible(false);
        setRenameTemp("");
        setRenameId(0);
    }

    const onRename = () => {
        request.put(apis.hexGroup + `/${renameId}`, {
            name: renameTemp
        })
        .then((res) => {
            if(res.code === 1) {
                const hexGroup = hexGroups.find((hexGroup) => hexGroup.id === res.data.id);
                hexGroup.name = renameTemp;
                setHexGroups([...hexGroups]);
                closeRenameModal();
                props.onRefresh();
            }
        })
    }


    const [ deleteVisible, setDeleteVisible ] = useState(false);
    const [ deleteLoading, setDeleteLoading ] = useState(false);
    const [ deleteId, setDeleteId ] = useState(0);
    const openDeleteModal = (id: number) => {
        setDeleteVisible(true);
        setDeleteId(id);
    }
    const closeDeleteModal = () => {
        setDeleteVisible(false);
        setDeleteId(0);
    }
    const onDelete = () => {
        request.delete(apis.hexGroup + `/${deleteId}`)
            .then((res) => {
                if(res.code === 1) {
                    const _hexGroups = hexGroups.filter((item) => item.id !== deleteId);
                    setHexGroups(_hexGroups);
                    closeDeleteModal();
                    props.onRefresh();
                    message.info("已删除");
                }
            });
    }


    const [ createVisible, setCreateVisible ] = useState(false);
    const [ createLoading, setCreateLoading ] = useState(false);
    const openGroupCreateModal = () => {
        setCreateVisible(true);
    }
    const closeGroupCreateModal = () => {
        setCreateVisible(false);
    }

    return (
        <>
        <Modal
            title="创建分组"
            centered
            visible={createVisible}
            destroyOnClose
            width={300}
            onOk={() => {
                createFormRef.current?.submit();
            }}
            onCancel={closeGroupCreateModal}
        >
            <Form ref={createFormRef} onFinish={(data) => {
                setCreateLoading(true);
                request.post(apis.hexGroup, {
                    name: data.name,
                    projectId: props.projectId,
                })
                .then((res) => {
                    if(res.code === 1) {
                        hexGroups.push(res.data);
                        setHexGroups([...hexGroups]);
                        props.onRefresh();
                        closeGroupCreateModal();
                    }
                })
                .finally(() => {
                    setCreateLoading(false);
                })
                
            }}>
                <Form.Item name="name" label="分组名">
                    <Input placeholder="请输入分组名" autoFocus autoComplete="off"/>
                </Form.Item>
            </Form>
        </Modal>
        <Modal 
            visible={renameVisible}
            centered
            width={300}
            title={"修改名称"}
            onOk={onRename}
            onCancel={closeRenameModal}
        >
            <>
                <div className="label" style={{marginBottom: 5}}>分组名称: </div>
                <div>
                    <Input autoFocus value={renameTemp} onChange={(e) => {
                        setRenameTemp(e.target.value);
                    }} placeholder="请输入分组名称" />
                </div>
            </>
        </Modal>
        <Modal 
            title="请确认是否删除该分组"
            centered 
            width={300} 
            visible={deleteVisible} 
            maskClosable={false}
            onOk={() => {
                onDelete();
            }} 
            okButtonProps={{
                danger: true,
                loading: deleteLoading,
                disabled: deleteLoading
            }}
            onCancel={() => {
                closeDeleteModal();
            }}
            cancelButtonProps={{
                disabled: deleteLoading
            }}
        >
            删除该分组后会一并删除该分组下的HEX文件等, 请确认是否删除?
        </Modal>
        <Modal 
            title="HEX分组"
            centered
            width={300}
            visible={props.visible}
            onOk={props.onOk}
            onCancel={props.onCancel}
        >
            <Table 
                size="small"
                pagination={false}
                dataSource={hexGroups}
                columns={[{
                    title: "#",
                    key: "index",
                    render: (_, __, index) => {
                        return (1 + index)
                    }
                }, {
                    title: "分组名",
                    key: "name",
                    dataIndex: "name",
                    render: (name, record) => {
                        return (
                            <span>
                                {name}
                                <EditOutlined style={{marginLeft: 5}} onClick={() => {
                                    openRenameModal(record.id, name)
                                }}/>
                            </span>
                        )
                    }
                }, {
                    title: "操作",
                    key: "actions",
                    render: (_, record) => {
                        return (
                            <>
                                <Button title={hexGroups.length <  2 ? "至少保留一个分组" : ""} disabled={hexGroups.length < 2} size="small" danger ghost onClick={() => {
                                    openDeleteModal(record.id);
                                }}>删除</Button>
                            </>
                        )
                    }
                }]}
            />

            <Button type="link" style={{marginTop: 10, marginBottom: -5, textAlign: 'center'}} onClick={openGroupCreateModal}><PlusCircleOutlined /> 添加分组</Button>
        </Modal>
        </>
    )

}