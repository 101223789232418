import { PlusOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import ModalForm from "../../../components/ModalForm";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface AreasLayoutProps {
    case?: any;
    onAreaChange?: (area: any) => void;
    onAreasChange?: (areas: any[]) => void;
    children?: ReactNode;
    //区域选择器
    selectedAreaId?: number;
    // areaSelectorVisible: boolean;
    // onAreaSelected: (area: any) => void;
    // onCloseAreaSelector: () => void;
}
function AreasLayout(props: AreasLayoutProps) {

    const [loading, setLoading] = useState(true);
    const [areas, setAreas] = useState([] as any[]);
    const [selectedAreaId, setSelectedAreaId] = useState(0);
    const [hasTip, setHasTip] = useState(false);


    useEffect(() => {
        getCaseAreas(props.case?.caseId);
    }, [props.case]);

    const getCaseAreas = async (caseId: number) => {
        if (!caseId) {
            setAreas([]);
            return;
        }
        setLoading(true);
        try {
            const res = await request.get(apis.area, { caseId });
            if (res.code === 1) {
                setAreas(res.data);
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        props.onAreasChange && props.onAreasChange(areas);
    }, [areas]);

    useEffect(() => {
        // console.log(props.case, areas, hasTip, loading);
        
        if (loading) {
            return;
        }
        if(!props.case) {
            return ;
        }
        if (areas.length === 0 && !hasTip) {
            addAreaRef.current?.open(props.case.caseId, "请创建第一个区域")
            setHasTip(true);
        }
    }, [props.case, areas, hasTip, loading]);

    useEffect(() => {
        if(areas.length > 0 && !selectedAreaId) {
            setSelectedAreaId(areas[0].id)
        }
    }, [selectedAreaId, areas])

    useEffect(() => {
        if (!selectedAreaId) {
            props.onAreaChange && props.onAreaChange(null);
        } else {
            const area = areas.find((a) => a.id === selectedAreaId);
            props.onAreaChange && props.onAreaChange(area);
        }
    }, [selectedAreaId]);

    const addAreaRef = useRef<any>();
    const onAreaAdd = (area: any) => {
        areas.push(area);
        setAreas([...areas]);
        setSelectedAreaId(area.id);
    }
    const onAddArea = () => {
        addAreaRef.current?.open(props.case?.caseId);

    }

    return (
        <div className="areas-layout-props">
            <div className="areas " style={{ padding: 10 }}>
                {/* <div className={`area-item ${0 === selectedAreaId ? 'bg-primary' : ''}`} key="all" onClick={() => {
                    setSelectedAreaId(0)
                }}>全部</div> */}
                {
                    areas.length > 0 && areas.map((area, index) => {
                        return (
                            <div key={"key-" + index} className={`area-item ${area.id === selectedAreaId ? 'bg-primary' : ''}`} onClick={() => {
                                setSelectedAreaId(area.id)
                            }}>
                                {area.name}
                            </div>
                        )
                    })
                }

                <div className="area-item area-add" onClick={onAddArea}>
                    <PlusOutlined /> 添加区域
                </div>

            </div>
            {props.children}
            <AreaAddModal
                ref={addAreaRef}
                onAreaAdd={onAreaAdd}
            />
        </div>
    )
}

export default AreasLayout;


interface AreaAddModalProps {
    onAreaAdd: (area: any) => void;
}
export const AreaAddModal = forwardRef((props: AreaAddModalProps, ref: any) => {

    const [caseId, setCaseId] = useState(0);
    const [tip, setTip] = useState("");
    const ref2 = useRef<any>();
    useImperativeHandle(ref, () => {
        return {
            open: (caseId: number, tip?: string) => {
                console.log("open", caseId, tip);
                
                setCaseId(caseId);
                setTip(tip || "");
                ref2.current?.open();
            }
        }
    });
    const onAddArea = async (data: any) => {
        data.caseId = caseId;
        const res = await request.post(apis.area, data);
        if(res.code === 1) {
            props.onAreaAdd(res.data);
            return true;
        }
    }

    return (
        <ModalForm
            ref={ref2}
            title={"添加区域"}
            onSubmit={onAddArea}
            maskClosable={false}
        >
            <Form.Item label="区域" name="name" rules={[{ required: true, message: "请输入区域名称" }]}>
                <Input placeholder="请输入区域名称" autoFocus autoComplete="off" />
            </Form.Item>

            {
                tip && (
                    <div className="color-primary" style={{ fontSize: 14, marginLeft: '3em' }}>{tip}</div>
                )
            }
        </ModalForm>
    )
})