import { LeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface Props extends RouteChildrenProps {
    caseId: number;
    onCaseChange?: (c: any) => void;
    children?: ReactNode;
    extra?: ReactNode | ReactNode[]
}

const CaseLayout = (props: Props) => {

    const [c, setCase] = useState(null as any);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.caseId) {
            getCase(props.caseId)
        }
    }, [props.caseId]);

    useEffect(() => {

    }, []);

    const getCase = async (caseId: number) => {
        setLoading(true);
        try {
            const res = await request.get(apis.case + `/${caseId}`);
            if (res.code === 1) {
                setCase(res.data);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        props.onCaseChange(c);
    }, [c]);


    return (

        <div className="case-editor-page">
            <div className="flex center bg-white" style={{ justifyContent: 'space-between', borderBottom: '1px solid rgba(19,18,43,0.07)' }}>
                <div className=" flex" style={{ height: 48, alignItems: 'center' }}>
                    <div className="flex center customer" style={{ alignItems: 'center', cursor: "pointer", margin: '0 5px' }} onClick={() => {
                        props.history.replace(`/project/${c?.customerId}`)
                    }}>
                        <div style={{ padding: '0 5px 0 5px' }}>
                            <LeftOutlined style={{ fontSize: 16 }} />
                        </div>
                        <div style={{ fontSize: 16, paddingRight: 8 }}>
                            <span style={{ position: 'relative', top: -3 }}>{c?.customerName || '客户'}</span>
                        </div>
                    </div>
                    <div style={{ height: '50%', width: 1, backgroundColor: "#aaa" }} />
                    <div style={{ padding: 10, alignItems: 'center' }} className="flex">
                        <span style={{ fontSize: 16, fontWeight: 'bold', position: 'relative', top: -3, marginRight: 10 }}>{c?.caseName}方案</span>
                    </div>
                </div>
                <div className="flex" style={{ paddingRight: 10, alignItems: 'center' }}>
                    <Button type="primary" icon={(<SaveOutlined />)}>完成</Button>
                    {
                        props.extra && (
                            <div style={{marginLeft: 10}}>{props.extra}</div>
                        )
                    }
                    
                </div>
            </div>
            <Spin spinning={loading}>
                {props.children}
            </Spin>
        </div>
    )

}

export default CaseLayout;