import { useEffect, useState } from "react";
import { ControlLayout, ControlWidget } from "./Connector";
import ControlProductCellWrapper, { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";
import { Switch, SwitchCellWrapper } from "./SwitchCell";

interface Props extends SwitchCellWrapper {
    silk1: { cn?: string, en?: string };
}

const OneSwitchCell = (props: Props) => {

    const [sources, setSources] = useState([] as any[]);
    const [targets, setTargets] = useState([] as any[]);

    const onDataChange = (sources: any[], targets: any[]) => {
        setSources(sources);
        setTargets(targets);
    }

    return (
        <ControlProductCellWrapper
            {...props}
            style={{background: props.backgroundColor || "#6a6867"}}
            onDataChange={onDataChange}
            controlWidgets={(
                <ControlLayout
                    layout="1-bottom"
                >
                    <ControlWidget
                        source={sources[0]}
                        target={targets[0]}
                        onSourceClick={props.onSourceClick}
                        onTargetClick={props.onTargetClick}
                        onControlLineClick={props.onControlLineClick}
                        sourceEndpoint={props.bindSourceEndpoint}
                        targetEndpoints={props.bindTargetEndpoints}
                        nameDisable
                    />
                </ControlLayout>
            )}
        >
            <Switch
                bottom={{...props.silk1, number: 0, endpoints: [sources[0], targets[0]]}}
                onChange={props.onSilkChange}
                editable={props.editable}
                color={props.color}
            />
        </ControlProductCellWrapper>
    )
}

export default OneSwitchCell;

