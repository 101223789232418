import React from 'react';
import { SwitchNodeCell } from '../components/SwitchNodeCell';

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    bindingTargets: any[];
    bindingAccepts: string;
    onSetBindingSource?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onNodeAppUpdate?: (nodeApp: any) => void;
    onViewTargetOf?: (nodeApp: any) => void;
    onRenameApp: (app: any) => void;
    viewSource: any;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

const endpointPairMap: any = {
    2: 9,
    9: 2
}

export const OneButtonSwitchNodeCell_v2 = (props: Props) => { 
    return (
        <SwitchNodeCell className="one-switch" {...props} endpointPairMap={endpointPairMap} />
    )
}