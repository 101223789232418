import React, { useState } from 'react'
import { useEffect } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { apis, host } from '../../utils/apis';
import { request } from '../../utils/request';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';
import './style.less';
import { Button, Divider, message, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import CommonLoginPage2 from '../../components/@common/CommonLoginPage';

interface Props extends RouteChildrenProps {

}

export const PageLogin = (props: Props) => {

    return (
        <CommonLoginPage2 
            logo={(
                <div className='flex center'>
                    <img className='relative' style={{position: 'relative', top: 1, marginRight: 6}} src="/favicon.ico" width={22} height={22}/>
                    <span style={{fontSize: 20}}>华飞物联后台</span>
                </div>
            )}
            imageUrl={"http://www.huafeiiot.com/upload/2018/12/26/1545816153878841aeg.jpg"}
            accountLogin={{
                onLogin: async (account: string, password: string) => {
                    const params = {
                        account,
                        password,
                        clientAppKey: 'hfms[browser]',
                        platform: "browser",   
                    }
                    const res = await request.post(apis['/upms/login'], params, {
                        autoErrorTip: false
                    });
                    if(res.access_token) {
                        return true;
                    }else {
                        message.warn("登陆失败，请检查账号或密码");
                        return false;
                    }
                    
                }
            }}
            // scanLogin={{
            //     code: "123",
            //     tip: "使用微信扫码进行登录"
            // }}
            onLoginSuccess={() => {
                props.history.replace("/")
            }}
        />
    )
}


let codeTemp = "";
let timer: any;
interface ScanLoginProps extends RouteChildrenProps {

}
export const ScanLogin = (props: ScanLoginProps) => {

    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [code, setCode] = useState("");
    const [logining, setLogining] = useState(false);

    const scanStatus = () => {

        return request.get(apis.authScanStatus + `/${codeTemp}`, {})
            .then((res) => {
                if (res.data < 0 || res.code < 0) {
                    refreshCode(true);
                } else if (res.data === 1) {
                    clearInterval(timer);
                    onLogin();
                }
            })
    }

    useEffect(() => {
        refreshCode();
        timer = setInterval(scanStatus, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);


    useEffect(() => {
        codeTemp = code;
    }, [code]);

    const onLogin = () => {
        setLogining(true);
        let localId = localStorage.getItem("local-id");
        request.post(apis.login + `/user-scan`, { code: codeTemp, localId: localId })
            .then((res) => {
                if (res && res instanceof Object && "access_token" in res) {
                    message.success("登录成功！");
                    localStorage.setItem("SK_LOGIN_INFO", JSON.stringify(res));
                    localStorage.setItem("SK_TOKEN_EXPIRE_AT", (Date.now() + res.expires_in * 1000).toString());
                    setTimeout(() => {
                        const redirect = localStorage.getItem("SK_LOGIN_REDIRECT");
                        if (!redirect) {
                            props.history.replace("/");
                        } else {
                            localStorage.removeItem("SK_LOGIN_REDIRECT");
                            props.history.replace(redirect);
                        }
                    }, 1000);
                } else {
                    message.warn("登录失败");
                }
            })
            .catch((e) => {
                message.warn("登录失败");
            })
            .finally(() => {
                setLogining(false);
            })
    }

    const refreshCode = (tip?: boolean) => {
        setRefreshing(true);
        let localId = localStorage.getItem("local-id");
        if (!localId) {
            localId = uuidv4();
            localStorage.setItem("local-id", localId);
        }
        return request.get(apis.authScanCode + "?localId=" + localId)
            .then((res) => {
                if (res.code === 1) {
                    if (tip) {
                        message.info("二维码已刷新");
                    }
                    setCode(res.data)
                } else {
                    message.warn(res.msg)
                }
            })
            .finally(() => {
                if (loading) {
                    setLoading(false);
                }
                setRefreshing(false);
            });
    }

    return (
        <div>
            <Spin spinning={refreshing || loading}>
                <div className="qr-code-wrapper">
                    {
                        loading ? (
                            <div style={{ height: 200, width: 200 }} />
                        ) : (
                            <Spin spinning={logining} tip={"正在登录..."}>
                                <QRCode
                                    value={"http://www.huafeiiot.com/qrc?scanlogin=" + code}  //value参数为生成二维码的链接
                                    size={200} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                />
                            </Spin>
                        )
                    }
                    <div style={{ marginTop: 20 }}>微信扫码登录 </div>
                    {/* <Button type="link" onClick={refreshCode}><ReloadOutlined /> 刷新二维码</Button> */}
                </div>
            </Spin>
        </div>
    )

}