import React, { useEffect } from 'react'
import { Button, Input, message, Modal, Tag } from 'antd'
import { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons';
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';

interface Props {
    caseId: number,
    nodes: any[],
    visible: boolean;
    onCancel: () => void;
    onSceneAdd: (scene: any) => void;
}

export const CaseSceneCreateModal = (props: Props) => {

    const [nodes, setNodes] = useState(props.nodes);
    const [targets, setTargets] = useState([] as any[]);
    const [selectedTargetKeys, setSelectedTargetKeys] = useState<string[]>([]);
    const [sceneApps, setSceneApps] = useState([] as any[]);
    const [sceneNameTemp, setSceneNameTemp] = useState("");


    useEffect(() => {
        setNodes(props.nodes);
    }, [props.nodes]);


    const onAddSceneApp = (target: any, commend: 0 | 1) => {
        const key = `${target.caseNodeId}-${target.endpoint}`;
        if (!selectedTargetKeys.includes(key)) {
            sceneApps.push({
                caseNodeId: target.caseNodeId,
                caseAreaName: target.caseAreaName,
                sceneAppName: target.appName,
                endpoint: target.endpoint,
                caseAppId: target.appId,
                commend,
            });
            selectedTargetKeys.push(key);
            setSceneApps([...sceneApps]);
            setSelectedTargetKeys([...selectedTargetKeys]);
        }
    }

    const onRemoveSceneApp = (sceneApp: any) => {
        const key = `${sceneApp.caseNodeId}-${sceneApp.endpoint}`;
        const _selectedTargetKeys = selectedTargetKeys.filter((_key) => _key !== key);
        const _sceneApps = sceneApps.filter((_sceneApp) => {
            const _key = `${_sceneApp.caseNodeId}-${_sceneApp.endpoint}`;
            return _key !== key;
        });
        setSceneApps(_sceneApps);
        setSelectedTargetKeys(_selectedTargetKeys);
    }


    useEffect(() => {
        const targets: any[] = [];
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if(!node.apps) {
                continue ;
            }
            for (let j = 0; j < node.apps.length; j++) {
                const app = node.apps[j];
                if (app.type?.indexOf("target") > -1) {
                    targets.push({
                        caseNodeId: node.caseNodeId || node.id,
                        nodeName: node.caseNodeId + '-' + node.name,
                        caseAreaId: node.caseAreaId || node.areaId,
                        caseAreaName: node.caseAreaName || node.areaName,
                        areaName:  node.caseAreaName || node.areaName,
                        endpoint: app.endpoint,
                        appName: app.name,
                        appType: app.type,
                        appId: app.id,
                        isWireless: app.isWireless
                    });
                }
            }
        }
        setTargets(targets)
    }, [nodes]);


    const reset = () => {
        setSceneNameTemp("");
        setSelectedTargetKeys([]);
        setSceneApps([]);
    }

    const onCreateScene = (data: any) => {
        request.post(apis.caseScene, data)
            .then((res) => {
                if (res.code === 1) {
                    message.success("已创建");
                    props.onSceneAdd(res.data);
                    reset();
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }


    return (
        <Modal
            title="创建场景"
            centered
            visible={props.visible}
            onCancel={props.onCancel}
            width={600}
            onOk={() => {
                if (!sceneNameTemp) {
                    message.warn("请输入场景名称");
                    return;
                }
                const data = {
                    caseId: props.caseId,
                    name: sceneNameTemp,
                    caseApps: sceneApps.map((sceneApp) => {
                        return {
                            caseAppId: sceneApp.caseAppId,
                            commend: sceneApp.commend,
                        }
                    })
                }
                onCreateScene(data);
            }}
        >
            <div className="create-scene-content" style={{ maxHeight: 600, overflow: 'auto' }}>
                <div>
                    <Input placeholder="请输入场景名称" value={sceneNameTemp} onChange={(e) => {
                        setSceneNameTemp(e.target.value);
                    }} />
                </div>
                <div style={{ padding: '10px 0', color: "orange" }}>Tip：机械面板的按钮，只有接控制线的才能作为被绑定目标</div>
                <div style={{ display: 'flex' }}>
                    <div className="selector">
                        {
                            targets.length > 0 && targets.map((target) => {
                                if (target.isWireless === 1) {
                                    return;
                                }
                                const key = `${target.caseNodeId}-${target.endpoint}`;
                                return (
                                    <div style={{ display: 'flex' }} className={"scene-app-item " + (selectedTargetKeys.includes(key) ? "disabled" : "")}>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            {
                                                <Tag>{target.areaName}</Tag>
                                            }
                                            <span>{target.nodeName} - </span>
                                            <span>{target.appName}</span>
                                        </div>

                                        <div style={{ display: 'flex', width: 95 }}>
                                            <Button disabled={selectedTargetKeys.includes(key)} type="primary" size="small" style={{ marginRight: 8 }} onClick={() => {
                                                onAddSceneApp(target, 1);
                                            }}>打开</Button>
                                            <Button disabled={selectedTargetKeys.includes(key)} type="primary" size="small" onClick={() => {
                                                onAddSceneApp(target, 0);
                                            }}>关闭</Button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="result">
                        <div style={{ padding: '5px 0' }}>场景效果:</div>
                        {
                            sceneApps.length > 0 && sceneApps.map((sceneApp) => {
                                return (
                                    <div className="scene-description" style={{ padding: "3px 0" }}>
                                        {sceneApp.commend === 0 ? "关闭" : "打开"}
                                        {sceneApp.caseAreaName}的
                                        {sceneApp.sceneAppName}
                                        <div className="close inline-block" onClick={() => {
                                            onRemoveSceneApp(sceneApp)
                                        }}>
                                            <CloseOutlined style={{ color: 'gray' }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}