import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ControlLayout, ControlWidget } from "./Connector";
import ControlProductCell from "./ControlProductCell";
import ControlProductCellWrapper, { ControlProductCellWrapperProps } from "./ControlProductCellWrapper";
import { Switch, SwitchCellWrapper } from "./SwitchCell";

interface Props extends SwitchCellWrapper {
    silk1: { cn?: string, en?: string };
    silk2: { cn?: string, en?: string };
    silk3: { cn?: string, en?: string };
    silk4: { cn?: string, en?: string };
}

const FourSwitchCell = forwardRef((props: Props, ref: any) => {

    const [sources, setSources] = useState([] as any[]);
    const [targets, setTargets] = useState([] as any[]);

    const onDataChange = (sources: any[], targets: any[]) => {
        
        setSources(sources);
        setTargets(targets);
    }


    const [fourTargets, setFourTargets] = useState([]);
    useEffect(() => {
        const fourTargets: any[] = [8, 9, 10, 11];
        for (let i = 0; i < fourTargets.length; i++) {
            const item = fourTargets[i];
            const target = targets.find((t) => t.endpoint === item);
            fourTargets[i] = target;
        }
        setFourTargets(fourTargets);
    }, [targets]);

    const [bind, setBind] = useState(null);

    useImperativeHandle(ref, () => {
        return {
            
        }
    })

    return (
        <ControlProductCellWrapper
            {...props}
            style={{background: props.backgroundColor || "#6a6867"}}
            onDataChange={onDataChange}
            controlWidgets={(
                <ControlLayout
                    layout="4-left-top|bottom|right-bottom|top"
                >
                {
                    sources.length > 0 && fourTargets.length > 0 && sources.map((s, i) => {
                        // console.log("s:");
                        // console.log(s);
                        return (
                            <ControlWidget
                                source={sources[i]}
                                target={fourTargets[i]}
                                onSourceClick={props.onSourceClick}
                                onTargetClick={props.onTargetClick}
                                onControlLineClick={props.onControlLineClick}
                                sourceEndpoint={props.bindSourceEndpoint}
                                targetEndpoints={props.bindTargetEndpoints}
                                nameDisable
                            />
                        )
                    })
                }
                </ControlLayout>
            )}
        >
            <div className="flex" style={{ height: "100%" }}>
                <Switch
                    border
                    width={119}
                    top={{ ...props.silk1, number: 0, endpoints: [sources[0], fourTargets[0]] }}
                    bottom={{ ...props.silk2, number: 1, endpoints: [sources[1], fourTargets[1]] }}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
                <Switch
                    border
                    width={120}
                    top={{ ...props.silk4, number: 3, endpoints: [sources[3], fourTargets[3]] }}
                    bottom={{ ...props.silk3, number: 2, endpoints: [sources[2], fourTargets[2]] }}
                    onChange={props.onSilkChange}
                    editable={props.editable}
                    color={props.color}
                />
            </div>
        </ControlProductCellWrapper>
    )
})

export default FourSwitchCell;

