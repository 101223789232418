import { Descriptions, Table } from "antd";
import dayjs from "dayjs";
import './print-template-style.less';

interface OrderA4TemplateProps {
    customer: any;
    products: any[];
}

const OrderA4Template = (props: OrderA4TemplateProps) => {
    return (
        <div className="a4-template bg-white portrait" style={{ width: "calc(21cm)", minHeight: '29.7cm', cursor: "grab", boxSizing: "content-box" }}>
            <div style={{ padding: 10 }}>

                <Descriptions 
                    title={(
                        <div className="flex" style={{ alignItems: 'center', marginBottom: 20 }}>
                            <img src={"/favicon.ico"} className="relative" style={{ top: 0, height: 16, width: 16 }} />
                            <span style={{ marginLeft: 5 }}>华飞物联 - 订单</span>
                        </div>
                    )} 
                    style={{ fontSize: 14 }}
                    bordered 
                    extra={(
                        <span style={{ position: 'relative', top: -10 }}>订单号：HFDHD{dayjs().format('YYYYMMDDsss')}</span>
                    )}
                    labelStyle={{background: "#eee"}}
                >
                    <Descriptions.Item label="客户名称" labelStyle={{ width: 100 }}>{props.customer?.name}</Descriptions.Item>
                    <Descriptions.Item label="制单员" labelStyle={{ width: 100 }}><></></Descriptions.Item>
                    <Descriptions.Item label="业务员" labelStyle={{ width: 100 }}><></></Descriptions.Item>
                    <Descriptions.Item label="客户地址" span={5}>
                        <span>{props.customer?.address}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="提交日期" span={1.5}>{dayjs().format('YYYY年MM月DD日 HH:mm')}</Descriptions.Item>
                    <Descriptions.Item label="交货日期" span={1.5}><></></Descriptions.Item>
                    <Descriptions.Item label="订单备注" span={5}>
                        面板颜色: 
                    </Descriptions.Item>
                </Descriptions>
                <Table
                    pagination={false}
                    // style={{marginTop: 10}}
                    dataSource={props.products}
                    
                    columns={[{
                        title: (<span style={{ marginLeft: 10 }}>产品列表</span>),
                        dataIndex: "spu",
                        width: 300,
                        className: "eee",
                        render: (spu) => {
                            return (
                                <div className="flex" style={{ marginLeft: 5 }}>
                                    <div className="flex center relative" style={{ top: 1, height: 36, width: 36, background: "#f2f2f2", cursor: "pointer" }}>
                                        <img src={spu.imageUrl} alt="" height={"100%"} width={"100%"} />
                                    </div>
                                    <div className="column" style={{ fontSize: 12, marginLeft: 10 }}>
                                        <div className="">{spu.spuName}</div>
                                        <div style={{fontSize: 12}}>{spu.spuMode}</div>
                                    </div>
                                </div>
                            )
                        }
                    }, {
                        title: "数量",
                        key: "quantity",
                        dataIndex: "quantity",
                        align: 'center',
                        width: 80,
                        className: "eee",
                        render: (quantity, product) => {
                            return (
                                <span className="" style={{ fontSize: 16 }}>{quantity} <span className="" style={{ fontWeight: 'normal', fontSize: 14}}>{product.spu.spuUnitName}</span> </span>
                            )
                        }
                    }, {
                        dataIndex: "remark",
                        align: 'left',
                        className: "eee",
                        // render: (remark, spu: any) => {
                        //         <span>{remark}<Button type="link" style={{ margin: '0 0 5px 0', padding: '0 0 5px 0' }}><EditOutlined />编辑</Button></span>
                        //     )
                        // }
                    }]}
                />
            </div>
        </div >
    )


}

export default OrderA4Template;