import { AppstoreOutlined, CalculatorOutlined, CodepenOutlined, DashboardFilled, FileOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Breadcrumb, Layout, Menu } from "antd";
import { Route, RouteChildrenProps } from "react-router-dom";
import HomeFileManagerPage from "../../#hex-manager/Home/index2";
import HexProjectListPage from "../HexVersionList";
import ProductListPage from "../ProductListPage";
import CaseProductAdd from "../CaseProductAdd";
import CaseProductList from "../CaseProductList";

interface Props extends RouteChildrenProps {

}

export function HomePage(props: Props) {

    return (
        <div style={{ height: 'calc(100vh - 40px)', }}>
            <Layout style={{ height: '100%' }}>
                <Layout.Sider style={{ height: '100%' }} theme="light">
                    <Menu defaultOpenKeys={["hex-management"]} selectedKeys={[props.location.pathname]} mode="inline">
                        <Menu.Item 
                            key={"/product/case-product"} 
                            icon={(<CodepenOutlined />)}
                            onClick={() => {
                                props.history.push("/product/case-product");
                            }}
                        >智能产品</Menu.Item>
                        <Menu.Item 
                            key={"/product/list"} 
                            icon={(<CodepenOutlined />)}
                            onClick={() => {
                                props.history.push("/product/list");
                            }}
                        >产品管理</Menu.Item>
                        <Menu.Item 
                            key={"/product/device-list"} 
                            icon={(<AppstoreOutlined />)}
                            onClick={() => {
                                props.history.push("/product/device-list");
                            }}
                        >设备管理</Menu.Item>
                        <Menu.Item 
                            key={"/product/hex-file"} 
                            icon={(<FileOutlined />)}
                            onClick={() => {
                                props.history.push('/product/hex-file')
                            }}
                        >HEX管理</Menu.Item>
                    </Menu>
                </Layout.Sider>
                <Layout>
                    <Route path={"/product/case-product"} exact component={CaseProductList} />
                    <Route path={"/product/list"} exact component={ProductListPage} />
                    <Route path={"/product/add"} exact component={CaseProductAdd} />
                    <Route path={"/product/hex-project"} exact component={HexProjectListPage} />
                    <Route path={"/product/hex-file/:projectId?"} exact component={HomeFileManagerPage} />
                </Layout>
            </Layout>
        </div>
    )


}