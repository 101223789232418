import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, message, Modal, Divider, Select, Radio, Checkbox } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { specValuesToSkus } from '../../../utils';
import { apis } from '../../../utils/apis';
import { request } from '../../../utils/request';

interface Props {
    // visible: boolean,
    // onCancel: () => void,
    // cates: any[],
    // onRefresh: () => Promise<void>,
    onSpuUpdate: (spu: any) => void
}


const spuUpdateFormRef = React.createRef<any>();
const tagCreateInputRef = React.createRef<any>();
const cateCreateInputRef = React.createRef<any>();
const unitCreateInputRef = React.createRef<any>();

const ProductEditModal = forwardRef((props: Props, ref: any) => {

    const [spuCates, setSpuCates] = useState<any[]>([]);
    const [spuTags, setSpuTags] = useState<any[]>([]);
    const [spuUnits, setSpuUnits] = useState<any[]>([]);

    const [updateLoading, setUpdateLoading] = useState(false);
    const [cateupdateLoading, setCateupdateLoading] = useState(false);
    const [unitupdateLoading, setUnitupdateLoading] = useState(false);
    const [tagupdateLoading, setTagupdateLoading] = useState(false);


    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [res1, res2, res3] = await Promise.all([
            request.get(apis.productSpuCate),
            request.get(apis.productSpuTag),
            request.get(apis.productSpuUnit)
        ]);
        if(res1.code === 1) {
            setSpuCates(res1.data);
        }
        if(res2.code === 1) {
            setSpuTags(res2.data);
        }
        if(res2.code === 1) {
            setSpuUnits(res3.data);
        }
    }

    const [spu, setSpu] = useState<any>(null);
    const [visible, setVisible] = useState(false);

    const open = (spu: any) => {
        setSpu(spu);
        console.log(spu);
        
        setVisible(true);
    }
    const close = () => {
        setSpu(null);
        setVisible(false);
    }
    useImperativeHandle(ref, () => {
        return {
            open,
        }
    });

    const [tagCreateTemp, setTagCreateTemp] = useState("");
    const [unitCreateTemp, setUnitCreateTemp] = useState("");
    const [cateCreateTemp, setCateCreateTemp] = useState("");
    //以下两项都会对sku产生影响
    const [spuNameTemp, setSpuNameTemp] = useState("");
    const [specValueTemp, setSpecValueTemp] = useState<any[]>([]);

    const onFinish = async (data: any) => {
        const dto = {
            name: data.name,
            desc: data.desc,
            mode: data.mode,
            spuCateId: data.cate,
            spuUnitId: data.unit,
            spuTagIds: data.tags,
            isSmart: Number(Boolean(data.isSmart)),
            chipType: data.chipType,
            gatewayType: data.gatewayType,
            isRowFrame: data.isRowFrame,
            supportRowFrame: data.supportRowFrame,
            endpoints: data.endpoints?.join(',') || ""
        }

        setUpdateLoading(true);
        request.put(apis.productSpu + `/${spu.spuId}`, dto).then((res) => {
            if (res.code === 1) {
                message.success("已修改！");
                close();
                props.onSpuUpdate(res.data);
            } else {
                throw new Error(res.msg);
            }
        }).finally(() => {
            setUpdateLoading(false);
        });
    }

    return (
        <Modal
            maskClosable={false}
            visible={visible} 
            title="编辑产品"
            onOk={() => {
                spuUpdateFormRef.current?.submit();
            }}
            onCancel={close}
            okButtonProps={{
                loading: updateLoading
            }}
            cancelButtonProps={{
                disabled: updateLoading
            }}
            destroyOnClose 
            bodyStyle={{height: 700, overflowY: 'auto'}}
        >
            <Form
                ref={spuUpdateFormRef}
                initialValues={{
                    name: spu?.spuName,
                    desc: spu?.desc,
                    mode: spu?.spuMode,
                    unit: spu?.spuUnitId,
                    cate: spu?.spuCateId,
                    tags: spu?.spuTags?.map((item: any) => item.tagId) || [],
                    isSmart: spu?.isSmart,
                    chipType: spu?.chipType,
                    gatewayType: spu?.gatewayType,
                    isRowFrame: spu?.isRowFrame,
                    supportRowFrame: spu?.supportRowFrame,
                    endpoints: spu?.endpoints,
                    displayOrderNo: 1,
                }}
                onValuesChange={(changedValues) => {
                    if (Object.keys(changedValues).includes('name')) {
                        setSpuNameTemp(changedValues.name);
                        specValuesToSkus(specValueTemp, (skus: any[]) => {
                            spuUpdateFormRef.current?.setFieldsValue({
                                skus: skus,
                            });
                        });
                    }
                    if (Object.keys(changedValues).includes('specValues')) {
                        spuUpdateFormRef.current?.setFieldsValue({
                            specValues: changedValues.specValues,
                        });
                        specValuesToSkus(changedValues.specValues, (skus: any[]) => {
                            spuUpdateFormRef.current?.setFieldsValue({
                                skus: skus,
                            });
                        });
                    }
                    if (Object.keys(changedValues).includes('specValues')) {
                        setSpecValueTemp(changedValues.specValues);
                    }

                }}
                onFinish={onFinish}
                labelCol={{ span: 3 }}
            >
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{
                        required: true,
                        message: "请输入商品名称"
                    }]}
                >
                    <Input placeholder="请输入商品名称" autoFocus autoComplete="off" />
                </Form.Item>
                <Form.Item
                    label="型号"
                    name="mode"
                    rules={[{
                        required: true,
                        message: "请输入商品型号"
                    }]}
                >
                    <Input placeholder="长度小于20" autoComplete="off" />
                </Form.Item>

                <Form.Item
                    label="分类"
                    name="cate"
                    rules={[{
                    required: true,
                    message: "请选择商品分类"
                    }]}
                >
                    <Select
                        dropdownRender={menu => {
                            return (
                                <div>
                                    {menu}
                                    <Divider />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input ref={cateCreateInputRef} style={{ flex: 'auto' }} value={cateCreateTemp} onChange={(e) => {
                                            setCateCreateTemp(e.target.value);
                                        }} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (!cateCreateTemp) {
                                                    message.info("请输入分类名");
                                                } else {
                                                    setCateupdateLoading(true);
                                                    request.post(apis.productSpuCate, {
                                                        name: cateCreateTemp,
                                                    }).then((res) => {
                                                        if (res.code === 1) {
                                                            // props.onRefresh()
                                                            setCateCreateTemp("");
                                                            message.success("已创建");
                                                        } else {
                                                            throw new Error(res.msg);
                                                        }
                                                    }).catch((e) => {
                                                        message.warn(e.message);
                                                    }).finally(() => {
                                                        setCateupdateLoading(false);
                                                    });
                                                }
                                            }}
                                        >
                                            {
                                                cateupdateLoading ? (
                                                    <LoadingOutlined spin />
                                                ) : (
                                                    <PlusOutlined />
                                                )
                                            }
                                            添加分类
                                        </a>
                                    </div>
                                </div>
                            )
                        }}
                    >
                        {
                            spuCates.length > 0 && spuCates.map((cate, index) => {
                                return (
                                    <Select.Option key={"goods-cate-" + index} value={cate.id}>{cate.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="单位"
                    name="unit"
                    rules={[{
                        required: true,
                        message: "请选择产品单位"
                    }]}
                >
                    <Select
                        dropdownRender={menu => {
                            return (
                                <div>
                                    {menu}
                                    <Divider />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input ref={unitCreateInputRef} style={{ flex: 'auto' }} onChange={(e) => {
                                            setUnitCreateTemp(e.target.value);
                                        }} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (!unitCreateTemp) {
                                                    message.info("请输入单位名");
                                                } else {
                                                    request.post(apis.productSpuUnit, {
                                                        name: unitCreateTemp,
                                                    }).then((res) => {
                                                        if (res.code === 1) {
                                                            setUnitCreateTemp("");
                                                            init();
                                                        } else {
                                                            throw new Error(res.msg);
                                                        }
                                                    }).catch((e) => {
                                                        message.warn(e.message);
                                                    });
                                                }
                                            }}
                                        >
                                            {
                                                tagupdateLoading ? (
                                                    <LoadingOutlined spin />
                                                ) : (
                                                    <PlusOutlined />
                                                )
                                            }
                                            添加单位
                                        </a>
                                    </div>
                                </div>
                            )
                        }}
                    >
                        {
                            spuUnits.length > 0 && spuUnits.map((unit, index) => {
                                return (
                                    <Select.Option key={"spu-unit-" + index} value={unit.id}>{unit.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="标签"
                    name="tags"
                >
                    <Select
                        mode="multiple"
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input ref={tagCreateInputRef} style={{ flex: 'auto' }} onChange={(e) => {
                                        setTagCreateTemp(e.target.value);
                                    }} />
                                    <a
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={() => {
                                            if (!tagCreateTemp) {
                                                message.info("请输入标签名");
                                            } else {
                                                request.post(apis.productSpuTag, {
                                                    name: tagCreateTemp,
                                                }).then((res) => {
                                                    if (res.code === 1) {
                                                        setTagCreateTemp("");
                                                        const data: any = res.data;
                                                        const oldTags = spuUpdateFormRef.current?.getFieldValue("tags");
                                                        spuTags.push(data);
                                                        setSpuTags([...spuTags]);
                                                        oldTags.push(data.id);
                                                        spuUpdateFormRef.current?.setFieldsValue({
                                                            tags: [...oldTags],
                                                        });
                                                        tagCreateInputRef.current?.setValue("");
                                                    } else {
                                                        throw new Error(res.msg);
                                                    }
                                                }).catch((e) => {
                                                    message.warn(e.message);
                                                });
                                            }
                                        }}
                                    >
                                        {
                                            tagupdateLoading ? (
                                                <LoadingOutlined spin />
                                            ) : (
                                                <PlusOutlined />
                                            )
                                        }
                                        添加标签
                                    </a>
                                </div>
                            </div>
                        )}
                    >
                        {
                            spuTags.length > 0 && spuTags.map((tag, index) => {
                                return (
                                    <Select.Option key={"tag-" + index} value={tag.id}>{tag.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="desc"
                    rules={[{ max: 100, message: "商品简述控制在100字以内" }]}
                >
                    {/* @ts-ignore */}
                    <Input.TextArea placeholder="在此处填写产品的一些说明" autoComplete="off" />
                </Form.Item>
                {/* <Form.Item
                    label="规格"
                    name="specValues"
                >
                    <SpecSelect footer={(
                        <></>
                    )} />
                </Form.Item>
                <Form.Item
                    label="对应单品"
                    name="skus"
                >
                    <SkuList spuName={spuNameTemp} />
                </Form.Item> */}
                
                <Divider >特性设置</Divider>
                <Form.Item label="是否智能" name="isSmart" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={1}>智能产品</Radio>
                        <Radio value={0}>非智能产品</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="芯片类型" name="chipType" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={"none"}>无</Radio>
                        <Radio value={"zigbee-TI"}>Zigbee TI</Radio>
                        <Radio value={"zigbee-Telink"}>Zigbee Telink</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否网关" name="gatewayType" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={0}>非网关</Radio>
                        <Radio value={1}>纯网关</Radio>
                        <Radio value={2}>网关模块</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="连排框" name="isRowFrame" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={1}>是连排框</Radio>
                        <Radio value={0}>非连排框</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="支持连排" name="supportRowFrame" rules={[{required: true, message: "请设置此项"}]}>
                    <Radio.Group>
                        <Radio value={1}>支持</Radio>
                        <Radio value={0}>不支持</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="支持端口" name="endpoints">
                    <Checkbox.Group
                        options={[{
                            label: 1,
                            value: 1,
                        }, {
                            label: 2,
                            value: 2,
                        },{
                            label: 3,
                            value: 3,
                        },{
                            label: 4,
                            value: 4,
                        },{
                            label: 5,
                            value: 5,
                        },{
                            label: 6,
                            value: 6,
                        },{
                            label: 7,
                            value: 7,
                        },{
                            label: 8,
                            value: 8,
                        },{
                            label: 9,
                            value: 9,
                        },{
                            label: 10,
                            value: 10,
                        },{
                            label: 11,
                            value: 11,
                        },{
                            label: 31,
                            value: 31,
                        }]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
})

export default ProductEditModal;