import { message } from "antd";
import copy from 'copy-to-clipboard';
import { CSSProperties } from "react";

export default function DoubleClickToCopy(props: { className?: string, style?: CSSProperties, children: string, tip?: boolean }) {

    return (
        <span className={props.className} style={{ cursor: "copy", userSelect: "none", ...(props?.style || {} )}} onDoubleClick={() => {
            copy(props.children);
            if(props.tip !== false) {
                message.info("已复制");
            }
        }}>{props.children}</span>
    )


}