import { Form, FormInstance, Input, Modal } from "antd"
import { useState } from "react";
import { useRef } from "react"
import { request } from '../../../../../../utils/request';
import { apis } from '../../../../../../utils/apis';

interface Props {
    visible: boolean;
    template: any;
    onCancel: () => void;
    onCaseTemplateUpdate: (template: any) => void;
}

export const UpdateCaseTemplateModal = (props: Props) => {

    const formRef = useRef<FormInstance>();

    const [loading, setLoading] = useState(false);

    
    const onCaseTemplateUpate = async (data: any) => {
        
        setLoading(true);
        let update: any = {};
        if(data.name !== props.template.name) {
            update.name = data.name;
        }
        const res = await request.put(apis.caseTemplate + `/${props.template.id}`, update); 
        if(res.code === 1) {
            props.onCaseTemplateUpdate(res.data);
        }
        setLoading(false);
    }

    return (
        <Modal
            title="修改房型名称"
            centered
            visible={props.visible}
            onOk={() => {
                formRef.current?.submit()
            }}
            okButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            onCancel={props.onCancel}
            cancelButtonProps={{
                disabled: loading,
                loading: loading,
            }}
            width={400}
            destroyOnClose
        >
            <Form
                ref={formRef}
                    labelCol={{span: 5}}
                    onFinish={onCaseTemplateUpate}
                    initialValues={{
                        name: props.template?.name,
                        desc: props.template?.desc,
                    }}
                >
                <Form.Item label="房型名称" name="name" rules={[{required: true, message: "请输入房型名称"}]}>
                    <Input placeholder="请输入房型名称" autoComplete="off" autoFocus/>
                </Form.Item>
             
                <Form.Item label="说明" name="desc" rules={[{message: "如需说明一些情况, 请写在这里"}]}>
                    <Input.TextArea placeholder="请输入说明" autoComplete="off"/>
                </Form.Item>
            </Form>
        </Modal>
    )

}