import { Checkbox, Tag } from "antd";
import { useEffect, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface AppsSelectorProps {
    value?: string[];
    onChange?: (value?: string[]) => void;
}
export const AppsSelector = (props: AppsSelectorProps) => {

    const [apps, setApps] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [checkedKeys, setCheckedKeys] = useState<string[]>(props.value || []);

    useEffect(() => {
        request.get(apis["/upms/apps/list"])
            .then((res) => {
                if(res.code === 1) {
                    setApps(res.data);
                    setLoading(false)
                }
            })

    }, []);

    const handlerChange = (keys: string[]) => {

        setCheckedKeys(keys);
        props.onChange && props.onChange(keys);
    }

    return (
        <div className="user-apps-selector">
        {
            apps.length > 0 && apps.map((item) => {
                item._key = item.key;
                return (
                    <AppItem 
                        {...item} 
                        checkedKeys={checkedKeys}
                        onCheckedKeysChange={handlerChange}
                    />
                )

            })
        }
        </div>
    )
}

interface AppItem {
    checkedKeys: string[];
    name: string;
    key: string;
    _key: string;
    platform: string;
    tenantChannelKey: string;
    tenantChannelName: string;
    scope?: string;
    children?: AppItem[],
    onCheckedKeysChange?: (checkedKeys: string[]) => void; 
}
export const AppItem = (props: AppItem) => {
        
    const onClick= () => {
        if(props.checkedKeys?.includes(props._key)) {
            //选中 -> 未选中
            let shouldRemovedKey: string[] = [props._key];
            if(props.children) {
                for (let i = 0; i < props.children.length; i++) {
                    const child = props.children[i];
                    child._key = child.key;
                    shouldRemovedKey.push(child._key);
                }
            }
            const newCheckedKeys = props.checkedKeys.filter((item) => !shouldRemovedKey.includes(item));
            props.onCheckedKeysChange && props.onCheckedKeysChange(newCheckedKeys);
        }else {
            //未选中 -> 选中
            let shouldAddKeys: string[] = [props._key];
            if(props.children) {
                for (let i = 0; i < props.children.length; i++) {
                    const item = props.children[i];
                    item._key = item.key;
                    if(!props.checkedKeys?.includes(item._key)) {
                        shouldAddKeys.push(item._key);
                    }
                }
            }
            const newCheckedKeys = props.checkedKeys ? props.checkedKeys.concat(shouldAddKeys) : shouldAddKeys;
            console.log("未选中 -> 选中", newCheckedKeys);
            props.onCheckedKeysChange && props.onCheckedKeysChange(newCheckedKeys);
        }
    }

    return (
        <div key={props.key} style={{marginBottom: 5}}>
            <div onClick={onClick} style={{cursor: "pointer"}}>
            <Checkbox checked={props.checkedKeys?.includes(props._key)} style={{marginRight: 10}}/>
            {
                props.tenantChannelKey && (
                    <Tag color="purple" title={props.tenantChannelKey}>{props.tenantChannelName || props.tenantChannelKey}</Tag>
                )
            }
            <span className="bold">{props.name}</span>
            <span style={{color: 'gray', marginLeft: 8, }}>{props._key}</span>
            </div>
            <div style={{marginLeft: 20, marginTop: 8}}>
            {
                props.children?.length > 0 && props.children.map((item) => {
                    item._key = item.key
                    return (
                        <AppItem
                            {...item}
                            checkedKeys={props.checkedKeys}
                            onCheckedKeysChange={props.onCheckedKeysChange}
                        />
                    )
                })
            }
            </div>
        </div>
    )
}