import { message } from "antd";
import { json2Form } from ".";

interface Options { autoErrorTip?: boolean }
class Request {
    
    loginInfo: any;

    getAccessToken() {
        if(!this.loginInfo) {
            const loginInfoString = localStorage.getItem("SK_LOGIN_INFO")
            this.loginInfo = JSON.parse(loginInfoString as string);
        }
        return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiOWZkOWIwNjAtYjViMy0xMWVjLTg5ZGMtYzk0MjUwOTciLCJ1c2VySWQiOjEsInBob25lTnVtYmVyIjoiIiwicGhvbmVWZXJpZmllZCI6MCwiZXhwaXJlc0F0IjoxNjcwNDgwNTY5NTc4fQ.wgO1dlw518u3cdxGhqVNVKIGpMWDBhpzQmfHAVNpajo"//this.loginInfo?.access_token;
    }

    baseRequest = (method: string, url: string, params: {[key: string]: any} = {}, options?: Options) => {
        // const authorization = this.getAccessToken();
        const authorization = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiOWZkOWIwNjAtYjViMy0xMWVjLTg5ZGMtYzk0MjUwOTciLCJ1c2VySWQiOjEsInBob25lTnVtYmVyIjoiIiwicGhvbmVWZXJpZmllZCI6MCwiZXhwaXJlc0F0IjoxNjcwNDgwNTY5NTc4fQ.wgO1dlw518u3cdxGhqVNVKIGpMWDBhpzQmfHAVNpajo";
        // const authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiN2UzYTQ4YjAtOTVkZS0xMWVjLWIwNWUtMjk0NzcxYmYiLCJ1c2VySWQiOjEsImV4cGlyZXNBdCI6MTY0OTIzODgwNzg1MX0.4a_rpal16pB3bhsBL4XBWIA9e8GyrOWEyafhYVoGjuc';
        const config: any = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            }
        }

        if(method === 'GET') {
            if(Object.keys(params)?.length > 0) {
                url += '?' + json2Form(params);
            }
        }else {
            config['body'] = JSON.stringify(params);
        }

        return fetch(url, config).then((res) => {
            return res.json();  
        }).then((res) => {
            if(options?.autoErrorTip !== false) {
                if(res.code !== 1) {
                    message.warn(res.msg || res.message);
                }
            }
            return res;
        });

    }



    get = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("GET", url, params, options);
    }

    post = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("POST", url, params, options);
    }
    
    put = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("PUT", url, params, options);
    }

    delete = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("DELETE", url, params, options);
    }
    
    patch = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("PATCH", url, params, options);
    }
    
}

export const request = new Request();
