import { Checkbox, message, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

import './index.less';

interface Props {
    value?: any[];
    onChange?: (value: any[]) => void;
    spuName: string;
    enabledSkus?: any[];
}

export const SkuList = (props: Props) => {

    const [skus, setSkus] = useState<any[]>(props.value || []);

    const handleChange = (newSkus: any[]) => {
        if (typeof props.onChange === 'function') {
            props.onChange(newSkus);
        }
    }

    useEffect(() => {
        setSkus(props.value || []);
    }, [props.value])

    return (
        <div className="sku-list">
            {
                skus.length > 0 && (
                    <div className="header">
                        <Space>
                            共选{skus.filter((sku) => sku.selected).length}个单品
                        </Space>
                    </div>
                )
            }
            <div className="list">
                {
                    skus.length > 0 ? skus.map((sku, index) => {
                        return (
                            <div className="item">
                                <Space>
                                    <Checkbox
                                        // disabled={(() => {
                                        //     if (props.enabledSkus instanceof Array) {
                                        //         for (let i = 0; i < props.enabledSkus.length; i++) {
                                        //             const _sku = props.enabledSkus[i];
                                        //             if (_sku.name === sku.names.join(',') && _sku.enabled > 0) {
                                        //                 return true;
                                        //             }
                                        //         }
                                        //     }
                                        //     return false;
                                        // })()}
                                        disabled={true}
                                        checked={sku.selected} onClick={() => {
                                            if (sku.selected && skus.filter((sku) => sku.selected).length === 1) {
                                                message.warn("至少保留一个");
                                                return;
                                            }
                                            sku.selected = !sku.selected;
                                            handleChange([...skus])
                                        }} />
                                    {(index + 1) + "、" + [props.spuName].concat(sku.names).join(',')}
                                    {
                                        sku.isNew && (
                                            <Tag color="red">新</Tag>
                                        )
                                    }
                                </Space>
                            </div>
                        )
                    }) : (
                            <div className="item">
                                <Checkbox checked={true} disabled />
                                <span style={{ color: "lightgray", fontSize: 12, marginLeft: 8 }}>单规格</span>
                            </div>
                        )
                }
            </div>
        </div>
    )
}