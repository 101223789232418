import { CheckCircleFilled } from "@ant-design/icons";
import { Button, message, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface Props {
    template: any;
    spu: any;
    hex: any;
}

const Result = (props: Props) => {


    const [loading, setLoading] = useState(true);
    const [hexConfig, setHexConfig] = useState(null as any);


    useEffect(() => {
        if (!props.hex) {
            return;
        }
        if (typeof props.hex.selectedVersionId === 'number') {
            getHexConfig(props.hex.selectedGroupId, props.hex.selectedVersionId);
        }

    }, [props.hex]);

    const getHexConfig = async (groupId: number, versionId: number) => {
        try {
            setLoading(true);
            let res: any;
            if (versionId) {
                res = await request.get(apis.hex + `/config`, { versionId: versionId });
            } else {
                res = await request.get(apis.hex + "/config", { groupId: groupId, versionId: "latest" });
            }
            if (res.code === 1) {
                if (!res.data) {
                    message.info("该版本HEX未配置，请先配置");
                    return;
                }
                setHexConfig(res.data)
            }
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <Skeleton active />
        )
    }
    if (!props.spu || !props.template || !props.hex) {
        return (<></>)
    }
    return (
        <div className="flex column center" style={{}}>
            <div style={{ fontSize: 28, marginBottom: 30 }}>
                <CheckCircleFilled style={{ marginRight: 5, color: "#52c41a", fontSize: 32, position: 'relative', top: 2 }} />
                创建成功！
            </div>
            {
                props.spu && props.template && hexConfig && (
                    <>
                    <props.template.component
                        spu={props.spu}
                        hexConfig={hexConfig}
                        silk1={{ cn: "私印1", en: "Silk Screen1" }}
                        silk2={{ cn: "私印2", en: "Silk Screen2" }}
                        silk3={{ cn: "私印3", en: "Silk Screen3" }}
                        silk4={{ cn: "私印4", en: "Silk Screen4" }}
                        silk5={{ cn: "私印5", en: "Silk Screen5" }}
                        silk6={{ cn: "私印6", en: "Silk Screen6" }}
                    />
                    {/* <span style={{fontSize: 16, marginTop: 5}}>{props.template.name}</span> */}
                    </>
                )
            }
            
            {
                props.hex && (
                    <Button type="link" style={{ marginTop: 10 }}>HEX: {props.hex.projectName} —— {props.hex.groups?.length > 1 && props.hex.selectedGroupName + '—— '}{props.hex.selectedVersionName}</Button>
                )
            }
        </div>
    )
}

export default Result;