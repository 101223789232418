import { Button, Modal, Spin, Table, Form, FormInstance, Input } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { request } from '../../../../utils/request';
import { apis } from '../../../../utils/apis';

interface Props {
    visible: boolean;
    onOk: () => void;
    onCancel: () => void;
    onRefresh: () => void;
}

export const CatesManager = (props: Props) => {

    const [cates, setCates] = useState([] as any[]);
    const [spinning, setSpinning] = useState(false);

    useEffect(() => {
        if(props.visible) {
            fetchData();
        }
    }, [props.visible]);
    
    const fetchData = () => {
        setSpinning(true);
        request.get(apis.hexProjectCate)
            .then((res) => {
                console.log(res);
                setCates(res.data);
            })
            .finally(() => {
                setSpinning(false);
            });
    }


    const [renameTemp, setRenameTemp] = useState("");
    const [renameId, setRenameId] = useState(0);
    const [renameVisible, setRenameVisible] = useState(false);

    const openRename = (currentId: number, currentName: string) => {
        setRenameTemp(currentName);
        setRenameId(currentId);
        setRenameVisible(true);
    }
    const closeRename = () => {
        setRenameTemp("");
        setRenameId(0);
        setRenameVisible(false);
    }

    const [renameLoading, setRenameLoading] = useState(false);

    const onRename = () => {
        setRenameLoading(true);
        request.put(apis.hexProjectCate, {
            id: renameId,
            name: renameTemp,
        })
        .then((res) => {
            console.log(res);
            if(res.code === 1) {
                const target = cates.find((cate) => cate.id === renameId);
                if(target) {
                    target.name = renameTemp;
                    setCates([...cates]);
                    props.onRefresh();
                }
                closeRename();
            }
        })
        .finally(() => {
            setRenameLoading(false);
        })
    }

    const onDelete = (id: number) => {
        request.delete(apis.hexProjectCate, {
            id
        })
        .then((res) => {
            if(res.code) {
                const _cates = cates.filter((cate) => cate.id !== id);
                setCates(_cates);
                props.onRefresh();
            }
        });
    }

    return (
        <>
        <Modal
            title="重命名"
            centered
            visible={renameVisible}
            onOk={() => {
                onRename();
            }}
            okButtonProps={{
                loading: renameLoading,
                disabled: renameLoading
            }}
            cancelButtonProps={{
                disabled: renameLoading
            }}
            onCancel={closeRename}
            width={300}
            destroyOnClose
        >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div className="lebal" style={{width: 50, marginRight: 10, textAlign: 'right'}}>分类名: </div>
                <Input style={{flex: 1}} value={renameTemp} autoFocus placeholder="请输入分类名" onChange={(e) => {
                    setRenameTemp(e.target.value);
                }}/>
            </div>
            
        </Modal>
        <Modal 
            title="项目分类管理"
            visible={props.visible}
            onOk={props.onOk}
            onCancel={props.onCancel}
            centered
            width={200}
        >
            <Spin spinning={spinning}>
                <Table 
                    size="small"
                    pagination={false}
                    dataSource={cates}
                    columns={[{
                        key: "index",
                        title: "#",
                        render: (_, __, index) => {
                            return (index + 1);
                        }
                    }, {
                        key: "name",
                        title: "分类名",
                        dataIndex: "name",
                        render: (name, record) => {
                            return (
                                <span>
                                    {name} 
                                    <EditOutlined style={{marginLeft: 5}} onClick={() => {
                                        openRename(record.id, name);
                                    }}/>
                                </span>
                            )
                        }
                    }, {
                        key: "actions",
                        title: "操作",
                        width: 50,
                        align: 'center',
                        render: (_, record) => {
                            return (
                                <div>
                                    <Button type="link" danger ghost size="small" onClick={() => {
                                        Modal.confirm({
                                            centered: true,
                                            title: "请确认是否删除" + record.name,
                                            onOk: () => {
                                                onDelete(record.id);
                                            },
                                            onCancel: () => {

                                            }
                                        })
                                    }}>删除</Button>
                                </div>
                            )
                        }
                    }]}
                />
            </Spin>
        </Modal>
        </>
        
    )
}