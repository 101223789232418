import { Empty, message, Modal, PageHeader, Progress, Spin } from 'antd';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { apis } from '../../../utils/apis';
import { ipcRenderer, store } from '../../../utils/electron';
import './style.less';

interface Props extends RouteChildrenProps {

}


let _uuidProgressMap = new Map<string, number>();
let _uuidOkMap = new Map<string, boolean>();

export const HexDownload = (props: Props) => {

    const [loading, setLoading] = useState(true);
    const [shouldDownloadFiles, setShouldDownloadFiles] = useState([]);
    const [uuidProgressMap, setUuidProgressMap] = useState<Map<string, any>>(new Map());
    const [uuidOkMap, setUuidOkMap] = useState<Map<string, boolean>>(new Map());

    const download = () => {
        const uuids: string[] = [];
        _uuidOkMap.forEach((ok, uuid) => {
            if (typeof ok === 'undefined' || ok === null) {
                uuids.push(uuid);
            }
        });
        if (uuids.length === 0) {
            let hasFail = false;
            for (const key in _uuidOkMap) {
                if(_uuidOkMap.get(key) === false) {
                    hasFail = true;
                    _uuidOkMap.set(key, null);
                }
            }
            if(hasFail) {
                Modal.warning({
                    title: "存在下载失败的文件，请点击重新下载",
                    centered: true,
                    closable: false,
                    onOk: () => {
                        download();
                    }
                });
            }else {
                //TODO md5校验
                props.history.goBack();
                message.success("HEX文件下载完成!");
            }
            return;
        }
        const uuid = uuids[0];
        ipcRenderer.send("download-hex-file", apis.hexDownload + `/${uuid}`, uuid);
        ipcRenderer.on("download-hex-file-progress", (e, ...args) => {
            const [uuid, receivedBytes, totalBytes, progress, speed] = args;
            const map = new Map();
            map.set(uuid, {
                progress,
                speed,
                receivedBytes,
                totalBytes
            });
            setUuidProgressMap(map);
        });
        ipcRenderer.on("download-hex-file-success", (e, ...args) => {
            console.log("success", args);
            _uuidOkMap.set(uuid, true);
            const map = new Map();
            _uuidOkMap.forEach((value, key) => map.set(key, value));
            setUuidOkMap(_uuidOkMap);
            ipcRenderer.removeAllListeners("download-hex-file-progress");
            ipcRenderer.removeAllListeners("download-hex-file-success");
            ipcRenderer.removeAllListeners("download-hex-file-failed");
            store.set("download-hex-file-uuids", (store.get("download-hex-file-uuids") as string[]).concat(uuid));
            download();
        });
        ipcRenderer.on("download-hex-file-failed", (e, ...args) => {
            console.log("failed", args);
            _uuidOkMap.set(uuid, false);
            const map = new Map();
            _uuidOkMap.forEach((value, key) => map.set(key, value));
            setUuidOkMap(_uuidOkMap);
            ipcRenderer.removeAllListeners("download-hex-file-progress");
            ipcRenderer.removeAllListeners("download-hex-file-success");
            ipcRenderer.removeAllListeners("download-hex-file-failed");
            download();
        });

    }

    useEffect(() => {
        setLoading(true);
        document.getElementsByTagName("title")[0].innerHTML = "同步服务器HEX文件";
        _uuidOkMap = new Map<string, boolean>();
        let shouldDownloadHexFileUuids: string[] = store.get("should-download-hex-file-uuids") as string[];
        if(!shouldDownloadHexFileUuids) {
            shouldDownloadHexFileUuids = [];
        }
        const hexFiles: any[] = store.get("hex-files") as any[];
        const _shouldDownloadFiles: any[] = [];
        for (let i = 0; i < hexFiles.length; i++) {
            const hexFile = hexFiles[i];
            if (shouldDownloadHexFileUuids.includes(hexFile.uuid)) {
                _shouldDownloadFiles.push(hexFile);
                _uuidOkMap.set(hexFile.uuid, null);
            }
        }

        setShouldDownloadFiles(_shouldDownloadFiles);
        setUuidProgressMap(_uuidProgressMap);
        setUuidOkMap(_uuidOkMap);
        setLoading(false);
        if(_shouldDownloadFiles.length > 0) {
            download();
        }
        return () => {
            _uuidProgressMap = new Map<string, number>();
            _uuidOkMap = new Map<string, boolean>();
        }
    }, []);

    return (
        <Spin spinning={loading}>

            <div className="hex-download" style={{height: '100%', width: '100%'}}>
                <div className="files">
                    {
                        shouldDownloadFiles.length > 0 ? shouldDownloadFiles.map((item) => {
                            const show = !uuidOkMap.has(item.uuid) || typeof uuidOkMap.get(item.uuid) === 'undefined' || uuidOkMap.get(item.uuid) === null;
                            if (show) {
                                const progress = uuidProgressMap.get(item.uuid);
                                const status = uuidOkMap.get(item.uuid);
                                return (
                                    <div className="file-item">
                                        <div className="progress-bar" style={{ width: `${(progress?.progress || 0) * 100}%` }}></div>
                                        <div>
                                            {item.fileName}
                                            <div style={{ fontSize: 12 }}>{item.uuid}.hex</div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>

                                            <Progress status={status === true ? "success" : status === false ? "exception" : "normal"} type="circle" percent={(progress?.progress || 0) * 100} width={40} format={(percent) => progress ? `${Math.round(percent)}%` : "等待"} />
                                            {
                                                progress && progress.progress !== 1 && (
                                                    <div style={{ display: 'inline-block', fontSize: 16, textAlign: 'center' }}>
                                                        {Math.round(progress.speed / 1024)}KB/s
                                                        <div style={{ fontSize: 10 }}>{Math.round(progress.receivedBytes / 1024)}KB / {Math.round(progress.totalBytes / 1024)}KB</div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                )
                            } else {
                                return undefined;
                            }
                        }) : (
                            <Empty description={"无需要下载的HEX文件"} />
                        )
                    }

                </div>
            </div>
        </Spin>
    )

}