import { Checkbox, Modal } from "antd";
import { useEffect, useState } from "react";
import { apis } from "../utils/apis";
import { request } from "../utils/request";

interface CaseSelectorModal {
    customerId?: number;
    dataSource?: any[];
    visible?: boolean;
    value?: number[];
    onChange: (value: number[]) => void;
    onClose?: () => void;
}

export const CaseSelectorModal = (props: CaseSelectorModal) => {

    
    const [cases, setCases] = useState<any[]>([]);
    const [selectedCaseIds, setSelectedCaseIds] = useState<number[]>(props.value || []);
    const [casesSelectorVisible, setCaseSelectorVisible] = useState(props.visible || false);

    useEffect(() => {
        if(!props.dataSource && props.customerId) {
            request.get(apis.case + "/list/v2", { customerId: props.customerId })
                .then((res) => {
                    if(res.code === 1) {
                        console.log(res.data);
                        const ids = res.data.map((item) => item.id)
                        setCases(res.data)
                        setSelectedCaseIds(ids);
                    }
                })
        }else if(props.dataSource) {
            setCases(props.dataSource);
            setSelectedCaseIds(props.dataSource.map((item) => item.id));
        }
        
    }, [props.dataSource, props.customerId]);

    useEffect(() => {
        if(props.value?.length > 0) {
            setSelectedCaseIds(props.value);
        }else if(selectedCaseIds.length !== 0) {
            setSelectedCaseIds(props.value || []);
        }
    }, [props.value]);

    const handleChange = (_selectedCaseIds: number[]) => {
        setSelectedCaseIds(_selectedCaseIds);
    }

    useEffect(() => {
        setCaseSelectorVisible(props.visible || false);
    }, [props.visible]);

    const onCancel = () => {
        setCaseSelectorVisible(false);
        props.onClose && props.onClose();

    }

    return (
        
        <Modal
            title="请选择要展示的"
            visible={casesSelectorVisible}
            onCancel={onCancel}

            onOk={() => {
                props.onChange && props.onChange(selectedCaseIds);
                onCancel();
            }}
        >
            <div style={{height: 400, overflow: 'auto', paddingTop: 10}}>
                <div className="pointer" style={{marginBottom: 10}} onClick={() => {
                    if(selectedCaseIds.length === cases.length) {
                        handleChange([]);
                    }else {
                        setSelectedCaseIds(cases.map((item) => item.id));
                    }
                }}><Checkbox checked={selectedCaseIds.length === cases.length}/> 全选</div>
                {
                    cases.length > 0 && cases.map((c) => {
                        return (
                            <div 
                                className="inline-block"
                                style={{
                                    cursor: 'pointer', 
                                    border: selectedCaseIds.includes(c.id) ? '1px solid blue' : "1px solid lightgray", 
                                    color: selectedCaseIds.includes(c.id) ? 'black' : 'gray',
                                    fontWeight: selectedCaseIds.includes(c.id) ? 'bold' : 'bold',
                                    marginBottom: 10,
                                    marginRight: 10,
                                    padding: '4px 8px', borderRadius: 4,}} onClick={() => {
                                if(selectedCaseIds.includes(c.id)) {
                                    let _selectedCaseIds = selectedCaseIds.filter((cid: any) => c.id !== cid);
                                    handleChange(_selectedCaseIds);
                                }else {
                                    selectedCaseIds.push(c.id);
                                    handleChange([...selectedCaseIds]);
                                }
                            }} >
                                <Checkbox style={{marginRight: 5}} checked={selectedCaseIds.includes(c.id)}/>
                                <span>{c.name}</span>
                            </div>
                        )
                    })
                }
            </div>

        </Modal>
    )
}