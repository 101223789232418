import { PrinterOutlined, SelectOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { CaseSelectorModal } from "../../../../../../components/CaseSelectorModal";
import { apis } from "../../../../../../utils/apis";
import { request } from "../../../../../../utils/request";

interface Props {
    customer: any;
}

const CaseOrder = (props: Props) => {

    const [products, setProducts] = useState([] as any[]);
    const [loading, setLoading] = useState(true);
    const [cases, setCases] = useState<any[]>([]);
    const [selectedCaseIds, setSelectedCaseIds] = useState<number[]>([]);
    const [casesSelectorVisible, setCaseSelectorVisible] = useState(false);

    const ref1 = useRef<any>();
    const ref2 = useRef<any>();
    const ref3 = useRef<any>();


    useEffect(() => {
        if (props.customer?.id) {
            initData(props.customer.id);
        }
    }, [props.customer]);

    const initData = async (customerId: number) => {
        setLoading(true);
        const [res1, res2] = await Promise.all([
            request.get(apis.case + `/spu/list`, {
                customerId: props.customer?.id,
                orderId: 0
            }),
            request.get(apis.case + "/list/v2", { 
                customerId: props.customer?.id 
            })
        ]);
        if (res1.code === 1) {
            setProducts(res1.data);
        }
        if(res2.code === 1) {
            const ids = res2.data.map((item) => item.id)
            setCases(res2.data)
            setSelectedCaseIds(ids);
        }
        setLoading(false);
    }

    return (
        <>
        {
            cases.length > 1 && (
                <CaseSelectorModal
                    customerId={props.customer} 
                    dataSource={cases}
                    value={selectedCaseIds}
                    visible={casesSelectorVisible}
                    onChange={(value: number[]) => {
                        setSelectedCaseIds(value);
                    }} 
                    onClose={() => {
                        setCaseSelectorVisible(false);
                    }}
                />
            )
        }
        <Tabs tabPosition="left" style={{width: 'calc(100vw - 420px)'}}>
            <Tabs.TabPane tab={("订单")} key="order">
                <div className="case-order relative" style={{ background: "#fff" }}>
                    <div className="bold " style={{ fontSize: 20, height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'calc(21cm)', margin: '0 auto', marginBottom: 5, marginTop: 3 }}>
                        <span>订单</span>
                        <div>
                            {
                                cases.length > 1 && (
                                    <Button type="link" onClick={() => {
                                        setCaseSelectorVisible(true);
                                    }}>
                                        <SelectOutlined />
                                        选择（{selectedCaseIds.length}/{cases.length}）
                                    </Button>
                                )
                            }
                            <Button type="link" onClick={() => {
                                console.log(ref1.current?.contentWindow?.print());
                            }}>
                                <PrinterOutlined />
                                打印订单
                            </Button>
                        </div>
                    </div>

                    <div className="center no-scroll-bar" style={{ padding: '0 0', width: 'calc(21cm + 20px)', height: 'calc(100vh - 90px - 40px - 48px)', margin: '0 auto', border: "10px solid #f2f2f2", overflowY: 'scroll' }}>
                        <iframe ref={ref1} src={"/#/print/customer-order/" + props.customer?.id + `?selectedCaseIds=${selectedCaseIds.join(',')}`} style={{ width: "calc(21cm + 17px)", height: '100%', border: 0 }} />
                    </div>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={("丝印")} key="silk-screen">
                <div className="bold " style={{ fontSize: 20, height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'calc(21cm)', margin: '0 auto', marginBottom: 5, marginTop: 3 }}>
                    <span>丝印</span>
                    <div>
                        {
                            cases.length > 1 && (
                                <Button type="link" onClick={() => {
                                    setCaseSelectorVisible(true);
                                }}>
                                    <SelectOutlined />
                                    选择（{selectedCaseIds.length}/{cases.length}）
                                </Button>
                            )
                        }
                        <Button type="link" onClick={() => {
                            console.log(ref2.current?.contentWindow?.print());
                        }}>
                            <PrinterOutlined />
                            打印丝印
                        </Button>
                    </div>
                </div>

                <div className="center no-scroll-bar" style={{ padding: '0 0', width: 'calc(21cm + 20px)', height: 'calc(100vh - 90px - 40px - 48px)', margin: '0 auto', border: "10px solid #f2f2f2", overflowY: 'scroll' }}>
                    <iframe ref={ref2} src={"/#/print/customer-silk-screen/" + props.customer?.id + `?selectedCaseIds=${selectedCaseIds.join(',')}`} style={{ width: "calc(21cm + 17px)", height: '100%', border: 0 }} />
                </div>

            </Tabs.TabPane>
            <Tabs.TabPane tab={("清单")} key="list">
                <div className="bold " style={{ fontSize: 20, height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'calc(29.7cm)', margin: '0 auto', marginBottom: 5, marginTop: 3 }}>
                    <span>清单</span>
                    <div>
                        {
                            cases.length > 1 && (
                                <Button type="link" onClick={() => {
                                    setCaseSelectorVisible(true);
                                }}>
                                    <SelectOutlined />
                                    选择（{selectedCaseIds.length}/{cases.length}）
                                </Button>
                            )
                        }
                        <Button type="link" onClick={() => {
                            console.log(ref3.current?.contentWindow?.print());

                            // ref.current?.print();
                        }}>
                            <PrinterOutlined />
                            打印清单
                        </Button>
                        {/* <Button type="primary">提交</Button> */}
                    </div>
                </div>

                <div className="center no-scroll-bar" style={{ padding: '0 0', width: 'calc(29.7cm + 20px)', height: 'calc(21cm + 20px)', margin: '0 auto', border: "10px solid #f2f2f2", overflowY: 'scroll' }}>
                    <iframe ref={ref3} src={"/#/print/customer-order-case-spu-list/" + props.customer?.id + `?selectedCaseIds=${selectedCaseIds.join(',')}`} style={{zIndex: 9, width: "calc(30cm + 17px)", height: '21cm', border: 0 }} />
                </div>

            </Tabs.TabPane>
        </Tabs >
        </>
    )

}

export default CaseOrder;



