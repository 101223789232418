export const json2Form = (json: any) => {
    let formStr = [];
    for (const key in json) {
        if (json.hasOwnProperty(key)) {
            formStr.push(encodeURIComponent(key) + '=' + encodeURIComponent(json[key]));
        }
    }
    return formStr.join('&');
}

export const arrJoin = (arr1: string[], arr2: string[], incre: (a1: any, a2: any) => any) => {
    const result = [];
    for (let i = 0; i < arr1.length; i++) {
        const a1 = arr1[i];
        for (let j = 0; j < arr2.length; j++) {
            const a2 = arr2[j];
            result.push(incre(a1, a2));
        }
    }
    return result;
}

export const specValuesToSkus = (specValues: any[], callback: (skus: any[]) => void) => {
    if (specValues.length === 0) {
        if (typeof callback === 'function') {
            callback([]);
        }
        return;
    }
    const arr1 = [];
    for (let i = 0; i < specValues.length; i++) {
        const sv = specValues[i];
        if (sv.values.length === 0) continue;
        arr1.push(sv.values.map((v: any) => {
            return {
                ids: [sv.id + '-' + v.id],
                names: [v.name],
            }
        }));
    }
    if (arr1.length === 0) {
        if (typeof callback === 'function') {
            callback([]);
        }
        return;
    }
    
    let result: any[] = arr1[0];
    for (let i = 1; i < arr1.length; i++) {
        result = arrJoin(result, arr1[i], (a1, a2) => {
            return {
                ids:  [...a1.ids, ...a2.ids],
                names: [...a1.names, ...a2.names],
            }
        });
    }
    const skus = result.map((item, index) => {
        return {
            selected: true,
            ...item,
        }
    });
    if (typeof callback === 'function') {
        callback(skus);
    }
    return;
}


export const sleep = async (ms: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    })
}



export const extraInfoChannelFromFileName = (fileName: string) => {
    let infoChannel = "";
    for (let j = 0; j < fileName.length; j++) {
        const code = fileName.charCodeAt(j);
        if((48 <= code && code <= 57) || (65 <= code && code <= 90) || (97 <= code && code <= 122)) {
            infoChannel += String.fromCharCode(code);
        }else {
            if(!infoChannel || (infoChannel.length > 2)) {
                infoChannel = "";
                continue ;
            }
            if(infoChannel) {
                if(["B", "C", "D", "E", "F", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "1A"].includes(infoChannel)) {
                    return infoChannel;
                }
            }
            infoChannel = "";
        }
    }
    if(["B", "C", "D", "E", "F", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "1A"].includes(infoChannel)) {
        return infoChannel;
    }
    return "" ;
}


/**search中的数据截取
 * @getSearchData window.location.search中的数据转换为对象,?name=zhangsan&age=18&sex=man
 * @return Object {name:"张三",age:18,sex:"男"}
 */
export const search2Query = (search: string) => {
    let obj: any = {};
    let arr = search.slice(1).split('&');
    arr.forEach(item => {
        let newArr = item.split('=');
        obj[newArr[0]] = newArr[1];
    })
    return obj;
}