import { Descriptions } from "antd";
import { useEffect } from "react";
import { TemplateComponent, templateKeyNameMap } from "../../../../../#case-manager/components/CaseNodeCell/template";
import './print-template-style.less';

interface OrderA4TemplateProps {
    customer: any;
    data: any[];
}

const SilkScreenA4Template = (props: OrderA4TemplateProps) => {

    useEffect(() => {
        console.log(props.data);

    }, [props.data])

    return (
        <div className="a4-template bg-white portrait" style={{ width: "calc(21cm)", minHeight: '29.7cm', cursor: "grab", boxSizing: "content-box" }}>
            <div style={{ padding: 10 }}>

                <Descriptions
                    title={(
                        <div className="flex" style={{ alignItems: 'center', marginBottom: 20 }}>
                            <img src={"/favicon.ico"} className="relative" style={{ top: 0, height: 16, width: 16 }} />
                            <span style={{ marginLeft: 5 }}>华飞物联 - 订单附件：丝印</span>
                        </div>
                    )}
                    style={{ fontSize: 14 }}
                    bordered
                    extra={(
                        <span style={{ position: 'relative', top: -10 }}>订单号：HFDHD20220927001</span>
                    )}
                    labelStyle={{ background: "#eee" }}
                >
                    {/* <Descriptions.Item label="客户名称" labelStyle={{ width: 100 }}>{props.customer?.name}</Descriptions.Item>
                    <Descriptions.Item label="制单员" labelStyle={{ width: 100 }}>某某某</Descriptions.Item>
                    <Descriptions.Item label="业务员" labelStyle={{ width: 100 }}>某某某</Descriptions.Item>
                    <Descriptions.Item label="客户地址" span={5}>
                        <span>{props.customer?.address}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="提交日期" span={1.5}>2022年9月20日</Descriptions.Item>
                    <Descriptions.Item label="交货日期" span={1.5}>2022年9月20日</Descriptions.Item>
                    <Descriptions.Item label="订单备注" span={5}>
                    </Descriptions.Item> */}
                </Descriptions>
            </div>
            <div style={{ padding: '10px 8px' }}>

                {
                    props.data.map((item, index) => {
                        return (
                            <table key={item.key} style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <div style={{padding: '0 10px'}} className="bold">
                                        {templateKeyNameMap[item.key]?.replace('控件', '')}
                                        </div>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        (() => {
                                            const result: any[] = [];
                                            for (let i = 0; i < item.groups.length; i += 3) {
                                                const g0 = item.groups[i];
                                                const g1 = item.groups[i + 1];
                                                const g2 = item.groups[i + 2];
                                                const node0 = g0?.node;
                                                const node1 = g1?.node;
                                                const node2 = g2?.node;
                                                result.push(
                                                    <tr>
                                                    <td width={'33%'}>
                                                            {
                                                                node0 && (
                                                                    <div className="flex column" style={{ alignItems: 'center' }}>
                                                                        <TemplateComponent
                                                                            templateKey={item.key}
                                                                            editable={false}
                                                                            silk1={node0.silkScreens[0]}
                                                                            silk2={node0.silkScreens[1]}
                                                                            silk3={node0.silkScreens[2]}
                                                                            silk4={node0.silkScreens[3]}
                                                                            silk5={node0.silkScreens[4]}
                                                                            silk6={node0.silkScreens[5]}
                                                                            backgroundColor={"#fff"}
                                                                            color={"#000"}
                                                                        />
                                                                        <span className="bold" style={{ marginTop: 5, fontSize: 18 }}>{g0.quantity}个</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        <td width={'33%'}>
                                                            {
                                                                node1 && (
                                                                    <div className="flex column" style={{ alignItems: 'center' }}>
                                                                        <TemplateComponent
                                                                            templateKey={item.key}
                                                                            editable={false}
                                                                            silk1={node1.silkScreens[0]}
                                                                            silk2={node1.silkScreens[1]}
                                                                            silk3={node1.silkScreens[2]}
                                                                            silk4={node1.silkScreens[3]}
                                                                            silk5={node1.silkScreens[4]}
                                                                            silk6={node1.silkScreens[5]}
                                                                            backgroundColor={"#fff"}
                                                                            color={"#000"}
                                                                        />
                                                                        <span className="bold" style={{ marginTop: 5, fontSize: 18 }}>{g1.quantity}个</span>

                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        <td width={'33%'}>
                                                            {
                                                                node2 && (
                                                                    <div className="flex column" style={{ alignItems: 'center' }}>
                                                                        <TemplateComponent
                                                                            templateKey={item.key}
                                                                            editable={false}
                                                                            silk1={node2.silkScreens[0]}
                                                                            silk2={node2.silkScreens[1]}
                                                                            silk3={node2.silkScreens[2]}
                                                                            silk4={node2.silkScreens[3]}
                                                                            silk5={node2.silkScreens[4]}
                                                                            silk6={node2.silkScreens[5]}
                                                                            backgroundColor={"#fff"}
                                                                            color={"#000"}
                                                                        />
                                                                        <span className="bold" style={{ marginTop: 5, fontSize: 18 }}>{g2.quantity}个</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            return result;
                                        })()
                                    }
                                </tbody>
                            </table>
                        )
                    })
                }
            </div>
        </div >
    )


}

export default SilkScreenA4Template;