import { Checkbox, Form, Tag } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import ModalForm from "../../../../components/ModalForm"
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

interface Props /* extends RouteChildrenProps */ {
    
}

export const UserAppsModal = forwardRef((props: Props, ref: any) => {

    const [userInfo, setUserInfo] = useState<any>({});

    const [apps, setApps] = useState<any[]>([]);
    
    useImperativeHandle(ref, () => {
        return {
            open: (userInfo: any) => {
                setUserInfo(userInfo);
                ref2.current?.open({
                    userId: userInfo.id,
                    
                })
            },
        }
    });

    // useEffect(() => {

    // }, [VISIBLE])
    
    const onSubmit = async () => {
        return false;
    }
    
    const ref2 = useRef<any>();



    return (
        <ModalForm
            ref={ref2}
            title={"用户(" + userInfo?.nickName + ")应用权限"}
            centered
            onSubmit={onSubmit}
            layout="vertical"
        >
            <Form.Item label="应用列表" name={"appKeys"}>
                {/* <UserAppsSelector /> */}
            </Form.Item>
        </ModalForm>
    )
})

