import { CheckOutlined } from "@ant-design/icons";
import { Col, message, Row, Skeleton, Tag } from "antd";
import { useEffect, useState } from "react";
import { templates } from "../../#case-manager/components/CaseNodeCell/template";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";

interface Props {
    spu: any;
    hex: any;
    selectedTemplateKey: string,
    onChange: (template: any) => void;
}

const CaseNodeCellTemplateSelector = (props: Props) => {

    const [loading, setLoading] = useState(true);
    const [hexConfig, setHexConfig] = useState(null as any);


    useEffect(() => {
        if (!props.hex) {
            return;
        }
        if (typeof props.hex.selectedVersionId === 'number') {
            getHexConfig(props.hex.selectedGroupId, props.hex.selectedVersionId);
        }

    }, [props.hex]);

    const getHexConfig = async (groupId: number, versionId: number) => {
        try {
            setLoading(true);
            let res: any;
            if (versionId) {
                res = await request.get(apis.hex + `/config`, { versionId: versionId });
            } else {
                res = await request.get(apis.hex + "/config", { groupId: groupId, versionId: "latest" });
            }
            if (res.code === 1) {
                if (!res.data) {
                    message.info("该版本HEX未配置，请先配置");
                    return;
                }
                setHexConfig(res.data)
            }
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <Skeleton active />
        )
    }
    return (
        <div className="case-node-cell-selector flex nowrap" style={{paddingBottom: 50}}>
            <Row>
                {
                    props.spu && hexConfig && templates.map((t) => {
                        const Component: any = t.component;
                        return (
                            <Col className="" span={6} style={{textAlign: 'center', marginBottom: 20}}>
                                <div className="relative" style={{ marginRight: 10, marginBottom: 10 }}>
                                    <div className="absolute flex center" style={{height: '100%', width: '100%', cursor: "pointer", zIndex: 1}}  onClick={() => {
                                    props.onChange(t)
                                }}>
                                        {
                                            t.key === props.selectedTemplateKey && (
                                                <span className="color-orange relative" style={{fontSize: 32, top: -5, zIndex: 999}}>{/* {<CheckOutlined className="color-orange absolute" style={{left: -40, top: 10 }}/>} */}已选择</span>
                                            )
                                        }
                                    </div>
                                    <Component
                                        spu={props.spu} 
                                        endpoints={hexConfig.endpoints} 
                                        silk1={{cn: "私印1", en: "Silk Screen1"}}  
                                        silk2={{cn: "私印2", en: "Silk Screen2"}} 
                                        silk3={{cn: "私印3", en: "Silk Screen3"}} 
                                        silk4={{cn: "私印4", en: "Silk Screen4"}}   
                                        silk5={{cn: "私印5", en: "Silk Screen5"}}   
                                        silk6={{cn: "私印6", en: "Silk Screen6"}}  
                                     
                                        
                                    />
                                </div>
                                <span className="" style={{marginTop: 10, fontSize: 14}}>
                                    {t.name}
                                    {
                                        t.options?.silkScreen && (
                                            <Tag color="blue" style={{marginLeft: 10}}>支持私印</Tag>
                                        )
                                    }
                                </span>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )

}

export default CaseNodeCellTemplateSelector;