import React, { useEffect, useState } from 'react'

interface Props {
    canSelect?: boolean;
    selected?: boolean;
    disabled?: boolean;
    connected?: boolean;
    onClick?: (disabled?: boolean, selected?: boolean) => void;
}

const Connector = (props: {
    lightColor: string;
    deepColor: string;
    selected?: boolean;
    disabled?: boolean;
    connected?: boolean;
    onClick?: (disabled?: boolean, selected?: boolean, connected?: boolean) => void;
}) => {
    const [ hover, setHover ]  = useState(false);
    const [ size, setSize] = useState(20);
    const [ background, setBackground] = useState<string | undefined>(undefined);
    const [ borderColor, setBorderColor ] = useState<string | undefined>(props.lightColor);
    const [ opacity, setOpacity ] = useState<0.15 | 1>();

    useEffect(() => {
        //selected 和 connected 互斥
        if(props.selected || props.connected) {
            setBackground(props.deepColor);
        }else {
            if(hover) {
                setBackground(props.deepColor);
            }else {
                setBackground(undefined);
            }
        }

        if(props.selected) {
            setBorderColor(props.deepColor);
        }else {
            setBorderColor(props.lightColor);
        }

        if(props.disabled) {
            setOpacity(0.15)
        }else {
            if(props.connected) {
                if(!props.selected) {
                    if(hover) {
                        setOpacity(1);
                    }else {
                        setOpacity(0.15);
                    }
                }else {
                    setOpacity(1)
                }
            }else {
                setOpacity(1);
            }
        }

    }, [props.selected, props.connected, props.disabled, props.deepColor, props.lightColor, hover])

    return (
        <div 
            className="connecter inline-block"  style={{margin: '0 2px'}}
                onMouseMove={() => {
                if(props.disabled || props.selected) {
                    return ;
                }
                setHover(true)
            }} 
            onClick={(e) => {
                e.stopPropagation();
                if(props.disabled) {
                    return ;
                }
                if(typeof props.onClick === 'function') {
                    props.onClick(props.disabled, props.selected, props.connected);
                }
            
            }}
            onMouseOut={() => {
                setHover(false);
            }}
        >
            <div 
                className="node-cell-connector source-connector pointer" 
                style={{ 
                    height: size, 
                    width: size, 
                    borderRadius: size / 2, 
                    border: `3px solid ${props.deepColor}`, 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    fontSize: 0,
                    cursor: props.disabled ? 'not-allowed' : 'pointer',
                    opacity: opacity
                }}>
                <div style={{ 
                    height: size - 2 * 3, 
                    width: size - 2 * 3, 
                    borderRadius: (size - 2 * 3) / 2, 
                    border: `3px solid ${borderColor}`,
                    background: background
                }}></div>
            </div>

        </div>
    )
}

export const SourceConnector = (props: Props) => {
    return (
        <Connector 
            deepColor={props.connected ? "#005826" : "#074baf"}
            lightColor={props.connected ? "#00a650" : "#02aff2"}
            connected={props.connected}
            disabled={props.disabled}  
            selected={props.selected} 
            onClick={props.onClick}
        />
    )
}

export const TargetConnector = (props: Props) => {
    return (
        <Connector
            deepColor={props.connected ? "#019875" : "orangered"}
            lightColor={props.connected ? "#3cb878" : "orange"}
            connected={props.connected}
            disabled={props.disabled}  
            selected={props.selected} 
            onClick={props.onClick}
        />
    )
}

