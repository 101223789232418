import React from 'react'
import { TouchNodeCell } from './components/TouchNodeCell'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingSource: any;
    onRenameApp: (app: any) => void;
    bindingAccepts: string;
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onRemoveBindingTarget?: (item: any) => void;
    className?: string;
    viewSource: any;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const TwoTouchSwitchNodeCell = (props: Props) => {

    return (
        <TouchNodeCell 
            className="two-switch"
            {...props}
        />
    )

}