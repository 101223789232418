import { QrcodeOutlined } from "@ant-design/icons";
import { Modal, Skeleton } from "antd";
import { useEffect, useState } from "react"
import { apis } from "../../utils/apis";
import { request } from "../../utils/request";

import QRCode from 'qrcode.react';

interface Props {

}

export const Welcome = (props: Props) => {

    const [cates, setCates] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCustomerProcessProgressing();
    }, []);

    const getCustomerProcessProgressing = async () => {
        const res = await request.get(apis.pcCaseCustomerProcessProgressing);
        if (res.code === 1) {
            setCates(res.data);
        }
    }

    return (
        <div className="welcome page">
            <div className="customer-list" >
                {
                    loading ? (
                        <Skeleton active />
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>

                            {
                                cates.length > 0 && cates.map((c, index) => {
                                    const customers = c.customers;
                                    return (
                                        <div className="item" style={{ flex: 1, width: "calc(100vw / 3 - 20px)", border: '1px solid #f00', padding: 10, margin: 10 }}>
                                            <div style={{ textAlign: 'center' }}>分类: {c.name}</div>
                                            {
                                                customers.length > 0 && customers.map((c, index) => {
                                                    return (
                                                        <div className="flex" style={{ alignItems: 'center' }}>
                                                            <QrcodeOutlined style={{ marginRight: 5 }} onClick={() => {
                                                                request.get(apis.caseCustomer + `/${c.id}/deliver/ticket`)
                                                                    .then((res) => {
                                                                        if(res.code === 1) {
                                                                            console.log(res.data);
                                                                            Modal.confirm({
                                                                                content: (
                                                                                    <QRCode value={"https://develop.huafeiiot.com/qrc?key=" + "GRANT_SH|" + res.data.lastCode}/>
                                                                                )
                                                                            })
                                                                        }
                                                                    })
                                                            }}/>
                                                            <div style={{marginRight: 5}}>{c.type}</div>
                                                            {
                                                                c.name
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }

                        </div>

                    )
                }

            </div>
        </div>
    )

}