import { LoadingOutlined, QrcodeOutlined, ScanOutlined } from "@ant-design/icons";
import { Form, Input, InputRef, FormInstance, Spin } from "antd";
import React from "react";
import { CSSProperties } from "react"
import { apis } from "../../utils/apis";
import { request } from "../../utils/request";

interface Props {
    style?: CSSProperties,
    onScan?: (code: string, data: any) => void;
    onBeforeScan?: (device: string) => Promise<boolean>;
}

interface State {
    loading: boolean;
    deviceInfo: any;
    blur: boolean;
    mask: boolean;
    disabled: boolean;
}

export default class extends React.Component<Props, State> {

    isBlur = false;
    
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: false,
            deviceInfo: null as any,
            blur: false,
            mask: false,
            disabled: false
        }
    }

    inputRef = React.createRef<InputRef>();
    formRef = React.createRef<FormInstance>();

    private startLoading = () => {
        this.setState({
            loading: true,
        })

    }
    private endLoading = () => {
        this.setState({
            loading: false,
        })
    }

    private onScan = async (data: { code: string }) => {
        if(!data.code) {
            return ;
        }
        this.startLoading();
        try {
            const res: any = await request.get(apis.productDeviceInfo + `/${data.code}`);
            if (res.code === 1) {
                let flag = true;
                if(this.props.onBeforeScan) {
                    const bool = await this.props.onBeforeScan(res.data);
                    if(!bool) {
                        flag = false;
                    }
                }
                if(flag) {
                    this.props.onScan(data.code, res.data);
                    this.setState({
                        deviceInfo: res.data
                    });
                }else {
                    this.props.onScan("", null);
                    this.setState({
                        deviceInfo: null
                    });
                }
            }else {
                this.setState({
                    deviceInfo: null
                });
            }
        } finally {
            this.endLoading();
            this.inputInit();
        }
    }

    private inputInit = () => {
        this.inputRef.current.focus();
        this.formRef.current.setFieldsValue({code: ""});
    }

    setDeviceInfo = (deviceInfo: any) => {
        this.setState({
            deviceInfo: {...deviceInfo}
        });
    }

    escape = () => {
        this.props.onScan("", null);
        this.setState({
            deviceInfo: null
        });
    }

    updateDevice = (device: any) => {
        this.setState({
            deviceInfo: device,
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.state.blur !== prevState.blur) {
            if(this.state.blur) {
                setTimeout(() => {
                    if(this.isBlur) {
                        this.setState({
                            mask: true
                        })
                    }
                }, 200);

            }else {
                this.setState({
                    mask: false
                });
            }
        }
    }

    disable = () => {
        console.log("disable");
        this.setState({
            disabled: true
        })
    }

    release = () => {
        console.log("release");
        this.setState({
            disabled: false
        }, () => {
            this.inputRef.current.focus();
        });

    }

    render() {
        return (
            <div className="scan-code-wrapper flex column relative" style={{ height: "100vh", width: '100vw', ...(this.props.style || {}) }}>
                {
                    this.state.mask && !this.state.disabled && (
                        <div
                            className="mask flex center"
                            style={{ position: 'absolute', top: 0, left: 0,width: '100%', height: '100%', background: "rgba(0, 0, 0, .6)", zIndex: 999 }}
                            onClick={() => {
                                this.inputRef.current?.focus();
                            }}
                        >
                            <span style={{ fontSize: 18, color: "#fff", cursor: "pointer" }}>已暂停，点此继续扫码</span>
                        </div>
                    )
                }
                <Spin spinning={this.state.loading}>
                    <div style={{ flex: 1 }} onClick={() => {
                        this.inputRef.current?.focus();
                    }}>
                        {this.props.children}
                    </div>
                </Spin>
                <div style={{ background: "#fff",/*  boxShadow: '0 3px 10px rgba(0, 0, 0, .5)', */ padding: '5px 10px' }}>
                    <Form ref={this.formRef} onFinish={this.onScan} layout="inline">
                        <Form.Item 
                            name="code" label={(<ScanOutlined style={{ color: "lightgray" }} />)} colon={false} labelCol={{ span: 4 }} style={{ marginBottom: 0 }} >
                            <Input
                                ref={this.inputRef}
                                type="number"
                                disabled={this.state.loading || this.state.disabled}
                                placeholder={"扫码"}
                                style={{ width: '6em', border: 0, borderRadius: 0, outline: "none" }}
                                autoComplete={"off"}
                                autoFocus
                                onBlur={() => {
                                    this.isBlur = true;
                                    this.setState({
                                        blur: true
                                    })
                                }}
                                onFocus={() => {
                                    this.isBlur = false;
                                    this.setState({
                                        blur: false
                                    })
                                }}
                            />
                        </Form.Item>
                        {
                            this.state.loading ? (
                                <span>
                                    <LoadingOutlined spin/>
                                </span>
                            ) : 
                            this.state.deviceInfo ? (
                                <Form.Item>
                                    <div className="flex " style={{alignItems: 'center', color: "#888"}}>
                                        <div className="item" style={{marginRight: 30}}>
                                            <span className="label"><QrcodeOutlined /></span>
                                            <span >{this.state.deviceInfo.deviceCode}</span>
                                            
                                        </div>
                                        <div className="item" style={{marginRight: 30}}>
                                            <span className="label">产品：</span>
                                            <span className="" style={{color: undefined}}>{this.state.deviceInfo.spu?.name}</span>
                                        </div>
                                        
                                        <div className="item" style={{marginRight: 30}}>
                                            <span className="label">MAC：</span>
                                            <span className="value" style={{color: this.state.deviceInfo.macAddr ? "green" : undefined }}>{this.state.deviceInfo.macAddr}</span>
                                        </div>
                                        <div className="item" style={{marginRight: 30}}>
                                            <span className="label">HEX：</span>
                                            <span className="value" style={{color: this.state.deviceInfo.hexFileName ? "green" : undefined }}>{this.state.deviceInfo.hexFileName ? "已烧录：" + this.state.deviceInfo.hexFileName : "未烧录"}</span>
                                        </div>
                                        <div className="item">
                                            <span className="label">当前：</span>
                                            <span className="value">{this.state.deviceInfo.status <= 1 ? "未入库" : this.state.deviceInfo.status === 2 ? "已入库" :  "已出库"}</span>
                                        </div>
                                        {/* <div className=""><CloseOutlined /></div> */}
                                    </div>
                                </Form.Item>
                            ) : (
                                <Form.Item colon={false} label={(<span className="bold">扫描获取设备信息</span>)} />
                            )
                        }
                    </Form>
                </div>
            </div>
        )
    }
}
