import { Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import AddCase from "./AddCase";
import CaseConfig from "./CaseConfig";
import CaseNodeHexWrite from "./CaseNodeHexWrite";
import CaseOrder from "./CaseOrder";

interface ProjectViewProps extends RouteChildrenProps<{ step?: string, caseId?: string }> {
    customer?: any;
    simple?: boolean;
    setSimple?: any;
    fullScreen?: boolean;
    ignoreTabs?: string[];
    onTabChange?: (key: string) => void;
    onGoConfig?: () => void;
    configAuto?: boolean;
}

export default function ProjectView(props: ProjectViewProps) {


    // useEffect(() => {
    //     if (props.customer?.id) {
    //         request.get(apis.case, { customerId: props.customer.id })
    //             .then((res) => {
    //                 if (res.code === 1) {
    //                     setCaseList(res.data)
    //                 }
    //             })
    //     }
    // }, [props.customer]);

    // const onAddCase = async (data: any) => {
    //     const res = await request.post(apis.case, data);
    //     if (res.code === 1) {
    //         window.open(`/#/project/${props.customer?.id}/case/${res.data.id}/create`, "_blank");
    //         caseList.push(res.data);
    //         setCaseList([...caseList]);
    //         return true;
    //     }
    // }

    const [tabKey, setTabKey] = useState(props.match.params.step);
    useEffect(() => {
        setTabKey(props.match.params.step);
    }, [props.match.params.step]);


    const [caseId, setCaseId] = useState(props.match.params.caseId ? Number(props.match.params.caseId) : 0);
    const onCaseChange = (c: any) => {
        if (c) {
            setCaseId(c.id || 0);
        }
    }

    return (

        <div className="bg-white" style={{ width: `calc(100vw${props.fullScreen ? '-20px' : ' - 220px'})`, height: `calc(100vh ${props.fullScreen ? '' : '- 100px'})`, paddingLeft: 0 }}>
            <Tabs
                destroyInactiveTabPane
                type="card"
                activeKey={tabKey}
                onChange={(key) => {
                    //生产工具适配
                    if(props.onTabChange) {
                        props.onTabChange(key);
                    }else {
                        props.history.replace(`/project/${props.customer?.id}/${key}`)
                        if (['hex-write', 'auto-config'].includes(key)) {
                            props.setSimple(true);
                        } else {
                            props.setSimple(false);
                        }
                    }
                }}
                tabBarStyle={{ marginBottom: 0 }}
                style={{ height: `calc(100vh${props.fullScreen ? '' : ' - 102px'})`, overflowY: 'scroll' }}
            >
                {
                    (!props.ignoreTabs || !props.ignoreTabs.includes("case-list")) && (
                        <Tabs.TabPane key={"case-list"} tab="客户方案">
                            <AddCase 
                                {...props}
                                customer={props.customer}
                            />
                        </Tabs.TabPane>
                    )
                }
                {/* <Tabs.TabPane key={"dueros"} tab="关联小度">

                </Tabs.TabPane> */}
                {/* <Tabs.TabPane key={"wifi"} tab="WIFI设置">

                </Tabs.TabPane>
                <Tabs.TabPane key={"verify"} tab="方案校验">

                </Tabs.TabPane> */}
                {
                    (!props.ignoreTabs || !props.ignoreTabs.includes("orders-list")) && (
                        <Tabs.TabPane key={"orders"} tab="生成订单">
                            <CaseOrder
                                customer={props.customer}
                            />
                        </Tabs.TabPane>
                    )
                }
                {
                    (!props.ignoreTabs || !props.ignoreTabs.includes("hex-write")) && (
                        <Tabs.TabPane key={"hex-write"} tab="设备烧录">
                            <CaseNodeHexWrite
                                {...props}
                                customer={props.customer}
                                caseId={caseId}
                                onCaseChange={onCaseChange}
                                fullScreen={props.fullScreen}
                                onGoConfig={() => {
                                    if(props.onGoConfig) {
                                        props.onGoConfig();
                                    }else {
                                        props.history.push(`/project/${props.customer?.id}/auto-config`);
                                    }
                                }}
                            />
                        </Tabs.TabPane>
                    )
                }
                {
                    (!props.ignoreTabs || !props.ignoreTabs.includes("auto-config")) && (
                        <Tabs.TabPane key={"auto-config"} tab="自动配置">
                            <CaseConfig
                                {...props}
                                customer={props.customer}
                                caseId={caseId}
                                onCaseChange={onCaseChange}
                                fullScreen={props.fullScreen}
                                auto={props.configAuto}
                                
                            />
                        </Tabs.TabPane>
                    )
                }
            </Tabs>
        </div>
    )

}


