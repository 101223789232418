import { AppstoreOutlined, CloseOutlined, SolutionOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Spin, Tabs, message } from 'antd';
import { useEffect, useState } from 'react';
import { apis } from '../../../../../utils/apis';
import { request } from '../../../../../utils/request';
import { CaseAreaCreateModal } from './components/CaseAreaCreateModal';

import { CaseExtraConfig } from './CaseExtraConfig';
import { CaseAreaDevicesEditor } from './components/CaseAreaDevicesEditor';
import { CaseControllableAppModal } from './components/CaseControllableAppModal';
import { CaseNodeMacModal } from './components/CaseNodeMacModal';
import { CaseNodeRenameModal } from './components/CaseNodeRenameModal';
import { CaseOfflineSpeakersConfig } from './components/CaseOfflineSpeakersConfig';
import { CaseOverviewModal } from './components/CaseOverviewModal';
import { CaseSceneCreateModal } from './components/CaseSceneCreateModal';
import { CaseSubmitModal } from './components/CaseSubmitModal';


import './style.less';

interface Props {
    case: any;
    mode: "edit" | "view" | "config",
    viewType: "cell" | "table",
    setViewType: any,
    onRefresh: () => void;
    setMode: (mode: "edit" | "view" | "config") => void,
    onSetCaseNet: (_caseNet: any) => void;
    onCaseNetUpdate: (_caseNet: any) => void;
}


export const CaseEditor = (props: Props) => {


    const [loading, setLoading] = useState(false);
    const [caseAreas, setCaseAreas] = useState<any[]>([]);
    const onCaseAreaAdd = (caseArea: any) => {
        caseAreas.push(caseArea);
        setCaseAreas([...caseAreas]);
        closeCaseAreaCreateModal();
    }

    const [selectedCaseAreaId, setSelectedCaseAreaId] = useState(0);
    const [caseNodes, setCaseNodes] = useState<any[]>([]);
    const [caseAreaNodesMap, setCaseAreaNodesMap] = useState<Map<number, any[]>>(new Map());
    const [caseScenes, setCaseScenes] = useState<any[]>([]);
    const [caseRelations, setCaseRelations] = useState<any[]>([]);
    const [caseOfflineSpeakers, setCaseOfflineSpeakers] = useState<any[]>([]);
    const [canConfig, setCanConfig] = useState(false);

    useEffect(() => {
        if (props.case?.caseId) {
            fetchData(props.case.caseId);
        }

    }, [props.case]);


    const onRefresh = async () => {
        props.onRefresh();
        if (props.case?.caseId) {
            await fetchData(props.case.caseId);
        }
    }


    useEffect(() => {
        if (props.mode !== 'edit') {
            props.setViewType("cell");
        }
    }, [props.mode])

    useEffect(() => {
        const map = new Map();
        for (let i = 0; i < caseNodes.length; i++) {
            const node = caseNodes[i];
            if (map.has(node.caseAreaId)) {
                map.get(node.caseAreaId).push(node)
            } else {
                map.set(node.caseAreaId, [node])
            }
        }
        setCaseAreaNodesMap(map);
    }, [caseNodes, caseAreas]);

    useEffect(() => {
        const _offlineSpeakers: any[] = [];
        let _canConfig = true;
        for (let i = 0; i < caseNodes.length; i++) {
            const node = caseNodes[i];
            if(!node.isOnline) {
                _canConfig = false;
            }
            for (let j = 0; j < node.apps.length; j++) {
                const app = node.apps[j];
                if (app.appDeviceId === 84) {
                    _offlineSpeakers.push(app);
                }
            }
        }
        setCanConfig(_canConfig);
        setCaseOfflineSpeakers(_offlineSpeakers);
    }, [caseNodes]);


    const [bindMode, setBindMode] = useState<"wait" | "targets" | "view">("wait");
    const [bindCurrentSource, setBindCurrentSource] = useState<any>(null);
    const [bindCurrentAccpets, setBindCurrentAccepts] = useState<string>("*");
    const [bindCurrentTargets, setBindCurrentTargets] = useState<{
        type: "app" | "scene" | "ir-sensor",
        target: any
    }[]>([]);
    const onSetCurrentSource = (app: any, accepts: string) => {
        setBindCurrentSource(app);
        setBindCurrentAccepts(accepts);
    }
    const onResetCurrentSource = () => {
        setBindCurrentSource(null);
        setBindCurrentTargets([]);
        setBindMode("wait");
    }
    const onResetView = () => {
        setViewSource({});
        setBindCurrentTargets([]);
        setBindMode("wait");
    }
    const onAddCurrentTarget = (type: "app" | "scene" | "ir-sensor", target: any/* app or scene */) => {
        const notSame = bindCurrentTargets.find((item) => item.type !== type);
        if (notSame) {
            message.warn("不能绑定不一样的");
            return;
        }
        if (type === "scene") {
            if (bindCurrentTargets.length > 0) {
                message.warn("场景只能绑定一个");
                return;
            }
        }
        if (target.key === "general_ir_human_sensor") {
            if (bindCurrentTargets.length > 0) {
                message.warn("分红外只能绑定一个总红外");
                return;
            }
        }
        bindCurrentTargets.push({
            type,
            target
        });
        setBindCurrentTargets([...bindCurrentTargets]);
    }
    const onViewTargets = (targets: any, targetType?: "app" | "scene" | "ir-sensor") => {
        const _bindCurrentTargets: any[] = [];
        // console.log(targets);
        if (targets instanceof Array) {
            //apps
            for (let i = 0; i < targets.length; i++) {
                const target = targets[i];
                if ("targetAppId" in target) {
                    for (let j = 0; j < caseNodes.length; j++) {
                        const node = caseNodes[j];
                        let _break = false;
                        for (let k = 0; k < node.apps.length; k++) {
                            const app = node.apps[k];
                            if (app.id === target.targetAppId) {
                                _bindCurrentTargets.push({
                                    type: targetType,
                                    target: app
                                })
                                _break = true;
                                break;
                            }
                        }
                        if (_break) {
                            break;
                        }
                    }
                }
            }
        } else if ("targetSceneId" in targets) {
            for (let j = 0; j < caseScenes.length; j++) {
                const scene = caseScenes[j];
                if (scene.caseSceneId === targets.targetSceneId) {
                    _bindCurrentTargets.push({
                        type: "scene",
                        target: scene
                    });
                    break;
                }
            }
        }
        setBindCurrentTargets(_bindCurrentTargets)
    }
    const onRemoveCurrentTarget = (type: "app" | "scene" | "ir-sensor", target: any) => {
        let _bindCurrentTargets: any[];
        if (type === "scene") {
            _bindCurrentTargets = [];
        } else {
            _bindCurrentTargets = bindCurrentTargets.filter((item) => {
                return item.target.id !== target.id;
            });
        }
        setBindCurrentTargets(_bindCurrentTargets);
    }

    const fetchData = (caseId: number) => {
        if (!loading) {
            setLoading(true);
        }
        return new Promise((resolve, reject) => {
            request.get(apis.area, { caseId })
                .then((res) => {
                    if (res.code === 1) {
                        setCaseAreas(res.data);
                        request.get(apis.caseNode, { caseId })
                            .then((res) => {
                                if (res.code === 1) {
                                    const nodes: any[] = res.data.caseNodes;
                                    for (let i = 0; i < nodes.length; i++) {
                                        const node = nodes[i];
                                        for (let j = 0; j < node.apps.length; j++) {
                                            const app = node.apps[j];
                                            app.caseNodeName = node.name;
                                            app.caseAreaId = node.caseAreaId;
                                            app.caseAreaName = node.caseAreaName;
                                            app.caseNodeId = node.caseNodeId
                                        }
                                    }
                                    setCaseNodes(nodes);
                                    Promise.all([
                                        request.get(apis.caseScene, { caseId })
                                            .then(res => {
                                                if (res.code === 1) {
                                                    setCaseScenes(res.data);
                                                } else {
                                                    message.warn(res.msg || res.message);
                                                }
                                            }),
                                        request.get(apis.caseNodeAppBuildRelation + `/${caseId}`)
                                            .then((res) => {
                                                if (res.code === 1) {
                                                    setCaseRelations(res.data);
                                                } else {
                                                    message.warn(res.msg || res.message)
                                                }
                                            })
                                    ])
                                        .then(() => {
                                            resolve("ok")
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        })
                                } else {
                                    message.warn(res.msg || res.message);
                                    throw new Error(res.msg || res.message);
                                }
                            })
                    } else {
                        message.warn(res.msg || res.message);
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        })

    }


    const [caseAreaCreateVisible, setCaseAreaCreateVisible] = useState(false);
    const openCaseAreaCreateModal = () => {
        setCaseAreaCreateVisible(true);
    }
    const closeCaseAreaCreateModal = () => {
        setCaseAreaCreateVisible(false);
    }


    const onCreateCaseNode = (data: any) => {
        request.post(apis.caseNode, data)
            .then((res) => {
                if (res.code === 1) {
                    message.success("已添加" + res.data.name)
                    onRefresh();

                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }


    const [createSceneModalVisible, setCreateSceneModalVisible] = useState(false);
    const openCreateSceneModal = () => {
        setCreateSceneModalVisible(true);
    }
    const closeCreateSceneModal = () => {
        setCreateSceneModalVisible(false);
    }
    const onSceneAdd = (scene: any) => {
        fetchData(props.case.caseId);
        closeCreateSceneModal();
    }



    const onUpdateNodeApp = async (nodeApps: any) => {
        for (let i = 0; i < caseNodes.length; i++) {
            const node = caseNodes[i];
            for (let j = 0; j < node.apps.length; j++) {
                const _nodeApp = node.apps[j];
                for (let k = 0; k < nodeApps.length; k++) {
                    const nodeApp = nodeApps[k];
                    if (_nodeApp.id === nodeApp.id) {
                        _nodeApp.isWireless = nodeApp.isWireless;
                        _nodeApp.name = nodeApp.name;
                        break;
                    }

                }
            }
        }
        setCaseNodes(JSON.parse(JSON.stringify(caseNodes)));
        onRefresh();
    }

    const onBuildRelation = (data: any) => {
        request.post(apis.caseNodeAppBuildRelation, data)
            .then((res) => {
                if (res.code === 1) {
                    const caseAppIds = res.data.map((item: any) => item.caseSourceAppId);
                    for (let i = 0; i < caseNodes.length; i++) {
                        const node = caseNodes[i];
                        for (let j = 0; j < node.apps.length; j++) {
                            const app = node.apps[j];
                            if (caseAppIds.includes(app.id)) {
                                app.isSource = true;
                            }
                        }
                    }
                    message.success("已添加绑定");
                    setCaseNodes([...caseNodes]);
                    fetchData(props.case.caseId);
                    onResetCurrentSource();
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }

    const onUpdateRelation = (data: any) => {
        request.patch(apis.caseNodeAppBuildRelation, data)
            .then((res) => {
                if (res.code === 1) {
                    if (res.data instanceof Object) {
                        const caseAppIds = res.data.map((item: any) => item.caseSourceAppId);
                        for (let i = 0; i < caseNodes.length; i++) {
                            const node = caseNodes[i];
                            for (let j = 0; j < node.apps.length; j++) {
                                const app = node.apps[j];
                                if (caseAppIds.includes(app.id)) {
                                    app.isSource = true;
                                }
                            }
                        }
                        message.success("已修改绑定");
                        setCaseNodes([...caseNodes]);
                    } else {
                        message.success(res.data);
                    }
                    fetchData(props.case.caseId);
                    onResetView();
                } else {
                    message.warn(res.msg || res.message);
                }
            })
    }

    const onRemoveCaseNode = (caseNodeId: number) => {
        Modal.confirm({
            centered: true,
            title: "请确认是否删除",
            content: "删除节点，将会删除场景、绑定中含此产品相关的内容",
            onOk: () => {
                request.delete(apis.caseNode + `/${caseNodeId}`)
                    .then((res) => {
                        if (res.code === 1) {
                            const _caseNodes = caseNodes.filter((item) => item.id !== res.data);
                            // console.log(caseScenes);
                            // const _caseScenes = _case
                            // setCaseNodes(_caseNodes);
                            onRefresh();
                            message.info("已删除");
                        } else {
                            message.warn(res.msg || res.message);
                        }
                    })
            },
            okButtonProps: {
                danger: true,
                ghost: true
            }
        })

    }


    const [macEditModalVisible, setMacEditModalVisible] = useState(false);
    const [macEditNode, setMacEditNode] = useState<any>();
    const openMacEditModal = (node: any) => {
        setMacEditNode(node);
        setMacEditModalVisible(true);
    }
    const closeMacEditModal = () => {
        setMacEditNode(undefined);
        setMacEditModalVisible(false);
    }

    const [nodeNameEditModalVisible, setNodeNameEditModalVisible] = useState(false);
    const [renameNode, setRenameNode] = useState<any>();
    const openNodeNameEditModal = (node: any) => {
        setNodeNameEditModalVisible(true);
        setRenameNode(node);
    }
    const closeNodeNameEditModal = () => {
        setRenameNode(undefined);
        setNodeNameEditModalVisible(false);
    }

    const onCaseNodeUpdate = (node: any) => {
        for (let i = 0; i < caseNodes.length; i++) {
            const caseNode = caseNodes[i];
            if (caseNode.caseNodeId === node.id) {
                if (caseNode.mac !== node.mac) {
                    caseNode.mac = node.mac;
                    closeMacEditModal();
                }
                if (caseNode.name !== node.name) {
                    caseNode.name = node.name;
                    closeNodeNameEditModal();
                }
                break;
            }
        }
        setCaseNodes([...caseNodes]);
    }

    const onUpdateCaseArea = (caseArea: any) => {
        for (let i = 0; i < caseAreas.length; i++) {
            const area = caseAreas[i];
            if (caseArea.id === area.id) {
                area.name = caseArea.name;
                break;
            }
        }
        setCaseAreas([...caseAreas]);
        onRefresh();
    }

    const onRemoveCaseArea = (caseArea: any) => {
        const _caseAreas = caseAreas.filter((item) => item.id !== caseArea.id);
        setCaseAreas(_caseAreas);
        onRefresh();
    }


    const onUpdateCaseScene = (caseScene: any) => {
        const _caseScene = caseScenes.find((item) => item.id === caseScene.id);
        if (_caseScene) {
            _caseScene.caseSceneName = caseScene.name;
        }
        setCaseScenes([...caseScenes]);
        onRefresh();
    }
    const onRemoveCaseScene = (caseSceneId: number) => {
        const _caseScenes = caseScenes.filter((item) => item.id !== caseSceneId);
        setCaseScenes(_caseScenes);
        onRefresh();
    }

    const [caseOverviewVisible, setCaseOverviewVisible] = useState(false);
    const openCaseOverviewModal = () => {
        setCaseOverviewVisible(true);
    }
    const closeCaseOverviewModal = () => {
        setCaseOverviewVisible(false);
    }

    const [caseControllableAppVisible, setCaseControllableAppVisible] = useState(false);
    const openCaseControllableAppModal = () => {
        setCaseControllableAppVisible(true);
    }
    const closeCaseControllableAppModal = () => {
        setCaseControllableAppVisible(false);
    }

    const [caseSubmitVisible, setCaseSubmitVisible] = useState(false);
    const openCaseSubmitModal = () => {
        setCaseSubmitVisible(true);
    }
    const closeCaseSubmitModal = () => {
        setCaseSubmitVisible(false);
    }

    const onCaseSubmit = (_case: any) => {
        onRefresh();
        closeCaseSubmitModal();
    }

    const [viewSource, setViewSource] = useState<any>();

    return (
        <>
            <CaseAreaCreateModal
                caseId={props.case?.caseId}
                visible={caseAreaCreateVisible}
                onCancel={closeCaseAreaCreateModal}
                onCaseAreaAdd={onCaseAreaAdd}
            />
            <CaseSceneCreateModal
                visible={createSceneModalVisible}
                onCancel={closeCreateSceneModal}
                caseId={props.case.caseId}
                nodes={caseNodes}
                onSceneAdd={onSceneAdd}
            />
            <CaseNodeMacModal
                visible={macEditModalVisible}
                onClose={closeMacEditModal}
                node={macEditNode}
                onCaseNodaUpdate={onCaseNodeUpdate}
            />
            <CaseNodeRenameModal
                visible={nodeNameEditModalVisible}
                onClose={closeNodeNameEditModal}
                node={renameNode}
                onCaseNodaUpdate={onCaseNodeUpdate}
            />
            <CaseOverviewModal
                visible={caseOverviewVisible}
                onCancel={closeCaseOverviewModal}
                caseNodes={caseNodes}
                caseAreas={caseAreas}
            />
            <CaseControllableAppModal
                visible={caseControllableAppVisible}
                onCancel={closeCaseControllableAppModal}
                caseNodes={caseNodes}
            />
            <CaseSubmitModal
                caseId={props.case.caseId}
                visible={caseSubmitVisible}
                onCancel={closeCaseSubmitModal}
                onCaseSubmit={onCaseSubmit}
            />
            <Spin spinning={loading}>
                <div className="case-editor">
                    <div style={{textAlign: 'center'}}>
                <Button type="link" onClick={openCaseControllableAppModal}>
                    <AppstoreOutlined />
                    被控设备信息
                </Button>

                    </div>
                    {
                        bindMode === "targets" && (
                            <div className="bind-confirm">
                                <div className="content">
                                    {
                                        bindCurrentSource && (
                                            <span>{bindCurrentSource.caseAreaName}的{bindCurrentSource.name}{bindCurrentSource.key === "sub_ir_human_sensor" ? "绑定" : "控制"}: </span>
                                        )
                                    }
                                    {
                                        bindCurrentTargets.length > 0 && bindCurrentTargets.map((item, index) => {
                                            if (item.type === "app" || item.type === "ir-sensor") {
                                                return item.target.caseAreaName + "的" + item.target.name
                                            } else if (item.type === "scene") {
                                                return item.target.caseSceneName + "情景"
                                            }
                                        }).join("、")
                                    }

                                </div>
                                <div className="buttons">
                                    <Button
                                        type="primary"
                                        size="small"
                                        disabled={bindCurrentTargets.length === 0}
                                        onClick={() => {
                                            const data: {
                                                sourceAppId: number;
                                                target: {
                                                    caseSceneId: number
                                                } | {
                                                    caseAppIds: number[]
                                                }
                                            } = {
                                                sourceAppId: bindCurrentSource.id,
                                                target: bindCurrentTargets[0].type === "scene" ? {
                                                    caseSceneId: bindCurrentTargets[0].target.caseSceneId
                                                } : {
                                                    caseAppIds: bindCurrentTargets.map((item) => item.target.id)
                                                }
                                            }
                                            onBuildRelation(data);
                                        }}
                                    >确认</Button>
                                    <Button type="link" style={{ color: "lightgray" }} onClick={onResetCurrentSource}>
                                        <CloseOutlined />
                                        取消
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                    {
                        bindMode === "view" && (
                            <div className="bind-confirm">
                                <div className="content">
                                    {
                                        viewSource && (
                                            <span>{viewSource.caseAreaName}的{viewSource.name}{viewSource.key === "sub_ir_human_sensor" ? "绑定" : "控制"}: </span>
                                        )
                                    }
                                    {
                                        bindCurrentTargets.length > 0 && bindCurrentTargets.map((item, index) => {
                                            if (item.type === "app" || item.type === "ir-sensor") {
                                                return item.target.caseAreaName + "的" + item.target.name
                                            } else if (item.type === "scene") {
                                                return item.target.caseSceneName + "情景"
                                            }
                                        }).join("、")
                                    }
                                </div>
                                <div className="buttons">
                                    <Button
                                        type="primary"
                                        size="small"
                                        // disabled={bindCurrentTargets.length === 0}
                                        onClick={() => {
                                            const data: {
                                                sourceAppId: number;
                                                target: {
                                                    caseSceneId: number
                                                } | {
                                                    caseAppIds: number[]
                                                } | null
                                            } = {
                                                sourceAppId: viewSource.id,
                                                target: bindCurrentTargets.length === 0 ? null : (bindCurrentTargets[0].type === "scene" ? {
                                                    caseSceneId: bindCurrentTargets[0].target.caseSceneId
                                                } : {
                                                    caseAppIds: bindCurrentTargets.map((item) => item.target.id)
                                                })
                                            }
                                            onUpdateRelation(data);
                                        }}
                                    >确认</Button>
                                    <Button type="link" style={{ color: "lightgray" }} onClick={onResetView}>
                                        <CloseOutlined />
                                        取消
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                    <div className="case-detail">
                        {/* <CaseNetEditor
                            canConfig={canConfig}
                            case={props.case}
                            mode={props.mode}
                            setMode={props.setMode}
                            onHandleSubmit={openCaseSubmitModal}
                            onCaseNetUpdate={props.onCaseNetUpdate}
                            onSetCaseNet={props.onSetCaseNet}
                            onRefresh={onRefresh}
                            caseNodes={caseNodes}
                        /> */}
                        <Tabs
                            tabBarExtraContent={{
                                left: (
                                    <div style={{width: 10}}>

                                    </div>
                                ),
                                right: (
                                    <>
                                        <Button type="link" onClick={openCaseControllableAppModal}>
                                            <AppstoreOutlined />
                                            被控设备信息
                                        </Button>
                                        <Button type="link" onClick={openCaseOverviewModal}>
                                            <SolutionOutlined />
                                            设备清单(总计:{caseNodes.length})
                                        </Button>
                                    </>
                                )
                            }}
                        >
                             <Tabs.TabPane tab="区域、设备与场景" key="area-devices">
                                <CaseAreaDevicesEditor
                                    case={props.case}
                                    mode={props.mode}
                                    viewType={props.viewType}
                                    setMode={props.setMode}
                                    caseAreas={caseAreas}
                                    caseNodes={caseNodes}
                                    caseScenes={caseScenes}
                                    caseRelations={caseRelations}
                                    selectedCaseAreaId={selectedCaseAreaId}
                                    setSelectedCaseAreaId={setSelectedCaseAreaId}
                                    openCaseAreaCreateModal={openCaseAreaCreateModal}
                                    onCreateCaseNode={onCreateCaseNode}
                                    openMacEditModal={openMacEditModal}
                                    openNodeNameEditModal={openNodeNameEditModal}
                                    onRemoveCaseNode={onRemoveCaseNode}
                                    bindMode={bindMode}
                                    bindCurrentSource={bindCurrentSource}
                                    bindCurrentAccpets={bindCurrentAccpets}
                                    bindCurrentTargets={bindCurrentTargets}
                                    setBindMode={setBindMode}
                                    onSetCurrentSource={onSetCurrentSource}
                                    onResetCurrentSource={onResetCurrentSource}
                                    onAddCurrentTarget={onAddCurrentTarget}
                                    onRemoveCurrentTarget={onRemoveCurrentTarget}
                                    onUpdateNodeApp={onUpdateNodeApp}
                                    openCreateSceneModal={openCreateSceneModal}
                                    onUpdateCaseArea={onUpdateCaseArea}
                                    onRemoveCaseArea={onRemoveCaseArea}
                                    onUpdateCaseScene={onUpdateCaseScene}
                                    onRemoveCaseScene={onRemoveCaseScene}
                                    onViewTargets={onViewTargets}
                                    viewSource={viewSource}
                                    onSetViewSource={setViewSource}
                                    setBindCurrentAccepts={setBindCurrentAccepts}
                                    onRefresh={onRefresh}
                                />
                            </Tabs.TabPane>
                            {
                                caseOfflineSpeakers.length > 0 && (
                                    <Tabs.TabPane key="offline-speaker-config" tab={(
                                        <Badge size="small" count={caseOfflineSpeakers.length}>离线音箱配置</Badge>
                                    )}>
                                        <CaseOfflineSpeakersConfig
                                            caseScenes={caseScenes}
                                            caseNodes={caseNodes}
                                            offlineSpeakers={caseOfflineSpeakers}
                                        />
                                    </Tabs.TabPane>
                                )
                            }
                            <Tabs.TabPane tab="其他配置" key="extra-config">
                                <CaseExtraConfig
                                    case={props.case}
                                />
                            </Tabs.TabPane>
                        </Tabs>

                    </div>
                </div>
            </Spin>

        </>
    )
}
