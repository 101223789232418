import { Modal, Form, FormInstance, Input, message } from 'antd';
import React from 'react'
import { createRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import { apis } from '../../../../../../utils/apis';
import { request } from '../../../../../../utils/request';

interface Props {
    visible: boolean,
    node: any,
    onClose: () => void;
    onCaseNodaUpdate: (caseNode: any) => void
}


const formRef = createRef<FormInstance>();

export const CaseNodeRenameModal = (props: Props) => {

    const [temp, setTemp] = useState("");
    useEffect(() => {
        if(props.visible) {
            if(props.node) {
                setTemp(props.node.name);
            }
        }else {
            setTemp("");
        }
    }, [props.visible])

    const onUpdateNodeMac = (data: any) => {
        
        request.put(apis.caseNode + `/${props.node.caseNodeId}`, data)
            .then((res) => {
                if(res.code === 1) {
                    props.onCaseNodaUpdate(res.data);
                    message.success("已设置");
                }else {
                    message.warn(res.msg || res.message)
                }
            })
    }

    return (
        <Modal
            centered
            title="关联硬件"
            visible={props.visible}
            onOk={() => {
                formRef.current?.submit();
            }}
            onCancel={props.onClose}
            width={300}
            destroyOnClose
        >
            <Form 
                ref={formRef}
                initialValues={{
                    name: temp
                }}
                onFinish={onUpdateNodeMac}
            >
                <Form.Item label="名称" name="name" rules={[{required: true, message: "请输入名称"}]}>
                    <Input placeholder="请输入名称" autoFocus autoComplete="off"/>
                </Form.Item>
            </Form>
        </Modal>
    )

}