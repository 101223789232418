import './App.less';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import { ConfigProvider, Form, Input, Modal, message } from 'antd';
import { PageLogin } from './pages/Login'
import { HomePage as HelperHomePage } from './pages/#helper-winx86/Home';
import { DeleteOutlined, HomeOutlined, ReloadOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { MainPage } from './pages/Main';

import { ipcRenderer, store } from './utils/electron';
import { useEffect, useRef, useState } from 'react';
import OrderPrintView from './pages/#project-manager/OrderPrintView';
import SilkScreenPrintView from './pages/#project-manager/SilkScreenPrintView';
import OrderCaseSpuListPrintView from './pages/#project-manager/OrderCaseSpuListPrintView';

const isDev = (window as any).isDev;

export const App = () => {

  useEffect(() => {
    if ((window as any).inElectron) {
      ipcRenderer.send("close-splash");
    }
  }, []);

  const tempPasswordForm = useRef<any>();
  const [loading, setLoading] = useState(false);

  return (
    <ConfigProvider locale={zhCN}>
      <HashRouter>
        <Switch>
          <Route exact path="/login" render={(props) => {
            return (
              <PageLogin {...props} />
            )
          }} />
          {/* 打印 */}
          <Route exact path="/print/customer-order/:customerId" render={(props) => {
            return (
              <OrderPrintView {...props} />
            )
          }} />
          <Route exact path="/print/customer-silk-screen/:customerId" render={(props) => {
            return (
              <SilkScreenPrintView {...props} />
            )
          }} />
          <Route exact path="/print/customer-order-case-spu-list/:customerId" render={(props) => {
            return (
              <OrderCaseSpuListPrintView {...props} />
            )
          }} />
          <Route path="/" render={(props) => {
            // if (!localStorage.getItem("SK_LOGIN_INFO")) {
            //   localStorage.setItem("SK_LOGIN_REDIRECT", props.location.pathname);
            //   return (
            //     <Redirect to="/login" />
            //   )
            // }
            // let expireAt: any = localStorage.getItem("SK_TOKEN_EXPIRE_AT");
            // expireAt = Number(expireAt);
            // if (!expireAt || expireAt < Date.now()) {
            //   localStorage.setItem("SK_LOGIN_REDIRECT", props.location.pathname);
            //   return (
            //     <Redirect to="/login" />
            //   )
            // }
            if (props.location.pathname?.toLowerCase()?.indexOf("/helper") === 0) {
              return (
                <HelperHomePage {...props} />
              )
            }
            if(!localStorage.getItem("SK_TEMP_TOKEN") || localStorage.getItem("SK_TEMP_TOKEN") !== '123qwe!@#QWE') {
              return (
                <div>

              <Modal 
                visible
                  title="请输入密码"
                  centered
                  onOk={() => {
                    tempPasswordForm.current?.submit();
                  }}
                  okButtonProps={{
                    loading: loading,
                    disabled: loading
                  }}
                >
                  <Form ref={tempPasswordForm} onFinish={(data: any) => {
                    setLoading(true);
                    setTimeout(() => {
                      if(data.password !== '123qwe!@#QWE') {
                        message.warn("密码错误");
                      }else {
                        localStorage.setItem("SK_TEMP_TOKEN", data.password);
                        window.location.reload();
                      }
                      setLoading(false);
                    }, 800);
                  }}>
                    <Form.Item name="password" rules={[{required: true, message: "请输入密码"}]}>
                      <Input autoFocus autoComplete='off' placeholder='请输入密码'/>
                    </Form.Item>
                  </Form>
                </Modal>
                </div>
              )
            }
            return (
              <MainPage {...props} />
            )
          }} />
        </Switch>
      </HashRouter>
      {
        (window as any).inElectron && (

          <div className="dev-tools">

          <div className="tool redirect-home" onClick={() => {
            window.location.href = window.location.href.replace(window.location.hash, "#/helper");
          }}>
            <HomeOutlined />
          </div>
          <div className="tool reload" onClick={() => {
            window.location.reload();
          }}>
            <ReloadOutlined />
          </div>
          {
            isDev && (
              <>
              <div className="tool reload" onClick={() => {
                store.clear();
              }}>
                <DeleteOutlined />
              </div>
              <div className="tool reload" onClick={() => {
              (window as any).store = store;
              }}>
                <UnorderedListOutlined />
              </div>
              
              </>
            )
          }
        </div>
        )
      }
    </ConfigProvider>
  )
}