import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, message, Select, Table } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ModalForm from "../../../../components/ModalForm";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";
import AddEndpointModal from "./AddEndpointModal";
import HexConfigEndpointEdit from "./HexConfigEndpointEdit";

interface AddHexConfigProps {
    version: any;
    onHexConfigAdd: (hexConfig: any) => Promise<any>
}

const AddHexConfig = forwardRef((props: AddHexConfigProps, ref: any) => {

    useEffect(() => {
        getConfigData()
    }, []);

    const [bindMethods, setBindMethods] = useState<any[]>([]);
    const [groupMethods, setGroupMethods] = useState<any[]>([]);
    const [sceneMethods, setSceneMethods] = useState<any[]>([]);
    const [availableSupports, setAvailableSupports] = useState<any[]>([]);
    const getConfigData = async () => {
        const [res1, res2, res3, res4] = await Promise.all([
            request.get(apis.case + `/node/bind/methods`),
            request.get(apis.case + `/node/group/methods`),
            request.get(apis.case + `/node/scene/methods`),
            request.get(apis.case + `/node/supports`)
        ]);
        setBindMethods(res1.data);
        setGroupMethods(res2.data);
        setSceneMethods(res3.data);
        setAvailableSupports(res4.data.map((item) => ({ label: item.name, value: item.key })));
    }

    const [endpoints, setEndpoints] = useState<any[]>([]);
    const addEndpointRef = useRef<any>();
    const editEndpointRef = useRef<any>();

    const onSubmit = async (data: any) => {
        data.endpoints = endpoints.map((endpoint) => {
            return {
                bindType: endpoint.bindType,
                appType: endpoint.appType,
                endpoint: Number(endpoint.endpoint),
                requireControlLine: endpoint.requireControlLine,
                opened: Number(endpoint.opened)
            }
        });
        const bindMethod = bindMethods.find((bm) => bm.key === data.bindMethod);
        data.bindMethod = bindMethod;
        const sceneMethod = sceneMethods.find((bm) => bm.key === data.sceneMethod);
        data.sceneMethod = sceneMethod;
        const groupMethod = groupMethods.find((bm) => bm.key === data.groupMethod);
        data.groupMethod = groupMethod;
        const supports = (data.supports || []).map((key: string) => {
            return availableSupports.find((as) => as.value === key)
        });
        data.supports = supports;
        props.onHexConfigAdd(data)
    }

    const ref2 = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            open: async () => {
                try {
                    const res = await request.get(apis.hex + `/config/latest`, {
                        groupId: props.version.hexGroupId
                    });
                    if(res.code === 1) {
                        if(res.data) {
                            const initialData = {
                                bindMethod: res.data.bindMethod.key,
                                groupMethod: res.data.groupMethod.key,
                                sceneMethod: res.data.sceneMethod.key,
                                supports: res.data.supports.map((s) => s.key)
                            }
                            setEndpoints(res.data.endpoints)
                            ref2.current?.open(initialData);
                            return ;
                        }
                    }
                    ref2.current?.open();
                }catch(e) {
                    ref2.current?.open();
                }
            }
        }
    })

    return (
        <>
            <HexConfigEndpointEdit
                ref={editEndpointRef}
                onEndpointChange={async (endpoint) => {
                    /* 以endpointId作为index */
                    if (Number(endpoints[endpoint.index].endpoint) !== Number(endpoint.endpoint)) {
                        const exists = endpoints.find((e) => Number(e.endpoint) === Number(endpoint.endpoint));
                        if (exists) {
                            message.info("已存在相同的endpoint");
                            return false;
                        }
                    }
                    endpoints[endpoint.index] = endpoint;
                    endpoints.sort((a, b) => Number(a.endpoint) > Number(b.endpoint) ? 1 : -1)
                    setEndpoints([...endpoints]);
                    return true;
                }}
            />
            <ModalForm
                ref={ref2}
                title="编辑配置信息"
                onSubmit={onSubmit}
                centered
                labelCol={{ span: 5 }}
                width={700}
                maskClosable={false}

            >
                <Form.Item label="Endpoint设置">
                    <Table
                        size="small"
                        pagination={false}
                        dataSource={endpoints}
                        style={{ minHeight: 100 }}
                        columns={[{
                            title: "E",
                            key: "endpoint",
                            width: 30,
                            dataIndex: "endpoint",
                            align: 'center'
                        }, {
                            title: "应用",
                            key: "appType",
                            width: 100,
                            dataIndex: "appType",
                            render: (appType: any) => {
                                return appType.name;
                            }
                        }, {
                            title: "绑定",
                            key: "bindType",
                            dataIndex: "bindType",
                            render: (bindType: any) => {
                                return bindType.name;
                            }
                        }, {
                            title: "控制线",
                            key: "requireControlLine",
                            dataIndex: "requireControlLine",
                            render: (requireControlLine: 0 | 1) => {
                                return requireControlLine ? "控制线" : ""
                            }
                        }, {
                            align: 'center',
                            title: "通信",
                            dataIndex: "opened",
                            key: "opened",
                            width: 50,
                            render: (opened: any) => {
                                return Boolean(opened) && (
                                    <span style={{ color: "green" }}>可通信</span>
                                )
                            }
                        }, {
                            title: "",
                            align: 'center',
                            key: 'action',
                            width: 140,
                            render: (_, endpoint: any, index) => {
                                return (
                                    <>
                                        <Button type="link" onClick={() => {
                                            editEndpointRef.current.open({
                                                ...endpoint,
                                                index: index,
                                                appType: endpoint.appType.key,
                                                bindType: endpoint.bindType.key
                                            });
                                        }}>
                                            <EditOutlined />
                                            编辑
                                        </Button>
                                        <Button type="link" danger onClick={() => {
                                            const _endpoints = endpoints.filter((_, idx) => index !== idx);
                                            // onEndpointsChange(_endpoints);
                                            setEndpoints(_endpoints);
                                        }}>
                                            <DeleteOutlined />
                                            删除
                                        </Button>
                                    </>
                                )
                            }
                        }]}
                        footer={() => (
                            <div style={{ textAlign: 'right' }}>
                                <Button type="link" onClick={() => {
                                    addEndpointRef.current?.open();
                                }}>
                                    <PlusOutlined />
                                    添加endpoint
                                </Button>
                                <AddEndpointModal
                                    ref={addEndpointRef}
                                    onEndpointAdd={async (endpoint: any) => {
                                        const exists = endpoints.find((e) => Number(e.endpoint) === Number(endpoint.endpoint));
                                        if (exists) {
                                            message.info("已存在相同的endpoint");
                                            return false;
                                        }
                                        endpoints.push(endpoint);
                                        endpoints.sort((a, b) => Number(a.endpoint) > Number(b.endpoint) ? 1 : -1)
                                        setEndpoints([...endpoints]);
                                        return true;
                                    }}
                                />
                            </div>
                        )}
                    />
                </Form.Item>
                <Form.Item name="bindMethod" label="配置方法-绑定" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                    <Select
                        style={{ width: 300 }}
                    >
                        {
                            bindMethods.map((bm) => {
                                return (
                                    <Select.Option value={bm.key}>{bm.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="groupMethod" label="配置方法-建组" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                    <Select
                        style={{ width: 300 }}
                    >
                        {
                            groupMethods.map((bm) => {
                                return (
                                    <Select.Option value={bm.key}>{bm.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="sceneMethod" label="配置方法-建场景" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                    <Select
                        style={{ width: 300 }}
                    >
                        {
                            sceneMethods.map((sm) => {
                                return (
                                    <Select.Option value={sm.key}>{sm.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="supports" label="支持功能">
                    <Checkbox.Group
                        options={availableSupports}
                    />
                </Form.Item>
            </ModalForm>
        </>
    )

});

export default AddHexConfig;