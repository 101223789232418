import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin } from 'antd';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import { sleep } from '../../utils';

interface Props {
    logo: any;
    imageUrl: string;
    scanLogin?: {
        code: string;
        tip: string;
    };
    accountLogin?: {
        onLogin: (account: string, password: string) => Promise<boolean>;
    };
    onLoginSuccess?: () => void;
}

export default function CommonLoginPage2(props: Props) {

    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState<"login" | "success">("login");

    return (

        <div className="flex column" style={{ height: '100vh', width: '100vw' }}>
            <div
                className="header flex"
                style={{ height: 50, padding: '0 10px', alignItems: 'center' }}
            >
                <div className="flex" style={{ alignItems: 'center' }}>
                    <div
                        style={{ padding: '0 10px', fontSize: 18 }}
                        className=""
                    >
                        {props.logo}
                    </div>
                    <div style={{ width: 1, background: '#ddd', height: 20 }}></div>
                    <div style={{ padding: '0 10px', fontSize: 20 }}>登录</div>
                </div>
            </div>
            <div
                className="flex center"
                style={{
                    flex: 1,
                    backgroundImage: `url(${props.imageUrl})`,
                    backgroundSize: '100% 100%',
                }}
            >
                <div
                    className="flex relative"
                    style={{ background: '#fff', padding: '50px 20px' }}
                >
                    {
                        submitting && (
                            <div className='flex center column' style={{ position: 'absolute', left: 0, top:  0, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, .5)', zIndex: 8 }}>
                                <LoadingOutlined spin style={{ fontSize: 48 }} className="color-primary" />
                                {
                                    <div className='color-primary' style={{ fontSize: 16, marginTop: 10, height: 24 }}>{status === 'login' && '正在登录...'}</div>    
                                }
                            </div>
                        )
                    }
                    {
                        status === 'success' && (
                            <div className='flex center column' style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, .2)', zIndex: 9 }}>
                                <CheckCircleFilled className="text-success" style={{fontSize: 48, opacity: 0.9}} />
                                <div className='text-success' style={{ fontSize: 16, marginTop: 10, height: 24, position: 'relative', top: 0 }}>登陆成功! 正在跳转...</div>
                            </div>
                        )
                    }
                    {props.scanLogin && (
                        <div style={{ padding: '0 60px', borderRight: '1px solid lightgray' }} className="flex column center">
                            <div
                                style={{ fontWeight: 'bold', marginBottom: 20, fontSize: 18 }}
                            >
                                扫描二维码
                            </div>
                            <QRCode value={props.scanLogin?.code} size={180} />
                            <div style={{ textAlign: 'center', width: 180, marginTop: 24, fontSize: 16, color: "gray" }}>
                                {props.scanLogin.tip}
                            </div>
                        </div>
                    )}
                    {props.accountLogin && (
                        <div style={{ padding: '0 40px', width: 400 }}>
                            <div
                                style={{ fontWeight: 'bold', marginBottom: 20, fontSize: 18 }}
                            >
                                账号登录
                            </div>
                            <Form
                                labelCol={{ span: 3 }}
                                onFinish={async (data) => {
                                    if(!props.accountLogin) {
                                        return ;
                                    }
                                    setSubmitting(true);
                                    let startTime = Date.now();
                                    try {
                                        const res = await props.accountLogin.onLogin(data.phoneNumber, data.password);
                                        if(res) {
                                            const deltaTime = Date.now() - startTime;
                                            let sleepTime = deltaTime < 500 ? 500 - deltaTime : 0;
                                            if(sleepTime > 0) {
                                                await sleep(sleepTime);
                                            }
                                            setTimeout(props.onLoginSuccess, 800);
                                            setStatus("success");
                                        }
                                        setSubmitting(false);
                                    }catch(e) {
                                        setSubmitting(false);
                                    }
                                }}
                                style={{ height: 'calc(100% - 54px)', minHeight: 200 }}
                                size="large"
                            >
                                <div className='flex column' style={{ height: '100%', justifyContent: 'space-between' }}>
                                    <div>
                                        <div style={{height: 60}}>
                                            <Form.Item
                                                name={'phoneNumber'}
                                                label="账号"
                                                rules={[{ required: true, message: '请输入账号（手机号）' }]}
                                            >
                                                <Input
                                                    placeholder="请输入账号（手机号）"
                                                    autoComplete="off"
                                                />
                                            </Form.Item>

                                        </div>
                                        <Form.Item
                                            name={'password'}
                                            label="密码"
                                            rules={[{ required: true, message: '请输入密码' }]}
                                        >
                                            <Input placeholder="请输入密码" type={'password'} />
                                        </Form.Item>

                                    </div>
                                    <div >
                                        <Form.Item >
                                            <Button
                                                style={{ width: '100%' }}
                                                htmlType="submit"
                                                type="primary"
                                                disabled={submitting}
                                                loading={submitting}
                                            >
                                                登录
                                            </Button>
                                        </Form.Item>
                                        <div className='relative' style={{ top: 0, textAlign: 'center', color: 'gray', fontSize: 14, marginTop: 10 }}>本系统仅内部人员可登录，忘记密码请联系IT</div>

                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
