import { useEffect, useState } from "react";
import { apis } from "../../../../../utils/apis";
import { request } from "../../../../../utils/request";

import { Sider } from '../../../../../components/Sider';
import { Menu, Skeleton } from "antd";
import { RightOutlined } from "@ant-design/icons";

interface Props {
    customerId: number,
    onCustomerChange: (customer: any) => void
}
/**
 * 客户列表V2组件， 仅做客户及客户分类的展示
 * @param props 
 */
export default function CustomerList(props: Readonly<Props>) {

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([] as any[]);
    const [customerTypes, setCustomerTypes] = useState([] as string[]);
    const [selectedCustomerType, setSelectedCustomerType] = useState("");
    const [displayCustomers, setDisplayCustomers] = useState([] as any[]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(props.customerId || 0);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setLoading(true);
            const res = await request.get(apis.customer);
            if (res.code === 1) {
                setCustomers(res.data);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const customerTypes = Array.from(new Set(customers.map((c) => c.type)));
        setCustomerTypes(customerTypes);
    }, [customers]);

    useEffect(() => {
        if (!selectedCustomerType && customerTypes.length > 0) {
            setSelectedCustomerType(customerTypes[0]);
        }
    }, [customerTypes]);

    useEffect(() => {
        if (selectedCustomerType && customers.length > 0) {
            const displayCustomers = customers.filter((c) => c.type === selectedCustomerType);
            setDisplayCustomers(displayCustomers);
        }
    }, [customers, selectedCustomerType]);

    useEffect(() => {
        if (displayCustomers.length > 0 && selectedCustomerId === 0) {
            setSelectedCustomerId(displayCustomers[0].id);
        }
    }, [displayCustomers]);


    useEffect(() => {
        if (selectedCustomerId > 0 && customers.length > 0) {
            const customer = customers.find((c) => c.id === selectedCustomerId);
            if(customer.type !== selectedCustomerType) {
                setSelectedCustomerType(customer.type);
            }
            props.onCustomerChange(customer);
        }
    }, [selectedCustomerId, customers]);



    const [caseCreateModalVisible, setCaseCreateModalVisible] = useState(false);
    const openCaseCreateModal = () => {
        setCaseCreateModalVisible(true);
    }
    const closeCaseCreateModal = () => {
        setCaseCreateModalVisible(false);
    }


    
    useEffect(() => {//外部url的caseId参数变化后, 被选择的caseId也应该随之变化
        if(props.customerId > 0) {
            setSelectedCustomerId(props.customerId);
        }
    }, [props.customerId]);
    return (
        <Sider
        >
            <div className='flex flex1 bg-white' style={{ borderRight: '1px solid rgba(0, 0, 0, .1)' }}>
                {
                    loading ? (
                        <Skeleton active style={{margin: '0 10px'}}/>
                    ) : (
                        <>

                            <div style={{ width: 'calc(1em + 10px)', borderRight: '1px solid rgba(0, 0, 0, .1)' }}>
                                {
                                    customerTypes.length > 0 && customerTypes.map((ct) => {
                                        return (
                                            <div
                                                className={"customer-type " + (ct === selectedCustomerType ? 'bg-primary' : '')}
                                                style={{ padding: '12px 5px' }}
                                                onClick={() => {
                                                    setSelectedCustomerType(ct);
                                                }}
                                            >{ct}</div>
                                        )
                                    })
                                }
                            </div>
                            <Menu className="no-scroll-bar" style={{ flex: 1, height: 'calc(100vh - 40px)', overflowY: 'scroll' }} selectedKeys={["customer-" + selectedCustomerId.toString()]}>
                                {
                                    displayCustomers.length > 0 && displayCustomers.map((dc) => {
                                        return (
                                            <Menu.Item key={"customer-" + dc.id.toString()} onClick={() => {
                                                setSelectedCustomerId(dc.id);
                                            }} style={{ paddingRight: 8 }}>
                                                <div style={{ width: 120, textOverflow: 'ellipsis' }} title={dc.name}>{dc.name}</div>
                                                {/* <RightOutlined style={{float: 'right', lineHeight: "32px"}}/> */}
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>

                        </>
                    )
                }
            </div>
        </Sider>
    )

}