import { ClockCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd";
import { useEffect, useState } from "react"
import { apis } from "../../../../../../utils/apis";
import { request } from "../../../../../../utils/request";

interface MacCheckProps {
    caseId?: number;
    onOk: () => void;

}

export const MacCheck = (props: MacCheckProps) => {

    useEffect(() => {
        if(!props.caseId) {
            return ;
        }
        getData(props.caseId);
    }, [props.caseId]);

    const getData = async (caseId: number) => {
        const [res1, res2] = await Promise.all([
            request.get(apis.caseNet + `/${caseId}`, { caseId }),
            request.get(apis.caseNode, { caseId })
        ]);
        let notOk = false;
        if(!res1.data.mac) {
            notOk = true;
        }
        for (let i = 0; i < res2.data.caseNodes.length; i++) {
            const node = res2.data.caseNodes[i];
            if(!node.mac) {
                notOk = true;
                break;
            }
        }
        if(notOk) {
            Modal.confirm({
                title: "有产品未烧录完成",
                centered: true,
                // content: ,
                okText: "重新尝试",
                onOk: () => {
                    getData(caseId);
                },
                cancelText: "取消",
            });
        }else {
            props.onOk();
        }
    }

    return (
        <div className="flex center column" style={{ height: 'calc(100vh - 300px)' }}>
            <ClockCircleOutlined spin className="" style={{ fontSize: 128, color: "lightblue" }} />
            <span className="bold" style={{ fontSize: 32, marginTop: 20, color: "lightblue" }}>正在<span style={{ color: "purple" }}>检查MAC地址</span></span>
        </div>
    )

}