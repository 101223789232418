import { Checkbox, Form, Input, Select } from "antd";
import { forwardRef, useEffect, useState } from "react"
import ModalForm from "../../../../components/ModalForm";
import { apis } from "../../../../utils/apis";
import { request } from "../../../../utils/request";

interface HexConfigEndpointEditProps {
    onEndpointChange: (endpoint: any) => Promise<any>
}

const HexConfigEndpointEdit = forwardRef((props: HexConfigEndpointEditProps, ref: any) => {
    
  
    const [appTypes, setAppTypes] = useState<any[]>([]);
    const [bindTypes, setBindTypes] = useState<any[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const [res1, res2] = await Promise.all([
            request.get(apis.case + `/app/types`),
            request.get(apis.case + `/app/bind/types`)
        ]);
        setAppTypes(res1.data);
        setBindTypes(res2.data);
    }

    const onSubmit = async (data: any) => {
        const bindType = bindTypes.find((bt) => bt.key === data.bindType);
        data.bindType = bindType;
        const appType = appTypes.find((at) => at.key === data.appType);
        data.appType = appType;
        data.requireControlLine = Number(Boolean(data.requireControlLine));
        return await props.onEndpointChange(data);
        
    }

    return (
        <ModalForm
            ref={ref}
            title="编辑Endpoint"
            onSubmit={onSubmit}
            centered
            labelCol={{span: 5}}
        >   
            <Form.Item hidden name="index" />
            <Form.Item hidden name="endpointId" />
            <Form.Item hidden name="configId" />
            <Form.Item name="endpoint" label="端口号" style={{ marginRight: 20 }} rules={[{ required: true, message: "请输入此项" }]}>
                <Input type={"number"} style={{ width: 56 }} min={0} max={255} autoFocus autoComplete="off" />
            </Form.Item>
            <Form.Item name="appType" label="应用类型" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                <Select style={{ width: 200 }}>
                    {
                        appTypes.map((appType) => {
                            return (
                                <Select.Option key={appType.key} value={appType.key}>{appType.name}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="bindType" extra="决定endpoint可以绑哪些或是否支持被绑定" label="绑定设置" style={{ marginRight: 20 }} rules={[{ required: true, message: "请选择此项" }]}>
                <Select style={{ width: 200 }}>
                    {
                        bindTypes.map((bindType) => {
                            return (
                                <Select.Option key={bindType.key} value={bindType.key}>{bindType.name}</Select.Option>
                            )
                        })  
                    }
                </Select>
            </Form.Item>
            <Form.Item name="requireControlLine" valuePropName="checked" label="控制线">
                <Checkbox>需接控制线才可被绑定</Checkbox>
            </Form.Item>
            <Form.Item name="opened" valuePropName="checked" label="是否可通信" style={{ marginRight: 20 }} /* rules={[{ required: true, message: "请选择此项" }]} */>
                <Checkbox> 可通信（服务器可通过网关与该端口号通信）</Checkbox>
            </Form.Item>

        </ModalForm>
    )

});

export default HexConfigEndpointEdit;