import { PlusOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table, Tag } from "antd";
import { createRef, useEffect, useMemo, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import DoubleClickToCopy from "../../../components/DoubleClickToCopy";
import { apis } from "../../../utils/apis";
import { request } from "../../../utils/request";
import { CreateTenantModal } from "./CreateTenantModal";

interface Props extends RouteChildrenProps {

}
export default function TenantMgmt(props: Props) {

    const [tenants, setTenants] = useState<any[]>([]);

    const [columns ] = useMemo(() => {
        const columns = [
            {
                key: "name",
                title: "租户",
                dataIndex: "name",
                width: 320,
                render: (_, tenant: any) => {
                    return (
                        <div>
                            <div>
                                {
                                    tenant.channel && (
                                        <Tag color="cyan" title={tenant.channelKey}>{tenant.channel.name}</Tag>
                                    )
                                }
                                <span className="bold" style={{fontSize: 16}}>{tenant.name}</span>
                            </div>
                            <div>
                                <DoubleClickToCopy>{tenant.uuid}</DoubleClickToCopy>
                            </div>
                        </div>
                    )
                }
            },
            // {
            //     key: "entity",
            //     title: "实体信息",
            //     render: () => {
            //         return (

            //         )
            //     }
            // },
            // {
            //     key: "user",
            //     title: "管理员",
            //     render: () => {
            //         return (
            //             <div></div>
            //         )
            //     }
            // },
            {
                key: "roles",
                title: "关联角色",
                render: () => {
                    return (
                        <div>
                            <Button type="link">
                                <PlusOutlined />
                                添加角色
                            </Button>
                        </div>
                    )
                }
            }
        ]
        return [
            columns
        ]
    }, []);

    useEffect(() => {
        request.get(apis['/upms/tenant/list'])
            .then((res) => {
                if(res.code === 1) {
                    setTenants(res.data)
                }
            })
    }, []);

    const refresh = () => {
        request.get(apis['/upms/tenant/list'])
            .then((res) => {
                if(res.code === 1) {
                    setTenants(res.data)
                }
            })

    }

    const createTenantRef = createRef<any>();
    return (

        <div>
            <CreateTenantModal 
                ref={createTenantRef}
                onTenantCreate={(tenant) => {
                    tenant.isNew = true;
                    tenants.unshift(tenant);
                    setTenants([...tenants]);
                    refresh();
                }}
            />
            <PageHeader
                title="租户管理"
                extra={(
                    <Button type="primary" ghost onClick={() => {
                        createTenantRef.current?.open();
                    }}>
                        <PlusOutlined />
                        添加租户
                    </Button>
                )}
            >
                <Table 
                    dataSource={tenants}
                    columns={columns}
                    pagination={false}
                
                />                
            </PageHeader>
        </div>
    )

}