import { AppstoreOutlined, EllipsisOutlined, HomeOutlined, LogoutOutlined, SettingOutlined, ToolFilled, ToolOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, Popover, Tag } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useEffect, useState } from 'react'
import { RouteChildrenProps } from 'react-router-dom';
import pkg from '../../../../../package.json';

const { Header } = Layout;

interface Props extends RouteChildrenProps {

}


export default function MainHeader(props: Props) {


    const [loginInfo, setLoginInfo] = useState({} as any);

    useEffect(() => {
        const _loginInfo = localStorage.getItem('SK_LOGIN_INFO');
        if (_loginInfo) {
            setLoginInfo(JSON.parse(_loginInfo));
        }
    }, []);

    const [urlModuleName, setUrlModuleName] = useState("");
    useEffect(() => {
        const splits = props.location.pathname.split("/");
        // console.log(props.location.pathname, splits);
        
        if(splits.length > 1) {
            setUrlModuleName("/" + splits[1]);
        }else {
            setUrlModuleName("/");
        }
        
    }, [props.location.pathname]);

    useEffect(() => {
        // console.log(urlModuleName);
        
    }, [urlModuleName]);

    const onLogOut = () => {
        localStorage.removeItem("SK_LOGIN_INFO");
        props.history.replace("/login");
    }

    return (
        <Header className="app-header">
            <div className="flex">
                <div className="app-header-prefix">
                    <div className="logo">
                        <img src="/favicon.ico" height="18" width="18" />
                        <span className="zh-CN bold">华飞物联后台</span>
                        <span className='version '>
                            <span className='label'>{(window as any).isDev || (process.env.NODE_ENV === 'development') ? '测' : '正'}</span>
                            <span className={`value ${(window as any).isDev || (process.env.NODE_ENV === 'development') ? 'dev' : 'prod'}`}>{pkg.version}</span>
                        </span>
                    </div>
                </div>
                <div className='nav flex flex1'>
                    <div className='home-guide' onClick={() => {
                        props.history.replace("/");
                    }}>
                        <div className='button'>
                            <HomeOutlined />
                        </div>
                    </div>
                    <Dropdown trigger={["click"]} overlay={(
                        <Menu>
                            <Menu.Item icon={(<ToolOutlined />)} onClick={() => {
                                props.history.replace("/helper");
                            }}>生产助手</Menu.Item>
                        </Menu>
                    )}>
                        <div className='entries'>
                            <div className='button'>
                                <AppstoreOutlined />
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
            <div className='menu flex1 flex' style={{ justifyContent: 'center' }}>
                <Menu mode="horizontal" selectedKeys={[urlModuleName]} style={{ height: 40 }}>
                    <Menu.Item key="/project" onClick={() => {
                        // if(props.)
                            props.history.replace("/project")
                    }}>
                        项目管理
                    </Menu.Item>
                    <Menu.Item key={"/customer"} onClick={() => {
                        console.log(props);
                        if(props.location.pathname?.indexOf("/customer") > -1) {
                            // window.location.reload();
                        }else {
                            props.history.replace("/customer")
                        }
                    }}>
                        方案管理
                    </Menu.Item>
                    <Menu.Item key={"/product"} onClick={() => {
                        props.history.push("/product/case-product");
                    }}>
                        产品管理
                    </Menu.Item>
                    <SubMenu key={"more"} title={"更多"} icon={(<EllipsisOutlined />)}>
                        <Menu.Item icon={(<UserOutlined />)}>用户管理</Menu.Item>
                        <Menu.Item icon={(<SettingOutlined />)}>系统设置</Menu.Item>
                    </SubMenu>
                </Menu>
            </div>
            <div className="app-header-suffix">
                <Dropdown trigger={["click"]} overlay={(
                    <Menu>
                        <Menu.Item icon={(<LogoutOutlined />)} style={{ color: 'orangered' }} onClick={onLogOut}>退出登录</Menu.Item>
                    </Menu>
                )}>
                    <div className="profile flex" style={{ alignItems: 'center' }}>
                        <div className="img-wrapper">
                            <img src={loginInfo.info?.avatar} alt={loginInfo.info?.nickname} />
                        </div>
                        <span>{loginInfo.info?.nickname}</span>
                    </div>
                </Dropdown>
            </div>
        </Header>
    )
}