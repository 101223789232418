import { AppstoreOutlined, EditOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PauseOutlined, PlayCircleOutlined, ReloadOutlined, SolutionOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Checkbox, Descriptions, Divider, message, PageHeader, Radio, Spin, Steps, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { CaseEditor } from './components/CaseEditor';
import { request } from '../../../utils/request';
import { apis } from '../../../utils/apis';

import "./style.less";
import dayjs from 'dayjs';

const { Step } = Steps;

interface Props extends RouteChildrenProps<{ caseId: string }> {
    caseId: number,
    setCollapsed: (collapsed: boolean) => void;
    collapsed: boolean;
}

export const CaseManager = (props: Props) => {
    const [_case, setCase] = useState({} as any);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onRefresh();
    }, [props.caseId]);

    const onRefresh = () => {
        if (!props.caseId) {
            return;
        }
        if (!loading) {
            setLoading(true);
        }
        request.get(apis.case + `/${props.caseId}`)
            .then((res) => {
                if (res.code === 1) {
                    setCase(res.data);
                } else {
                    message.warn(res.msg || res.message);
                }
            }).finally(() => {
                setLoading(false);
            })
    }

    const onSetCaseNet = (caseNet: any) => {
        _case.caseNet = { caseNetId: caseNet.id, caseNetMac: caseNet.mac };
        setCase({ ..._case });
    }

    const onCaseNetUpdate = (caseNet: any) => {
        _case.caseNet = { caseNetId: caseNet.id, caseNetMac: caseNet.mac, inTheQueue: caseNet.inTheQueue, infoChannel: caseNet.infoChannel };
        setCase({ ..._case });
    }

    const [mode, setMode] = useState<"edit" | "view" | "config">("view");
    const [viewType, setViewType] = useState<"cell" | "table">("cell")

    useEffect(() => {
        if (mode === "edit" || mode === "config") {
            props.setCollapsed(true);
        }else {
            props.setCollapsed(false);
        }
    }, [mode])

    return (
        <Spin spinning={loading} style={{height: '100%', position: 'relative'}}>
            <div className={"case-manager mode-" + mode}>
                <div className="case-customer">
                    <PageHeader
                        title={(
                            <>
                            {
                                _case.caseTemplateId ? (
                                    <Tag color="orange">房型</Tag>
                                ) : _case.caseParentTemplateId ? (
                                    <Tag color="yellow">房间</Tag>
                                ) : (
                                    <Tag color="blue">方案</Tag>
                                )
                            }
                            <span style={{fontSize: 24}}>{_case.caseName}</span>
                            </>
                        )}
                        
                        // extra={_case.templateCaseId ? [
                            
                        //     <Steps current={1} size="small" style={{width: 640, position: 'relative', top: -3}}>
                        //         <Step title="已创建" description={dayjs(_case.gmtCreate).format("YY-MM-DD HH:mm")}/>
                        //         <Step title={_case.submit ? '已提交' : '编辑中, 待提交'} />
                        //         <Step title="备货"/>
                        //         <Step title="配置" />
                        //         <Step title="运行中" />
                        //     </Steps>
                        // ] :  undefined}
                    >
                        {/* <Descriptions size="small" column={3}>
                            <Descriptions.Item label="客户名称" style={{fontWeight: 'bold'}}><a>{_case.customerName}</a></Descriptions.Item>
                            <Descriptions.Item label="联系人">
                                <a>{_case.liaisonName}</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="联系方式">{_case.liaisonPhone}</Descriptions.Item>
                            <Descriptions.Item label="客户类型"><Tag>{_case.customerType}</Tag></Descriptions.Item>
                            <Descriptions.Item label="地址">{_case.address}</Descriptions.Item>
                            <Descriptions.Item label="方案负责人"> </Descriptions.Item>
                        </Descriptions> */}
                    </PageHeader>
                </div>
                <div className="case-detail">
                    <CaseEditor
                        viewType={viewType}
                        setViewType={setViewType}
                        case={_case}
                        mode={mode}
                        setMode={setMode}
                        onSetCaseNet={onSetCaseNet}
                        onCaseNetUpdate={onCaseNetUpdate}
                        onRefresh={onRefresh}
                    />
                </div>
                {/* <div className="footer">
                    <div className="flex">
                    <view style={{marginRight: 10}}>i
                        <Button type="link" style={{color: 'gray'}} onClick={() => {
                            props.setCollapsed(!props.collapsed);
                        }}>
                            {
                                props.collapsed ? (
                                    <MenuFoldOutlined />
                                ) : (
                                    <MenuUnfoldOutlined />
                                )
                            }
                        </Button>
                    </view>
                    <div className="view-type">
                        <Radio.Group value={viewType} onChange={(e) => {
                            setViewType(e.target.value);
                        }}>
                            <Radio.Button value={"cell"}>
                                <AppstoreOutlined title="卡片排列" />
                            </Radio.Button>
                            <Radio.Button value={"table"}>
                                <TableOutlined title="表格排列" />
                            </Radio.Button>
                        </Radio.Group>
                    </div> 

                    </div>
                    <div>
                    {
                        mode === "edit" ? (
                            <span>正在编辑：{_case.caseName}</span>
                        ) : mode === "config" ? (
                            <span>配置模式：{_case.caseName}</span>
                        ) : (
                            <span></span>
                        )
                    }
                    </div>
                </div> */}
                {/* <div>
                    <div className='label'>配置选项</div>
                    <div ></div>
                    <div>
                        <Checkbox checked={options.indicatorSync}/>
                        多控情况下，指示灯开关同步
                    </div>
                </div>
                <div>
                    <div className=''>组一</div>
                    <div>所有场景都建在组一下</div>
                </div>
                <div className=''>随机租ID</div>
                <div></div> */}
                {/* <div ></div> */}
            </div>
        </Spin>
    )
}


/**
 * 处于移动端
 */
interface SmartCaseGeneratorProps {
    
}

export const SmartCaseGenerator = (props: SmartCaseGeneratorProps) => {

    return (
        <div className=''>
            {/* 智能生成方案 */}
            
        </div>
    )

}

/**
 * 方案订单生成
 */
interface CaseOrderGeneratorProps {

}

export const CaseOrderGenerator = (props:  CaseOrderGeneratorProps) => {

    return (
        <div></div>
    )

}


/**
 * 抽象概念的方案产品
 */
// interface CaseOrde