import { Button, message, Result, Skeleton } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import BurnTool from "../../../../../#burn-tool";
import { TemplateComponent } from "../../../../../#case-manager/components/CaseNodeCell/template";
import CaseList from "../../../../../#case-manager/Home/components/CaseList";
import { apis } from "../../../../../../utils/apis";
import { getNodeImageUrlByTypeKey } from "../../../../../../utils/constants";
import { request } from "../../../../../../utils/request";
import { CaseNodesRow } from "./CaseNodesRow";

interface Props extends RouteChildrenProps {
    customer?: any;
    caseId: number;
    onCaseChange: (c: any) => void;
    fullScreen?: boolean;
    onGoConfig?: () => void;
}


const CaseNodeHexWrite = (props: Props) => {

    const [writeTarget, setWriteTarget] = useState(null as any);
    const [_case, setCase] = useState(null as any);

    const rowRef = useRef<any>();
    const burnRef = useRef<any>();

    useEffect(() => {
        getWriteTargetHexFile(writeTarget, _case);
    }, [writeTarget, _case]);

    const getWriteTargetHexFile = async (writeTarget: any, _case: any) => {
        if (!_case || !writeTarget) {
            return;
        }
        let hexFile: any;
        if (writeTarget.type === "net") {
            hexFile = await getHexFile(writeTarget.data.infoChannel, { typeKey: "gateway", stageName: "出货" });
        } else if (writeTarget.type === "node") {
            //兼容2.0 在既没有groupId和versionId的情况下，通过typeKey获取hex
            if (!writeTarget.data.hexGroupId && !writeTarget.data.versionId) {
                //通过typeKey获取hex
                hexFile = await getHexFile(writeTarget.additional.infoChannel, { typeKey: writeTarget.data.typeKey, stageName: "出货" });
            } else {
                let condition: any = {};
                if (writeTarget.data.hexGroupId) {
                    condition.groupId = writeTarget.data.hexGroupId;
                    condition.stageName = '出货';
                } else if (writeTarget.data.hexVersionId) {
                    condition.versionId = writeTarget.data.hexVersionId;
                }
                hexFile = await getHexFile(writeTarget.additional.infoChannel, condition);
            }
        }
        // setHexFile(hexFile);
        console.log("get new hex file", hexFile?.fileName);
        burnRef.current?.setHexFile(hexFile)
    }

    const getHexFile = async (infoChannel: string, condition: any) => {
        const res = await request.get(apis.hex + `/file/smart`, {
            ...condition,
            infoChannel: infoChannel
        });
        if (res.code === 1) {
            return res.data;
        }
        return null;
    }

    const onNetOrNodeSelect = (type, data, additional) => {
        setWriteTarget({
            type,
            data,
            additional
        });
        burnRef.current?.onEscape();
        if (type === "finish") {
            rowRef.current?.showAll();
        }
    }

    const onBeforeScan = async (device: any) => {
        if (writeTarget.type === 'net') {
            if (device.spuId !== writeTarget.additional.productSpuId) {
                message.warn("方案产品与扫码产品不一致");
                return false;
            }
        } else if (writeTarget.type === 'node') {
            if (device.spuId !== writeTarget.data.productSpuId) {
                message.warn("方案产品与扫码产品不一致");
                return false;
            }
        } else if (writeTarget.type === 'loading' || writeTarget.type === "finish") {
            return false;
        }
        return true;
    }

    const onErase = async () => {
        if (writeTarget.type === "net") {
            await request.post(apis.caseNet + `/erase/hex-file`, { id: writeTarget.data.id })
        } else if (writeTarget.type === "node") {
            await request.post(apis.case + `/node/erase/hex-file`, { id: writeTarget.data.id })
        }
    }

    const centerRef = useRef<any>();
    const onWriteHexFinish = async (data: { device: any, hexFile: any }) => {
        if (writeTarget.type === "net") {
            request.post(apis.caseNet + `/fill-device-info`, {
                caseId: props.caseId,
                device: data.device,
                hexFile: data.hexFile
            })
                .then((res) => {
                    if (res.code === 1) {
                        writeTarget.data = res.data;
                        centerRef.current?.setWriteTarget({ ...writeTarget });
                    }
                });
        } else if (writeTarget.type === "node") {
            //与caseNode关联device
            request.post(apis.case + '/node/fill-device-info', {
                caseNodeId: writeTarget.data.id,
                device: data.device,
                hexFile: data.hexFile
            }).then((res) => {
                if (res.code === 1) {
                    writeTarget.data = {
                        ...writeTarget.data,
                        deviceCode: res.data.deviceCode,
                        mac: res.data.mac,
                        hexFileName: res.data.hexFileName
                    };
                    centerRef.current?.setWriteTarget({ ...writeTarget });
                }
            })
        }
    }

    const onStart = () => {
        rowRef.current?.setSelectable(false);
    }

    const onInterrupt = () => {
        rowRef.current?.setSelectable(true);
    }

    const onEnd = () => {
        if (writeTarget.type === "net") {
            rowRef.current?.updateCaseNet(writeTarget.data)
        } else if (writeTarget.type === "node") {
            rowRef.current?.updateCaseNode(writeTarget.data)
        }
        rowRef.current?.setSelectable(true);
        message.success("烧录完成");
    }

    return (
        <div className="flex">
            <div style={{ width: 200, height: `calc(100vh ${props.fullScreen ? '' : ' - 160px'})`, overflowY: 'scroll', overflowX: 'hidden' }} className="no-scroll-bar">
                <CaseList
                    customer={props.customer}
                    onCaseChange={(c: any) => {
                        if (c) {
                            setCase(c);
                        }
                        props.onCaseChange && props.onCaseChange(c);
                    }}
                    caseId={props.caseId}
                    ignoreTemplate={true}
                />
            </div>
            <div style={{ flex: 1 }}>
                <CaseNodesRow
                    ref={rowRef}
                    caseId={props.caseId}
                    onSelected={onNetOrNodeSelect}
                    style={{ width: props.fullScreen ? 'calc(100vw - 200px)' : undefined }}
                />
                <BurnTool
                    {...props}
                    ref={burnRef}
                    simple={true}
                    selectable={false}
                    style={{ height: `calc(100vh${props.fullScreen ? ' - 268px' : ' - 138px - 236px'})`, width: `calc(100vw${props.fullScreen ? '- 200px' : ' - 420px'})`, }}
                    onBeforeScan={onBeforeScan}
                    onStart={onStart}
                    onInterrupt={onInterrupt}
                    onErase={onErase}
                    onFinish={onWriteHexFinish}
                    onEnd={onEnd}
                >
                    <CenterView
                        ref={centerRef}
                        target={writeTarget}
                        onGoConfig={props.onGoConfig}
                    />
                </BurnTool>
            </div>
        </div>
    )

}

export default CaseNodeHexWrite;


const CenterView = forwardRef((props: { target: any, onGoConfig: () => void }, ref: any) => {

    const [writeTarget, setWriteTarget] = useState(props.target);

    useImperativeHandle(ref, () => {
        return {
            setWriteTarget
        }
    });

    useEffect(() => {
        setWriteTarget(props.target);
    }, [props.target])


    return (
        <div className="flex column center" style={{ height: "100%", width: "100%", color: "#000" }}>
            {
                writeTarget && (
                    <div className="relative" style={{left: -400, top: 10}}>
                        <div style={{fontSize: 16}}>烧录器：</div>
                     <img className="absolute" style={{width: 160}} src={"/assets/images/writer/" + writeTarget.data?.chipType + ".jpg"} alt="" />
                    </div>
                )
            }
            {
                writeTarget?.type === 'net' && writeTarget.additional && (
                    <div key={"net"} className="relative">
                        <div className="absolute bold" style={{ border: "5px solid lightgreen", fontSize: 16, color: "lightgreen", padding: 10, height: 75, left: 75, top: 130, zIndex: 111 }}>
                            网关部分
                        </div>
                        <TemplateComponent
                            templateKey={writeTarget.additional.templateKey}
                            spu={writeTarget.additional.spu}
                            silk1={writeTarget.additional.silkScreens[0]}
                            silk2={writeTarget.additional.silkScreens[1]}
                            silk3={writeTarget.additional.silkScreens[2]}
                            silk4={writeTarget.additional.silkScreens[3]}
                            silk5={writeTarget.additional.silkScreens[4]}
                            silk6={writeTarget.additional.silkScreens[5]}
                        />
                        <div style={{ marginTop: 5 }}>
                            <div style={{ color: writeTarget.data.deviceCode ? "green" : undefined, fontWeight: writeTarget.data.deviceCode ? "bold" : undefined }}>二维码：{writeTarget.data.deviceCode}</div>
                            <div style={{ color: writeTarget.data.mac ? "green" : undefined, fontWeight: writeTarget.data.mac ? "bold" : undefined }}>MAC地址：{writeTarget.data.mac}</div>
                            <div style={{ width: 240, overflowX: 'hidden', textOverflow: "ellipsis", color: writeTarget.data.hexFileName ? "green" : undefined, fontWeight: writeTarget.data.hexFileName ? "bold" : undefined }}>HEX文件：{writeTarget.data.hexFileName}</div>
                        </div>
                    </div>
                )
            }
            {
                writeTarget?.type === "node" && writeTarget.data && (
                    <div key={"node"}>
                        <TemplateComponent
                            templateKey={writeTarget.data.templateKey}
                            spu={writeTarget.data.spu}
                            silk1={writeTarget.data.silkScreens[0]}
                            silk2={writeTarget.data.silkScreens[1]}
                            silk3={writeTarget.data.silkScreens[2]}
                            silk4={writeTarget.data.silkScreens[3]}
                            silk5={writeTarget.data.silkScreens[4]}
                            silk6={writeTarget.data.silkScreens[5]}
                            imageUrl={writeTarget.data.spu?.imageUrl || getNodeImageUrlByTypeKey(writeTarget.data.typeKey)}
                        />
                        <div style={{ marginTop: 5 }}>
                            <div style={{ color: writeTarget.data.deviceCode ? "green" : undefined, fontWeight: writeTarget.data.deviceCode ? "bold" : undefined }}>二维码：{writeTarget.data.deviceCode}</div>
                            <div style={{ color: writeTarget.data.mac ? "green" : undefined, fontWeight: writeTarget.data.mac ? "bold" : undefined }}>MAC地址：{writeTarget.data.mac}</div>
                            <div style={{ width: 240, overflowX: 'hidden', color: writeTarget.data.hexFileName ? "green" : undefined, fontWeight: writeTarget.data.hexFileName ? "bold" : undefined }}>HEX文件：{writeTarget.data.hexFileName}</div>
                        </div>
                    </div>
                )
            }
            {
                writeTarget?.type === "loading" && (
                    <Skeleton active />
                )
            }
            {
                writeTarget?.type === 'finish' && (
                    <Result
                        status={"success"}
                        title="全部烧录完成"
                        extra={(
                            <Button type="link" onClick={props.onGoConfig}>去配置</Button>
                        )}
                    />
                )
            }
        </div>
    )
})