import { useEffect, useRef, useState } from 'react';
import { ControlProductCellWrapperProps } from './ControlProductCellWrapper';
export interface SwitchCellWrapper extends ControlProductCellWrapperProps {
    onSilkChange?: (number: number, position: string, cnText: string, endpoints: any[]) => void;
    backgroundColor?: string;
    color?: string;
}
interface SwitchProps {
    top?: { 
        number: number;
        cn?: string;
        en?: string;
        endpoints: any[];
    }

    bottom?: {
        number: number;
        cn?: string;
        en?: string;
        endpoints: any[];
    }
    border?: boolean;
    width?: number;
    onChange: (number: number, position: string, cnText: string, endpoints: string[]) => void;
    editable?: boolean;
    color?: string;
}
/**
 * 单列开关
 */
export function Switch(props: SwitchProps) {

    return (
        <div className="flex column" style={{ flex: 1, width: props.width, height: '100%', borderRight: props.border ? '1px solid #000' : undefined }}>
            <div className="flex column" style={{ flex: 1 }}>
                {
                    props.top ? (
                        <SilkScreen 
                            editable={props.editable}
                            position="top"
                            color={props.color}
                            text={props.top}  
                            onChange={(cnText: string) => {
                                props.onChange && props.onChange(props.top.number, "top", cnText, props.top.endpoints)
                            }}
                        />

                    ) : (
                        <div />
                    )
                }
            </div>
            <div className="flex column" style={{ flex: 1 }}>
                {
                    props.bottom ? (

                        <SilkScreen 
                            editable={props.editable}
                            text={props.bottom}
                            color={props.color}
                            position="bottom" 
                            onChange={(cnText: string) => {
                                props.onChange && props.onChange(props.bottom.number, "bottom", cnText, props.bottom.endpoints)
                            }}
                        />
                    ) : (
                        <div />
                    )
                }
            </div>
        </div>
    )
}


/**
 * 开关上的三点
 */
interface SilkScreenProps {
    position: "bottom" | "top"
    color?: string;
    size?: number;
    editable?: boolean;
    text?: {
        cn?: string;
        en?: string;
    };
    onChange?: (cnText: string) => void;
}
export function SilkScreen(props: SilkScreenProps) {

    const [size, setSize] = useState(props.size || 4);
    const [color, setColor] = useState(props.color || 'rgba(255, 255, 255, .7)');
    const [cnText, setCnText] = useState("");

    useEffect(() => {
        if (props.size !== undefined) {
            setSize(props.size);
        }
    }, [props.size]);

    useEffect(() => {
        setColor(props.color || 'rgba(255, 255, 255, .7)');
    }, [props.color]);

    useEffect(() => {
        setCnText(props.text?.cn || "");
    }, [props.text?.cn]);

    const [mode, setMode] = useState<"view" | "edit">("view");
    const [temp, setTemp] = useState("");

    const startEdit = () => {
        if(!props.editable) {
            return ;
        }
        setTemp(cnText);
        setMode("edit");
    }

    const endEdit = () => {
        if(temp === cnText) {
            setMode("view");
            return ;
        }
        props.onChange && props.onChange(temp);
        setCnText(temp);
        setMode("view");
        setTemp("");
    }
    const inputRef = useRef<any>();

    return (
        <div className="flex column" style={{ height: '100%', alignItems: 'center', textAlign: 'center', justifyContent: props.position === 'top' ? 'flex-start' : 'flex-end' }}>
            {
                props.text && props.position === "bottom" && (
                    <div style={{ marginBottom: 30 }}>
                        {
                            !cnText || mode === "edit" ? props.editable ? (
                                <input 
                                    ref={inputRef}
                                    placeholder='填丝印' 
                                    value={temp} 
                                    onBlur={endEdit} 
                                    autoFocus
                                    onChange={(e) => {
                                        setTemp(e.target.value);
                                    }}
                                    className='relative placeholder-white' 
                                    onKeyDown={(e) => {
                                        if(e.code === 'Enter') {
                                            endEdit();
                                        }
                                    }}
                                    style={{
                                        zIndex: 99, 
                                        backgroundColor: "rgba(0, 0, 0, .2)", 
                                        height: 16,
                                        padding: 0,
                                        margin: 0, 
                                        outline: "none", 
                                        border: 0,
                                        width: 72, 
                                        lineHeight: "16px", 
                                        textAlign: 'center', 
                                        color, 
                                        fontSize: 16, 
                                        fontFamily: "隶书",
                                    }}

                                />
                            ) : (<></>) : (
                                <div className='relative' title="点击进行编辑" style={{ zIndex: 99, marginBottom: 3, fontSize: 16, color: color, lineHeight: "16px", fontFamily: '隶书', whiteSpace: "nowrap", cursor: "pointer", textOverflow: "ellipsis" }} onClick={startEdit}>{cnText}</div>
                            )
                        }
                        {/* {
                            props.text.en && (
                                <div style={{ fontSize: 12, minWidth: 80, lineHeight: "12px", fontFamily: 'dubai', letterSpacing: '1px', transform: 'scale(0.7)', opacity: 0.9, color, whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{props.text.en}</div>
                            )
                        } */}
                    </div>
                )
            }   
            <div className="flex" style={{ width: size * 5.4, height: size, justifyContent: 'space-between', alignItems: 'center', paddingTop: props.position === "top" ? 20 : 0, paddingBottom: props.position === 'bottom' ? 20 : 0 }}>
                <div style={{ height: size, width: size, borderRadius: size, background: color }} />
                <div style={{ height: size, width: size, borderRadius: size, background: color }} />
                <div style={{ height: size, width: size, borderRadius: size, background: color }} />
            </div>
            {
                props.text && props.position === "top" && (
                    <div style={{ marginTop: 30 }}>
                        {
                            (!cnText || mode === "edit") ? props.editable ? (
                                <input 
                                    ref={inputRef}
                                    placeholder='填丝印' 
                                    value={temp} 
                                    onBlur={endEdit} 
                                    autoFocus={mode === 'edit'}
                                    onChange={(e) => {
                                        setTemp(e.target.value);
                                    }}
                                    className='relative placeholder-white' 
                                    onKeyDown={(e) => {
                                        if(e.code === 'Enter') {
                                            endEdit();
                                        }
                                    }}
                                    style={{
                                        zIndex: 99, 
                                        backgroundColor: "rgba(0, 0, 0, .2)", 
                                        padding: 0,
                                        margin: 0, 
                                        outline: "none", 
                                        height: 16,
                                        overflowY: 'hidden',
                                        border: 0,
                                        width: 72, 
                                        lineHeight: "16px", 
                                        textAlign: 'center', 
                                        color, 
                                        fontSize: 16, 
                                        fontFamily: "隶书",
                                    }}
                                />
                            ) : (<></>) : (  
                                <div className='relative' style={{zIndex: 99, marginBottom: 3, cursor: "pointer", fontSize: 16, color: color, lineHeight: "16px", fontFamily: '隶书', whiteSpace: "nowrap", textOverflow: "ellipsis" }} onClick={startEdit}>{cnText}</div>
                            )
                        }
                        {/* {
                            props.text.en && (
                                <div style={{ marginBottom: 30, fontSize: 12, lineHeight: "12px", fontFamily: 'dubai', letterSpacing: '1px', transform: 'scale(0.7)', opacity: 0.9, whiteSpace: "nowrap", textOverflow: "ellipsis", color }}>{props.text.en}</div>
                            )
                        } */}
                    </div>
                )
            }
        </div>
    )
}