import { Modal, Table, Tag } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'

interface Props {
    caseNodes: any[];
    visible: boolean;
    onCancel: () => void;
}

export const CaseControllableAppModal = (props: Props) => {

    const [targets, setTargets] = useState<any[]>([]);

    useEffect(() => {
        let targets: any[] = [];
        for (let i = 0; i < props.caseNodes.length; i++) {
            const node = props.caseNodes[i];
            for (let j = 0; j < node.apps.length; j++) {
                const app = node.apps[j];
                if(app.isWireless === 1) {
                    continue ;
                }
                if (app.type?.indexOf("target") > -1) {
                    const target = {
                            mac: node.mac,
                            caseNodeId: node.caseNodeId,
                            nodeName: node.name,
                            caseAreaId: node.caseAreaId,
                            caseAreaName: node.caseAreaName,
                            endpoint: app.endpoint,
                            appName: app.name,
                            appType: app.type,
                            appId: app.id,
                            isWireless: app.isWireless
                    }
                    targets.push(target);
                    
                }
            }
        }
        setTargets(targets);
    }, [props.caseNodes]);

    return (
        <Modal
            title="设备清单"
            centered
            visible={props.visible}
            onOk={props.onCancel}
            onCancel={props.onCancel}
            width={400}
        >
            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                <Table
                    pagination={false}
                    size={"small"}
                    columns={[{
                        title: "区域",
                        key: "area",
                        dataIndex: "caseAreaName",
                        render: (caseAreaName) => {
                            return (
                                <Tag>{caseAreaName}</Tag>
                            )
                        }
                    }, {
                        title: "名称",
                        dataIndex: "appName",
                        key: "name"
                    }, {
                        title: "长地址",
                        dataIndex: "mac",
                        key: "ieeeAddr",
                        render: (mac) => {
                            if(mac)
                                return parseInt("0x" + mac.split(' ').join(''), 16); 
                        }
                    }, {
                        title: "端口号",
                        dataIndex: "endpoint",
                        key: "endpoint"
                    }]}
                    dataSource={targets}
                />
            </div>
        </Modal>
    )
}