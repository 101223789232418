import { Alert, Button, Card, message, Modal, Steps, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { LeftOutlined, WarningFilled } from '@ant-design/icons';
import { useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { request } from '../../../utils/request';
import { apis } from '../../../utils/apis';
import { ScanWrapper } from '../components/ScanWrapper'

import '../Inbound2/style.less';

import { flashProgrammer } from './utils';
import { NODE_KEY_NAME_MAP } from "../../../utils/constants";
import { sleep } from "../../../utils";
const { Step } = Steps;

interface Props extends RouteChildrenProps {

}

let codeTemp = "";

const _plans = (plans: any[]) => {
    const finished = plans.filter((item) => item.totalProgress === item.totalPlan);
    const unfinished = plans.filter((item) => item.totalProgress !== item.totalPlan);
    if(unfinished.length > 0) {
        for (let i = 0; i < unfinished.length; i++) {
            const item = unfinished[i];
            if(i === 0) {
                item.isNew = true;
                item.isFirst = true;
            }else {
                item.isNew = false;
                item.isFirst = false;
            }
        }
    }
    return unfinished.concat(finished.map((item) => ({...item, isFinished: true})));
}

export const PageOnbound = (props: Props) => {


    const [scanDisabled, setScanDisabled] = useState(true);
    const [customers, setCustomers] = useState<any[]>([]);
    const [curCustomer, setCurCustomer] = useState<any>({});
    const [flashVisible, setFlashVisible] = useState(false);

    const [caseIds, setCaseIds] = useState<number[]>([]);

    const [flashProgress, setFlashProgress] = useState<number>(0);
    const [flashStep, setFlashStep] = useState<number>(0);
    const [flashErrorMessage, setFlashErrorMessage] = useState("");

    const [plans, setPlans] = useState<any[]>([]);
    const [curPlan, setCurPlan] = useState({} as any);

    const [curHex, setCurHex] = useState({} as any);

    const reset = () => {
        // setPlans([]);
        setCurPlan({});
        setCurHex({});
        setFlashProgress(0);
        setFlashStep(0);
        setCurCustomer({});
    }

    useEffect(() => {
        if (!curCustomer.id) {
            getOutboundCaseCustomer();
        }else {
            getPlans();
        }
    }, [curCustomer]);

    const getPlans = () => {
        request.get(apis.caseOutboundCustomerNodeList + `/${curCustomer.id}`)
            .then((res) => {
                if (res.code === 1) {
                    if (res.data.length > 0) {
                        setPlans(_plans(res.data));
                    }
                }
            })
    }

    useEffect(() => {
        if(plans.length > 0) {
            const curPlan = plans[0];
            if(!curPlan.isFinished) {
                setCurPlan(curPlan);
            }
        }
    }, [plans])

    useEffect(() => {
        if("typeKey" in curPlan && "infoChannel" in curPlan) {
            request.get(apis.hexSearchUuid, {
                infoChannel: curPlan.infoChannel,
                typeKey: curPlan.typeKey,
                stageName: "出货",
            }).then((res) => {
                if (res.code === 1) {
                    setCurHex(res.data);
                }
            });
        }
    }, [curPlan]);


    useEffect(() => {
        return () => {
            reset();
        }
    }, []);

    const getOutboundCaseCustomer = async () => {
        const res = await request.get(apis.caseCustomerOutboundList);
        if (res.code === 1) {
            setCustomers(res.data);
        }
    }


    useEffect(() => {
        if ("id" in curCustomer) {
            setScanDisabled(false);
        } else {
            setScanDisabled(true);
        }
    }, [curCustomer]);

    const outbound = async (code: string) => {
        codeTemp = code;
        if (!(window as any).inElectron) {
            message.info("该功能得在助手工具桌面端中才能使用")
            return;
        }
        setFlashErrorMessage("");
        setFlashVisible(true);
    }

    const check = async () => {
        setFlashProgress(0)
        setFlashErrorMessage("");
        let errorMsg  = "";
        const res = await request.post(apis.productDeviceCheckType + `/${codeTemp}`, {
            typeKey: curPlan.typeKey
        });
        if(res.code !== 1) {
            errorMsg = res.msg || res.message;
            return errorMsg;
        }
        return "ok";
    }
    
    const onHandleMac = async (mac: string) => {
        setFlashProgress(50);
        let errorMsg = "";
        //上报product
        const res1 = await request.post(apis.productDeviceOutbound + `/${codeTemp}`, {
            mac: mac,
            typeKey: curPlan.typeKey,
        });
        if (res1.code !== 1) {
            errorMsg = res1.msg || res1.message;
            return errorMsg;
        }
        const res2 = await request.post(apis.caseOutboundNodeReport, {
            typeKey: curPlan.typeKey,
            infoChannel: curPlan.infoChannel,
            customerId: curCustomer.id,
        });
        if(res2.code !== 1) {   
            errorMsg = res2.msg || res2.message;
            return errorMsg;
        }
        getPlans();
        setFlashProgress(100);
        return "ok";
    }

    const onReportHex = async (uuid: string) => {
        let errorMsg  = "";
        const res = await request.put(apis.productDeviceHexReport + `/${codeTemp}`, {
            hexUuid: uuid
        });
        if(res.code !== 1) {
            errorMsg = res.msg || res.message;
            return errorMsg;
        }
        return "ok";
    }


    return (
        <div className="page-outbound">
            <ScanWrapper placeholder="扫描二维码入库" disabled={scanDisabled} onEnter={outbound}>
                <Onbound
                    curPlan={curPlan}
                    plans={plans}
                    curHex={curHex}
                    flashErrorMessage={flashErrorMessage} customer={curCustomer} onClose={reset} />
                <Card style={{ border: 0 }} title="请选择要出货的客户">
                    {
                        customers.length > 0 && customers.map((c, index) => {
                            return (
                                <Card.Grid style={{ width: "50%", cursor: 'pointer', padding: 0 }} className={'flex ' + (curCustomer.id === c.id ? 'bg-primary' : '')} key={"key-" + index}>
                                    <div className="flex1" style={{ width: '100%', padding: 24 }} onClick={() => {
                                        setCurCustomer(c);
                                    }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 36, display: 'flex', alignItems: 'center' }}>
                                            <Tag color="blue">{c.type}</Tag>
                                            {c.name}</div>
                                        <div className="item">
                                            <span style={{ fontSize: 12, marginRight: 8, opacity: 0.5 }}>需烧录的方案数:</span>
                                            <span style={{ fontSize: 18 }}>{c.casesCount}</span>
                                        </div>
                                        <div className="item">
                                            <span style={{ fontSize: 12, marginRight: 8, opacity: 0.5 }}>总方案数:</span>
                                            <span style={{ fontSize: 18 }}>{c.totalCount}</span>
                                        </div>
                                        {
                                            c.isMultiplePlans && (
                                                <div className="item">
                                                    <span style={{ fontSize: 12, marginRight: 8, opacity: 0.5 }}>非首次提交</span>
                                                </div>

                                            )
                                        }
                                    </div>
                                </Card.Grid>
                            )
                        })
                    }
                </Card>
                <FlashModal
                    check={check}
                    onReportHex={onReportHex}
                    curHex={curHex} //当前烧录的hex
                    errorMessage={flashErrorMessage}
                    onReadMac={onHandleMac}
                    step={flashStep}
                    percent={flashProgress}
                    reportStep={setFlashStep}
                    reportPercent={setFlashProgress}
                    onInterrupt={(r) => {
                        setFlashErrorMessage(r);
                        setFlashProgress(0);
                    }}
                    visible={flashVisible}
                    onFlashFinish={() => {
                        setFlashVisible(false);
                        setFlashStep(0);
                        setFlashProgress(0)
                    }}
                    onClose={() => {
                        setFlashVisible(false);
                    }}
                />
            </ScanWrapper>
        </div>
    )

}

const Onbound = (props: {
    plans: any[],
    curPlan: any,
    curHex: any,
    flashErrorMessage: string,
    customer: any,
    // nodes: any[],
    // noMacNodes: any[],
    onClose: () => void,
}) => {

    return (
        <div className={"flex1 " + ("id" in props.customer ? 'flex' : 'none')} style={{ color: "#333", position: 'absolute', background: "#f2f2f2", zIndex: 9, top: 0, left: 0, width: '100vw', height: 'calc(100vh - 30px)' }}>
            <div style={{ marginRight: 10 , width: 120}} className="flex column">
                <div style={{ padding: '10px 12px', fontSize: 14, cursor: 'pointer', background: "#fff", marginBottom: 10 }} onClick={props.onClose}>
                    <LeftOutlined />
                    返回
                </div>
                <div className="flex1" style={{ backgroundColor: "#fff", padding: 12, marginBottom: 10 }}>
                    <div style={{ fontSize: 14 }}>当前客户: </div>
                    <div className="flex">
                        <div></div>
                        <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                            <Tag color="blue">{props.customer.type}</Tag>
                            {props.customer.name}
                        </div>
                    </div>
                    <div style={{fontSize: 16, marginTop: 50}}>
                        拿取一个 <span style={{fontWeight: 'bold', fontSize: 20, color: 'orangered'}}>{NODE_KEY_NAME_MAP[props.curPlan.typeKey]}</span>, <span style={{fontWeight: 'bold', fontSize: 20, color: 'blue'}}>先扫码</span>   
                    </div>
                    {/* <div className="flex">
                        <div>型号：</div>
                        <div></div>
                    </div>
                    <div className="flex">
                        <div>说明：</div>
                        <div></div>
                    </div> */}
                </div>
                {/* <div style={{ textAlign: 'center', padding: 12, background: "#fff" }}>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>{props.curHex.fileName}</div>
                    <div>当前：烧录文件</div>
                </div> */}
            </div>
            <div className="flex1 bg-white" style={{ overflowY: 'scroll' }}>
                <Table
                    dataSource={props.plans}
                    pagination={false}
                    style={{ background: "#fff" }}
                    rowClassName={(record) => {
                        if (!record) {
                            return;
                        }
                        let className = "";
                        if (record.isNew) {
                            className += "active "
                        }
                        if (record.isFirst) {
                            className += "first"
                        }
                        return className;
                    }}
                    columns={[/* {
                        title: "产品名称",
                        width: 200,
                        key: "caseName",
                        align: 'center',
                        dataIndex: "caseName",
                    } *//* , {
                        title: "区域",
                        width: 80,
                        key: "times",
                        align: 'center',
                        dataIndex: "areaName",
                    } */, {
                        title: "产品名称",
                        align: 'center',
                        // width: 200,
                        dataIndex: "typeKey",
                        key: "typeKey",
                        render: (typeKey) => {
                            
                            return NODE_KEY_NAME_MAP[typeKey] || typeKey
                        }
                    }, {
                        title: "信道",
                        width: 50,
                        dataIndex: "infoChannel",
                        key: "infoChannel"
                    }, {
                        title: "MAC地址",
                        width: 150,
                        key: "mac",
                        dataIndex: "mac",
                        render: (mac) => {
                            return (
                                <span>{mac || "未烧录"}</span>
                            )
                        }
                    }/* , {
                        key: "file",
                        title: "烧录文件",
                        render: (_, __, index) => {
                            if (index === 0) {
                                return props.curHex.fileName
                            }
                        }
                    } */, {
                        title: "已烧录数",
                        key: "totalProgress",
                        dataIndex: "totalProgress",
                        
                    }, {
                        title: "总数",
                        key: "totalPlan",
                        dataIndex: "totalPlan",
                        
                    }]}
                />
            </div>

        </div>
    )
}


export class FlashModal extends React.Component<{
    curHex: any,
    percent: number,
    step: number,
    check: () => Promise<string>,
    errorMessage: string,
    visible: boolean;
    onReadMac: (mac: string) => Promise<any>,
    onReportHex: (uuid: string) => Promise<string>,
    onFlashFinish: () => void,
    onInterrupt: (r: any) => void,
    reportStep: (step: number) => void,
    reportPercent: (percent: number) => void,   //组件内， flash时再调用
    onClose: () => void,
}, {
    loading: boolean;
}> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    isStop = false;

    stop() {
        this.isStop = true;
    }

    private start() {
        this.isStop = true;
    }

    lastError = "";

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                const onSpaceEnter = async (e) => {
                    if(e.keyCode === 32) {
                        window.removeEventListener("keydown", onSpaceEnter);
                        const res: any = await this.process();
                        if (!res) {
                            this.props.onInterrupt(this.lastError);
                        } else {
                            setTimeout(() => {
                                this.props.onFlashFinish();
                            }, 2000);
                        }
                    }
                }
                window.addEventListener("keydown", onSpaceEnter);
            }
        }
        if(prevProps.errorMessage !== this.props.errorMessage && this.props.errorMessage) {
            const onSpaceEnter = async (e) => {
                if(e.keyCode === 32) {
                    window.removeEventListener("keydown", onSpaceEnter);
                    const res: any = await this.process();
                    if (!res) {
                        this.props.onInterrupt(this.lastError);
                    } else {
                        setTimeout(() => {
                            this.props.onFlashFinish();
                        }, 2000);
                    }
                }
            }
            window.addEventListener("keydown", onSpaceEnter);
        }
    }

    async process() {
        if (this.isStop) {
            return;
        }
        
        await sleep(300);
        let cRes = await this.props.check();
        if(cRes !== 'ok') {
            this.lastError = cRes;
            return ;
        }
        let eRes = await this.erase();
        if (!eRes) {
            return;
        }
        await sleep(300);
        this.props.reportStep(1);
        if (this.isStop) {
            return;
        }
        let rRes = await this.readMac();
        if (!rRes) {
            return;
        }
        if (this.isStop) {
            return;
        }
        await sleep(300);
        this.props.reportStep(2);
        let fRes = await this.flash();
        if (!fRes) {
            return;
        }
        await sleep(300);
        this.props.reportStep(3);
        const _res = await this.props.onReportHex(this.props.curHex.uuid);
        if(_res !== "ok") {
            this.lastError = _res;
            return ;
        }
        const mRes = await this.props.onReadMac(rRes);
        if (mRes !== "ok") {
            this.lastError = mRes;
            return;
        }
        await sleep(300);
        this.props.reportStep(4);
        return mRes;
    }

    async readMac() {
        console.log("read mac");
        const READ_MAC_CMD = `C:/"Program Files (x86)"/"Texas Instruments"/"SmartRF Tools"/"Flash Programmer"/bin/SmartRFProgConsole.exe S RI(F=0) `;
        let lastLine = "";
        await flashProgrammer(READ_MAC_CMD, (message, type) => {
            if (type === 'stderr') {
                this.lastError = `操作中断, 注意烧录针插好、插稳(${message})"`;
                return ;
            } else {
                lastLine = message;
            }
        });
        if (lastLine.indexOf('IEEE address:') > -1) {
            return lastLine.slice(lastLine.length - 23, lastLine.length).split('.').join(" ");
        } else {
            return ;
        }
    }

    async erase() {
        const ERASE_CMD = `C:/"Program Files (x86)"/"Texas Instruments"/"SmartRF Tools"/"Flash Programmer"/bin/SmartRFProgConsole.exe S CE`;
        let lastLine = "";
        await flashProgrammer(ERASE_CMD, (message, type) => {
            if (type === 'stderr') {
                this.lastError = `操作中断, 注意烧录针插好、插稳(${message})"`;
                return ;
            } else {
                lastLine = message;
            }
        });
        if (lastLine.indexOf('Chip erased OK') > -1) {
            return "ok";
        } else {
            return ;
        }
    }

    async flash() {
        let percent = 0;
        const filePath = "D:\\hex-files\\core\\" + this.props.curHex.uuid + ".hex";
        const CMD = `C:/"Program Files (x86)"/"Texas Instruments"/"SmartRF Tools"/"Flash Programmer"/bin/SmartRFProgConsole.exe S EPV F="${filePath}" LD`;
        let lastLine = "";
        await flashProgrammer(CMD, (message, type) => {
            if (type === 'stderr') {
                this.lastError = `操作中断, 注意烧录针插好、插稳(${message})"`;
                return ;
            } else {
                if (message === '.') {
                    this.props.reportPercent(++percent)
                }
                lastLine = message;
            }
        }); 
        this.props.reportPercent(100);
        if (lastLine.indexOf('Erase, program and verify OK') > -1) {
            return "ok";
        } else {
            return ;
        }
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                title={(<span style={{fontSize: 20, color: 'yellow'}}>插好烧录针后, 按空格进行烧录</span>)}
                centered
                width={800}
                closable={false}
                maskClosable={false}
                // okButtonProps={{
                //     loading: this.state.loading
                // }}
                // okText={"烧录"}
                footer={this.props.errorMessage && (
                    <Button onClick={this.props.onClose}>关闭</Button>
                )}
            >
                <Steps current={this.props.step} size="default" percent={this.props.percent} status={this.props.errorMessage ? 'error' : this.props.step === 3 ? 'finish' : 'process'}>
                    <Step title="初始化"  /* description={this.props.step === 1 ? this.props.errorMessage : undefined} */ />
                    <Step title="MAC地址" /* description={this.props.step === 2 ? this.props.errorMessage : undefined} */ />
                    <Step title="烧录HEX" /* description={this.props.step === 3 ? this.props.errorMessage : undefined} */ />
                    <Step title="完成"    /* description={this.props.step === 4 ? this.props.errorMessage : undefined} */ />
                </Steps>
                {
                    this.props.errorMessage && (
                        <>
                            <Alert message={this.props.errorMessage} icon={(<WarningFilled style={{ color: 'yellow' }} />)} closable={false} type="error" style={{ marginTop: 20, background: 'orangered', color: "#fff", fontSize: 20, textAlign: 'center' }} />
                            <div style={{textAlign: 'center', marginTop: 10, color: 'yellow', fontSize: 20}}>处理好后, 按空格重试</div>
                        </>
                    )
                }
            </Modal>
        )
    }
}