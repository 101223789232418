import { Button, Checkbox, Col, Divider, Form, Input, message, Modal, Select, Table } from "antd"
import React, { useRef } from "react"
import { useState } from "react";
import { useEffect } from "react";
import { request } from '../../../../utils/request';
import { apis } from '../../../../utils/apis';
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import AddEndpointModal from "./AddEndpointModal";
import HexConfigEndpointEdit from "./HexConfigEndpointEdit";

interface Props {
    visible: boolean,
    onCancel: () => void,
    project: any,
    onRefresh: (projectId: number) => void;
}

const createFormRef = React.createRef<any>();
const firstFocusRef = React.createRef<any>();

const HexVersionCreateModal = (props: Props) => {

    useEffect(() => {
        if (props.visible) {
            setInitialData();
            setImmediate(() => {
                firstFocusRef.current?.focus();
            });
        } else {
            setInitialConfigMap({});
        }
    }, [props.visible]);

    useEffect(() => {
        resetData();
    }, [props.project]);

    const [selectedHexGroupIds, setSelecteHexGroupIds] = useState([]);
    const [initialConfigMap, setInitialConfigMap] = useState({} as any);


    const setInitialData = async () => {
        //设置初始化的配置信息
        const map: any = {};
        let hexGroupIds: number[] = [];
        if ("hexGroups" in props.project) {
            hexGroupIds = props.project.hexGroups.map((hexGroup: any) => hexGroup.id);
            const autoFillConfigs = await Promise.all(hexGroupIds.map((groupId) => {
                return request.get(apis.hex + `/config/latest`, { groupId })
                    .then((res) => {
                        if (res.code === 1) {
                            return res.data
                        }
                        return null;
                    })
            }));
            for (let i = 0; i < autoFillConfigs.length; i++) {
                const config = autoFillConfigs[i];
                map[hexGroupIds[i]] = config;
            }
        }
        if (!createFormRef.current) {
            return;
        }
        createFormRef.current.setFieldsValue({
            hexGroupIds: hexGroupIds
        });
        if ("id" in props.project) {
            createFormRef.current.setFieldsValue({
                projectId: props.project.id
            });
        }
        setInitialConfigMap(map);
        setSelecteHexGroupIds(hexGroupIds);
    }


    const resetData = () => {

        if (createFormRef.current) {
            createFormRef.current.setFieldsValue({
                versionName: "",
                description: "",
            });

        }
    }

    return (
        <Modal
            title="新建版本"
            centered
            visible={props.visible}
            onOk={() => createFormRef.current?.submit()}
            onCancel={props.onCancel}
            width={800}
            bodyStyle={{ height: window.innerHeight - 200, overflowY: 'auto' }}
            destroyOnClose
            maskClosable={false}
            keyboard={false}

        >
            <Form
                ref={createFormRef}
                labelCol={{ span: 5 }}
                onValuesChange={(changedValues) => {
                    if ("hexGroupIds" in changedValues) {
                        setSelecteHexGroupIds(changedValues['hexGroupIds']);
                    }
                }}
                onFinish={(data) => {
                    for (const key in data) {
                        if (key === "description") {
                            data["description-" + props.project.hexGroups[0].id] = data[key];
                            delete data[key];
                        }
                        if (key.indexOf("description-") === 0 && !data[key]) {
                            data[key] = "";
                        }
                        if (key.indexOf("hexConfig") === 0) {
                            data[key].endpoints = data[key].endpoints.map((endpoint) => {
                                return {
                                    ...endpoint,
                                    appType: endpoint.appType.key,
                                    bindType: endpoint.bindType.key,
                                }
                            })
                        }
                        if (key === "hexConfig") {
                            data['hexConfig-' + props.project.hexGroups[0].id] = data[key];
                            delete data[key];
                        }
                    }
                    request.post(apis.hexVersion, data)
                        .then((res) => {
                            if (res.code === 1) {
                                message.success("创建版本" + data.versionName + "成功");
                                if (createFormRef.current) {
                                    for (let i = 0; i < props.project.hexGroups.length; i++) {
                                        const hexGroup = props.project.hexGroups[i];
                                        createFormRef.current.setFieldsValue({
                                            ["description-" + hexGroup.id]: "",
                                        });
                                    }
                                }
                                props.onRefresh(props.project.id);
                                props.onCancel();
                            } else {
                                throw new Error(res.msg);
                            }
                        }).catch((e) => {
                            message.warn(e.message);
                        })
                }}
            >
                <Form.Item hidden name="projectId" />
                <Form.Item label="版本名" name="versionName" rules={[{ required: true, message: "请输入版本名" }]}>
                    <Input ref={firstFocusRef} placeholder="请输入版本名" autoComplete="off" />
                </Form.Item>
                <Form.Item
                    hidden={props.project.hexGroups?.length < 2}
                    help="(可多选)"
                    style={{ marginBottom: 10 }}
                    name="hexGroupIds"
                    label="请选择分组"
                    rules={[{ required: true, message: "请选择分组" }]}
                >
                    <Select style={{ minWidth: 100 }} mode="multiple">
                        {
                            props.project.hexGroups?.length > 0 && props.project.hexGroups.map((hexGroup: any, index: number) => {
                                return (
                                    <Select.Option value={hexGroup.id} key={"hex-" + index}>
                                        {hexGroup.name}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                {
                    props.project.hexGroups && (
                        props.project.hexGroups.length < 2 ? (
                            <Form.Item label="版本说明" name="description">
                                <Input.TextArea placeholder={"请说明此版本"} />
                            </Form.Item>
                        ) : selectedHexGroupIds.map((id, index) => {
                            return (
                                <Form.Item key={"description-" + index} label={props.project.hexGroups[index].name + "版本说明"} name={"description-" + id}>
                                    <Input.TextArea placeholder={"请说明" + props.project.hexGroups[index].name + "的此版本"} />
                                </Form.Item>
                            )
                        })
                    )
                }
                <Divider>配置相关</Divider>
                {
                    props.project.hexGroups && (
                        props.project.hexGroups.length < 2 ? (
                            <Form.Item name="hexConfig" rules={[{ required: true, message: "请完善配置信息" }]}>
                                <HexVersionConfig
                                    initialConfig={initialConfigMap[selectedHexGroupIds[0]]}
                                />
                            </Form.Item>
                        ) : props.project.hexGroups.map((group, index: number) => {
                            return (
                                <Form.Item name={"hexConfig" + index} rules={[{ required: true, message: "请完善配置信息" }]}>
                                    <HexVersionConfig
                                        initialConfig={initialConfigMap[group.id]}

                                    />
                                </Form.Item>
                            )
                        })
                    )
                }
            </Form>
        </Modal>
    )
}

export default HexVersionCreateModal;

interface HexVersionConfigProps {
    initialConfig: any;
    value?: {
        endpoints: any[];
        bindMethod: string;
        groupMethod: string;
        sceneMethod: string;
        supports: string[],
    },
    onChange?: (data: {
        endpoints: any[];
        bindMethod: string;
        groupMethod: string;
        sceneMethod: string;
        supports: string[];
    }) => void
}
function HexVersionConfig(props: HexVersionConfigProps) {

    const addEndpointRef = useRef<any>();


    useEffect(() => {
        getConfigData()
    }, []);


    const [bindMethods, setBindMethods] = useState<any[]>([]);
    const [groupMethods, setGroupMethods] = useState<any[]>([]);
    const [sceneMethods, setSceneMethods] = useState<any[]>([]);
    const [availableSupports, setAvailableSupports] = useState<any[]>([]);
    const getConfigData = async () => {
        const [res1, res2, res3, res4] = await Promise.all([
            request.get(apis.case + `/node/bind/methods`),
            request.get(apis.case + `/node/group/methods`),
            request.get(apis.case + `/node/scene/methods`),
            request.get(apis.case + `/node/supports`)
        ]);
        setBindMethods(res1.data);
        setGroupMethods(res2.data);
        setSceneMethods(res3.data);
        setAvailableSupports(res4.data.map((item) => ({ label: item.name, value: item.key })));
    }

    const [endpoints, setEndpoints] = useState((props.value?.endpoints || props.initialConfig?.endpoints || []) as any[]);
    const [bindMethod, setBindMethod] = useState(props.value?.bindMethod || props.initialConfig?.bindMethod?.key || "");
    const [groupMethod, setGroupMethod] = useState(props.value?.groupMethod || props.initialConfig?.groupMethod?.key || "");
    const [sceneMethod, setSceneMethod] = useState(props.value?.sceneMethod || props.initialConfig?.sceneMethod?.key || "");
    const [supports, setSupports] = useState<any[]>(props.value?.supports || props.initialConfig?.supports.map((s: any) => s.key) || []);

    const onEndpointsChange = (_endpoints: any[]) => {
        if (_endpoints.length === 0 && endpoints.length === 0) {
            return;
        }
        setEndpoints(_endpoints);
        props.onChange && props.onChange({
            endpoints: _endpoints,
            bindMethod,
            groupMethod,
            sceneMethod,
            supports
        });
    }

    const onBindMethodChange = (bindMethod: string) => {
        setBindMethod(bindMethod);
        props.onChange && props.onChange({
            endpoints,
            bindMethod,
            groupMethod,
            sceneMethod,
            supports
        });
    }

    const onGroupMethodChange = (groupMethod: string) => {
        setGroupMethod(groupMethod);
        props.onChange && props.onChange({
            endpoints,
            bindMethod,
            groupMethod,
            sceneMethod,
            supports
        });
    }

    const onSceneMethodChange = (sceneMethod: string) => {
        setSceneMethod(sceneMethod);
        props.onChange && props.onChange({
            endpoints,
            bindMethod,
            groupMethod,
            sceneMethod,
            supports
        });
    }

    const onSupportsChange = (supports: string[]) => {
        setSupports(supports);
        props.onChange && props.onChange({
            endpoints,
            bindMethod,
            groupMethod,
            sceneMethod,
            supports
        });
    }

    useEffect(() => {
        if (!props.initialConfig) {
            onEndpointsChange([]);
            onBindMethodChange("");
            onGroupMethodChange("");
            onSceneMethodChange("");
            onSupportsChange([]);
            return;
        }
        setEndpoints(props.initialConfig.endpoints);
        setBindMethod(props.initialConfig.bindMethod?.key);
        setGroupMethod(props.initialConfig.groupMethod?.key);
        setSceneMethod(props.initialConfig.sceneMethod?.key);
        setSupports(props.initialConfig.supports.map((s: any) => s.key));
        props.onChange && props.onChange({
            endpoints: props.initialConfig.endpoints,
            bindMethod: props.initialConfig.bindMethod?.key,
            groupMethod: props.initialConfig.groupMethod?.key,
            sceneMethod: props.initialConfig.sceneMethod?.key,
            supports: props.initialConfig.supports.map((s: any) => s.key)
        });
    }, [props.initialConfig]);

    const editEndpointRef = useRef<any>();

    return (
        <div>
            <HexConfigEndpointEdit
                ref={editEndpointRef}
                onEndpointChange={async (endpoint) => {
                    /* 以endpointId作为index */
                    if (Number(endpoints[endpoint.index].endpoint) !== Number(endpoint.endpoint)) {
                        const exists = endpoints.find((e) => Number(e.endpoint) === Number(endpoint.endpoint));
                        if (exists) {
                            message.info("已存在相同的endpoint");
                            return false;
                        }
                    }
                    endpoints[endpoint.index] = endpoint;
                    endpoints.sort((a, b) => Number(a.endpoint) > Number(b.endpoint) ? 1 : -1);
                    onEndpointsChange([...endpoints]);
                    return true;
                }}
            />
            <Col offset={2} span={24}>
                <Form.Item label="Endpoint设置">
                    <Table
                        size="small"
                        pagination={false}
                        dataSource={endpoints}
                        style={{ minHeight: 100 }}
                        columns={[{
                            title: "E",
                            key: "endpoint",
                            width: 30,
                            dataIndex: "endpoint",
                            align: 'center'
                        }, {
                            title: "应用",
                            key: "appType",
                            width: 100,
                            dataIndex: "appType",
                            render: (appType: any) => {
                                return appType.name;
                            }
                        }, {
                            title: "绑定",
                            key: "bindType",
                            dataIndex: "bindType",
                            render: (bindType: any) => {
                                return bindType.name;
                            }
                        }, {
                            title: "控制线",
                            key: "requireControlLine",
                            dataIndex: "requireControlLine",
                            render: (requireControlLine: 0 | 1) => {
                                return requireControlLine ? "控制线" : ""
                            }
                        }, {
                            align: 'center',
                            title: "通信",
                            dataIndex: "opened",
                            key: "opened",
                            width: 50,
                            render: (opened: any) => {
                                return Boolean(opened) && (
                                    <span style={{ color: "green" }}>可通信</span>
                                )
                            }
                        }, {
                            title: "",
                            key: 'action',
                            width: 140,
                            render: (_, endpoint: any, index) => {
                                return (
                                    <>
                                        <Button type="link" onClick={() => {
                                            editEndpointRef.current.open({
                                                ...endpoint,
                                                index: index,
                                                appType: endpoint.appType.key,
                                                bindType: endpoint.bindType.key
                                            });
                                        }}>
                                            <EditOutlined />
                                            编辑
                                        </Button>
                                        <Button type="link" danger onClick={() => {
                                            const _endpoints = endpoints.filter((_, idx) => index !== idx);
                                            onEndpointsChange(_endpoints);
                                        }}>
                                            <DeleteOutlined />
                                            删除
                                        </Button>
                                    </>
                                )
                            }
                        }]}
                        footer={() => (
                            <div style={{ textAlign: 'right' }}>
                                <Button type="link" onClick={() => {
                                    addEndpointRef.current?.open();
                                }}>
                                    <PlusOutlined />
                                    添加endpoint
                                </Button>
                                <AddEndpointModal
                                    ref={addEndpointRef}
                                    onEndpointAdd={async (endpoint: any) => {
                                        endpoints.push(endpoint);
                                        endpoints.sort((a, b) => Number(a.endpoint) > Number(b.endpoint) ? 1 : -1)
                                        onEndpointsChange([...endpoints]);
                                        return true;
                                    }}
                                />
                            </div>
                        )}
                    />
                </Form.Item>
                <Form.Item label="配置方法-绑定" style={{ marginRight: 20 }}>
                    <Select
                        style={{ width: 300 }}
                        value={bindMethod}
                        onChange={(bindMethod) => {
                            onBindMethodChange(bindMethod);
                        }}
                    >
                        {
                            bindMethods.map((bm) => {
                                return (
                                    <Select.Option key={bm.key} value={bm.key}>{bm.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="配置方法-建组" style={{ marginRight: 20 }}>
                    <Select
                        style={{ width: 300 }}
                        value={groupMethod}
                        onChange={(value) => {
                            onGroupMethodChange(value);
                        }}
                    >
                        {
                            groupMethods.map((bm) => {
                                return (
                                    <Select.Option key={bm.key} value={bm.key}>{bm.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="配置方法-建场景" style={{ marginRight: 20 }}>
                    <Select
                        style={{ width: 300 }}
                        value={sceneMethod}
                        onChange={(value) => {
                            onSceneMethodChange(value);
                        }}
                    >
                        {
                            sceneMethods.map((sm) => {
                                return (
                                    <Select.Option key={sm.key} value={sm.key}>{sm.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="支持功能">
                    <Checkbox.Group
                        value={supports}
                        onChange={(values: string[]) => {
                            onSupportsChange(values || []);
                        }}
                        options={availableSupports}
                    />
                </Form.Item>
            </Col>
        </div>
    )

}

