import { Card, message, Modal, Popover, Table } from "antd";
import { useEffect } from "react";
import { LeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { request } from '../../../utils/request';
import { apis } from '../../../utils/apis';
import { ScanWrapper } from '../components/ScanWrapper'

import './style.less';
import dayjs from "dayjs";


interface  Props extends RouteChildrenProps {

}

export const PageInbound2 = (props: Props) => {


    const [scanDisabled, setScanDisabled] = useState(true);
    const [spus, setSpus] = useState<any[]>([]);
    const [curSpu, setCurSpu] = useState<any>({});
    const [list, setList] = useState<any[]>([]);
    
    useEffect(() => {
        getSpus();
        return () => {
            setList([]);
        }
    }, []);
    
    const getSpus = async () => {
        const res = await request.get(apis.productSpu, { isSmart: 1 });
        if(res.code === 1) {
            setSpus(res.data);
        }
    }

    useEffect(() => {
        if(list.length > 0) {
            if(list[0].isNew) {
                setTimeout(() => {
                    list[0].isNew = false;
                    setList([...list]);
                }, 800);
            }
        }
    }, [list]);

    useEffect(() => {
        if("spuId" in curSpu) {
            setScanDisabled(false);
        }else {
            setScanDisabled(true);
        }
    }, [curSpu]);

    const inbound = async (code: string) => {
        const res = await request.post(apis.productDeviceInbound + `/${code}`, {
            spuId: curSpu.spuId
        });
        if(res.code === 1) {
            const { data } = res;
            if(list.length > 0) {
                list[0].isNew = false;
                list[0].isFirst = false;
            }
            list.unshift({
                code: data.deviceCode,
                times: data.inboundTimes,
                gmtInbound: new Date(data.lastBurnTime).toString(),
                isNew: true,
                isFirst: true
            });
            setList([...list]);
        }else {
            message.warn(res.msg || res.message);
        }
    }


    return (
        <div className="page-inbound2">
            <ScanWrapper placeholder="扫描二维码入库" disabled={scanDisabled} onEnter={inbound}>
                <Inbound spu={curSpu} inboundList={list} onClose={() => {
                    setCurSpu({});
                    setList([]);  
                }}/>
                <Card style={{border: 0}} title="请选择要入库的SPU">
                    {
                        spus.length > 0 && spus.map((spu, index) => {
                        return (
                            <Card.Grid style={{width: "50%",  cursor: 'pointer', padding: 0}} className={'flex ' + (curSpu.spuId === spu.spuId ? 'bg-primary' : '')} key={"key-" + index}>
                                <div className="flex1" style={{ width: '100%', padding: 24}} onClick={() => {
                                    setCurSpu(spu);
                                }}> 
                                    <div style={{fontWeight: 'bold', fontSize: 36}}>{spu.spuName}</div>
                                    <div className="item">
                                        <span style={{fontSize: 12, marginRight: 8, opacity: 0.5}}>型号:</span>
                                        <span style={{fontSize: 18}}>{spu.spuMode}</span>
                                    </div>
                                    <div style={{height: 50}}>
                                        <Popover content={spu.spuDesc || "无描述"}>
                                        <span style={{fontSize: 12, marginRight: 8, opacity: 0.5}}>说明:</span>
                                            {spu.spuDesc}
                                        </Popover>
                                    </div>
                                </div>
                            </Card.Grid>
                        )
                    })
                }
                </Card>
            </ScanWrapper>
        </div>
    )
}

const Inbound = (props: {
    spu: any,
    inboundList: any[],
    onClose: () => void,
}) => {
    return (
        <div className={"flex1 " + ("spuId" in props.spu ? 'flex' :  'none')} style={{color: "#333", position: 'absolute', background: "#f2f2f2", zIndex: 9, top: 0, left: 0, width: '100vw', height: 'calc(100vh - 30px)'}}>
            <div style={{marginRight: 10}} className="flex column">
                <div style={{padding: '10px 12px', fontSize: 14, cursor: 'pointer', background: "#fff", marginBottom: 10}} onClick={() => {
                    if(props.inboundList.length > 0) {

                        Modal.confirm({
                            centered: true,
                            title: "这一批"  + props.spu?.spuName + "共入库" + props.inboundList.length + "个, 是否返回?",
                            onOk: props.onClose,
                            okText: "确认返回",
                            cancelText: "继续入库"
                            
                        });
                    }else {
                        props.onClose();
                    }
                }}>
                    <LeftOutlined />
                    返回
                </div>
                <div className="flex1" style={{backgroundColor: "#fff", padding: 12, marginBottom: 10}}>
                    <div style={{fontSize: 14}}>当前入库SPU: </div>
                    <div className="flex">
                        <div></div>
                        <div style={{fontWeight: 'bold', fontSize: 18}}>{props.spu.spuName}</div>
                    </div>
                    <div className="flex">
                        <div>型号：</div>
                        <div>{props.spu.spuMode}</div>
                    </div>
                    <div className="flex">
                        <div>说明：</div>
                        <div>{props.spu.spuDesc}</div>
                    </div>
                </div>
                <div style={{textAlign: 'center', padding: 12, background: "#fff"}}>
                    <div style={{fontSize: 36, fontWeight: 'bold'}}>{props.inboundList.length}</div>
                    <div>本次入库统计</div>
                </div>
            </div>
            <div className="flex1 bg-white" style={{overflowY: 'scroll'}}>
                <Table
                    dataSource={props.inboundList}
                    pagination={false}
                    style={{background: "#fff"}}
                    rowClassName={(record) => {
                        if(!record) {
                            return ;
                        }
                        let className = "";
                        if(record.isNew) {
                            className += "active "
                        }
                        if(record.isFirst) {
                            className += "first"
                        }
                        return className;
                        // if(record?.id === currentDevice?.id) {
                        //     return "active"
                        // }else if(record?.status === 2) {
                        //     return "inbound"
                        // }
                    }}  
                    columns={[{
                        title: "二维码",
                        key: "code",
                        align: 'center',
                        dataIndex: "code",
                    }, {
                        title: "入库次数",
                        key: "times",
                        align: 'center',
                        dataIndex: "times",
                        render: (times) => {
                            return (
                                <span>{times === 1 ? '首次入库' : times}</span>
                            )
                        }
                    }, {
                        title: "入库时间",
                        align: 'center',
                        key: "gmtInbound",
                        dataIndex: "gmtInbound",
                        render: (gmtInbound) => {
                            return dayjs(gmtInbound).format('YY-MM-DD HH:mm:ss')
                        }
                    }]}
                />
            </div>
        </div>
    )
}
