import { CurtainControlDoubleNodeCell } from "../CurtainControlDoubleNodeCell";
import { CurtainControlNodeCell } from "../CurtainControlNodeCell";
import { CurtainMotorNodeCell } from "../CurtainMotorNodeCell";
import { DimmableDownlightNodeCell } from "../DimmableDownlightNodeCell";
import { FourButtonSceneAndSpeakerNodeCell } from "../FourButtonSceneAndSpeakerNodeCell";
import { FourButtonSwitchNodeCell } from "../FourButtonSwitchNodeCell";
import { FourTouchSwitchNodeCell } from "../FourTouchSwitchNodeCell";
import { IRHumanSensorNodeCell } from "../IRHumanSensorNodeCell";
import { OfflineSpeakerNodeCell } from "../OfflineSpeakerNodeCell";
import { OneButtonSwitchNodeCell } from "../OneButtonSwitchNodeCell";
import { OneTouchSwitchNodeCell } from "../OneTouchSwitchNodeCell";
import { Plug_inCardPowerNodeCell } from "../Plug_inCardPowerNodeCell";
import { Plug_inCardPowerNodeCell2 } from "../Plug_inCardPowerNodeCell2";
import { RGBLightStripNodeCell } from "../RGBLightStripNodeCell";
import { SmartControlNodeCell } from "../SmartControlNodeCell";
import { ThreeButtonSceneNodeCell } from "../ThreeButtonSceneNodeCell";
import { ThreeButtonSwitchNodeCell } from "../ThreeButtonSwitchNodeCell";
import { ThreeTouchSwitchNodeCell } from "../ThreeTouchSwitchNodeCell";
import { TwoButtonSwitchNodeCell } from "../TwoButtonSwitchNodeCell";
import { TwoTouchSwitchNodeCell } from "../TwoTouchSwitchNodeCell";
import { FourButtonSwitchNodeCell_v2 } from "../v2/FourButtonSwitchNodeCell_v2";
import { OneButtonSwitchNodeCell_v2 } from "../v2/OneButtonSwitchNodeCell_v2";
import { ThreeButtonSwitchNodeCell_v2 } from "../v2/ThreeButtonSwitchNodeCell_v2";
import { TwoButtonSwitchNodeCell_v2 } from "../v2/TwoButtonSwitchNodeCell_v2";

interface Props {

    caseNode: any,
    

}
export default function NodeCell(props: Props) {

    const { caseNode } = props;
    
    let NodeCell: any = null;
    switch (caseNode.typeKey) {
        case "plug-in_card_power":
            NodeCell = Plug_inCardPowerNodeCell;
            break;
        case "plug-in_card_power_v2":
            NodeCell = Plug_inCardPowerNodeCell2;
            break;
        case "general_ir_human_sensor":
        case "sub_ir_human_sensor":
            NodeCell = IRHumanSensorNodeCell;
            break;
        case "scene_and_speaker":
            NodeCell = FourButtonSceneAndSpeakerNodeCell;
            break;
        case "curtain_control":
        case "curtain_control_battery":
            NodeCell = CurtainControlNodeCell;
            break;
        case "curtain_control_double":
            NodeCell = CurtainControlDoubleNodeCell;
            break;
        case "curtain_motor":
            NodeCell = CurtainMotorNodeCell;
            break;
        case "smart_control":
        case "smart_socket":
        case "universal_ir_remote_control":
            NodeCell = SmartControlNodeCell;
            break;
        case "dimmable_downlight":
        case "color_temp_dimmable_downlight":
            NodeCell = DimmableDownlightNodeCell;
            break;
        case "rgb_light_strip":
            NodeCell = RGBLightStripNodeCell;
            break;
        case "three_button_scene":
            NodeCell = ThreeButtonSceneNodeCell;
            break;
        case "four_button_switch":
            NodeCell = FourButtonSwitchNodeCell;
            break;
        case "three_button_switch":
            NodeCell = ThreeButtonSwitchNodeCell;
            break;
        case "two_button_switch":
            NodeCell = TwoButtonSwitchNodeCell;
            break;
        case "one_button_switch":
            NodeCell = OneButtonSwitchNodeCell;
            break;
        case "four_touch_switch":
            NodeCell = FourTouchSwitchNodeCell;
            break;
        case "three_touch_switch":
            NodeCell = ThreeTouchSwitchNodeCell;
            break;
        case "two_touch_switch":
            NodeCell = TwoTouchSwitchNodeCell;
            break;
        case "single_knob_switch":
        case "double_knob_switch":
        case "one_touch_switch":
            NodeCell = OneTouchSwitchNodeCell;
            break;
        case "offline_speaker":
            NodeCell = OfflineSpeakerNodeCell;
            break;
        case "four_button_switch_4_4":
        case "four_button_switch_4_3":
        case "four_button_switch_4_2":
        case "four_button_switch_4_1":
        case "four_button_switch_4_0":
            NodeCell = FourButtonSwitchNodeCell_v2;
            break;
        case "three_button_switch_3_3":
        case "three_button_switch_3_2":
        case "three_button_switch_3_1":
        case "three_button_switch_3_0":
            NodeCell = ThreeButtonSwitchNodeCell_v2;
            break;
        case "two_button_switch_2_2":
        case "two_button_switch_2_1":
        case "two_button_switch_2_0":
            NodeCell = TwoButtonSwitchNodeCell_v2;
            break;
        case "one_button_switch_1_1":
        case "one_button_switch_1_0":
            NodeCell = OneButtonSwitchNodeCell_v2;
            break;
        default:
            return (
                <div>
                    -- {caseNode.name} {caseNode.typeKey} --
                </div>
            )
    }
    return (
        <div style={{display: 'inline-block', verticalAlign: 'top'}}>
            <NodeCell
                node={caseNode}
                
            />

        </div>
    )
}