import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Input } from "antd"
import React, { useEffect, useState } from "react"
import { List as IList, List } from 'immutable';

interface Props {
    value?: any[],
    onChange?: (arg: any) => void
}

export const GroupInput = (props: Props) => {

    const [groups, setGroups] = useState<IList<any>>(IList());
    

    useEffect(() => {
        setGroups(IList(props.value || []));
    }, [props.value]);


    return (
        <div className="group-input">
            {
                props.value? props.value.length > 0 && props.value.map((group, index) => {
                    return (
                        <div className="group-item" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16}}>
                            <Input placeholder="请输入分组名称" value={group.name} onChange={(e) => {
                                const g = groups.get(index);
                                g.name = e.target.value;
                                const temp = groups.set(index, {...g});
                                if(typeof props.onChange === 'function') {
                                    props.onChange(temp.toJS());
                                }
                            }}/>
                            <MinusCircleOutlined 
                                style={{fontSize: 24, margin: '0 8px', color: '#999', cursor: 'pointer'}}
                                onClick={() => {
                                    const temp = groups.delete(index);
                                    if(typeof props.onChange === 'function') {
                                        props.onChange(temp.toJS());
                                    }
                                }}
                            />
                            
                        </div>
                    )
                }) : (
                    <></>
                )
            }
            <Button
                type="dashed"
                onClick={() => {
                    if(typeof props.onChange === 'function') {
                        const temp = groups.push({
                            name: ""
                        })
                        props.onChange(temp.toJS());
                    }
                }}
                icon={<PlusOutlined />}
            >
                添加分组
            </Button>
        </div>
    )

}